import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import ElementPrint from "../components/Elements/ElementPrint";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import OfferHeader from "../components/Offers/OfferHeader/OfferHeader";
import Spinner from "../components/ui/Spinner/Spinner";
import { ErrorResponse } from "../custom-fetch";
import { useElementsService } from "../services/elements-service";
import { QueryKey } from "../services/query-keys";
import IElementDTO from "../shared/IElementDTO";
import { printWithOrientation } from "../utils/print-utils";
import { useEffect, useState } from "react";

const ProductionLineElementsPage: React.FC = () => {
  const { id } = useParams();
  const { fetchProductionLineElements } = useElementsService();

  const {
    data: elements,
    isLoading,
    isError,
    error,
  } = useQuery<IElementDTO[], ErrorResponse>({
    queryKey: [QueryKey.elements, id, "print"],
    queryFn: ({ signal }) =>
      fetchProductionLineElements({ signal, productionLineId: id }),
    staleTime: 5000,
  });

  const [printOpened, setPrintOpened] = useState(false);
  useEffect(() => {
    if (elements && !printOpened) {
      setPrintOpened(true);
      setTimeout(() => {
        printWithOrientation("landscape")
      }, 100);
    }
  }, [elements, printOpened]);

  return (
    <>
      {isError ? (
        <ErrorsAlert errors={error.messages} />
      ) : isLoading ? (
        <Spinner />
      ) : (
        <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
          <OfferHeader
            onSubmit={() => printWithOrientation("landscape")}
            submitText="Tulosta"
            isLoading={isLoading}
            title="Tulosta"
          />
          {elements &&
            elements.map((element) => (
              <ElementPrint element={element} key={element.id} />
            ))}
        </div>
      )}
    </>
  );
};

export default ProductionLineElementsPage;
