import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import ITool from '../shared/ITool';
import IAuditHistory, { AuditHistoryType } from '../shared/IAuditHistory';

export interface IFetchTools extends ISignal {
  factory?: string;
  waybillDeliveryDate?: string;
}

interface IGetTool extends ISignal {
  id: string;
}

export const useToolsService = () => {
  const customFetch = useCustomFetch();

  const fetchTools = useCallback(async ({ signal, factory }: IFetchTools) => {
    let url = "/api/tools/list";
    if (factory) url += `/${factory}`;
    const [tools] = await customFetch<ITool[]>(url, { signal });
    return tools;
  }, [customFetch]);
  
  const getTool = useCallback(async ({ signal, id }: IGetTool) => {
    const [tool] = await customFetch<ITool>("/api/tools/get/" + id, { signal });
    return tool;
  }, [customFetch]);

  const saveTool = useCallback(async (data: ITool) => {
    const [tool] = await customFetch<ITool>("/api/tools/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return tool;
  }, [customFetch]);
  
  const updateTool = useCallback(async (id: string, data: ITool) => {
    const [tool] = await customFetch<ITool>("/api/tools/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return tool;
  }, [customFetch]);
  
  const deleteTool = useCallback(async (id: string) => {
    await customFetch<ITool>("/api/tools/delete/" + id, { method: "DELETE" });
    return true;
  }, [customFetch]);

  const saveToolAuditHistory = useCallback(async (type: AuditHistoryType, id: string, data: IAuditHistory, file: File | null) => {
    const formData = new FormData();
    if (file) formData.append("file", file);
    formData.append("text", data.text);
    formData.append("date", data.date);
    formData.append("accepted", data.accepted);
    formData.append("kind", type);
    const [tool] = await customFetch<ITool>("/api/tools/audit-histories/add/" + id, {
      method: "PUT",
      body: formData,
      multipart: true,
    });
    return tool;
  }, [customFetch]);

  const deleteToolAuditHistory = useCallback(async (type: AuditHistoryType, id: string, auditHistoryId: string) => {
    await customFetch<ITool>(`/api/tools/audit-histories/delete/${id}?kind=${type}&auditHistoryId=${auditHistoryId}`, { method: "DELETE" });
    return true;
  }, [customFetch]);
  
  return { fetchTools, getTool, saveTool, updateTool, deleteTool, saveToolAuditHistory, deleteToolAuditHistory };
}
