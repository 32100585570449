import IWarehouseBalanceReportDTO from "../../shared/IWarehouseBalanceReportDTO";
import Table from "../ui/Table/Table";

interface IProps {
  warehouseBalanceReports: IWarehouseBalanceReportDTO[];
}

const WarehouseBalanceReportsTable: React.FC<IProps> = ({
  warehouseBalanceReports,
}) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Tehdas</th>
          <th>kpl</th>
          <th>jm</th>
          <th>m²</th>
          <th>Tonnit</th>
        </tr>
      </thead>
      <tbody>
        {warehouseBalanceReports.map((report) => (
          <tr key={report.factory}>
            <td>{report.factory}</td>
            <td>{report.size}</td>
            <td>{report.lengthInMeters}</td>
            <td>{report.area}</td>
            <td>{report.weightTons}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default WarehouseBalanceReportsTable;
