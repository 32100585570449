import { ReactNode } from "react";
import Button from "../Button/Button";
import { EModalId, EModalSize, useModalContext } from "./modal-context";
import ModalBody from "./ModalBody/ModalBody";
import ModalFooter from "./ModalFooter/ModalFooter";

export const useDialog = () => {
  const { addModal, closeModal } = useModalContext();

  const handler = (title: string, text: ReactNode, size: EModalSize = EModalSize.SMALL): Promise<boolean> => {
    return new Promise((resolve) => {
      addModal({
        id: EModalId.DIALOG,
        isOpen: true,
        size,
        title: title,
        content: (
          <>
            <ModalBody style={{ whiteSpace: "pre-wrap" }}>{text}</ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  closeModal(EModalId.DIALOG);
                  resolve(false);
                }}
              >
                {"Sulje"}
              </Button>
            </ModalFooter>
          </>
        ),
      });
    });
  };

  return handler;
};
