import { useCallback, useEffect, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import IElement from "../../shared/IElement";
import IElementUnloading, {
  ElementSlot
} from "../../shared/IElementUnloading";
import IWaybillRow from "../../shared/IWaybillRow";
import ElementUnloadingContextProvider from "./element-unloading-context";
import ElementUnloadingDragAndDropTable from "./ElementUnloadingDragAndDropTable";
import { useElementUnloadingDragAndDrop } from "./useElementUnloadingDragAndDrop";

interface IProps {
  warehouseRows: IWaybillRow[];
  initialElements: IElement[];
  setWarehouseRows: React.Dispatch<React.SetStateAction<IWaybillRow[]>>;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

const warehouseRowsToElements = (
  elements: IElement[],
  warehouseRows: IWaybillRow[],
  slot: string
): IElement[] => {
  if (slot === "TODO") {
    if (!warehouseRows?.length) return elements;
    const slotRows = warehouseRows.filter((row) => row.slot === "TODO");
    const rowElements = elements.filter((element) => slotRows.find((row) => row.elementId === element.id) || !warehouseRows.find(el => el.elementId === element.id));
    // console.log(slotRows);
    return rowElements;
  }
  const slotRows = warehouseRows.filter((row) => row.slot === slot);
  return slotRows.map((row) => ({
    ...elements.find((element) => element.id === row.elementId)!,
    position: +(row.order ?? 0),
    spacer: row.spacer,
  })).filter(element => !!element.id);
};

const elementsToWarehouseRows = (elements: IElement[]): IWaybillRow[] => {
  return elements
    .map((element) => ({
      id: uuid(),
      elementId: element.id,
      order: `${element.position ?? ""}`,
      slot: element.slot === "TODO" ? undefined : element.slot,
      spacer: element.spacer,
    }))
    .filter((row) => typeof row.slot !== "undefined");
};

/**Warehouse -> Varastoon vienti*/
const ProductionLineElementWarehouseDragAndDropTable: React.FC<IProps> = ({
  warehouseRows,
  initialElements,
  setWarehouseRows,
  setIsDirty,
}) => {
  const [items, setItems] = useState<IElementUnloading[]>([]);

  useEffect(() => {
    if (initialElements.length > 0 || warehouseRows.length > 0) {
      // console.log("set initial", initialElements.length, warehouseRows.length);
      setItems([
        ...["TODO", "A", "B", "C", "D", "E", "F", "G", "H"].map((key) => ({
          slot: key as ElementSlot,
          elements: warehouseRowsToElements([...initialElements],[...warehouseRows],key),
        })),
      ]);
    }
  }, [initialElements, warehouseRows]);

  const { dragEndHandler, dragUpdateHandler } = useElementUnloadingDragAndDrop(
    items,
    setItems
  );

  const updateHandler = useCallback(
    async (result: DropResult) => {
      const newItems = dragEndHandler(result);
      if (newItems) {
        const newRows = elementsToWarehouseRows(newItems);
        //   setUnloadingRows([]);
        setWarehouseRows((warehouseRows) => [
          ...warehouseRows.filter(
            (row) =>
              typeof row.slot !== "undefined" &&
              newItems.findIndex(item => item.id === row.elementId) === -1
          ),
          ...newRows,
        ]);
        // console.log(newItems);
        // console.log(newRows);
      }
      setIsDirty(true);
    },
    [dragEndHandler, setIsDirty, setWarehouseRows]
  );

  const addElementsHandler = useCallback((slot: ElementSlot, selectedElements: IElement[]) => {
    // TODO: refactor
    setItems(items => {
      const newItems = [...items];
      const slotIndex = newItems.findIndex(item => item.slot === slot);
      if (slotIndex > -1) {
        newItems[slotIndex].elements = [...selectedElements, ...newItems[slotIndex].elements].map(((el, index, arr) => ({
          ...el,
          position: arr.length > 0 ? arr.length - (index ?? 0) : 1,
          slot,
        })));
        // const selectedIds = selectedElements.map(el => el.id);
        // newItems[0].elements = newItems[0].elements.filter(el => !selectedIds.includes(el.id));
        // update state
        const newRows = elementsToWarehouseRows(newItems[slotIndex].elements);
        setWarehouseRows((warehouseRows) => [
          ...warehouseRows.filter(
            (row) =>
              typeof row.slot !== "undefined" &&
              newItems[slotIndex].elements.findIndex(item => item.id === row.elementId) === -1
          ),
          ...newRows,
        ]);
      }
      return newItems;
    });
    setIsDirty(true);
    // setSelectedElements([]);
  }, [setIsDirty, setWarehouseRows]);

  const removeElementHandler = useCallback((slot: ElementSlot, element: IElement) => {
    // console.log(element);
    let newOrder = 0;
    setWarehouseRows((rows) => {
      let newRows = [...rows];
      newRows = newRows.filter((row) => row.elementId !== element.id);
      const slotRows = newRows.filter(row => row.slot === slot);
      return newRows.map((row) => (row.slot === slot ? { ...row, order: `${slotRows.length - newOrder++}` } : row))
    });
    setIsDirty(true);
  }, [setIsDirty, setWarehouseRows]);

  const addSpacerHandler = useCallback((slot: ElementSlot, element: IElement) => {
    setWarehouseRows((rows) => [
      ...rows.map((row) =>
        row.slot === slot && row.elementId === element.id ? { ...row, spacer: !row.spacer } : row
      ),
    ]);
    setIsDirty(true);
  }, [setIsDirty, setWarehouseRows]);

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <ElementUnloadingContextProvider value={{
      items,
      // setItems,
      dragUpdateHandler,
      updateHandler,
      addElementsHandler,
      removeElementHandler,
      addSpacerHandler,
    }}>
      <ElementUnloadingDragAndDropTable />
    </ElementUnloadingContextProvider>
  );
};

export default ProductionLineElementWarehouseDragAndDropTable;
