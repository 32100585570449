import Container from "../components/ui/Container/Container";
import WaybillsSearch, { IWaybillsSearch } from "../components/Waybills/WaybillsSearch";
import { useMemo, useState } from "react";
import { storage } from "../utils/localstorage-utils";
import WaybillsPrintList from "../components/Waybills/WaybillsPrintList";
import classes from '../components/Waybills/WaybillsPrint.module.scss';
import { formatDate } from "../utils/date-utils";

const WaybillsPrintPage: React.FC = () => {
  const searchJson = useMemo(() => storage.getWaybillsSearch(), []);
  const initialSearch = useMemo(() => searchJson ? JSON.parse(searchJson ?? "") : null, [searchJson]);

  const [search, setSearch] = useState<IWaybillsSearch | null>({ ...initialSearch });

  return (
    <>
      <div className={classes.search}>
        <Container>
          <WaybillsSearch search={search} setSearch={setSearch} print={true}/>
        </Container>
      </div>
      <div className={classes.container}>
        <div style={{marginBottom: "1rem"}}>
          <span>
            <span style={{fontSize: "2rem", fontWeight: "bold"}}>Tehtaan rahdit</span>
            <span style={{marginLeft: "3rem", fontWeight: "bold"}}>{search?.factory}</span>
            <span style={{marginLeft: "5rem", fontWeight: "bold"}}>{formatDate(search?.deliveryDateStart)}</span>
            <span style={{marginLeft: "1rem", fontWeight: "bold"}}>{formatDate(search?.deliveryDateEnd)}</span>
          </span>
        </div>
        <WaybillsPrintList />
      </div>
    </>
  );
};

export default WaybillsPrintPage;
