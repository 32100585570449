import { IOption } from "../components/ui/Input/Input";

export enum EProvisionType {
  circle = "circle",
  rectangle = "rectangle",
  other = "other",
  slot = "slot",
  liftingLoop = "liftingLoop",
  wireLoop = "wireLoop",
  fill = "fill",
  split = "split",
  hollow = "hollow",
  hole = "hole",
  slanted = "slanted",
  deepPlug = "deepPlug",
  insulation = "insulation",
  concreteType = "concreteType",
  truss = "truss",
}

export const provisionOptions: IOption[] = [
  { value: EProvisionType.truss, label: "Ansaat" },
  { value: EProvisionType.insulation, label: "Eriste" },
  { value: EProvisionType.split, label: "Halkaistu" },
  { value: EProvisionType.fill, label: "Kolo" },
  { value: EProvisionType.other, label: "Muu leikkaus" },
  { value: EProvisionType.liftingLoop, label: "Nostolenkki" },
  { value: EProvisionType.wireLoop, label: "Pasilenkki" },
  { value: EProvisionType.concreteType, label: "Poikkeava massa" },
  { value: EProvisionType.hole, label: "Reikä" },
  { value: EProvisionType.rectangle, label: "Suorakaide" },
  { value: EProvisionType.deepPlug, label: "Syvätulppa" },
  { value: EProvisionType.hollow, label: "Syvennys" },
  { value: EProvisionType.slot, label: "Ura" },
  { value: EProvisionType.slanted, label: "Vino" },
  { value: EProvisionType.circle, label: "Ympyrä" },
];

export enum EDirection {
  left = "left",
  right = "right",
}

export const DIRECTION_OPTIONS: IOption[] = [
  { value: EDirection.left, label: "Vasen" },
  { value: EDirection.right, label: "Oikea" },
];

interface IProvision {
  id: string;
  type?: EProvisionType;
  edge1: string;
  edge2: string;
  count: string;
  grossAreaPerCount: string;
  provisionNumber?: number;
  distance: string;
  direction?: EDirection;
  name: string;
  sections: string;
}

export interface IProvisionSummaryDTO {
  countCircle: number;
  countRectangle: number;
  countOther: number;
  countSlot: number;
  countLiftingLoop: number;
  countWireLoop: number;
  countFill: number;
  countSplit: number;
  countHollow: number;
  countHole: number;
  countSlanted: number;
  countDeepPlug: number;

  hasLeft: boolean;
  hasRight: boolean;
  hasSplit: boolean;
  hasDeepPlug: boolean;
  hasSlanted: boolean;
  hasSlantedLeft: boolean;
  hasSlantedRight: boolean;
  hasFillLeft: boolean;
  hasFillRight: boolean;
  hasSlotLeft: boolean;
  hasSlotRight: boolean;

  slantedDistanceLeft: number;
  slantedDistanceRight: number;

  leftString: string;
  rightString: string;
}

export default IProvision;
