import { useCallback } from "react";
import { useCustomFetch } from "../custom-fetch";
import IElementsCSVResponseDTO from "../shared/IElementsCSVResponseDTO";

export const useAIService = () => {
  const customFetch = useCustomFetch();

  const uploadFileToAI = useCallback(
    async (kind: string, parentId: string, prePrompt: string, file: File, phaseId?: string, productTypeId?: string, factory?: string, productionWeek?: string, deliveryWeek?: string) => {
      const data = new FormData();
      data.append("file", file);
      data.append("prePrompt", prePrompt);
      data.append("phaseId", phaseId ?? "");
      data.append("productTypeId", productTypeId ?? "");
      data.append("factory", factory ?? "");
      data.append("productionWeek", productionWeek ?? "");
      data.append("deliveryWeek", deliveryWeek ?? "");
      const [aiResponse] = await customFetch<IElementsCSVResponseDTO>(
        `/api/ai/${kind}/${parentId}`,
        {
          method: "POST",
          body: data,
          multipart: true,
        }
      );
      return aiResponse;
    },
    [customFetch]
  );

  return { uploadFileToAI };
};
