import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IDriverOrganization from '../shared/IDriverOrganization';

interface IGetDriverOrganization extends ISignal {
  id: string;
}

export const useDriverOrganizationService = () => {
  const customFetch = useCustomFetch();

  const fetchDriverOrganizations = useCallback(async ({ signal }: ISignal, archived?: boolean) => {
    const [driverOrganizations] = await customFetch<IDriverOrganization[]>(`/api/driver-organizations/list${archived !== undefined ? `?archived=${archived}`:""}`, { signal });
    return driverOrganizations;
  }, [customFetch]);
  
  const getDriverOrganization = useCallback(async ({ signal, id }: IGetDriverOrganization) => {
    const [driverOrganization] = await customFetch<IDriverOrganization>("/api/driver-organizations/get/" + id, { signal });
    return driverOrganization;
  }, [customFetch]);
  
  const saveDriverOrganization = useCallback(async (data: IDriverOrganization) => {
    const [driverOrganization] = await customFetch<IDriverOrganization>("/api/driver-organizations/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return driverOrganization;
  }, [customFetch]);
  
  const updateDriverOrganization = useCallback(async (id: string, data: IDriverOrganization) => {
    const [driverOrganization] = await customFetch<IDriverOrganization>("/api/driver-organizations/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return driverOrganization;
  }, [customFetch]);
  
  const deleteDriverOrganization = useCallback(async (id: string) => {
    await customFetch<IDriverOrganization>("/api/driver-organizations/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);

  const archivedUpdateDriverOrganization = useCallback(async (id: string) => {
    const [driverOrganization] = await customFetch<IDriverOrganization>("/api/driver-organizations/update-archived/" + id, {
      method: "PUT",
    });
    return driverOrganization;
  }, [customFetch]);
  
  const archivedDeleteDriverOrganizations = useCallback(async () => {
    await customFetch<IDriverOrganization>("/api/driver-organizations/delete-archived", {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);
  
  return { fetchDriverOrganizations, getDriverOrganization, saveDriverOrganization, updateDriverOrganization, deleteDriverOrganization, archivedUpdateDriverOrganization, archivedDeleteDriverOrganizations };
}