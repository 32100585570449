import { useCallback } from "react";
import IUser from "../../shared/IUser";
import {
  EModalId,
  EModalSize,
  useModalContext,
} from "../ui/Modal/modal-context";
import UserEditModal from "./UserEditModal";

export const useProjectUserEditModal = () => {
  const { addModal, closeModal, updateModal } = useModalContext();

  const closeModalHandler = useCallback(() => closeModal(EModalId.USER_EDIT), [closeModal]);

  const handler = (user?: IUser): Promise<IUser | null> => {
    const title = !!user ? "Muokkaa käyttäjää" : "Lisää käyttäjä";
    return new Promise((resolve) => {
      addModal({
        id: EModalId.USER_EDIT,
        isOpen: true,
        size: EModalSize.SMALL,
        title,
        backdropNotClose: true,
        content: (
          <UserEditModal
            onAccept={async (data) => {
              closeModalHandler();
              resolve(data);
            }}
            onCancel={() => {
              updateModal(EModalId.USER_EDIT, { loading: false, error: null });
              closeModalHandler();
              resolve(null);
            }}
            user={user}
          />
        ),
      });
    });
  };

  return handler;
};
