import Container from "../components/ui/Container/Container";
import { useNavigate } from "react-router-dom";
import { Route } from "../routes";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import WaybillsList from "../components/Waybills/WaybillsList";
import WaybillsSearch, { IWaybillsSearch } from "../components/Waybills/WaybillsSearch";
import { useMemo, useState } from "react";
import { storage } from "../utils/localstorage-utils";
import Button, { EButtonSize } from "../components/ui/Button/Button";

const WaybillsPage: React.FC = () => {
  const navigate = useNavigate();

  const addHandler = () => {
    navigate(Route.waybill("add"));
  }

  const printHandler = () => {
    navigate(Route.waybillsPrint);
  }

  const searchJson = useMemo(() => storage.getWaybillsSearch(), []);
  const initialSearch = useMemo(() => searchJson ? JSON.parse(searchJson ?? "") : null, [searchJson]);

  const [search, setSearch] = useState<IWaybillsSearch | null>({ ...initialSearch });

  return (
    <>
      <PageHeading onAdd={addHandler}>Rahtikirjat <Button style={{fontSize: "1rem"}} size={EButtonSize.SMALL} onClick={printHandler}>Tulosta</Button></PageHeading>
      <Container>
        <WaybillsSearch search={search} setSearch={setSearch} />
      </Container>
      <Container>
        <WaybillsList />
      </Container>
    </>
  );
};

export default WaybillsPage;
