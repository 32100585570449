import IElement from "../../../shared/IElement";
import ElementsDragAndDropTable from "../../Elements/ElementsDragAndDropTable";
import Button, { EButtonColor } from "../../ui/Button/Button";
import PageHeading from "../../ui/PageHeading/PageHeading";

interface IProps {
  hasElements: boolean;
  autofillHandler: () => void;
  isLoading: boolean;
  resetHandler: () => void;
  reverseHandler: () => void;
  elements: IElement[];
  setElements: React.Dispatch<React.SetStateAction<IElement[]>>;
  onElementClick: (element: IElement) => void;
  lineLength: string;
  setActiveElement: (element: IElement | null) => void;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProductionLineDraft: React.FC<IProps> = ({
  hasElements,
  autofillHandler,
  isLoading,
  resetHandler,
  reverseHandler,
  elements,
  setElements,
  onElementClick,
  lineLength,
  setActiveElement,
  setIsDirty,
}) => {
  return (
    <>
      <div
        style={{
          // width: "1000px",
          // maxWidth: "1000px",
          background: "#ccc",
          padding: "1rem",
          height: "fit-content",
          // zIndex: "99",
          position: "sticky",
          top: "1rem",
          right: "0",
        }}
      >
        <div style={{ display: "flex" }}>
          <PageHeading variant="h3" style={{ marginLeft: "0" }}>
            Valitut elementit
          </PageHeading>
          <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            {hasElements && (
              <>
                <Button onClick={autofillHandler} disabled={isLoading}>
                  Täytä automaattisesti
                </Button>
                <Button
                  onClick={resetHandler}
                  color={EButtonColor.SECONDARY}
                  disabled={isLoading}
                >
                  Tyhjennä
                </Button>
                <Button onClick={reverseHandler} disabled={isLoading}>
                  Käännä järjestys
                </Button>
              </>
            )}
          </div>
        </div>
        {hasElements ? (
          <ElementsDragAndDropTable
            elements={elements}
            setElements={setElements}
            showTotalRow
            onDelete={isLoading ? undefined : onElementClick}
            lineLength={lineLength}
            onClick={(element) => setActiveElement(element)}
            setIsDirty={setIsDirty}
          />
        ) : (
          <p>Ei valittuja elementtejä</p>
        )}
      </div>
    </>
  );
};

export default ProductionLineDraft;
