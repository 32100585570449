import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import IProjectUser from "../../../shared/IProjectUser";
import { RefHandle } from "../../../shared/RefHandle";
import { TInputValue } from "../../ui/Input/Input";
import InputContextProvider, {
    EInputUpdateAction,
} from "../../ui/Input/input-context";
import { formatInputValue } from "../../ui/Input/input-utils";
import { TCreateInput } from "../../ui/Input/useCreateInput";
import { useConfirmModal } from "../../ui/Modal/useConfirmModal";
import PageHeading from "../../ui/PageHeading/PageHeading";
import Table from "../../ui/Table/Table";
import { useProjectEditContext } from "../ProjectEdit/project-edit-context";
import ProjectUserEdit from "./ProjectUserEdit";
import { useProjectsService } from "../../../services/projects-service";
import { useMutation } from "@tanstack/react-query";
import { ErrorResponse } from "../../../custom-fetch";
import ErrorsAlert, { combineErrors } from "../../ErrorsAlert/ErrorsAlert";

interface IProps {
  createProjectInput: TCreateInput;
}

const ProjectSecurity: React.ForwardRefRenderFunction<
  RefHandle<IProjectUser[]>,
  IProps
> = ({ createProjectInput }, ref) => {
  const openConfirmModal = useConfirmModal();
  const { id, projectUsers, setProjectUsers, setIsProjectDirty, submitHandler } = useProjectEditContext();
  
  const { sendLoginEmailMutate, isSending, isSendError, sendError } = useSendLoginEmail(id);
  const [sendingId, setSendingId] = useState<string | null>(null);
  
  const addHandler = useCallback(async () => {
    setProjectUsers((users) => [...users, createItem()]);
    setIsProjectDirty((dirty) => ({ ...dirty, projectUsers: true }));
  }, [setIsProjectDirty, setProjectUsers]);

  const deleteHandler = useCallback(
    async (event: React.MouseEvent, id: string) => {
      const isConfirm = event.shiftKey
        ? true
        : await openConfirmModal(
            "Oletko varma, että haluat poistaa käyttäjän?"
          );
      if (isConfirm) {
        setProjectUsers((users) => users.filter((user) => user.id !== id));
        setIsProjectDirty((dirty) => ({ ...dirty, projectUsers: true }));
      }
    },
    [openConfirmModal, setIsProjectDirty, setProjectUsers]
  );

  const sendHandler = useCallback(async (uuid: string, email: string) => {
    const isConfirm = await openConfirmModal(`Oletko varma, että haluat lähettää kirjautumislinkin osoitteeseen ${email}?`);
    if (isConfirm) {
        setSendingId(uuid);
        try {
            await submitHandler(); // save before sending, email may be stale
            await sendLoginEmailMutate(uuid);
        } catch (error) {
            console.error(error);
        }
        setSendingId(null);
    }
  }, [openConfirmModal, sendLoginEmailMutate, submitHandler]);

  const updateHandler = useCallback(
    async (inputName: string, value: TInputValue, action: string, _: any) => {
      if (action === EInputUpdateAction.PROJECT_USERS) {
        const data = _ as IProjectUser;
        const newUsers = [...projectUsers];
        const index = newUsers.findIndex((user) => user.id === data.id);
        if (index > -1) {
          newUsers[index] = {
            ...data,
            [inputName]: formatInputValue(value),
          };
        }
        setProjectUsers(newUsers);
        setIsProjectDirty((dirty) => ({ ...dirty, projectUsers: true }));
      }
      return Promise.resolve(true);
    },
    [projectUsers, setIsProjectDirty, setProjectUsers]
  );

  const rowsRef = useRef<RefHandle<IProjectUser>[]>([]);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const rows = rowsRef.current;
      const newRows: IProjectUser[] = [];
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        if (row) {
          const rowData = await row.getData();
          if (rowData) {
            newRows.push(rowData);
          }
        }
      }
      return newRows;
    },
  }));

  const errorMessages = combineErrors({ isError: isSendError, error: sendError });

  return (
    <>
      <PageHeading variant="h3" noHorizontalMargin onAdd={addHandler}>
        Käyttäjät
      </PageHeading>
      {errorMessages?.length > 0 && <ErrorsAlert errors={errorMessages} />}
      {projectUsers.length > 0 && (
        <Table>
          <thead>
            <tr>
              <th style={{ width: "200px" }}>Käyttäjä</th>
              <th style={{ width: "500px" }}>Tehtävä</th>
              <th style={{ width: "350px" }}>Käyttöoikeudet</th>
              <th style={{ width: "200px" }}>Viimeinen kirjautumispvm</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <InputContextProvider initDone onAutoUpdate={updateHandler}>
              {projectUsers?.map((user, i) => (
                <ProjectUserEdit
                  key={user.id}
                  user={user}
                  onDelete={(event) => deleteHandler(event, user.id)}
                  onSend={() => sendHandler(user.id, user.user?.email ?? "")}
                  ref={(rowHandle) => (rowsRef.current[i] = rowHandle!)}
                  setProjectUsers={setProjectUsers}
                  setIsProjectDirty={setIsProjectDirty}
                  isLoading={isSending && sendingId === user.id}
                />
              ))}
            </InputContextProvider>
          </tbody>
        </Table>
      )}
      <hr />
      {createProjectInput("dataProtectionObject")}
    </>
  );
};

const createItem = (): IProjectUser => {
  const id = uuid();
  return {
    id,
    user: undefined,
    role: "",
    allowLoginEndDate: "",
    allowWaybills: false,
    allowProjectStatus: false,
    allowBilling: false,
  };
};

const useSendLoginEmail = (id: string) => {
  const { sendLoginEmail } = useProjectsService();

  const {
    mutateAsync: sendLoginEmailMutate,
    isPending: isSending,
    isError: isSendError,
    error: sendError,
  } = useMutation<boolean, ErrorResponse, string>({
    mutationFn: (uuid) => sendLoginEmail({ id, uuid }),
  });

  return { sendLoginEmailMutate, isSending, isSendError, sendError };
};

export default forwardRef(ProjectSecurity);
