import React from "react";

const initialStyle: React.CSSProperties = {
  height: "2px",
  background: "black",
  margin: "0",
};

const Spacer: React.FC<{ style?: React.CSSProperties }> = ({ style }) => (
  <>
    <hr style={{ ...initialStyle, ...style }} />
    <hr style={{ ...initialStyle, ...style }} />
  </>
);

export default Spacer;
