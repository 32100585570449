import QRCode from "react-qr-code";
import { adminUiUrl } from "../../../config";
import { Route } from "../../../routes";
import ITool from "../../../shared/ITool";
import classes from "./ToolsQRCodesPrint.module.scss";
import { useEffect } from "react";

interface IProps {
  tools: ITool[];
}

const ToolsQRCodesPrint: React.FC<IProps> = ({ tools }) => {
  useEffect(() => {
    window.print();
  }, []);
  return (
    <div className={classes.Container}>
      {tools.map((tool) => (
        <div key={tool.id} className={classes.QRContainer}>
          <QRCode
            className={classes.QRCode}
            value={adminUiUrl + Route.toolQRCode(tool.uuid)}
          />
          <span className={classes.Label}>
            {tool.toolTypeLabel} {tool.number}
          </span>
        </div>
      ))}
    </div>
  );
};

export default ToolsQRCodesPrint;
