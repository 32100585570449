import { faRemove } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useId, useMemo } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { useDragAndDrop } from "../../hooks/useDragAndDrop";
import IElement from "../../shared/IElement";
import Button, { EButtonColor, EButtonSize } from "../ui/Button/Button";
import Table from "../ui/Table/Table";

interface IProps {
  elements: IElement[];
  showTotalRow?: boolean;
  setElements: React.Dispatch<React.SetStateAction<IElement[]>>;
  onDelete?: (element: IElement) => void;
  lineLength: string;
  onClick: (element: IElement) => void;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

export const calculateTotalLength = (elements: IElement[]) => {
  let lengthTotal = 0;
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    lengthTotal += +(element.calculatedLength ?? 0);
  }
  return lengthTotal;
};

export const addCalculationFields = (items: IElement[]) => {
  const elements = [...items];
  const maxWidth = 1200;

  let skipNextSplit = false;

  for (let i = 0; i < elements.length; i++) {
    const current = elements[i];
    const next = elements[i + 1];

    const currentSummary = current.provisionSummary;
    const nextSummary = next?.provisionSummary;

    const currentWidth = +(current.width ?? 0);
    const nextWidth = +(next?.width ?? 0);
    const totalWidth = currentWidth + nextWidth;

    const currentLength = +(current.length ?? 0);
    const nextLength = +(next?.length ?? 0);

    // provision split
    if (!skipNextSplit) {
      if (currentSummary?.hasSplit && nextSummary?.hasSplit && totalWidth <= maxWidth) {
        if (nextLength > currentLength) {
          current.calculatedLength = 0;
          next.calculatedLength = nextLength;
          current.splitString = "Rinnalla ↓";
          next.splitString = undefined;
        } else {
          current.calculatedLength = currentLength;
          next.calculatedLength = 0;
          current.splitString = undefined;
          next.splitString = "Rinnalla ↑";
        }
        skipNextSplit = true;
        continue; // other provision logic cant be done if split logic is done
      } else {
        current.splitString = undefined;
      }
    }

    if (next) {
      next.calculatedLength = nextLength;
      next.calculatedSlantedDistanceLeft = 0;
      next.calculatedSlantedDistanceRight = 0;
    }

    if (!skipNextSplit) {
      if (currentSummary?.hasSlantedLeft && nextSummary?.hasSlantedRight && !current.calculatedSlantedDistanceLeft && !current.calculatedSlantedDistanceRight) {
        next.calculatedLength = nextLength - nextSummary.slantedDistanceRight
        next.calculatedSlantedDistanceRight = nextSummary.slantedDistanceRight
        next.calculatedSlantedDistanceLeft = 0;
      } else if (currentSummary?.hasSlantedRight && nextSummary?.hasSlantedLeft && !current.calculatedSlantedDistanceLeft && !current.calculatedSlantedDistanceRight) {
        next.calculatedLength = nextLength - nextSummary.slantedDistanceLeft
        next.calculatedSlantedDistanceLeft = nextSummary.slantedDistanceLeft
        next.calculatedSlantedDistanceRight = 0;
      } else if (currentSummary?.hasSlantedLeft && currentSummary?.hasSlantedRight) { //extra logic to both sides slanted
        if (nextSummary?.hasSlantedRight && !current.calculatedSlantedDistanceLeft) {
          next.calculatedLength = nextLength - nextSummary.slantedDistanceRight
          next.calculatedSlantedDistanceRight = nextSummary.slantedDistanceRight
          next.calculatedSlantedDistanceLeft = 0;
        } else if (nextSummary?.hasSlantedLeft && !current.calculatedSlantedDistanceRight) {
          next.calculatedLength = nextLength - nextSummary.slantedDistanceLeft
          next.calculatedSlantedDistanceLeft = nextSummary.slantedDistanceLeft
          next.calculatedSlantedDistanceRight = 0;
        }
      }
    } else {
      skipNextSplit = false;
    }

    current.calculatedLength = current.calculatedLength !== undefined ? current.calculatedLength : currentLength;
  }

  return elements;
};

const ElementsDragAndDropTable: React.FC<IProps> = ({ elements, showTotalRow, setElements, onDelete, lineLength, onClick, setIsDirty }) => {
  const { dragEndHandler, dragUpdateHandler, dragStartHandler, items, getItemStyle, getListStyle } = useDragAndDrop(elements, setElements);

  const updatedItems = useMemo(()=>addCalculationFields(items), [items]);

  const lengthTotal = useMemo(
    () => (showTotalRow ? calculateTotalLength(updatedItems) : 0),
    [showTotalRow, updatedItems]
  );

  const onDragEnd = useCallback((result: DropResult) => {
    dragEndHandler(result);
    setIsDirty(true);
  }, [dragEndHandler, setIsDirty]);

  return (
    <Table>
      <thead>
        <tr>
          <th style={{ width: "80px" }}>#</th>
          <th style={{ width: "125px" }}>Tunnus</th>
          <th style={{ width: "125px" }}>Pituus</th>
          <th style={{ width: "125px" }}>Vaijerityyppi</th>
          <th style={{ width: "125px" }}>Varaukset</th>
          <th style={{ width: "125px" }}>Halkaisija</th>
          <th style={{ width: "125px" }}>Vasen</th>
          <th style={{ width: "125px" }}>Oikea</th>
          <th style={{ width: "125px" }}>Paino</th>
          <th style={{ width: "100px" }}>Vko</th>
          <th style={{ width: "100px" }}>Toim</th>
          <th style={{ width: "125px" }}></th>
        </tr>
      </thead>
      <DragDropContext onDragUpdate={dragUpdateHandler} onDragEnd={onDragEnd} onDragStart={dragStartHandler}>
        <Droppable droppableId={useId()}>
          {(provided, snapshot) => (
            <tbody
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {updatedItems.map((element, index) => (
                <Draggable
                  key={element.id}
                  draggableId={element.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <tr
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      onClick={(event) => {
                        event.stopPropagation();
                        onClick(element);
                      }}
                    >
                      <td style={{ width: "80px" }}>{element.position || index + 1}</td>
                      <td style={{ width: "125px" }}>{element.name}</td>
                      <td style={{ width: "125px" }}>{element.splitString ? "("+element.length+")" : element.length}</td>
                      <td style={{ width: "125px" }}>{element.typeName}</td>
                      <td style={{ width: "125px" }} title={element.provisionAmountLong}>
                        {element.provisionAmountShort}
                        {element.provisionAmountShort && !element.isPlanned && !element.hasAttachments && <span title="Varausten liitteet puuttuu" style={{ alignSelf: "center", fontWeight: "bold" }}>!</span>}
                      </td>
                      <td style={{ width: "125px" }}>{element.width} {element.splitString && <span><br/>{element.splitString}</span>}</td>
                      <td style={{ width: "125px" }}>{element.provisionSummary?.leftString} {element.calculatedSlantedDistanceLeft ? <span style={{fontSize: "0.8rem"}}><br/>-{element.calculatedSlantedDistanceLeft}</span> : ""}</td>
                      <td style={{ width: "125px" }}>{element.provisionSummary?.rightString} {element.calculatedSlantedDistanceRight ? <span style={{fontSize: "0.8rem"}}><br/>-{element.calculatedSlantedDistanceRight}</span> : ""}</td>
                      <td style={{ width: "125px" }}>{element.weightTons}</td>
                      <td style={{ width: "100px" }}>{element.productionWeek}</td>
                      <td style={{ width: "100px" }}>{element.deliveryWeek}</td>
                      <td style={{ width: "125px" }}><Button onClick={(event) => { event.stopPropagation(); onDelete && onDelete(element) }} size={EButtonSize.SMALL} color={EButtonColor.DANGER} icon={faRemove} title="Poista pediltä" disabled={!onDelete} /></td>
                    </tr>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
      <tfoot>
        {showTotalRow && (
          <tr>
            <td>Yhteensä</td>
            <td></td>
            <td colSpan={7} style={lengthTotal > +(lineLength ?? 0) ? { color: "red" } : {}}>{lengthTotal} / {lineLength} = jäljellä {+(lineLength ?? 0)-lengthTotal}</td>

          </tr>
        )}
      </tfoot>
    </Table>
  );
};

export default ElementsDragAndDropTable;
