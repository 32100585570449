import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { IOption } from "../components/ui/Input/Input";
import { ErrorResponse } from "../custom-fetch";
import { useProjectsService } from "../services/projects-service";
import { QueryKey } from "../services/query-keys";
import IProject from "../shared/IProject";
import { EFactory } from "../shared/IOfferRow";

interface IProps {
  showProjectNumber?: boolean
  factory?: string;
  factoryRequired?: boolean;
}

const projectToOption = (project: IProject, showProjectNumber?: boolean) => {
  return {
    value: project.id,
    label:
      showProjectNumber && project.projectNumber
        ? project.projectNumber + " " + project.name
        : project.name,
  } as IOption;
};

export const useProjectOptions = (props: IProps = {}) => {
  const { showProjectNumber = true, factory, factoryRequired } = props;
  const { fetchProjects } = useProjectsService();

  const {
    data: projects,
    isLoading: isPending,
    isError,
  } = useQuery<IProject[], ErrorResponse>({
    queryKey: [QueryKey.projectOptions, factory ?? ""],
    queryFn: fetchProjects,
    staleTime: 5000,
    enabled: factoryRequired ? !!factory : true,
  });

  const filteredProjects = useMemo(() => factory ? [...projects ?? []].filter((project) => project.factories.includes(factory as EFactory)) : projects ?? [], [factory, projects]);

  const options = useMemo(() =>
      isError 
      ? [] 
      : filteredProjects.map(project => projectToOption(project, showProjectNumber)),
    [isError, filteredProjects, showProjectNumber]
  );

  return { options, loading: isPending, projects: filteredProjects };
};
