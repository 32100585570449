import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { queryClient } from "../../App";
import { ErrorResponse } from "../../custom-fetch";
import { Route } from "../../routes";
import { QueryKey } from "../../services/query-keys";
import { useWireReelService } from "../../services/wireReels-service";
import IWireReel, { WIRE_REEL_TYPE_OPTIONS } from "../../shared/IWireReel";
import ErrorsAlert, { combineErrors } from "../ErrorsAlert/ErrorsAlert";
import FormButtons from "../ui/FormButtons/FormButtons";
import { ECommonValue, EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import { FactoryOptions } from "../../shared/FactoryOptions";

interface IProps {
  id: string;
  wireReel?: IWireReel;
}

enum EInputs {
  shipmentFactory = "shipmentFactory",
  locationFactory = "locationFactory",
  type = "type",
  transferDate = "transferDate",
  activationDate = "activationDate",
  arrivalDate = "arrivalDate",
  activationLocation = "activationLocation",
  reelNumber = "reelNumber",
  manufacturer = "manufacturer",
  notes = "notes",
  crossSectionalArea = "crossSectionalArea",
  pricePerTon = "pricePerTon",
  priceTotal = "priceTotal",
  elasticModulus = "elasticModulus",
  massPerMeter = "massPerMeter",
  weight = "weight",
  proof02 = "proof02",
  elongation = "elongation",
  breakingStrength = "breakingStrength",
  finished = "finished",
  archived = "archived",
}

const WireReelEdit: React.FC<IProps> = ({ id, wireReel }) => {
  const isEdit = id !== "add";
  
  const navigate = useNavigate();
  const redirectHandler = () => {
    return navigate(Route.wireReels);
  };

  const { saveOrUpdate, error, isError, isPending } = useSaveOrUpdate(id, isEdit, redirectHandler);
  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete(id, redirectHandler);
  const { createInput, submit/*, inputs, setInputs*/ } = useWireReelInputs(wireReel);
  /*
  const pricePerTon = +(inputs[EInputs.pricePerTon].value as string);
  const weight = +(inputs[EInputs.weight].value as string);
  const priceTotal = pricePerTon * (weight / 1000);

  useEffect(() => {
    setInputs((inputs) => {
      const newInputs = {...inputs};
      newInputs[EInputs.priceTotal].value = priceTotal ? priceTotal.toFixed(2) : "0";
      return newInputs;
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceTotal]);
  */

  const submitHandler = useCallback(async () => {
    const data = await submit();
    if (data) {
      saveOrUpdate(data);
    }
  }, [saveOrUpdate, submit]);

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.repeat) return;
      if (!e.ctrlKey) return;
      const key = e.key.toLowerCase();
      switch (key) {
        case "s":
          e.preventDefault();
          submitHandler();
          break;
      }
    };

    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [submitHandler]);

  const errorMessages = combineErrors({ isError, error }, { isError: isDeletingError, error: deletingError });

  return (
    <>
      <div style={{ display: "flex", width: "1500px" }}>
        <div style={{ width: "490px", marginRight: "15px" }}>
          {createInput(EInputs.reelNumber)}
          {createInput(EInputs.locationFactory)}
          {createInput(EInputs.activationDate)}
          {createInput(EInputs.type)}
          {createInput(EInputs.activationLocation)}
          
          {createInput(EInputs.arrivalDate)}
          {createInput(EInputs.shipmentFactory)}
          {createInput(EInputs.transferDate)}

          {createInput(EInputs.finished)}
        </div>
        <div style={{ width: "490px" }}>
          {createInput(EInputs.manufacturer)}
          {createInput(EInputs.priceTotal)}
          
         
          {createInput(EInputs.pricePerTon)}
          {createInput(EInputs.notes)}          
        </div>
        <div style={{ width: "290px", marginLeft: "15px" }}>
        {createInput(EInputs.weight)}
        
        {createInput(EInputs.massPerMeter)}
          {createInput(EInputs.crossSectionalArea)}
          {createInput(EInputs.proof02)}
          {createInput(EInputs.elasticModulus)}
          {createInput(EInputs.breakingStrength)}
          
          {createInput(EInputs.elongation)}
        </div>
      </div>
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      <FormButtons
        onSubmit={submitHandler}
        isLoading={isPending}
        onDelete={isEdit ? deleteMutate : undefined}
        isDeleting={isDeleting}
        deleteConfirmMessage="Haluatko varmasti poistaa vaijerikelan?"
      />
    </>
  );
};

const useWireReelInputs = (data?: IWireReel) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.shipmentFactory]: {
      type: EInputType.reactSelect,
      label: "Hankinta tehdas",
      value: "",
      options: FactoryOptions
    },
    [EInputs.locationFactory]: {
      type: EInputType.reactSelect,
      label: "Sijainti tehdas",
      value: "",
      options: FactoryOptions
    },
    [EInputs.type]: {
      type: EInputType.reactSelect,
      label: "Elementtityyppi",
      value: "",
      options: WIRE_REEL_TYPE_OPTIONS
    },
    [EInputs.transferDate]: {
      type: EInputType.date,
      label: "Siirto pvm",
      value: "",
    },
    [EInputs.activationDate]: {
      type: EInputType.date,
      label: "Käyttöönotto pvm",
      value: "",
    },
    [EInputs.arrivalDate]: {
      type: EInputType.date,
      label: "Saapunut pvm",
      value: "",
      validation: {
        required: true,
      },
    },
    [EInputs.activationLocation]: {
      type: EInputType.text,
      label: "Käyttöönotto kohde",
      value: "",
    },
    [EInputs.reelNumber]: {
      type: EInputType.text,
      label: "Vyyhtinumero",
      value: "",
      autoFocus: true,
      validation: {
        required: true,
      },
    },
    [EInputs.manufacturer]: {
      type: EInputType.text,
      label: "Valmistaja",
      value: "",
    },
    [EInputs.notes]: {
      type: EInputType.textarea,
      label: "Huomio",
      value: "",
      rows: 3
    },
    [EInputs.crossSectionalArea]: {
      type: EInputType.number,
      label: "Poikkipinta-ala mm²",
      value: "",
    },
    [EInputs.pricePerTon]: {
      type: EInputType.number,
      label: "Hinta € / ton",
      value: "",
    },
    [EInputs.priceTotal]: {
      type: EInputType.number,
      label: "Hinta yhteensä €",
      value: "",
    },
    [EInputs.elasticModulus]: {
      type: EInputType.number,
      label: "Kimmomoduuli",
      value: "",
    },
    [EInputs.massPerMeter]: {
      type: EInputType.number,
      label: "Paino g / m",
      value: "",
    },
    [EInputs.weight]: {
      type: EInputType.number,
      label: "Paino yhteensä kg",
      value: "",
    },
    [EInputs.proof02]: {
      type: EInputType.number,
      label: "Raja 0.2, kN",
      value: "",
    },
    [EInputs.elongation]: {
      type: EInputType.number,
      label: "Tasavenymä",
      value: "",
    },
    [EInputs.breakingStrength]: {
      type: EInputType.number,
      label: "Murtolujuus",
      value: "",
      disallowDecimals: true
    },
    [EInputs.finished]: {
      type: EInputType.checkbox,
      label: "Käytetty loppuun",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
    [EInputs.archived]: {
      type: EInputType.checkbox,
      label: "Arkistoitu",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit, inputs, setInputs };
};

const useSaveOrUpdate = (id: string, isEdit: boolean, redirectHandler: () => void) => {
  const { updateWireReel, saveWireReel } = useWireReelService();

  const mutationFn = (data: IWireReel) => {
    return isEdit ? updateWireReel(id, data) : saveWireReel(data);
  };

  const {
    mutate: saveOrUpdate,
    isPending,
    isError,
    error,
  } = useMutation<IWireReel, ErrorResponse, IWireReel>({
    mutationFn,
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: [QueryKey.wireReels, id] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.wireReels] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.wireReelOptions] });
      redirectHandler();
    },
  });

  return { saveOrUpdate, isPending, isError, error };
};

const useDelete = (id: string, redirectHandler: () => void) => {
  const { deleteWireReel } = useWireReelService();

  const {
    mutate: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse>({
    mutationFn: () => deleteWireReel(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.wireReels],
        refetchType: "none",
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKey.wireReelOptions],
        refetchType: "none",
      });
      redirectHandler();
    },
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

export default WireReelEdit;
