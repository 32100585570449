import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { forwardRef, useImperativeHandle, useState } from "react";
import { RefHandle } from "../../shared/RefHandle";
import Button, { EButtonColor, EButtonSize } from "../ui/Button/Button";
import { EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";

enum EInputs {
  email = "email",
}

export interface IRecipient {
  id: string;
  email: string;
}

interface IProps {
  recipient?: IRecipient;
  onDelete: () => void;
}

const RecipientEdit: React.ForwardRefRenderFunction<RefHandle<IRecipient>, IProps> = ({ recipient, onDelete }, ref) => {
  const { createInput, submit } = useRecipientInputs(recipient);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const data = await submit();
      // console.log(data);
      if (!data) return null;
      return { ...recipient, ...data };
    },
  }));

  return (
    <tr>
      <td style={{ width: "300px", minWidth: "300px" }}>{createInput(EInputs.email)}</td>
      <td style={{ alignContent: "center" }}>
        <div>
          <Button style={{ marginRight: "0.25rem" }} onClick={(e) => { e.stopPropagation(); onDelete(); }} size={EButtonSize.SMALL} color={EButtonColor.DANGER} icon={faTrash} title="Poista" />
        </div>
      </td>
    </tr>
  );
};

const useRecipientInputs = (data?: IRecipient) => {
  const [inputs, setInputs] = useState<IInputField>({
      [EInputs.email]: {
        type: EInputType.email,
        value: "",
        label: "Email",
      },
    });
  
    const { createInput, submit } = useInputs({ data, inputs, setInputs });
  
    return { createInput, submit };
};

export default forwardRef(RecipientEdit);
