import { useCallback } from 'react';
import { IProjectsSearch } from '../components/Projects/ProjectsSearch';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IElementsStatusDTO from '../shared/IElementsStatusDTO';
import IProject, { IProjectPhase } from '../shared/IProject';
import IWaybillUpdatePricesDTO from '../shared/IWaybillUpdatePricesDTO';
import ITool from '../shared/ITool';

interface IFetchOffers extends ISignal {
  search?: IProjectsSearch | null;
}

interface IGetProject extends ISignal {
  id: string;
}

interface ISendLoginEmail {
  id: string;
  uuid: string;
}

export const useProjectsService = () => {
  const customFetch = useCustomFetch();

  const fetchProjects = useCallback(async ({ signal, search }: IFetchOffers) => {
    let url = "/api/projects/list";
    const [projects] = await customFetch<IProject[]>(url, {
      signal,
      method: "POST",
      body: JSON.stringify(search),
    });
    return projects;
  }, [customFetch]);
  
  const getProject = useCallback(async ({ signal, id }: IGetProject) => {
    const [project] = await customFetch<IProject>("/api/projects/get/" + id, { signal });
    return project;
  }, [customFetch]);

  const saveProject = useCallback(async (data: IProject) => {
    const [project] = await customFetch<IProject>("/api/projects/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return project;
  }, [customFetch]);
  
  const updateProject = useCallback(async (id: string, data: IProject) => {
    const [project] = await customFetch<IProject>("/api/projects/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return project;
  }, [customFetch]);
  
  const deleteProject = useCallback(async (id: string) => {
    await customFetch<IProject>("/api/projects/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);

  const saveProjectPhase = useCallback(async (id: string, data: IProjectPhase) => {
    const [phase] = await customFetch<IProjectPhase>("/api/projects/phases/add/" + id, {
      method: "POST",
      body: JSON.stringify(data),
    });
    return phase;
  }, [customFetch]);

  const deleteProjectPhase = useCallback(async (projectId: string, phaseId: string) => {
    const [phase] = await customFetch<boolean>(`/api/projects/phases/delete/${projectId}?phaseId=${phaseId}`, {
      method: "DELETE",
    });
    return phase;
  }, [customFetch]);

  const fetchProjectElementStatus = useCallback(
    async ({ signal, id }: IGetProject) => {
      let url = "/api/projects/list-element-status/" + id;
      const [elements] = await customFetch<IElementsStatusDTO>(url, {
        signal,
      });
      return elements;
    },
    [customFetch]
  );

  const sendLoginEmail = useCallback(
    async ({ id, uuid }: ISendLoginEmail) => {
      let url = "/api/projects/send-login-email/" + id + "?uuid=" + uuid;
      const [success] = await customFetch<boolean>(url, {
        method: "PUT",
      });
      return success;
    },
    [customFetch]
  );

  const updateWaybillPrices = useCallback(async (projectId: string, waybillId: string) => {
    const [dto] = await customFetch<IWaybillUpdatePricesDTO>(`/api/projects/update-delivery-prices/${projectId}?waybillId=${waybillId}`, { method: "PUT" });
    return dto;
  }, [customFetch]);

  const listProjectTools = useCallback(async ({ signal, id }: IGetProject) => {
    const [tools] = await customFetch<ITool[]>(`/api/projects/list-tools/${id}`, { signal });
    return tools;
  }, [customFetch]);
  
  return { fetchProjects, getProject, saveProject, updateProject, deleteProject, saveProjectPhase, deleteProjectPhase, fetchProjectElementStatus, sendLoginEmail, updateWaybillPrices, listProjectTools };
}
