import { useQuery } from "@tanstack/react-query";
import { ErrorResponse } from "../../../custom-fetch";
import { useProjectsService } from "../../../services/projects-service";
import { QueryKey } from "../../../services/query-keys";
import IElementsStatusDTO from "../../../shared/IElementsStatusDTO";
import ErrorsAlert from "../../ErrorsAlert/ErrorsAlert";
import Spinner from "../../ui/Spinner/Spinner";
import ElementStatusTable from "./ElementStatusTable";

interface IProps {
  projectId: string;
}

const ElementStatus: React.FC<IProps> = ({ projectId }) => {
  const {
    elements,
    isFetchingElements,
    isFetchElementsError,
    fetchElementsError,
  } = useFetchElementStatus(projectId);

  if (isFetchElementsError) {
    return <ErrorsAlert errors={fetchElementsError!.messages} />;
  }

  if (isFetchingElements) {
    return <Spinner />;
  }

  if (!elements || (elements.elementsByProductType?.length === 0 && elements.elementsByPhaseId?.length === 0)) {
    return <p>Ei suunniteltuja elementtejä</p>;
  }

  return <ElementStatusTable elements={elements} />;
};

const useFetchElementStatus = (projectId: string | null) => {
  const { fetchProjectElementStatus } = useProjectsService();

  const {
    data: elements,
    isPending: isFetchingElements,
    isError: isFetchElementsError,
    error: fetchElementsError,
  } = useQuery<IElementsStatusDTO, ErrorResponse>({
    queryKey: [QueryKey.elementsStatus, projectId],
    queryFn: ({ signal }) => fetchProjectElementStatus({ signal, id: projectId! }),
    staleTime: 5000,
    enabled: !!projectId,
  });

  return {
    elements,
    isFetchingElements,
    isFetchElementsError,
    fetchElementsError,
  };
};

export default ElementStatus;
