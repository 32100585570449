import { faDownload } from "@fortawesome/free-solid-svg-icons";
import IToolDTO from "../../../shared/IToolDTO";
import { formatDate } from "../../../utils/date-utils";
import Button, { EButtonSize } from "../../ui/Button/Button";
import AuditHistories from "../AuditHistories/AuditHistories";
import { useMutation } from "@tanstack/react-query";
import { ErrorResponse } from "../../../custom-fetch";
import { usePublicQRCodesService } from "../../../services/publicQRCodes-service";
import ErrorsAlert, { combineErrors } from "../../ErrorsAlert/ErrorsAlert";
import { useCallback } from "react";

interface IProps {
  tool: IToolDTO;
  onReturn?: () => void;
}

const ToolQRCode: React.FC<IProps> = ({ tool, onReturn }) => {
  const { downloadMutate, isDownloading, isDownloadError, downloadError } = useDownloadLatestAttachment();
  const errorMessages = combineErrors({ isError: isDownloadError, error: downloadError });

  const downloadHandler = useCallback(
    async (id: string) => {
      try {
        const attachment = await downloadMutate(id);
        const objectUrl = URL.createObjectURL(attachment);
        window.open(objectUrl, "_blank");
        URL.revokeObjectURL(objectUrl);
      } catch (error) {
        console.error(error);
      }
    },
    [downloadMutate]
  );

  return (
    <>
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      <div style={{ maxWidth: "600px" }}>
        <h1>Nostoväline</h1>
        <div
          style={{ display: "flex", fontWeight: "bold", whiteSpace: "nowrap" }}
        >
          <div style={{ flex: "0 0 50%" }}>
            <p>Tyyppi:</p>
            <p>Tyypin selite:</p>
            <p>Valmistaja:</p>
            <p>Numero:</p>
            <p>Käyttöönottopvm:</p>
            <p>Viimeisin tarkistus:</p>
            <p>Voimassaolopvm:</p>
            <p>Kuorma SWL(kN):</p>
            <p>Koekuorma 1,1xSWL(kN):</p>
            <p>Koekuorma 1,25xSWL(kN):</p>
            <p>Raksi pituus (m):</p>
            <p>Sisäputki mitat:</p>
            <p>Ulkoputki mitat:</p>
            <p>Paino (kg):</p>
          </div>
          <div style={{ flex: "0 0 50%" }}>
            <p>{tool.toolTypeLabel || "-"}</p>
            <p>{tool.type || "-"}</p>
            <p>{tool.manufacturer || "-"}</p>
            <p>{tool.number || "-"}</p>
            <p>{formatDate(tool.introductionDate) || "-"}</p>
            <p>
              {formatDate(tool.latestAuditDate) || "-"}{" "}
              {tool.latestAuditHistoryId && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    downloadHandler(tool.latestAuditHistoryId);
                  }}
                  size={EButtonSize.X_SMALL}
                  icon={faDownload}
                  title="Lataa liite"
                  loading={isDownloading}
                />
              )}
            </p>
            <p>{formatDate(tool.expirationDate) || "-"}</p>
            <p>{tool.safeWorkingLoad || "-"}</p>
            <p>{tool.safeWorkingLoad11 || "-"}</p>
            <p>{tool.safeWorkingLoad125 || "-"}</p>
            <p>{tool.slingLength || "-"}</p>
            <p>{tool.innerTubeMeasurements || "-"}</p>
            <p>{tool.outerTubeMeasurements || "-"}</p>
            <p>{tool.weightKg || "-"}</p>
          </div>
        </div>
        <hr />
        <AuditHistories
          toolId={tool.id}
          auditHistories={tool?.remarks ?? []}
          type="Remark"
          isPublic
        />
      </div>
      {onReturn && (
        <Button
            onClick={onReturn}
            style={{ marginTop: "1rem" }}
        >
            Takaisin
        </Button>
      )}
    </>
  );
};

const useDownloadLatestAttachment = () => {
  const { downloadLatestAttachment } = usePublicQRCodesService();

  const {
    mutateAsync: downloadMutate,
    isPending: isDownloading,
    isError: isDownloadError,
    error: downloadError,
  } = useMutation<Blob, ErrorResponse, string>({
    mutationFn: (auditHistoryId) =>
      downloadLatestAttachment({
        kind: "AuditHistory",
        parentId: auditHistoryId,
      }),
  });

  return {
    downloadMutate,
    isDownloading,
    isDownloadError,
    downloadError,
  };
};

export default ToolQRCode;
