import React, { ReactNode, CSSProperties } from "react";

import classes from "./Table.module.scss";

interface IProps {
  children: ReactNode;
  hover?: boolean;
  className?: string;
  style?: CSSProperties;
  containerStyles?: CSSProperties;
  containerClassName?: string;
  bordered?: boolean;
  condensed?: boolean;
  removeGap?: boolean;
  removeBorder?: boolean;
}

const Table: React.FC<IProps> = ({
  children,
  hover,
  className,
  style,
  containerClassName,
  bordered,
  containerStyles,
  condensed,
  removeGap,
  removeBorder,
}) => {
  const tableClassNames = [classes.Table];
  if (hover) {
    tableClassNames.push(classes.Hover);
  }
  if (removeGap) {
    tableClassNames.push(classes.RemoveGap);
  }
  if (className) {
    tableClassNames.push(className);
  }
  const containerClassNames = [classes.Container];
  if (containerClassName) {
    containerClassNames.push(containerClassName);
  }
  if (bordered) {
    tableClassNames.push(classes.Bordered);
  }
  if (condensed) {
    tableClassNames.push(classes.Condensed);
  }
  if (removeBorder) {
    tableClassNames.push(classes.RemoveBorder);
  }
  return (
    <div style={containerStyles} className={containerClassNames.join(" ")}>
      <table style={style} className={tableClassNames.join(" ")}>
        {children}
      </table>
    </div>
  );
};

export default Table;
