import { useCallback, useMemo, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import IElement from "../../shared/IElement";
import { ElementSlot } from "../../shared/IElementUnloading";
import ElementsList from "../Elements/ElementsList";
import ElementsSearch, { EInputs, IElementsSearch } from "../Elements/ElementsSearch";
import { useElementUnloadingContext } from "./element-unloading-context";
import ElementUnloadingSelectedElements from "./ElementUnloadingSelectedElements";
import ElementUnloadingTable from "./ElementUnloadingTable";
import ProductionLineActiveElement from "../ProductionLines/ProductionLineEdit/ProductionLineActiveElement";

const ElementUnloadingDragAndDropTable: React.FC = () => {
  const {
    items,
    dragUpdateHandler,
    updateHandler,
    isDropDisabled = false,
    isDragDisabled = false,
    isWaybill = false,
    waybillDeliveryDate,
    addElementsHandler,
    removeElementHandler,
    addSpacerHandler,
  } = useElementUnloadingContext();
  
  const [search, setSearch] = useState<IElementsSearch | null | undefined>(null);
  const [selectedElements, setSelectedElements] = useState<IElement[]>([]);
  const [activeElement, setActiveElement] = useState<IElement | null>(null);
  
  const searchHandler = useCallback((data?: IElementsSearch | null) => {
    // console.log("search", data);
    setSearch(data);
  }, []);

  const filteredAvailableElements = useMemo(() => {
    const elements = items[0].elements ?? [];
    if (!elements) return [];
    let filtered = [...elements].filter((value, index, array) => array.findIndex(el => el.id === value.id) === index);
    // project exists -> filter project id = this or no project id
    if (search?.name) {
      filtered = filtered.filter((element) =>
        element.name
          ?.toLowerCase()
          .includes(search?.name?.toLowerCase() ?? "")
      );
    }
    if (search?.phaseName) {
      filtered = filtered.filter((element) =>
        element.phaseName
          ?.toLowerCase()
          .includes(search?.phaseName?.toLowerCase() ?? "")
      );
    }
    return filtered;
  }, [items, search?.name, search?.phaseName]);

  const deleteHandler = useCallback((slot: ElementSlot, element: IElement) => {
    // console.log(element);
    removeElementHandler(slot, element);
  }, [removeElementHandler]);

  const selectHandler = useCallback((element: IElement) => {
    setSelectedElements(elements => {
      const newElements = [...elements];
      const index = newElements.findIndex(el => el.id === element.id);
      if (index > -1) return newElements.filter(el => el.id !== element.id);
      return [...elements, element];
    });
  }, []);

  const addHandler = useCallback((slot: ElementSlot) => {
    // setItems(items => {
    //   const newItems = [...items];
    //   const slotIndex = newItems.findIndex(item => item.slot === slot);
    //   if (slotIndex > -1) {
    //     newItems[slotIndex].elements = [...selectedElements, ...newItems[slotIndex].elements].map(((el, index, arr) => ({
    //       ...el,
    //       position: arr.length > 0 ? arr.length - (index ?? 0) : 1,
    //       slot,
    //     })));
    //     const selectedIds = selectedElements.map(el => el.id);
    //     newItems[0].elements = newItems[0].elements.filter(el => !selectedIds.includes(el.id));
    //   }
    //   return newItems;
    // });
    addElementsHandler(slot, selectedElements);
    setSelectedElements([]);
  }, [addElementsHandler, selectedElements]);

  const updateActiveElement = useCallback((element: IElement) => {
    setActiveElement(element);
    setSelectedElements(elements => elements.map(el => el.id === element.id ? {...element} : el));
  }, []);

  return (
    <>
      <DragDropContext
        onDragUpdate={dragUpdateHandler}
        onDragEnd={updateHandler}
      >
        <div style={{ display: "flex", gap: "1rem" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              height: "100%",
            }}
          >
            {items.map((item, index) =>
              index === 0 ? null : (
                <ElementUnloadingTable
                  key={item.slot}
                  item={{ slot: item.slot, elements: item.elements }}
                  isDropDisabled={isDropDisabled}
                  isDragDisabled={isDragDisabled}
                  style={{ flex: "1 0 34%" }}
                  isWaybill={isWaybill}
                  waybillDeliveryDate={waybillDeliveryDate}
                  deleteHandler={(element) => deleteHandler(item.slot, element)}
                  onClickHandler={(element) => setActiveElement(element)}
                  onAddSpacer={isWaybill || !addSpacerHandler ? undefined : (element) => addSpacerHandler(item.slot, element)}
                />
              )
            )}
          </div>
          <span style={{ borderLeft: "thin solid gray", height: "auto" }} />
          <div style={{ width: "100%" }}>
            <ElementsSearch
              searchHandler={searchHandler}
              enabledInputs={[[EInputs.phaseName, EInputs.name]]}
            />
            {/* <ElementUnloadingTable
              key={items[0].slot}
              item={{ slot: items[0].slot, elements: items[0].elements }}
              isDropDisabled={isDropDisabled}
              isDragDisabled={isDragDisabled}
              isWaybill={isWaybill}
              waybillDeliveryDate={waybillDeliveryDate}
              isSearch
            /> */}
            <ElementsList
              productionLineElements={filteredAvailableElements}
              fetchAvailableElements={false}
              selectedElements={selectedElements}
              onClick={selectHandler}
            />
          </div>
          <span style={{ borderLeft: "thin solid gray", height: "auto" }} />
          <div style={{ width: "100%" }}>
            <ElementUnloadingSelectedElements
              selectedElements={selectedElements}
              selectHandler={selectHandler}
              addHandler={addHandler}
              isWaybill={isWaybill}
            />
          </div>
          {activeElement && (
            <>
              <span style={{ borderLeft: "thin solid gray", height: "auto" }} />
              <div style={{ width: "100%" }}>
                <ProductionLineActiveElement
                  productionLineId={activeElement.productionLineId!}
                  element={activeElement}
                  unselectHandler={() => setActiveElement(null)}
                  updateActiveElement={updateActiveElement}
                />
              </div>
            </>
          )}
        </div>
      </DragDropContext>
    </>
  );
};

export default ElementUnloadingDragAndDropTable;
