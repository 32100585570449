import React, { Fragment } from "react";
import IElement from "../../shared/IElement";
import IElementDTO from "../../shared/IElementDTO";
import { ElementSlot } from "../../shared/IElementUnloading";
import IWaybillRow from "../../shared/IWaybillRow";
import { findElement } from "../../utils/element-utils";
import PageHeading from "../ui/PageHeading/PageHeading";
import Table from "../ui/Table/Table";
import Spacer from "../Spacer/Spacer";

interface IProps {
  rows: IWaybillRow[];
  elements: IElement[] | IElementDTO[];
}

const ProductionLineWarehouseTable: React.FC<IProps> = ({
  rows,
  elements: unsortedElements,
}) => {
  if (!rows || !unsortedElements) return null;
  const rowsBySlot = rows
    .filter((row) => typeof row.slot !== "undefined" && !!row.elementId)
    .reduce(
      (result: Record<string, IWaybillRow[]>, currentValue: IWaybillRow) => {
        (result[currentValue.slot!] = result[currentValue.slot!] || []).push(
          currentValue
        );
        return result;
      },
      {}
    );
  const elementsBySlot = ["A", "B", "C", "D", "E", "F", "G", "H"].map(
    (key) => ({
      slot: key as ElementSlot,
      elements: rowsBySlot[key] ?? [],
    })
  );
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        height: "100%",
      }}
    >
      {elementsBySlot.map(({ slot, elements }) => (
        <div
          key={slot}
          style={{
            flex: "0 0 50%",
            display: "flex",
            gap: "0.5rem",
            padding: "0.5rem",
            alignItems: "flex-start",
          }}
        >
          <PageHeading variant="h2" noHorizontalMargin>
            {slot}
          </PageHeading>
          <Table removeBorder condensed style={{ whiteSpace: "nowrap" }}>
            <thead>
              <tr>
                <th>#</th>
                <th>Työmaa</th>
                <th>Tunnus</th>
                <th>Pituus</th>
                <th>Halk</th>
                <th>Paino</th>
              </tr>
            </thead>
            <tbody>
              {elements.map((row) => {
                const element = row.elementId
                  ? findElement(row.elementId, unsortedElements as IElement[])
                  : undefined;
                return (
                  <Fragment key={row.id}>
                    <tr>
                        <td>{row.order}</td>
                        <td>{element?.projectNumber}</td>
                        <td>{element?.name}</td>
                        <td>{element?.length}</td>
                        <td>{element?.provisionSummary?.hasSplit ? element.width : ""}</td>
                        <td>{element?.weightTons}</td>
                    </tr>
                    {row.spacer && (
                        <tr>
                            <td colSpan={6}>
                                <Spacer />
                            </td>
                        </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </Table>
        </div>
      ))}
    </div>
  );
};

export default ProductionLineWarehouseTable;
