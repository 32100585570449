import { useQuery } from "@tanstack/react-query";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorResponse } from "../../custom-fetch";
import { Route } from "../../routes";
import { QueryKey } from "../../services/query-keys";
import { useWireReelService } from "../../services/wireReels-service";
import IWireReel, { WIRE_REEL_TYPE_OPTIONS } from "../../shared/IWireReel";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import Table from "../ui/Table/Table";
import { queryClient } from "../../App";
import Button, { EButtonSize } from "../ui/Button/Button";
import { faChevronDown, faChevronUp, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate } from "../../utils/date-utils";

enum EListSortBy {
  locationFactory = "locationFactory",
  arrivalDate = "arrivalDate",
  reelNumber = "reelNumber",
  manufacturer = "manufacturer",
  priceTotal = "priceTotal",
  weight = "weight",
  activationDate = "activationDate",
  finished = "finished",
  activationLocation = "activationLocation",
  notes = "notes",
  archived = "archived"
}

const WireReelsList: React.FC = () => {
  const { fetchWireReels, archivedUpdateWireReel } = useWireReelService();
  const navigate = useNavigate();
  const [updatingIds, setUpdatingIds] = useState<string[]>([]);

  const {
    data: wireReels,
    isPending,
    isError,
    error,
  } = useQuery<IWireReel[], ErrorResponse>({
    queryKey: [QueryKey.wireReels],
    queryFn: fetchWireReels,
    staleTime: 5000,
  });

  const [sorted, setSorted] = useState<IWireReel[]>([]);
  const [sort, setSort] = useState<EListSortBy>();
  const [sortDirection, setSortDirection] = useState<boolean>(false); // true = up, false = down
  const changeSortOrDirection = (sortBy: EListSortBy) => {
    if (sortBy === sort) {
      setSortDirection(!sortDirection);
    } else {
      setSort(sortBy);
      setSortDirection(false);
    }
  }
  const getChevron = () => {
    return <FontAwesomeIcon style={{ marginLeft: '.5rem', fontSize: '.75rem'}} icon={sortDirection ? faChevronUp : faChevronDown} />
  };

  useEffect(() => {
    const newSorted = [...sorted];
    if (sort && newSorted) {
      if (sortDirection) {
        newSorted.reverse();
        setSorted(newSorted);
        return;
      }
      newSorted.sort((a, b) => {
        const getFieldValue = (item: IWireReel, field: EListSortBy) => {
          return item[field];
        };
        const aValue = getFieldValue(a, sort) || "";
        const bValue = getFieldValue(b, sort) || "";
        if (aValue === bValue) return 0;
        return aValue > bValue ? 1 : -1;
      });
      setSorted(newSorted);
    }
    // eslint-disable-next-line
  }, [sort, sortDirection]);

  useEffect(() => {
    if (wireReels) {
      setSorted(wireReels);
    }
  }, [wireReels])

  const archivedUpdateHandler = async (id: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setUpdatingIds((prevIds) => [...prevIds, id]);
    try {
      const updated = await archivedUpdateWireReel(id);
      queryClient.setQueryData([QueryKey.wireReels], (wireReels: IWireReel[]) => 
        wireReels.map((item) => 
          item.id === id ? { ...item, archived: updated.archived } : item
        )
      );
    } catch (error) {
      console.error('Error updating:', error);
    } finally {
      setUpdatingIds((prevIds) => prevIds.filter((rowId) => rowId !== id));
    }
  };

  const openHandler = (id: string) => {
    navigate(Route.wireReel(id));
  };

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  if (!wireReels || wireReels.length === 0) {
    return <p>Ei vaijerikeloja</p>;
  }

  return (
    <>
      <Table hover>
        <thead style={{cursor: "pointer"}}>
          <tr>
            <th onClick={() => changeSortOrDirection(EListSortBy.locationFactory)}>Tehdas {sort === EListSortBy.locationFactory && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.arrivalDate)}>Tulo pvm {sort === EListSortBy.arrivalDate && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.reelNumber)}>Vyyhtinro {sort === EListSortBy.reelNumber && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.manufacturer)}>Valmistaja {sort === EListSortBy.manufacturer && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.priceTotal)}>Hinta € {sort === EListSortBy.priceTotal && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.weight)}>Paino kg {sort === EListSortBy.weight && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.activationDate)}>Käyttöönotto pvm / tyyppi {sort === EListSortBy.activationDate && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.finished)}>Käytetty {sort === EListSortBy.finished && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.activationLocation)}>Käyttöönotto kohde {sort === EListSortBy.activationLocation && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.notes)}>Huom {sort === EListSortBy.notes && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.archived)}>Arkistoitu {sort === EListSortBy.archived && getChevron()}</th>
          </tr>
        </thead>
        <tbody>
          {sorted.map((wireReel) => (
            <tr
              key={wireReel.id}
              onClick={() => openHandler(wireReel.id)}
              style={{
                color: wireReel.archived ? "gray" : "inherit",
                fontStyle: wireReel.archived ? "italic" : "inherit",
              }}
            >
              <td>{wireReel.locationFactory}</td>
              <td>{formatDate(wireReel.arrivalDate)}</td>
              <td>{wireReel.reelNumber}</td>
              <td>{wireReel.manufacturer}</td>
              <td>{wireReel.priceTotal}</td>
              <td>{wireReel.weight}</td>
              <td>{formatDate(wireReel.activationDate)} {wireReel.type && WIRE_REEL_TYPE_OPTIONS.find(type => type.value === wireReel.type)?.label}</td>
              <td>{wireReel.finished ? 'käytetty': ''}</td>
              <td>{wireReel.activationLocation}</td>
              <td title={wireReel.notes}>{wireReel.notes ? wireReel.notes.substring(0,200) : ""}</td>
              <td><Button size={EButtonSize.X_SMALL} loading={updatingIds.includes(wireReel.id)} onClick={(event)=>archivedUpdateHandler(wireReel.id, event)} icon={wireReel.archived ? faEyeSlash : faEye} title={wireReel.archived ? "Poista arkistosta" : "Arkistoi"} /></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default WireReelsList;
