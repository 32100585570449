import { useCallback, useId, useMemo } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { useDragAndDrop } from "../../hooks/useDragAndDrop";
import IElement from "../../shared/IElement";
import IWaybillRow from "../../shared/IWaybillRow";
import { addCalculationFields, calculateTotalLength } from "../Elements/ElementsDragAndDropTable";
import PageHeading from "../ui/PageHeading/PageHeading";
import Table from "../ui/Table/Table";
import { findElement } from "../../utils/element-utils";
import ProductionLineUnloadingTable from "./ProductionLineUnloadingTable";
import Button, { EButtonSize } from "../ui/Button/Button";

interface IProps {
  elements: IElement[];
  unloadingRows: IWaybillRow[];
  castingRows: IWaybillRow[];
  setCastingRows: React.Dispatch<React.SetStateAction<IWaybillRow[]>>;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  lineLength: string;
}

const ProductionLineElementUnloadingDragAndDropTable: React.FC<IProps> = ({ elements, unloadingRows, castingRows, setCastingRows, setIsDirty, lineLength }) => {
  const { dragEndHandler, dragUpdateHandler, dragStartHandler, getItemStyle, getListStyle } = useDragAndDrop(castingRows, setCastingRows, "order");

  const castingElements = useMemo(() => castingRows.map(row => elements?.find(element => element.id === row.elementId)!), [castingRows, elements]);
  const calculationElements = useMemo(() => addCalculationFields(castingElements), [castingElements]);

  const lengthTotal = useMemo(() => (calculateTotalLength(calculationElements)),[calculationElements]);

  const onDragEnd = useCallback((result: DropResult) => {
    dragEndHandler(result);
    setIsDirty(true);
  }, [dragEndHandler, setIsDirty]);

  const reverseHandler = useCallback(() => {
    setCastingRows(castingRows => [...castingRows].reverse().map((row, index) => ({ ...row, order: `${index + 1}` })));
    setIsDirty(true);
  }, [setCastingRows, setIsDirty]);

  return (
    <div style={{ display: "flex", gap: "1rem" }}>
      <ProductionLineUnloadingTable rows={unloadingRows} elements={calculationElements} title="3. Purkujärjestys" />
      <Table style={{ background: "#ccc", padding: "1rem" }}>
        <caption>
          <PageHeading variant="h3" noHorizontalMargin>
            4. Valujärjestys
            <Button onClick={reverseHandler} size={EButtonSize.SMALL}>
              Käännä järjestys
            </Button>
          </PageHeading>
        </caption>
        <thead>
          <tr>
            <th style={{ width: "80px" }}>#</th>
            <th style={{ width: "125px" }}>Työmaa</th>
            <th style={{ width: "125px" }}>Tunnus</th>
            <th style={{ width: "125px" }}>Pituus</th>
            <th style={{ width: "125px" }}>Vaijerityyppi</th>
            <th style={{ width: "125px" }}>Varaukset</th>
            <th style={{ width: "125px" }}>Halkaisija</th>
            <th style={{ width: "125px" }}>Vasen</th>
            <th style={{ width: "125px" }}>Oikea</th>
            <th style={{ width: "125px" }}>Paino</th>
            <th style={{ width: "100px" }}>Vko</th>
            <th style={{ width: "100px" }}>Toim</th>
          </tr>
        </thead>
        <DragDropContext onDragUpdate={dragUpdateHandler} onDragEnd={onDragEnd} onDragStart={dragStartHandler}>
          <Droppable droppableId={useId()}>
            {(provided, snapshot) => (
              <tbody
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {castingRows.map((row, index) => (
                  <Draggable key={row.id} draggableId={row.id} index={index}>
                    {(provided, snapshot) => {
                        const element = row.elementId ? findElement(row.elementId, calculationElements) : undefined;
                        return (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <td style={{ width: "80px" }}>{row.order}</td>
                              {element ? (
                                <>
                                  <td style={{ width: "125px" }}>{element.projectNumber}</td>
                                  <td style={{ width: "125px" }}>{element.name}</td>
                                  <td style={{ width: "125px" }}>{element.splitString ? "("+element.length+")" : element.length}</td>
                                  <td style={{ width: "125px" }}>{element.typeName}</td>
                                  <td style={{ width: "125px" }} title={element.provisionAmountLong}>
                                    {element.provisionAmountShort}
                                    {element.provisionAmountShort && !element.isPlanned && !element.hasAttachments && <span title="Varausten liitteet puuttuu" style={{ alignSelf: "center", fontWeight: "bold" }}>!</span>}
                                  </td>
                                  <td style={{ width: "125px" }}>{element.width} {element.splitString && <span><br/>{element.splitString}</span>}</td>
                                  <td style={{ width: "125px" }}>{element.provisionSummary?.leftString} {element.calculatedSlantedDistanceLeft ? <span style={{fontSize: "0.8rem"}}><br/>-{element.calculatedSlantedDistanceLeft}</span> : ""}</td>
                                  <td style={{ width: "125px" }}>{element.provisionSummary?.rightString} {element.calculatedSlantedDistanceRight ? <span style={{fontSize: "0.8rem"}}><br/>-{element.calculatedSlantedDistanceRight}</span> : ""}</td>
                                  <td style={{ width: "125px" }}>{element.weightTons}</td>
                                  <td style={{ width: "100px" }}>{element.productionWeek}</td>
                                  <td style={{ width: "100px" }}>{element.deliveryWeek}</td>
                                </>
                              ) : <td colSpan={10}></td>}
                            </tr>
                        );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
        <tfoot>
          <tr>
            <td>Yhteensä</td>
            <td></td>
            <td colSpan={7} style={lengthTotal > +(lineLength ?? 0) ? { color: "red" } : {}}>{lengthTotal} / {lineLength} = jäljellä {+(lineLength ?? 0)-lengthTotal}</td>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
};

export default ProductionLineElementUnloadingDragAndDropTable;
