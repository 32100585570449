import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Route } from "../../routes";
import IProductionLine from "../../shared/IProductionLine";
import { formatDate } from "../../utils/date-utils";
import Table from "../ui/Table/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import IWireType from "../../shared/IWireType";

interface IProps {
  productionLines: IProductionLine[];
  showTotalRow?: boolean
}

enum EListSortBy {
  statusLabel = "statusLabel",
  startDate = "startDate",
  productionLineNumber = "productionLineNumber",
  position = "position",
  factory = "factory",
  projectNumberPhasesName = "projectNumberPhasesName",
  wireType = "wireType",
  height = "height",
  brushed = "brushed",
  linealMeters = "linealMeters",
  count = "count",
  weightTons = "weightTons",
  concreteVolume = "concreteVolume",
  deliveryWeeks = "deliveryWeeks",
  liftingLoopCount = "liftingLoopCount"
}

const ProductionLinesTable: React.FC<IProps> = ({ productionLines, showTotalRow }) => {
  const navigate = useNavigate();
  const [sorted, setSorted] = useState<IProductionLine[]>(productionLines);
  const [sort, setSort] = useState<EListSortBy>();
  const [sortDirection, setSortDirection] = useState<boolean>(false); // true = up, false = down
  const changeSortOrDirection = (sortBy: EListSortBy) => {
    if (sortBy === sort) {
      setSortDirection(!sortDirection);
    } else {
      setSort(sortBy);
      setSortDirection(false);
    }
  }
  const getChevron = () => {
    return <FontAwesomeIcon style={{ marginLeft: '.5rem', fontSize: '.75rem'}} icon={sortDirection ? faChevronUp : faChevronDown} />
  };

  useEffect(() => {
    const newSorted = [...sorted];
    if (sort && newSorted) {
      if (sortDirection) {
        newSorted.reverse();
        setSorted(newSorted);
        return;
      }
      newSorted.sort((a, b) => {
        const getFieldValue = (item: IProductionLine, field: EListSortBy) => {
          return item[field];
        };
        const aValue = getFieldValue(a, sort) || "";
        const bValue = getFieldValue(b, sort) || "";
        if (aValue === bValue) return 0;
        return aValue > bValue ? 1 : -1;
      });
      setSorted(newSorted);
    }
    // eslint-disable-next-line
  }, [sort, sortDirection]);

  const openHandler = useCallback(
    (id: string) => {
      navigate(Route.productionLine(id));
    },
    [navigate]
  );

  return (
    <Table hover>
      <thead style={{cursor: "pointer"}}>
        <tr>
          <th onClick={() => changeSortOrDirection(EListSortBy.statusLabel)}>Tila {sort === EListSortBy.statusLabel && getChevron()}</th>
          <th onClick={() => changeSortOrDirection(EListSortBy.startDate)}>Valu pvm {sort === EListSortBy.startDate && getChevron()}</th>
          <th onClick={() => changeSortOrDirection(EListSortBy.productionLineNumber)}>Peti {sort === EListSortBy.productionLineNumber && getChevron()}</th>
          {/* <th>Purku pvm</th> */}
          <th onClick={() => changeSortOrDirection(EListSortBy.position)}>Järj. {sort === EListSortBy.position && getChevron()}</th>
          <th onClick={() => changeSortOrDirection(EListSortBy.factory)}>Tehdas {sort === EListSortBy.factory && getChevron()}</th>
          <th onClick={() => changeSortOrDirection(EListSortBy.projectNumberPhasesName)}>TM {sort === EListSortBy.projectNumberPhasesName && getChevron()}</th>
          <th onClick={() => changeSortOrDirection(EListSortBy.wireType)}>Vaijerityyppi {sort === EListSortBy.wireType && getChevron()}</th>
          <th onClick={() => changeSortOrDirection(EListSortBy.height)}>Paksuus {sort === EListSortBy.height && getChevron()}</th>
          <th onClick={() => changeSortOrDirection(EListSortBy.brushed)}>Harjattu {sort === EListSortBy.brushed && getChevron()}</th>
          <th onClick={() => changeSortOrDirection(EListSortBy.linealMeters)}>Jm {sort === EListSortBy.linealMeters && getChevron()}</th>
          <th onClick={() => changeSortOrDirection(EListSortBy.count)}>Kpl {sort === EListSortBy.count && getChevron()}</th>
          <th onClick={() => changeSortOrDirection(EListSortBy.weightTons)}>Tonnit {sort === EListSortBy.weightTons && getChevron()}</th>
          <th onClick={() => changeSortOrDirection(EListSortBy.liftingLoopCount)}>Nostolenkit {sort === EListSortBy.liftingLoopCount && getChevron()}</th>
          <th onClick={() => changeSortOrDirection(EListSortBy.concreteVolume)}>Massan määrä {sort === EListSortBy.concreteVolume && getChevron()}</th>
          <th onClick={() => changeSortOrDirection(EListSortBy.deliveryWeeks)}>Toimitusvko {sort === EListSortBy.deliveryWeeks && getChevron()}</th>
        </tr>
      </thead>
      <tbody>
        {sorted.map((productionLine) => (
          <tr
            key={productionLine.id}
            onClick={() => openHandler(productionLine.id!)}
          >
            <td>{productionLine.statusLabel}</td>
            <td>{formatDate(productionLine.startDate)}</td>
            <td>{productionLine.productionLineNumber}</td>
            {/* <td>{formatDate(productionLine.endDate)}</td> */}
            <td>{productionLine.position}</td>
            <td>{productionLine.factory}</td>
            <td style={{ background: productionLine.customerDisruption ? "red" : "" }} title={productionLine.customerDisruption ? "Edistäminen vaatii luvan" : undefined}>{productionLine.projectNumberPhasesName}</td>
            <td>{(productionLine.wireType as IWireType)?.name}</td>
            <td>{productionLine.height}</td>
            <td>{productionLine.brushed && <FontAwesomeIcon icon={faCheck} />}</td>
            <td>{productionLine.linealMeters}</td>
            <td>{productionLine.count}</td>
            <td>{productionLine.weightTons}</td>
            <td>{productionLine.liftingLoopCount}</td>
            <td>
              {productionLine.concreteVolume?.toFixed(2)}
              {" "}
              {productionLine.concreteTypeNames && <span style={{ fontWeight: "bold "}}>! {productionLine.concreteTypeNames}</span>}
            </td>
            <td>{productionLine.deliveryWeeks}</td>
          </tr>
        ))}
        {showTotalRow && (
            <tr>
                <td colSpan={7}>Yhteensä</td>
                <td>{productionLines.map(productionLine => productionLine.linealMeters).reduce((prev, cur) => cur += prev).toFixed(2)}</td>
                <td></td>
            </tr>
        )}
      </tbody>
    </Table>
  );
};

export default ProductionLinesTable;
