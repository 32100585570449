import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { ErrorResponse } from "../../custom-fetch";
import { useWaybillsService } from "../../services/waybills-service";
import {
  EModalId,
  EModalSize,
  useModalContext,
} from "../ui/Modal/modal-context";
import WaybillSendModal from "./WaybillSendModal";

export interface ISendWaybills {
  waybillIds?: string[];
  recipients: string[];
}

export const useWaybillSendModal = () => {
  const { addModal, closeModal } = useModalContext();
  const closeModalHandler = useCallback(() => closeModal(EModalId.WAYBILLS_SEND), [closeModal]);

  const { sendWaybillsMutate
    // isSending, isSendingError, sendingError
   } = useSendWaybills();

  const handler = (initialData: ISendWaybills): Promise<boolean> => {
    let title = "Lähetä rahtikirja";
    if ((initialData.waybillIds?.length ?? 0) > 1) title += "t";
    return new Promise((resolve) => {
      addModal({
        id: EModalId.WAYBILLS_SEND,
        isOpen: true,
        size: EModalSize.SMALL,
        title,
        // backdropNotClose: true,
        content: (
          <WaybillSendModal
            onAccept={async (data) => {
              try {
                await sendWaybillsMutate({ waybillIds: initialData.waybillIds, recipients: data.recipients });
                // console.log(organization);
                resolve(true);
                closeModalHandler();
              } catch (e) {
                console.error(e);
              }
            }}
            onCancel={() => {
              closeModalHandler();
              resolve(false);
            }}
            initialData={initialData}
          />
        ),
      });
    });
  };

  return handler;
};

const useSendWaybills = () => {
  const { sendWaybills } = useWaybillsService();

  const {
    mutateAsync: sendWaybillsMutate,
    isPending: isSending,
    isError: isSendingError,
    error: sendingError,
  } = useMutation<boolean, ErrorResponse, ISendWaybills>({
    mutationFn: sendWaybills,
  });

  return { sendWaybillsMutate, isSending, isSendingError, sendingError };
};
