import { useCallback } from "react";
import IProductionLine from "../../shared/IProductionLine";
import {
  EModalId,
  EModalSize,
  useModalContext,
} from "../ui/Modal/modal-context";
import ProductionLineEditModal from "./ProductionLineEditModal";
import { useMutation } from "@tanstack/react-query";
import { ErrorResponse } from "../../custom-fetch";
import { useProductionLinesService } from "../../services/productionLines-service";
import { useConfirmModal } from "../ui/Modal/useConfirmModal";

export const useProductionLineEditModal = () => {
  const { addModal, closeModal } = useModalContext();

  const closeModalHandler = useCallback(
    () => closeModal(EModalId.PRODUCTION_LINE_EDIT),
    [closeModal]
  );

  const { updateMutate
    // isUpdating, isUpdateError, updateError
   } = useUpdate();

  const openConfirmModal = useConfirmModal();

  const handler = (productionLine: IProductionLine): Promise<IProductionLine | null> => {
    let title = "Pedin purku";
    return new Promise((resolve) => {
      addModal({
        id: EModalId.PRODUCTION_LINE_EDIT,
        isOpen: true,
        size: EModalSize.SMALL,
        title,
        // backdropNotClose: true,
        content: (
          <ProductionLineEditModal
            onAccept={async (data) => {
              if (data.status) {
                const isConfirm = await openConfirmModal("Oletko varma, että haluat päivittää pedin tilan puretuksi?");
                if (!isConfirm) {
                  return;
                }
              }
              try {
                const newProductionLine = await updateMutate({ ...productionLine, ...data, status: data.status ?? productionLine.status });
                // console.log(organization);
                resolve(newProductionLine);
                closeModalHandler();
              } catch (e) {
                console.error(e);
              }
            }}
            onCancel={() => {
              closeModalHandler();
              resolve(null);
            }}
            initialData={productionLine}
          />
        ),
      });
    });
  };

  return handler;
};

const useUpdate = () => {
  const { updateProductionLineEndDate } = useProductionLinesService();

  const {
    mutateAsync: updateMutate,
    isPending,
    isError,
    error,
  } = useMutation<IProductionLine, ErrorResponse, IProductionLine>({
    mutationFn: (data) => updateProductionLineEndDate(data.id!, data),
    // onSuccess: (data) => {
    //   // if (data.factory) {
    //   //   queryClient.invalidateQueries({ queryKey: [QueryKey.productionLines, data.factory] });
    //   // }
    //   // queryClient.setQueryData([QueryKey.productionLines, data.id], data);
    // },
  });

  return {
    updateMutate,
    isUpdating: isPending,
    isUpdateError: isError,
    updateError: error,
  };
};
