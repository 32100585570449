import { useQuery } from "@tanstack/react-query";
import { useParams, useSearchParams } from "react-router-dom";
import ClaimEdit from "../components/Claims/ClaimEdit/ClaimEdit";
import Container from "../components/ui/Container/Container";
import { ErrorResponse } from "../custom-fetch";
import { QueryKey } from "../services/query-keys";
import { useMemo } from "react";
import IProductionLine from "../shared/IProductionLine";
import { usePublicQRCodesService } from "../services/publicQRCodes-service";
import IElementDTO from "../shared/IElementDTO";
import IWaybillDTO from "../shared/IWaybillDTO";
import ErrorsAlert, { combineErrors } from "../components/ErrorsAlert/ErrorsAlert";
import IClaim from "../shared/IClaim";
import Spinner from "../components/ui/Spinner/Spinner";

type Params = {
  id: string;
};

const ClaimPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const projectId = useMemo(() => searchParams.get("projectId"), [searchParams]);
  const elementId = useMemo(() => searchParams.get("elementId"), [searchParams]);
  const productionLineId = useMemo(() => searchParams.get("productionLineId"), [searchParams]);
  const waybillId = useMemo(() => searchParams.get("waybillId"), [searchParams]);
  const { id } = useParams<Params>();
  const { getElementByUuid, getProductionLineByUuid, getWaybillByUuid, getClaim } = usePublicQRCodesService();

  const {
    data: claim,
    isLoading,
    isError,
    error,
  } = useQuery<IClaim, ErrorResponse>({
    queryKey: [QueryKey.claims, id],
    queryFn: ({ signal }) => getClaim({ signal, id: id! }),
    enabled: id !== "add",
  });

  const {
    data: element,
    isLoading: elementIsLoading,
    isError: elementIsError,
    error: elementError,
  } = useQuery<IElementDTO, ErrorResponse>({
    queryKey: [QueryKey.elements, elementId],
    queryFn: ({ signal }) => getElementByUuid({ signal, id: elementId! }),
    enabled: !!elementId,
  });

  const {
    data: productionLine,
    isLoading: productionLineIsLoading,
    isError: productionLineIsError,
    error: productionLineError,
  } = useQuery<IProductionLine, ErrorResponse>({
    queryKey: [QueryKey.productionLines, productionLineId],
    queryFn: ({ signal }) => getProductionLineByUuid({ signal, id: productionLineId! }),
    enabled: !!productionLineId,
  });

  const {
    data: waybill,
    isLoading: waybillIsLoading,
    isError: waybillIsError,
    error: waybillError,
  } = useQuery<IWaybillDTO, ErrorResponse>({
    queryKey: [QueryKey.waybills, waybillId],
    queryFn: ({ signal }) => getWaybillByUuid({ signal, id: waybillId! }),
    enabled: !!waybillId,
  });

  const errorMessages = combineErrors({ isError, error }, { isError: elementIsError, error: elementError }, { isError: productionLineIsError, error: productionLineError }, { isError: waybillIsError, error: waybillError });

  return (
    <>
      <h1 style={{ margin: "1rem" }}>Ilmoita reklamaatio / poikkeama</h1>
      <h2 style={{ margin: "1rem" }}>{claim ? claim.identifier : element ? element.name : productionLine ? "Peti "+productionLine.productionLineNumber : waybill ? "Rahkirja "+waybill.waybillId : ""}</h2>
      <Container>
        {errorMessages.length > 0 ? (
          <ErrorsAlert errors={errorMessages} />
        ) : isLoading || elementIsLoading || productionLineIsLoading || waybillIsLoading ? (
          <Spinner />
        ) : (
          <ClaimEdit id={id ?? ""} claim={claim} projectId={projectId} element={element} productionLine={productionLine} waybill={waybill} isPublic={true}/>
        )}
      </Container>
    </>
  );
};

export default ClaimPage;
