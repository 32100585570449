import { useQuery } from "@tanstack/react-query";
import { Params, useParams } from "react-router-dom";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import ToolEdit from "../components/Tools/ToolEdit";
import Container from "../components/ui/Container/Container";
import Spinner from "../components/ui/Spinner/Spinner";
import { ErrorResponse } from "../custom-fetch";
import { QueryKey } from "../services/query-keys";
import { useToolsService } from "../services/tools-service";
import ITool from "../shared/ITool";

const ToolPage: React.FC = () => {
  const { id } = useParams<Params>();
  const { getTool } = useToolsService();

  const {
    data: tool,
    isLoading,
    isError,
    error,
  } = useQuery<ITool, ErrorResponse>({
    queryKey: [QueryKey.tools, id],
    queryFn: ({ signal }) => getTool({ signal, id: id! }),
    enabled: id !== 'add',
  });

  return (
    <>
      <h1 style={{ margin: "1rem" }}>Nostoväline</h1>
      <Container>
        {isError ? (
          <ErrorsAlert errors={error.messages} />
        ) : isLoading ? (
          <Spinner />
        ) : (
          <ToolEdit id={id!} tool={tool} />
        )}
      </Container>
    </>
  );
};

export default ToolPage;
