import { forwardRef, useImperativeHandle, useState } from "react";
import { EInputType, IInputField } from "../../ui/Input/Input";
import { useInputs } from "../../ui/Input/useInputs";
import { RefHandle } from "../../../shared/RefHandle";

enum EInputs {
  height = "height",
}

interface IMeasurementResult {
  height: string;
}

interface IProps {
  number: number;
  measurementResult?: IMeasurementResult;
}

const MeasurementResult: React.ForwardRefRenderFunction<
  RefHandle<string>,
  IProps
> = ({ number, measurementResult }, ref) => {
  const { createInput, submit } = useMeasurementResultInputs(measurementResult);
  useImperativeHandle(
    ref,
    () => ({
      getData: async () => {
        const data = await submit();
        if (!data) return null;
        return data.height;
      },
    }),
    [submit]
  );
  return <>{createInput(EInputs.height, { label: number + ". Korkeus (mm)" })}</>;
};

const useMeasurementResultInputs = (data?: IMeasurementResult) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.height]: {
      type: EInputType.number,
      value: "",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit };
};

export default forwardRef(MeasurementResult);
