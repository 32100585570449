import { faRoute } from "@fortawesome/free-solid-svg-icons";
import { useCallback } from "react";
import imgTruckCab from "../../assets/truck-cab.png";
import { getFactoryAddress } from "../../shared/IOfferRow";
import IWaybillDTO from "../../shared/IWaybillDTO";
import { formatDate } from "../../utils/date-utils";
import Accordion from "../ui/Accordion/Accordion";
import Button, { EButtonSize } from "../ui/Button/Button";

interface IProps {
  waybill: IWaybillDTO;
  onReturn: () => void;
}

const elementNamesStyle = {
  fontWeight: "600",
};

const CustomerWaybill: React.FC<IProps> = ({ waybill, onReturn }) => {
  const { elementDtos: elements, factoryDto: factory } = waybill;

  const getSlotsWeight = (slots: string[]) => {
    const data = waybill.waybillRows?.filter(
      (row) => row.slot && slots.includes(row.slot)
    );
    const totalWeightTons =
      data?.reduce((sum, row) => {
        const element = elements?.find((e) => e.id === row.elementId);
        const weightTons = +(element?.weightTons ?? "0");
        return sum + weightTons;
      }, 0) ?? 0;
    return totalWeightTons;
  };

  const totalWeightCar = getSlotsWeight(["A", "B", "C", "D", "1", "2"]);
  const totalWeightTruck = getSlotsWeight([
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "3",
    "4",
    "5",
  ]);
  const totalWeight = totalWeightCar + totalWeightTruck;

  const totalAmount = waybill.waybillRows?.length ?? "0";

  const elementText =
    "Kuorma: " + totalAmount + " kpl / " + totalWeight.toFixed(2) + " ton";

  const getSlotElements = (slot: string) => {
    const data = waybill.waybillRows?.filter((row) => row.slot === slot);
    const totalWeightTons = getSlotsWeight([slot]).toFixed(2);

    return (
      data &&
      data.length > 0 && (
        <td
          colSpan={2}
          style={{ textAlign: "left", border: "1px solid darkgray" }}
        >
          <table>
            <tbody>
              {data.map((row) => {
                const element = elements?.find((e) => e.id === row.elementId);
                return (
                  <tr key={element?.id}>
                    <td>{row.order}.</td>
                    <td style={{ ...elementNamesStyle, whiteSpace: "nowrap" }}>
                      {element?.name}
                    </td>
                    <td>{element?.length}</td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {element?.weightTons} t
                    </td>
                    <td>
                      {element?.provisionSummary.hasSplit && "H"}
                      {element?.provisionSummary.hasDeepPlug && "T"}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td
                  colSpan={4}
                  style={{
                    ...elementNamesStyle,
                    whiteSpace: "nowrap",
                    textAlign: "right",
                  }}
                >
                  {totalWeightTons} t
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      )
    );
  };

  const slotElementsA = getSlotElements("A");
  const slotElementsB = getSlotElements("B");
  const slotElementsC = getSlotElements("C");
  const slotElementsD = getSlotElements("D");
  const slotElementsE = getSlotElements("E");
  const slotElementsF = getSlotElements("F");
  const slotElementsG = getSlotElements("G");
  const slotElementsH = getSlotElements("H");
  const slotElementsI = getSlotElements("I");
  const slotElementsJ = getSlotElements("J");
  const slotElements1 = getSlotElements("1");
  const slotElements2 = getSlotElements("2");
  const slotElements3 = getSlotElements("3");
  const slotElements4 = getSlotElements("4");
  const slotElements5 = getSlotElements("5");

  const openGoogleMapsHandler = useCallback(() => {
    if (!waybill.destination || !waybill.factory) {
      return;
    }
    const url = `https://www.google.com/maps/dir/?api=1&travelmode=driving&origin=${encodeURIComponent(
      getFactoryAddress(waybill.factory)
    )}&destination=${encodeURIComponent(waybill.destination)}`;
    window.open(url, "_blank");
  }, [waybill.destination, waybill.factory]);

  return (
    <div>
      <h3 style={{ margin: "0", lineHeight: "1.2" }}>
        Lähete: {waybill.waybillId}
      </h3>
      <h3 style={{ margin: "0", lineHeight: "1.2" }}>
        Toimitetaan: {formatDate(waybill.deliveryDate)} {waybill.deliveryTime}{" "}
      </h3>
      <div>
        Työmaa: {waybill.projectNumber} {waybill.projectName}
      </div>
      <div>
        {waybill.orderNumber ? "Tilausnumero: " + waybill.orderNumber : ""}
      </div>
      <div>
        <b>{waybill.destination}</b>{" "}
        {waybill.destination && waybill.factory && (
          <Button
            onClick={openGoogleMapsHandler}
            icon={faRoute}
            size={EButtonSize.X_SMALL}
          />
        )}
      </div>
      <div>{waybill.kilometers} km </div>
      {/* <br /> */}
      <p>Huomautukset:</p>
      <p style={{ paddingLeft: "20px" }}>{waybill.notes}</p>
      <p style={{ paddingLeft: "20px" }}>{waybill.additionalNotes}</p>
      <p>
        Lähettämö:{" "}
        <a href={`tel:${factory?.phoneNumberShipping}`}>
          {factory?.phoneNumberShipping}
        </a>
      </p>
      {waybill.contactPersonName && (
        <p>Yhteyshenkilön nimi: {waybill.contactPersonName}</p>
      )}
      {waybill.contactPersonPhone && (
        <p>Yhteyshenkilön puhelin: {waybill.contactPersonPhone}</p>
      )}
      {waybill.contactPersonEmail && (
        <p>Yhteyshenkilön email: {waybill.contactPersonEmail}</p>
      )}
      <Accordion
        text="Lähettäjä ja kuljetusliike"
        style={{ backgroundColor: "#DDD" }}
      >
        <p>Pielisen Betoni Oy</p>
        <p>{factory?.streetAddress}</p>
        <p>
          {factory?.zip} {factory?.city}
        </p>
        <p>Puh: {factory?.phoneNumber}</p>

        {/* <p>Fax: {factory?.fax}</p> */}
        <p>
          Email: <a href={`mailto:${factory?.email}`}>{factory?.email}</a>
        </p>
        <p>Y-tunnus: 2872212-8 ALV rek.</p>

        <p>Kuljetusliike: {waybill.driverOrganization?.name}</p>
        <p>
          Auto / Kuski: {waybill.car?.name} / {waybill.driver}
        </p>
      </Accordion>
      <Accordion text={elementText} style={{ backgroundColor: "#fff" }}>
        {(slotElementsA ||
          slotElementsB ||
          slotElementsC ||
          slotElementsD ||
          slotElements1 ||
          slotElements2) && (
          <>
            <div>Vetoauto, {totalWeightCar.toFixed(2)} ton</div>
            <img
              src={imgTruckCab}
              style={{ width: "100%", maxWidth: "350px" }}
              alt="truck"
            />
            <div style={{ overflow: "scroll" }}>
              <table style={{ width: "95%", border: "none", margin: "10px" }}>
                <tbody>
                  <tr>
                    <td colSpan={2}>{slotElementsA && "A"}</td>
                    <td colSpan={2}>{slotElementsB && "B"}</td>
                  </tr>
                  <tr>
                    {slotElementsA ? slotElementsA : <td colSpan={2} />}
                    {slotElementsB ? slotElementsB : <td colSpan={2} />}
                  </tr>
                  <tr>
                    <td></td>
                    <td>{slotElements1 && "1."}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "30%" }}></td>
                    {slotElements1}
                    <td style={{ width: "30%" }}></td>
                  </tr>
                  <tr>
                    <td colSpan={2}>{slotElementsC && "C"}</td>
                    <td colSpan={2}>{slotElementsD && "D"}</td>
                  </tr>
                  <tr>
                    {slotElementsC ? slotElementsC : <td colSpan={2} />}
                    {slotElementsD ? slotElementsD : <td colSpan={2} />}
                  </tr>
                  <tr>
                    <td></td>
                    <td>{slotElements2 && "2."}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "30%" }}></td>
                    {slotElements2}
                    <td style={{ width: "30%" }}></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr></hr>
          </>
        )}
        {(slotElementsE ||
          slotElementsF ||
          slotElementsG ||
          slotElementsH ||
          slotElementsI ||
          slotElementsJ ||
          slotElements3 ||
          slotElements4 ||
          slotElements5) && (
          <>
            <div>Perävaunu, {totalWeightTruck.toFixed(2)} ton</div>
            <div style={{ overflow: "scroll" }}>
              <table style={{ width: "95%", border: "none", margin: "10px" }}>
                <tbody>
                  <tr>
                    <td colSpan={2}>{slotElementsE && "E"}</td>
                    <td colSpan={2}>{slotElementsF && "F"}</td>
                  </tr>
                  <tr>
                    {slotElementsE ? slotElementsE : <td colSpan={2} />}
                    {slotElementsF ? slotElementsF : <td colSpan={2} />}
                  </tr>
                  <tr>
                    <td></td>
                    <td>{slotElements3 && "3."}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "30%" }}></td>
                    {slotElements3}
                    <td style={{ width: "30%" }}></td>
                  </tr>
                  <tr>
                    <td colSpan={2}>{slotElementsG && "G"}</td>
                    <td colSpan={2}>{slotElementsH && "H"}</td>
                  </tr>
                  <tr>
                    {slotElementsG ? slotElementsG : <td colSpan={2} />}
                    {slotElementsH ? slotElementsH : <td colSpan={2} />}
                  </tr>
                  <tr>
                    <td></td>
                    <td>{slotElements4 && "4."}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "30%" }}></td>
                    {slotElements4}
                    <td style={{ width: "30%" }}></td>
                  </tr>
                  <tr>
                    <td colSpan={2}>{slotElementsI && "I"}</td>
                    <td colSpan={2}>{slotElementsJ && "J"}</td>
                  </tr>
                  <tr>
                    {slotElementsI ? slotElementsI : <td colSpan={2} />}
                    {slotElementsJ ? slotElementsJ : <td colSpan={2} />}
                  </tr>
                  <tr>
                    <td></td>
                    <td>{slotElements5 && "5"}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "30%" }}></td>
                    {slotElements5}
                    <td style={{ width: "30%" }}></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
        <span style={{ fontSize: "70%" }}>
          * H = halkaistu <br />* T = syvätulppa
        </span>
      </Accordion>

      <Accordion text="Elementtilista" style={{ backgroundColor: "#DDD" }}>
        <div>Paikka Tunnus Paino Pituus*Leveys Peti</div>
        <ul>
          {waybill?.waybillRows?.map((row) => {
            const element = elements?.find((e) => e.id === row.elementId);
            return (
              <li key={row.elementId}>
                {row.slot} {row.order}.
                <span style={elementNamesStyle}> {element?.name} </span>
                {element?.weightTons} t, {element?.length}*{element?.width}{" "}
                {element?.productionLineNumber}/{element?.position}{" "}
                {element?.provisionSummary.hasSplit && "H"}
                {element?.provisionSummary.hasDeepPlug && "T"}
              </li>
            );
          })}
        </ul>
      </Accordion>
      <Button
        onClick={onReturn}
        style={{ marginTop: "1rem" }}
      >
        Takaisin
      </Button>
    </div>
  );
};

export default CustomerWaybill;
