import { useCallback } from "react";
import { ISignal, useCustomFetch } from "../custom-fetch";
import IProductionLine, { EProductionLineStatus } from "../shared/IProductionLine";
import IElement from "../shared/IElement";
import IWeeklyProductionLine from "../shared/IWeeklyProductionLine";
import IWaybillRow from "../shared/IWaybillRow";

interface IFetchProductionLines extends ISignal {
  factory?: string;
}

interface IFetchWeeklyProductionLines extends ISignal {
  factory: string;
  startDate: string;
}

interface IGetProductionLine extends ISignal {
  id: string;
}

export const useProductionLinesService = () => {
  const customFetch = useCustomFetch();

  const fetchProductionLines = useCallback(async ({ signal, factory }: IFetchProductionLines) => {
    let url = "/api/production-lines/list";
    if (factory) {
      url += "/" + factory;
    }
    const [productionLines] = await customFetch<IProductionLine[]>(url, { signal });
    return productionLines;
  }, [customFetch]);

  const getProductionLine = useCallback(async ({ signal, id }: IGetProductionLine) => {
    const [productionLine] = await customFetch<IProductionLine>("/api/production-lines/get/" + id, { signal });
    return productionLine;
  }, [customFetch]);

  const saveProductionLine = useCallback(async (data: IProductionLine) => {
    const [productionLine] = await customFetch<IProductionLine>("/api/production-lines/add", { method: "POST", body: JSON.stringify(data) });
    return productionLine;
  }, [customFetch]);

  const updateProductionLine = useCallback(async (id: string, data: IProductionLine) => {
    const [productionLine] = await customFetch<IProductionLine>("/api/production-lines/update/" + id, { method: "PUT", body: JSON.stringify(data) });
    return productionLine;
  }, [customFetch]);

  const deleteProductionLine = useCallback(async (id: string) => {
    await customFetch<IProductionLine>("/api/production-lines/delete/" + id, { method: "DELETE" });
    return true;
  }, [customFetch]);

  const createProductionLines = useCallback(async (projectId: string, data: IElement[]) => {
    const [elements] = await customFetch<IElement[]>("/api/production-lines/create/" + projectId, { method: "PUT", body: JSON.stringify(data) });
    return elements;
  }, [customFetch]);

  const fetchWeeklyProductionLines = useCallback(async ({ signal, factory, startDate }: IFetchWeeklyProductionLines) => {
    let url = "/api/production-lines/list-weekly";
    const params = new URLSearchParams();
    if (factory) {
      params.append('factory', factory);
    }
    if (startDate) {
      params.append('startDate', startDate);
    }
    if (params.toString()) {
      url += `?${params.toString()}`;
    }
    const [productionLines] = await customFetch<IWeeklyProductionLine[]>(url, { signal });
    return productionLines;
  }, [customFetch]);

  const reorderProductionLines = useCallback(async (data: IProductionLine[]) => {
    await customFetch<boolean>("/api/production-lines/reorder", { method: "PUT", body: JSON.stringify(data) });
    return true;
  }, [customFetch]);

  const createUnloadingOrder = useCallback(async (id: string, data: IWaybillRow[]) => {
    const [rows] = await customFetch<IWaybillRow[]>("/api/production-lines/create-unloading-order/" + id, { method: "PUT", body: JSON.stringify(data) });
    return rows;
  }, [customFetch]);

  const updateStatus = useCallback(async (id: string, status: EProductionLineStatus) => {
    const [productionLine] = await customFetch<IProductionLine>(`/api/production-lines/update-status/${id}?status=${status}`, { method: "PUT" });
    return productionLine;
  }, [customFetch]);

  const updateProductionLineEndDate = useCallback(async (id: string, data: IProductionLine) => {
    const [productionLine] = await customFetch<IProductionLine>("/api/production-lines/update-end-date/" + id, { method: "PUT", body: JSON.stringify(data) });
    return productionLine;
  }, [customFetch]);

  const updateProductionLineSlabMeasurement = useCallback(async (id: string, data: IProductionLine) => {
    const [productionLine] = await customFetch<IProductionLine>("/api/production-lines/update-slab-measurement/" + id, { method: "PUT", body: JSON.stringify(data) });
    return productionLine;
  }, [customFetch]);

  return { fetchProductionLines, getProductionLine, saveProductionLine, updateProductionLine, deleteProductionLine, createProductionLines, fetchWeeklyProductionLines, reorderProductionLines, createUnloadingOrder, updateStatus, updateProductionLineEndDate, updateProductionLineSlabMeasurement };
};
