import IElementsStatusDTO from "../../../shared/IElementsStatusDTO";
import PageHeading from "../../ui/PageHeading/PageHeading";
import Table from "../../ui/Table/Table";

interface IProps {
  elements: IElementsStatusDTO;
}

const ElementStatusTable: React.FC<IProps> = ({ elements }) => {
  return (
    <>
      <PageHeading variant="h3">Laattatyypeittäin</PageHeading>
      {elements.elementsByProductType.length > 0 ? (
        <Table containerStyles={{ padding: "0 1rem" }}>
          <thead>
            <tr>
              <th>Tuote</th>
              <th title="määrä tilauksessa">Myyty (kpl / m² / ton)</th>
              <th title="suunniteltuja elementtejä">Tekemättä (kpl / m² / ton)</th>
              <th>Tehty (kpl / m² / ton)</th>
              <th>Toimitettu (kpl / m² / ton)</th>
            </tr>
          </thead>
          <tbody>
            {elements.elementsByProductType?.map((element) => (
              <tr key={element.productType.id}>
                <td>{element.productType.name}</td>
                <td>{element.sold}kpl / {element.soldArea}m² / {element.soldWeightTons}ton</td>
                <td>{element.notDone}kpl / {element.notDoneArea}m² / {element.notDoneWeightTons}ton</td>
                <td>{element.done}kpl / {element.doneArea}m² / {element.doneWeightTons}ton</td>
                <td>{element.delivered}kpl / {element.deliveredArea}m² / {element.deliveredWeightTons}ton</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : <p style={{ margin: "1rem" }}>Ei elementtejä</p>}

      <PageHeading variant="h3">Kerroksittain ja laattatyypeittäin</PageHeading>
      {elements.elementsByPhaseId.length > 0 ? (
        <Table containerStyles={{ padding: "0 1rem" }}>
          <thead>
            <tr>
              <th>Kerros / lohko</th>
              <th>Tuote</th>
              <th>Karkea kuorma (kpl / m²)</th>
              <th title="suunniteltuja elementtejä">Tekemättä (kpl / m² / ton)</th>
              <th>Tehty (kpl / m² / ton)</th>
              <th>Toimitettu (kpl / m² / ton)</th>
            </tr>
          </thead>
          <tbody>
            {elements.elementsByPhaseId?.map((element) => (
              <tr key={`${element.phase?.phaseId}-${element.productType.id}`}>
                <td>{element.phase?.name}</td>
                <td>{element.productType.name}</td>
                <td>{element.sold}kpl / {element.soldArea}m²</td>
                <td>{element.notDone}kpl / {element.notDoneArea}m² / {element.notDoneWeightTons}ton</td>
                <td>{element.done}kpl / {element.doneArea}m² / {element.doneWeightTons}ton</td>
                <td>{element.delivered}kpl / {element.deliveredArea}m² / {element.deliveredWeightTons}ton</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : <p style={{ margin: "1rem" }}>Ei elementtejä</p>}
    </>
  );
};

export default ElementStatusTable;
