import { IOption } from "../components/ui/Input/Input";
import IDriverOrganization from "./IDriverOrganization";
import ITool from "./ITool";
import IVehicle from "./IVehicle";
import IWaybillRow from "./IWaybillRow";

export enum EWaybillType {
	PROJECT = "PROJECT", 
	INTERNAL_TRANSFER = "INTERNAL_TRANSFER",
}

export const WAYBILL_TYPE_OPTIONS: IOption[] = [
	{ value: EWaybillType.PROJECT, label: "Työmaalle" },
	{ value: EWaybillType.INTERNAL_TRANSFER, label: "Sisäinen siirto" },
];

interface IWaybill {
	id: string;
	waybillId: string;
	projectId: string;
	projectNumber: string;
	projectName: string;
	destination: string;
	driverOrganization?: IDriverOrganization;
	driver: string;
	car?: IVehicle;
	deliveryDate: string;
	deliveryTime: string;
	deliveryPrice: string;
	waitingTime: string;
	waitingPrice: string;
	waitingReason: EWaitingReason;
	waitingReasonString: string;
	waitingPayer: EWaitingPayer;
	waitingPayerString: string;
	waitingNotes: string;
	kilometers: string;
	accepted: string;
	price: string;
	tons: string;
	tonsInvoice: string;
	count: string;
	notes: string;
	additionalNotes: string;
	factory: string;
	orderNumber: string;
	nakki: boolean;
	waybillRows: IWaybillRow[];
	uuid: string;
	locations: ILocation[];
	arriveTime: string;
	departTime: string;
	loadEndTime: string;
	unloadStartTime: string;
	latestLocation?: ILocation;
	contactPersonName: string;
	contactPersonPhone: string;
	contactPersonEmail: string;
	type: EWaybillType;
	transportOrderId: string;
	delayTime: string;
	delayReason: EDelayReason;
	delayReasonString: string;
	delayNotes: string;
	unloadTime: string;
	unloadFreeTime: string;
	driverNotes: string;
	toolsFromFactory: ITool[];
	toolsToFactory: ITool[];
	toolIds: string[];

	prevWaybillId?: string;
	nextWaybillId?: string;
}

export interface ILocation {
  latitude?: number;
  longitude?: number;
  accuracy?: number;
  created?: string;
  x?: number;
  y?: number;
  hwId?: string;
  hwName?: string;
}

export enum EWaitingPayer {
  PRODUCTION = "PRODUCTION",
  CUSTOMER = "CUSTOMER",
  DRIVER_ORGANIZATION = "DRIVER_ORGANIZATION",
}

export const WAITING_PAYER_OPTIONS: IOption[] = [
  { value: EWaitingPayer.PRODUCTION, label: "Tuotanto" },
  { value: EWaitingPayer.CUSTOMER, label: "Asiakas" },
  { value: EWaitingPayer.DRIVER_ORGANIZATION, label: "Kuljetusliike" },
];

export enum EWaitingReason {
	ELEMENTS = "ELEMENTS",
	LIFTING = "LIFTING",
	INSTALL_BREAK = "INSTALL_BREAK",
	UNLOAD_DELAY = "UNLOAD_DELAY",
	INSTALL_ISSUE = "INSTALL_ISSUE",
	CAR_UNLOADING = "CAR_UNLOADING",
}

export const WAITING_REASON_OPTIONS: IOption[] = [
	{ value: EWaitingReason.ELEMENTS, label: "Paljon elementtejä" },
	{ value: EWaitingReason.LIFTING, label: "Välinostoja" },
	{ value: EWaitingReason.INSTALL_BREAK, label: "Asennusryhmän tauko" },
	{ value: EWaitingReason.UNLOAD_DELAY, label: "Purku alkoi myöhässä" },
	{ value: EWaitingReason.INSTALL_ISSUE, label: "Asennuksessa ongelmia" },
	{ value: EWaitingReason.CAR_UNLOADING, label: "Edellinen auto purussa" },
];

export enum EDelayReason {
	LOADING = "LOADING",
	TECHNICAL = "TECHNICAL",
	BREAK = "BREAK",
	UNEXPECTED = "UNEXPECTED",
	TRAFFIC = "TRAFFIC",
	WEATHER = "WEATHER",
}

export const DELAY_REASON_OPTIONS: IOption[] = [
	{ value: EDelayReason.LOADING, label: "Lastaus viivästyi" },
	{ value: EDelayReason.TECHNICAL, label: "Tekninen vika" },
	{ value: EDelayReason.BREAK, label: "Tauko/lepoajat" },
	{ value: EDelayReason.UNEXPECTED, label: "Odottamaton tilanne liikenteessä" },
	{ value: EDelayReason.TRAFFIC, label: "Liikenne" },
	{ value: EDelayReason.WEATHER, label: "Sääolosuhteet" },
];

export const DriverOrganizationOptions: IOption[]=[
  {value:"Kuljetusliike Kontkanen", label:"Kuljetusliike Kontkanen"},
  {value:"Pielisen Betoni", label:"Pielisen Betoni"},
]

export default IWaybill;