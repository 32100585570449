import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { IOption } from "../components/ui/Input/Input";
import { ErrorResponse } from "../custom-fetch";
import { QueryKey } from "../services/query-keys";
import { useDriverOrganizationService } from "../services/driverOrganizations-service";
import IDriverOrganization from "../shared/IDriverOrganization";

export const useDriverOrganizationOptions = () => {
  const { fetchDriverOrganizations } = useDriverOrganizationService();

  const {
    data: driverOrganizations,
    isPending,
    isError,
  } = useQuery<IDriverOrganization[], ErrorResponse>({
    queryKey: [QueryKey.driverOrganizationOptions],
    queryFn: ({ signal }) => fetchDriverOrganizations({ signal }, false),
    staleTime: Infinity,
  });

  const options = useMemo(
    () =>
      isError
        ? []
        : driverOrganizations?.map(
            (d) => ({ value: d.id, label: `${d.name || ""}` } as IOption)
          ),
    [driverOrganizations, isError]
  );

  return { options, loading: isPending, driverOrganizations };
};
