import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import Spinner from "../components/ui/Spinner/Spinner";
import WaybillPrint from "../components/Waybills/WaybillPrint";
import { ErrorResponse } from "../custom-fetch";
import { usePublicQRCodesService } from "../services/publicQRCodes-service";
import { QueryKey } from "../services/query-keys";
import IWaybillDTO from "../shared/IWaybillDTO";
import { useEffect, useState } from "react";

type Params = {
  id: string;
};

const WaybillPrintPage: React.FC = () => {
  const { id } = useParams<Params>();
  const { getWaybillByUuid } = usePublicQRCodesService();
  
  const {
    data: waybill,
    isLoading,
    isError,
    error,
  } = useQuery<IWaybillDTO, ErrorResponse>({
    queryKey: [QueryKey.publicWaybills, id],
    queryFn: ({ signal }) => getWaybillByUuid({ signal, id: id! }),
  });

  const [printOpened, setPrintOpened] = useState(false);
  useEffect(() => {
    if (waybill && !printOpened) {
      setPrintOpened(true);
      setTimeout(() => {
        window.print();
      }, 100);
    }
  }, [waybill, printOpened]);

  return (
    <>
      {isError ? (
          <ErrorsAlert errors={error.messages} />
        ) : isLoading ? (
          <Spinner />
        ) : waybill && (
          <WaybillPrint waybill={waybill} />
        )}
    </>
  )
}
export default WaybillPrintPage;