import { useQuery } from "@tanstack/react-query";
import { ErrorResponse } from "../../custom-fetch";
import { QueryKey } from "../../services/query-keys";
import { useToolsService } from "../../services/tools-service";
import ITool from "../../shared/ITool";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import ToolsTable from "./ToolsTable";

interface IProps {
  factory?: string;
}

const ToolsList: React.FC<IProps> = ({ factory }) => {
  const { fetchTools } = useToolsService();

  const {
    data: tools,
    isPending,
    isError,
    error,
  } = useQuery<ITool[], ErrorResponse>({
    queryKey: [QueryKey.tools],
    queryFn: ({ signal }) => fetchTools({ signal }),
  });

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  return <ToolsTable tools={tools} factory={factory} />;
};

export default ToolsList;
