import { useCallback, useMemo, useState } from "react";
import IElement from "../../../shared/IElement";
import IProductType from "../../../shared/IProductType";
import { IProjectPhase } from "../../../shared/IProject";
import { EInputType, IInputField, IOption, TInputValue } from "../../ui/Input/Input";
import InputContextProvider, {
  EInputUpdateAction,
} from "../../ui/Input/input-context";
import { useInputs } from "../../ui/Input/useInputs";
import InputGroup from "../../ui/InputGroup/InputGroup";
import { updateInputHandler } from "../../ui/Input/input-utils";

interface IProps {
  elements: IElement[];
  phase?: IProjectPhase;
  selectAllHandler: (type: ESelectAll, productTypeId?: string, disableUnselect?: boolean) => void;
  unselectAllHandler: () => void;
}

export enum ESelectAll {
  productTypeId = "productTypeId",
  isPlanned = "isPlanned",
  isNotPlanned = "isNotPlanned",
  tendonNotPlanned = "tendonNotPlanned",
}

const selectAllOptions: IOption[] = [
  { value: ESelectAll.productTypeId, label: "Tyyppi" },
  { value: ESelectAll.isPlanned, label: "Suunniteltu" },
  { value: ESelectAll.isNotPlanned, label: "Suunnittelematta" },
  { value: ESelectAll.tendonNotPlanned, label: "Punostamatta" },
];

enum EInputs {
  type = "type",
  productTypeId = "productTypeId",
}

const ElementsSelectAll: React.FC<IProps> = ({ elements, phase, selectAllHandler, unselectAllHandler }) => {
  const { createInput, inputs, setInputs } = useSelectAllInputs();

  const showProductTypeInput = useMemo(() => inputs[EInputs.type].value as ESelectAll === ESelectAll.productTypeId, [inputs]);
  const productTypeId = useMemo(() => inputs[EInputs.productTypeId].value as string, [inputs]);

  const updateHandler = useCallback(async (inputName: string, value: TInputValue, action: string, _: any) => {
    if (action === EInputUpdateAction.ELEMENTS_SELECT_ALL) {
      if (inputName === EInputs.productTypeId) {
        if (value) {
          if (value !== productTypeId) selectAllHandler(ESelectAll.productTypeId, value as string, true);
        } else {
          unselectAllHandler();
        }
      } else {
        if (productTypeId) {
          updateInputHandler(EInputs.productTypeId, "", setInputs);
        }
        const type = value as ESelectAll;
        if (type && type !== ESelectAll.productTypeId) {
          selectAllHandler(type, undefined, true);
        } else {
          unselectAllHandler();
        }
      }
    }
    // console.log(inputName, value, action);
    return Promise.resolve(true);
  }, [productTypeId, selectAllHandler, setInputs, unselectAllHandler]);

  const selectAllProductTypeOptions = useMemo(() => {
    return elements
      ? [...(phase?.phaseId ? elements.filter((el) => el.phaseId === phase?.phaseId) : elements)]
          .filter((el) => !!el.productType)
          .map((el) => el.productType as IProductType)
          .filter((value, index, array) => array.findIndex((el) => el.id === value.id) === index)
          .map((productType) => ({ value: productType.id, label: productType.name }))
      : [];
  }, [elements, phase?.phaseId]);

  return (
    <InputGroup style={{ alignItems: "center" }}>
      {selectAllProductTypeOptions.length > 0 &&
      <InputContextProvider initDone onAutoUpdate={updateHandler}>
        {createInput(EInputs.type)}
        {showProductTypeInput && createInput(EInputs.productTypeId, {
          options: selectAllProductTypeOptions,
          containerStyles: { width: "200px" },
        })}
      </InputContextProvider>
      }
      {/* <Button onClick={() => {}}>Punoksettomat</Button>
      <Button onClick={() => {}} color={EButtonColor.WARNING_SECONDARY}>Suunnittelemattomat</Button> */}
    </InputGroup>
  );
};

const useSelectAllInputs = (data?: {}) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.type]: {
      type: EInputType.reactSelect,
      options: selectAllOptions,
      value: "",
      menuPosition: "fixed",
      placeholder: "",
      label: "Valitse kaikki, joissa",
    },
    [EInputs.productTypeId]: {
      type: EInputType.reactSelect,
      options: [],
      value: "",
      menuPosition: "fixed",
      placeholder: "",
      label: "Tyyppi",
    },
  });

  const { createInput, submit } = useInputs({
    data,
    inputs,
    setInputs,
    updateAction: EInputUpdateAction.ELEMENTS_SELECT_ALL,
  });

  return { createInput, submit, inputs, setInputs };
};

export default ElementsSelectAll;
