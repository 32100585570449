import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import Spinner from "../components/ui/Spinner/Spinner";
import { ErrorResponse } from "../custom-fetch";
import { usePublicQRCodesService } from "../services/publicQRCodes-service";
import { QueryKey } from "../services/query-keys";
import IWaybillDTO from "../shared/IWaybillDTO";
import { useEffect } from "react";
import WaybillQR from "../components/Waybills/WaybillQR";
import { getCurrentLocation } from "../utils/location-utils";

const WaybillQRCodePage: React.FC = () => {
  const { uuid } = useParams();
  const { getWaybillByUuid, saveLocationWaybill } = usePublicQRCodesService();

  const {
    data: waybill,
    isLoading,
    isError,
    error,
  } = useQuery<IWaybillDTO, ErrorResponse>({
    queryKey: [QueryKey.publicWaybills, uuid],
    queryFn: ({ signal }) => getWaybillByUuid({ signal, id: uuid! }),
    staleTime: 5000,
  });

  useEffect(() => {
    if (!isLoading && !isError && waybill) {
      getCurrentLocation()
      .then(async (location) => {
        try {
          /*
          const response = await fetch(
            "http://www.randomnumberapi.com/api/v1.0/random?min=1&max=1000&count=2"
          );
          if (!response.ok) {
            throw new Error("Failed to fetch random numbers");
          }
          const randomNumbers = await response.json();
          location.x = randomNumbers[0];
          location.y = randomNumbers[1];
          */
          // randomNumberApi gives cors so 1-1000 with js
          // location.x = Math.floor(Math.random() * 1000) + 1;
          // location.y = Math.floor(Math.random() * 1000) + 1;
          // location.hwId = "OKU_nosturi_1";
          // location.hwName = "OKU nosturi 1";
          
          saveLocationWaybill(uuid!, location);
        } catch (error) {
          console.error("Error fetching random numbers:", error);
        }
      })
      .catch((error) => {
        console.error("Error in getCurrentLocation:", error);
      });
  }
  }, [isLoading, isError, waybill, saveLocationWaybill, uuid]);

  return (
    isError ? (
      <ErrorsAlert errors={error.messages} />
    ) : isLoading ? (
      <Spinner />
    ) : waybill ? <WaybillQR waybill={waybill}/>
    : <></>
  );
};

export default WaybillQRCodePage;
