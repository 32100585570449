import { Fragment, useMemo } from "react";
import { EElementGroupBy, ELEMENT_GROUP_BY_OPTIONS } from "../../../services/elements-service";
import IProject from "../../../shared/IProject";
import IProjectElementDTO from "../../../shared/IProjectElementDTO";
import { formatDate } from "../../../utils/date-utils";
import Logo from "../../Logo/Logo";
import OfferHeader from "../../Offers/OfferHeader/OfferHeader";
import Container from "../../ui/Container/Container";
import RadioButton from "../../ui/RadioButton/RadioButton";
import Table from "../../ui/Table/Table";
import ProjectElement from "./ProjectElement";
import classes from "./ProjectElements.module.scss";

interface IProps {
  project: IProject;
  projectElements: IProjectElementDTO[];
  groupBy: EElementGroupBy;
  setGroupBy: React.Dispatch<React.SetStateAction<EElementGroupBy>>;
}

const thStyle: React.CSSProperties = { borderTop: "4px solid black", borderBottom: "4px solid black" };

const ProjectElements: React.FC<IProps> = ({ project, projectElements, groupBy, setGroupBy }) => {
  // useEffect(() => {
  //   window.print();
  // }, []);

  const hasContactPerson = useMemo(() => project.offer.contactPersonName || project.offer.contactPersonEmail || project.offer.contactPersonPhone, [project.offer.contactPersonEmail, project.offer.contactPersonName, project.offer.contactPersonPhone]);
  
  return (
    <>
      <OfferHeader
        onSubmit={window.print}
        submitText="Tulosta"
        isLoading={false}
        title={`Työmaa ${project.projectNumber}`}
        hideReturn
      >
        <RadioButton 
          onChange={(value) => setGroupBy(value as EElementGroupBy)}
          options={ELEMENT_GROUP_BY_OPTIONS}
          value={groupBy}
        />
      </OfferHeader>
      <Container className={classes.Container}>
        <PrintTable
          header={(
            <>
              <div style={{ display: "flex", gap: "1rem" }}>
                <Logo size="medium" />
                <div style={{ width: "100%" }}>
                  <Spacer />
                  <h1>Työmaan laatat</h1>
                </div>
              </div>
              <span style={{ fontStyle: "italic" }}>
                {formatDate(new Date().toISOString())}
              </span>
              <div style={{ display: "flex", gap: "1rem", padding: "1rem 0" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ fontWeight: "bold" }}>{project.projectNumber}</span>
                  <span>{project.offer.deliveryStreetAddress}</span>
                  <span>{project.offer.deliveryZip}</span>
                  <span>{project.offer.deliveryCity}</span>
                  <span style={{ fontWeight: "bold" }}>{project.factories}</span>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span>{project.offer.customerContactPerson && "Asiakkaan yhteyshenkilö"}</span>
                  <span style={{ fontWeight: "bold" }}>{project.offer.customer?.number} {project.offer.customer?.name}</span>
                  <span>{project.offer.customerContactPerson?.name}</span>
                  <span>{project.offer.customerContactPerson?.email}</span>
                  <span>{project.offer.customerContactPerson?.phoneNumber}</span>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span>{hasContactPerson && "Yhteyshenkilö"}</span>
                  <span>{project.offer.contactPersonName}</span>
                  <span>{project.offer.contactPersonEmail}</span>
                  <span>{project.offer.contactPersonPhone}</span>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span>{project.offer.engineer && "Suunnittelutoimisto"}</span>
                  <span>{project.offer.engineer?.number} {project.offer.engineer?.name}</span>
                  <span>{project.offer.engineerContactPerson?.name}</span>
                  <span>{project.offer.engineerContactPerson?.email}</span>
                  <span>{project.offer.engineerContactPerson?.phoneNumber}</span>
                </div>
              </div>
            </>
          )}
          body={(
            <>
              <Table>
                <thead>
                  <tr>
                    <th style={{ ...thStyle, width: "80px" }}></th>
                    <th style={{ ...thStyle, width: "80px" }}>Laattatyyppi</th>
                    <th style={{ ...thStyle, width: "80px" }} colSpan={2}>P/O</th>
                    <th style={{ ...thStyle, width: "125px" }}>Tunnus</th>
                    <th style={{ ...thStyle, width: "80px" }}>Kpl</th>
                    <th style={{ ...thStyle, width: "125px" }}>Pituus mm</th>
                    <th style={{ ...thStyle, width: "80px" }}>Koko m</th>
                    <th style={{ ...thStyle, width: "80px" }}>Halk</th>
                    <th style={{ ...thStyle, width: "80px" }} colSpan={2}>V/O</th>
                    <th style={{ ...thStyle, width: "200px" }}>Varaukset</th>
                    <th style={{ ...thStyle, width: "80px" }}>Netto t</th>
                    <th style={{ ...thStyle, width: "80px" }}>T vko</th>
                    <th style={{ ...thStyle, width: "80px" }}>Bruttoala</th>
                    <th style={{ ...thStyle }}>Nettoala</th>
                  </tr>
                </thead>
                <tbody>
                  {projectElements.map((dto) => (
                    <Fragment key={`${dto.groupedById}-${dto.tendonCountThick ?? 0}-${dto.tendonCountThin ?? 0}`}>
                      {groupBy === EElementGroupBy.tendon ? (
                        <tr>
                          <td>{dto.groupedByName}</td>
                          <td></td>
                          <td>{dto.tendonCountThick}</td>
                          <td>{dto.tendonCountThin}</td>
                          <td colSpan={12}></td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan={16}>{dto.groupedByName}</td>
                        </tr>
                      )}
                      {dto.elements.map(element => <ProjectElement key={element.id} element={element} />)}
                      <tr>
                        <td>Yhteensä</td>
                        <td colSpan={4}></td>
                        <td>{dto.count} kpl</td>
                        <td></td>
                        <td>{dto.lengthInMeters}</td>
                        <td>{dto.splitLengthInMeters}</td>
                        <td colSpan={2}></td>
                        <td>{dto.provisionAmountLong}</td>
                        <td>{dto.weightTons}</td>
                        <td></td>
                        <td>{dto.grossArea}</td>
                        <td>{dto.area}</td>
                      </tr>
                    </Fragment>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        />
      </Container>
    </>
  );
};

interface IPrintProps {
  header: React.ReactNode;
  body: React.ReactNode;
}

const PrintTable: React.FC<IPrintProps> = ({ header, body }) => (
  <table>
    <thead><tr><td>{header}</td></tr></thead>
    <tbody><tr><td>{body}</td></tr></tbody>
  </table>
);

const Spacer = () => <hr style={{ height: "4px", background: "black" }} />;

export default ProjectElements;
