import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import ErrorsAlert, {
  combineErrors,
} from "../components/ErrorsAlert/ErrorsAlert";
import ProjectElements from "../components/Projects/ProjectElements/ProjectElements";
import Spinner from "../components/ui/Spinner/Spinner";
import { ErrorResponse } from "../custom-fetch";
import { EElementGroupBy, useElementsService } from "../services/elements-service";
import { useProjectsService } from "../services/projects-service";
import { QueryKey } from "../services/query-keys";
import IProject from "../shared/IProject";
import IProjectElementDTO from "../shared/IProjectElementDTO";

const ProjectElementsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { project, isPending, isError, error } = useGetProject(id!);
  const [groupBy, setGroupBy] = useState<EElementGroupBy>(EElementGroupBy.phase);

  const {
    elements,
    isFetchingElements,
    isFetchElementsError,
    fetchElementsError,
  } = useFetchElements(id!, groupBy);

  const errorMessages = combineErrors(
    { isError, error },
    { isError: isFetchElementsError, error: fetchElementsError }
  );

  const isLoading = useMemo(
    () => isPending || isFetchingElements,
    [isFetchingElements, isPending]
  );

  if (errorMessages.length > 0) {
    return <ErrorsAlert errors={errorMessages} />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  return <ProjectElements project={project!} projectElements={elements ?? []} groupBy={groupBy} setGroupBy={setGroupBy} />;
};

const useGetProject = (id: string) => {
  const { getProject } = useProjectsService();

  const {
    data: project,
    isPending,
    isError,
    error,
  } = useQuery<IProject, ErrorResponse>({
    queryKey: [QueryKey.projects, id],
    queryFn: ({ signal }) => getProject({ signal, id: id! }),
  });

  return { project, isPending, isError, error };
};

const useFetchElements = (projectId: string, groupBy?: EElementGroupBy) => {
  const { fetchGroupedElements } = useElementsService();

  const {
    data: elements,
    isPending: isFetchingElements,
    isError: isFetchElementsError,
    error: fetchElementsError,
  } = useQuery<IProjectElementDTO[], ErrorResponse>({
    queryKey: [QueryKey.elements, projectId, groupBy ?? ""],
    queryFn: ({ signal }) => fetchGroupedElements({ signal, projectId, groupBy }),
    enabled: !!groupBy,
  });

  return {
    elements,
    isFetchingElements,
    isFetchElementsError,
    fetchElementsError,
  };
};

export default ProjectElementsPage;
