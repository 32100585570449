import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { queryClient } from "../../App";
import { ErrorResponse } from "../../custom-fetch";
import { useProjectOptions } from "../../hooks/useProjectOptions";
import { Route } from "../../routes";
import { QueryKey } from "../../services/query-keys";
import { useTransportOrdersService } from "../../services/transportOrders-service";
import { FactoryOptions } from "../../shared/FactoryOptions";
import { EFactory } from "../../shared/IOfferRow";
import ITransportOrder from "../../shared/ITransportOrder";
import IWaybill from "../../shared/IWaybill";
import ErrorsAlert, { combineErrors } from "../ErrorsAlert/ErrorsAlert";
import WaybillsList from "../Waybills/WaybillsList";
import Button from "../ui/Button/Button";
import FormButtons from "../ui/FormButtons/FormButtons";
import { EInputType, IInputField } from "../ui/Input/Input";
import { updateInputHandler } from "../ui/Input/input-utils";
import { useInputs } from "../ui/Input/useInputs";
import InputGroup from "../ui/InputGroup/InputGroup";
import PageHeading from "../ui/PageHeading/PageHeading";
import { useWaybillSendModal } from "../WaybillSendModal/useWaybillSendModal";

interface IProps {
  id: string;
  transportOrder?: ITransportOrder;
  factoryParam?: string | null;
}

enum EInputs {
  factory = "factory",
  notes = "notes",
  internalNotes = "internalNotes",
  projectId = "projectId",
  deliveryDate = "deliveryDate",
  deliveryTime = "deliveryTime",
  contactPersonName = "contactPersonName",
  contactPersonPhone = "contactPersonPhone",
  contactPersonEmail = "contactPersonEmail",
}

const TransportOrderEdit: React.FC<IProps> = ({ id, transportOrder, factoryParam }) => {
  const isEdit = id !== "add";
  const navigate = useNavigate();
  const openWaybillSendModal = useWaybillSendModal();

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isWaybillSendModalOpen, setIsWaybillSendModalOpen] = useState(false);

  const { saveOrUpdate, error, isError, isPending } = useSaveOrUpdate(id, isEdit, navigate);
  // const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete(id, navigate);
  const { createInput, submit, inputs, setInputs } = useTransportOrderInputs(transportOrder);

  useEffect(() => {
    if (factoryParam) {
      updateInputHandler(EInputs.factory, factoryParam, setInputs);
    }
  }, [factoryParam, setInputs]);

  const factory = useMemo(() => inputs[EInputs.factory].value as EFactory, [inputs]);
  const { options, loading, projects } = useProjectOptions({ factory, factoryRequired: true });
  
  const projectId = useMemo(() => inputs[EInputs.projectId].value as string, [inputs]);
  const selectedProject = useMemo(() => projects.find(project => project.id === projectId), [projectId, projects]);
  
  useEffect(() => {
    if (selectedProject) {
      setInputs((inputs) => {
        const newInputs = {...inputs};
        newInputs[EInputs.contactPersonName].value = selectedProject.contactPersonName;
        newInputs[EInputs.contactPersonPhone].value = selectedProject.contactPersonPhone;
        newInputs[EInputs.contactPersonEmail].value = selectedProject.contactPersonEmail;
        newInputs[EInputs.notes].value = selectedProject.waybillNotes;
        return newInputs;
      });
    }
    // eslint-disable-next-line
  }, [selectedProject]);
  
  const submitHandler = useCallback(async () => {
    const data = await submit();
    if (data) {
      if (data.projectId) {
        const project = projects.find(project => project.id === data.projectId);
        data.projectNumber = project?.projectNumber ?? "";
        data.projectName = project?.name ?? "";
      } else {
        data.projectNumber = undefined;
        data.projectName = undefined;
      }
      if (isEdit) {
        const waybills = queryClient.getQueryData([QueryKey.waybills, id]) as IWaybill[];
        if (waybills && waybills.length > 0) {
          data.tons = waybills.reduce((sum, waybill) => sum + +(waybill.tons ?? 0), 0).toFixed(2);
          data.count = waybills.reduce((sum, waybill) => sum + +(waybill.count ?? 0), 0);
        }
      }
      saveOrUpdate(data);
    }
  }, [id, isEdit, projects, saveOrUpdate, submit]);

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (isWaybillSendModalOpen) return;
      if (e.repeat) return;
      if (!e.ctrlKey) return;
      const key = e.key.toLowerCase();
      switch (key) {
        case "s":
          e.preventDefault();
          submitHandler();
          break;
      }
    };

    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [isWaybillSendModalOpen, submitHandler]);

  const errorMessages = combineErrors(
    { isError, error },
    // { isError: isDeletingError, error: deletingError }
  );

  const addHandler = useCallback(() => {
    navigate(Route.waybill(`add?transportOrderId=${id}`));
  }, [id, navigate]);

  const sendHandler = useCallback(async () => {
    setIsWaybillSendModalOpen(true);
    let recipients: string[] = [];
    const waybills = queryClient.getQueryData<IWaybill[]>([QueryKey.waybills, transportOrder!.id]) ?? [];
    if (waybills && waybills.length > 0) {
      const emails = waybills.filter(waybill => !!waybill.car?.email).map(waybill => waybill.car!.email).filter((email, index, arr) => !!email && arr.indexOf(email) === index);
      if (emails.length > 0) recipients = [...emails];
    }
    if (transportOrder?.contactPersonEmail && !recipients.includes(transportOrder?.contactPersonEmail)) recipients.push(transportOrder.contactPersonEmail);
    const isSuccess = await openWaybillSendModal({ waybillIds: selectedIds, recipients });
    if (isSuccess) setSelectedIds([]);
    setIsWaybillSendModalOpen(false);
  }, [openWaybillSendModal, selectedIds, transportOrder]);

  return (
    <>
      <InputGroup>
        {createInput(EInputs.factory)}
        {createInput(EInputs.projectId, { options, loading, containerStyles: { width: "325px" } })}
      </InputGroup>
      <InputGroup>
        {createInput(EInputs.deliveryDate)}
        {createInput(EInputs.deliveryTime)}
      </InputGroup>
      <InputGroup>
        {createInput(EInputs.contactPersonName)}
        {createInput(EInputs.contactPersonPhone)}
        {createInput(EInputs.contactPersonEmail)}
      </InputGroup>
      {createInput(EInputs.notes)}
      {createInput(EInputs.internalNotes)}
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      {isEdit && (
        <>
          <hr />
          <PageHeading variant="h3" onAdd={addHandler} noHorizontalMargin>Rahtikirjat</PageHeading>
          <WaybillsList transportOrderId={id} showTotalRow selectedIds={selectedIds} setSelectedIds={setSelectedIds} isWaybillSendModalOpen={isWaybillSendModalOpen} />
          <hr />
        </>
      )}
      <FormButtons
        onSubmit={submitHandler}
        isLoading={isPending}
        // onDelete={isEdit ? deleteMutate : undefined}
        // isDeleting={isDeleting}
        deleteConfirmMessage="Haluatko varmasti poistaa kuljetustilauksen?"
      >
        {selectedIds.length > 0 && <Button onClick={sendHandler}>Lähetä</Button>}
      </FormButtons>
    </>
  );
};

const useTransportOrderInputs = (data?: ITransportOrder) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      label: "Tehdas",
      value: "",
      options: FactoryOptions,
      validation: {
        required: true,
      },
    },
    [EInputs.projectId]: {
      type: EInputType.reactSelect,
      label: "Työmaa",
      value: "",
      options: [],
    },
    [EInputs.notes]: {
      type: EInputType.textarea,
      label: "Huomautukset rahtikirjalle",
      value: "",
      rows: 4,
    },
    [EInputs.internalNotes]: {
      type: EInputType.textarea,
      label: "Muistiinpanot",
      value: "",
      rows: 4,
    },
    [EInputs.deliveryDate]: {
      type: EInputType.date,
      label: "Kuljetuksen pvm",
      value: "",
    },
    [EInputs.deliveryTime]: {
      type: EInputType.time,
      label: "Kuljetuksen aika",
      value: "",
    },
    [EInputs.contactPersonName]: {
      type: EInputType.text,
      label: "Yhteyshenkilön nimi",
      value: "",
    },
    [EInputs.contactPersonPhone]: {
      type: EInputType.text,
      label: "Yhteyshenkilön puhelin",
      value: "",
    },
    [EInputs.contactPersonEmail]: {
      type: EInputType.text,
      label: "Yhteyshenkilön email",
      value: "",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit, inputs, setInputs };
};

const useSaveOrUpdate = (id: string, isEdit: boolean, navigate: NavigateFunction) => {
  const { updateTransportOrder, saveTransportOrder } = useTransportOrdersService();

  const mutationFn = (data: ITransportOrder) => {
    return isEdit ? updateTransportOrder(id, data) : saveTransportOrder(data);
  };

  const {
    mutate: saveOrUpdate,
    isPending,
    isError,
    error,
  } = useMutation<ITransportOrder, ErrorResponse, ITransportOrder>({
    mutationFn,
    onSuccess: (data) => {
      queryClient.removeQueries({ queryKey: [QueryKey.transportOrders, data.id] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.transportOrders] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.transportOrderOptions] });
      if (!isEdit) navigate(Route.transportOrder(data.id), { replace: true });
    },
  });

  return { saveOrUpdate, isPending, isError, error };
};

// const useDelete = (id: string, navigate: NavigateFunction) => {
//   const { deleteTransportOrder } = useTransportOrdersService();

//   const {
//     mutate: deleteMutate,
//     isPending: isDeleting,
//     isError: isDeletingError,
//     error: deletingError,
//   } = useMutation<boolean, ErrorResponse>({
//     mutationFn: () => deleteTransportOrder(id),
//     onSuccess: () => {
//       queryClient.invalidateQueries({ queryKey: [QueryKey.transportOrders], refetchType: "none" });
//       queryClient.invalidateQueries({ queryKey: [QueryKey.transportOrderOptions], refetchType: "none" });
//       navigate(Route.transportOrders);
//     },
//   });

//   return { deleteMutate, isDeleting, isDeletingError, deletingError };
// };

export default TransportOrderEdit;
