import {
  faCheck,
  faDownload,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IAuditHistory, { AuditHistoryType } from "../../../shared/IAuditHistory";
import Button, { EButtonColor, EButtonSize } from "../../ui/Button/Button";
import { formatDate } from "../../../utils/date-utils";

interface IProps {
  auditHistory: IAuditHistory;
  onDelete?: (event: React.MouseEvent) => void;
  isDeleting?: boolean;
  onDownload: () => void;
  isDownloading?: boolean;
  type: AuditHistoryType;
}

const AuditHistory: React.FC<IProps> = ({
  auditHistory,
  onDelete,
  isDeleting,
  onDownload,
  isDownloading,
  type,
}) => {
  return (
    <tr>
      <td>{formatDate(auditHistory.date)}</td>
      <td>{auditHistory.text}</td>
      {type === "AuditHistory" && (
        <td>{auditHistory.accepted && <FontAwesomeIcon icon={faCheck} />}</td>
      )}
      <td style={{ borderTop: 0, display: "flex", gap: "0.1rem" }}>
        {onDelete && (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onDelete(e);
            }}
            size={EButtonSize.SMALL}
            color={EButtonColor.DANGER}
            icon={faTrash}
            title="Poista"
            loading={isDeleting}
          />
        )}
        {auditHistory.hasAttachments && (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onDownload();
            }}
            size={EButtonSize.SMALL}
            icon={faDownload}
            title="Lataa liite"
            loading={isDownloading}
          />
        )}
      </td>
    </tr>
  );
};

export default AuditHistory;
