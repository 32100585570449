import { IInstalmentRow } from "../../shared/IInstalmentTable";
import PageHeading from "../ui/PageHeading/PageHeading";
import Table from "../ui/Table/Table";

interface IProps {
  instalmentRows: IInstalmentRow[];
}

const CustomerInstalmentTable: React.FC<IProps> = ({ instalmentRows }) => {
  if (!instalmentRows) return null;
  return (
    <>
      <PageHeading variant="h3" noHorizontalMargin>Maksuerätaulukko</PageHeading>
      <Table style={{ borderStyle: "solid" }}>
        <thead>
          <tr>
            <th>Selite</th>
            <th>Määrä</th>
            <th>Veloitus</th>
          </tr>
        </thead>
        <tbody>
          {instalmentRows.map((row) => (
            <tr key={row.number}>
              <td>{row.description}</td>
              <td>{row.amount}</td>
              <td style={{ width: "200px" }}>{row.rowSum}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default CustomerInstalmentTable;
