import { useMutation } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { queryClient } from "../../../App";
import { ErrorResponse } from "../../../custom-fetch";
import { useAttachmentsService } from "../../../services/attachments-service";
import { QueryKey } from "../../../services/query-keys";
import { useToolsService } from "../../../services/tools-service";
import IAuditHistory, { AuditHistoryType } from "../../../shared/IAuditHistory";
import ITool from "../../../shared/ITool";
import ErrorsAlert, { combineErrors } from "../../ErrorsAlert/ErrorsAlert";
import { useConfirmModal } from "../../ui/Modal/useConfirmModal";
import PageHeading from "../../ui/PageHeading/PageHeading";
import Table from "../../ui/Table/Table";
import { useAuditHistoryEditModal } from "./useAuditHistoryEditModal";
import AuditHistory from "./AuditHistory";
import { usePublicQRCodesService } from "../../../services/publicQRCodes-service";

interface IProps {
  toolId: string;
  auditHistories: IAuditHistory[];
  type: AuditHistoryType;
  isPublic?: boolean;
}

const AuditHistories: React.FC<IProps> = ({ toolId, auditHistories, type, isPublic }) => {
  const openConfirmModal = useConfirmModal();
  const openAuditHistoryModal = useAuditHistoryEditModal(type, toolId, undefined, isPublic);
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [downloadingId, setDownloadingId] = useState<string | null>(null);

  const { deleteMutate, isDeleting, isDeletingError, deletingError } =
    useDelete(type, toolId);
  const { downloadMutate, isDownloading, isDownloadError, downloadError } =
    useDownloadLatestAttachment(type, isPublic);

  const saveHandler = useCallback(async () => {
    try {
      await openAuditHistoryModal();
    } catch (error) {
      console.error(error);
    }
  }, [openAuditHistoryModal]);

  const deleteHandler = useCallback(
    async (event: React.MouseEvent, id: string) => {
      const isConfirm = event.shiftKey
        ? true
        : await openConfirmModal(
            "Oletko varma, että haluat poistaa tarkastushistorian?"
          );
      if (!isConfirm) return;
      setDeletingId(id);
      try {
        await deleteMutate(id);
      } catch (error) {
        console.error(error);
      }
      setDeletingId(null);
    },
    [deleteMutate, openConfirmModal]
  );

  const downloadHandler = useCallback(
    async (id: string) => {
      setDownloadingId(id);
      try {
        const attachment = await downloadMutate(id);
        const objectUrl = URL.createObjectURL(attachment);
        window.open(objectUrl, "_blank");
        URL.revokeObjectURL(objectUrl);
      } catch (error) {
        console.error(error);
      }
      setDownloadingId(null);
    },
    [downloadMutate]
  );

  const errorMessages = combineErrors(
    {
      isError: isDeletingError,
      error: deletingError,
    },
    {
      isError: isDownloadError,
      error: downloadError,
    }
  );

  return (
    <>
      <PageHeading variant="h3" noHorizontalMargin onAdd={saveHandler}>
        {type === "AuditHistory" && "Tarkastuspöytäkirjat"}
        {type === "Remark" && "Huomiot"}
      </PageHeading>
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      {auditHistories.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th style={{ width: "200px" }}>Pvm</th>
              <th style={{ width: "200px" }}>Selite</th>
              {type === "AuditHistory" && (
                <th style={{ width: "200px" }}>Hyväksytty</th>
              )}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {auditHistories.map((auditHistory) => (
              <AuditHistory
                key={auditHistory.id}
                auditHistory={auditHistory}
                onDelete={isPublic ? undefined : (event) => deleteHandler(event, auditHistory.id)}
                isDeleting={isDeleting && deletingId === auditHistory.id}
                onDownload={() => downloadHandler(auditHistory.id)}
                isDownloading={
                  isDownloading && downloadingId === auditHistory.id
                }
                type={type}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <p>
          Ei {type === "AuditHistory" && "tarkastuspöytäkirjoja"}
          {type === "Remark" && "huomioita"}
        </p>
      )}
    </>
  );
};

const useDelete = (type: AuditHistoryType, toolId: string) => {
  const { deleteToolAuditHistory } = useToolsService();

  const {
    mutateAsync: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse, string>({
    mutationFn: (id) => deleteToolAuditHistory(type, toolId, id),
    onSuccess: (_, id) => {
      const oldData = queryClient.getQueryData<ITool>([QueryKey.tools, toolId]);
      if (oldData) {
        if (type === "AuditHistory") {
          queryClient.setQueryData<ITool>([QueryKey.tools, toolId], {
            ...oldData,
            auditHistories: [...(oldData.auditHistories ?? [])].filter(
              (row) => row.id !== id
            ),
          });
        } else if (type === "Remark") {
          queryClient.setQueryData<ITool>([QueryKey.tools, toolId], {
            ...oldData,
            remarks: [...(oldData.remarks ?? [])].filter(
              (row) => row.id !== id
            ),
          });
        }
      }
    },
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

const useDownloadLatestAttachment = (type: AuditHistoryType, isPublic?: boolean) => {
  const { downloadLatestAttachment } = useAttachmentsService();
  const { downloadLatestAttachment: downloadLatestPublicAttachment } = usePublicQRCodesService();

  const {
    mutateAsync: downloadMutate,
    isPending: isDownloading,
    isError: isDownloadError,
    error: downloadError,
  } = useMutation<Blob, ErrorResponse, string>({
    mutationFn: (auditHistoryId) => isPublic 
      ? downloadLatestPublicAttachment({
        kind: type,
        parentId: auditHistoryId,
      })
      : downloadLatestAttachment({
        kind: type,
        parentId: auditHistoryId,
      }),
  });

  return {
    downloadMutate,
    isDownloading,
    isDownloadError,
    downloadError,
  };
};

export default AuditHistories;
