import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import IToolDTO from "../../shared/IToolDTO";
import { formatDate } from "../../utils/date-utils";
import Table from "../ui/Table/Table";

interface IProps {
  factory?: string;
  tools?: IToolDTO[];
  onClick: (tool: IToolDTO) => void;
}

enum EListSortBy {
  factory = "factory",
  toolType = "toolType",
  type = "type",
  manufacturer = "manufacturer",
  number = "number",
  introductionDate = "introductionDate",
  latestAuditDate = "latestAuditDate",
}

const CustomerTools: React.FC<IProps> = ({ tools, factory, onClick }) => {
  const [sorted, setSorted] = useState<IToolDTO[]>([]);
  const [sort, setSort] = useState<EListSortBy>();
  const [sortDirection, setSortDirection] = useState<boolean>(false); // true = up, false = down
  const changeSortOrDirection = (sortBy: EListSortBy) => {
    if (sortBy === sort) {
      setSortDirection(!sortDirection);
    } else {
      setSort(sortBy);
      setSortDirection(false);
    }
  };
  const getChevron = () => {
    return (
      <FontAwesomeIcon
        style={{ marginLeft: ".5rem", fontSize: ".75rem" }}
        icon={sortDirection ? faChevronUp : faChevronDown}
      />
    );
  };

  useEffect(() => {
    const newSorted = [...sorted];
    if (sort && newSorted) {
      if (sortDirection) {
        newSorted.reverse();
        setSorted(newSorted);
        return;
      }
      newSorted.sort((a, b) => {
        const getFieldValue = (item: IToolDTO, field: EListSortBy) => {
          return item[field];
        };
        const aValue = getFieldValue(a, sort) || "";
        const bValue = getFieldValue(b, sort) || "";
        if (aValue === bValue) return 0;
        return aValue > bValue ? 1 : -1;
      });
      setSorted(newSorted);
    }
    // eslint-disable-next-line
  }, [sort, sortDirection]);

  useEffect(() => {
    if (tools) {
      setSorted(tools);
    }
  }, [tools]);

  const filteredTools = factory
    ? sorted?.filter((tool) => tool.factory === factory)
    : sorted;

  if (!filteredTools || filteredTools?.length === 0) {
    return <p>Ei nostovälineitä</p>;
  }

  return (
    <Table hover>
      <thead style={{ cursor: "pointer" }}>
        <tr>
          <th onClick={() => changeSortOrDirection(EListSortBy.factory)}>
            Tehdas {sort === EListSortBy.factory && getChevron()}
          </th>
          <th onClick={() => changeSortOrDirection(EListSortBy.toolType)}>
            Tyyppi {sort === EListSortBy.toolType && getChevron()}
          </th>
          <th onClick={() => changeSortOrDirection(EListSortBy.type)}>
            Tyypin selite {sort === EListSortBy.type && getChevron()}
          </th>
          <th onClick={() => changeSortOrDirection(EListSortBy.manufacturer)}>
            Valmistaja {sort === EListSortBy.manufacturer && getChevron()}
          </th>
          <th onClick={() => changeSortOrDirection(EListSortBy.number)}>
            Numero {sort === EListSortBy.number && getChevron()}
          </th>
          <th
            onClick={() => changeSortOrDirection(EListSortBy.introductionDate)}
          >
            Käyttöönottopvm{" "}
            {sort === EListSortBy.introductionDate && getChevron()}
          </th>
          <th
            onClick={() => changeSortOrDirection(EListSortBy.latestAuditDate)}
          >
            Viimeisin tarkistus pvm{" "}
            {sort === EListSortBy.latestAuditDate && getChevron()}
          </th>
        </tr>
      </thead>
      <tbody>
        {filteredTools.map((tool) => (
          <tr key={tool.id} onClick={() => onClick(tool)}>
            <td>{tool.factory}</td>
            <td>{tool.toolTypeLabel}</td>
            <td>{tool.type}</td>
            <td>{tool.manufacturer}</td>
            <td>{tool.number}</td>
            <td>{formatDate(tool.introductionDate)}</td>
            <td>{formatDate(tool.latestAuditDate)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default CustomerTools;
