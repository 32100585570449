import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ToolsQRCodes from "../components/Tools/ToolsQRCodes/ToolsQRCodes";
import Button, { EButtonColor, EButtonSize } from "../components/ui/Button/Button";
import Container from "../components/ui/Container/Container";
import { EInputType, IInputField } from "../components/ui/Input/Input";
import { useInputs } from "../components/ui/Input/useInputs";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import { FactoryOptions } from "../shared/FactoryOptions";
import { EFactory } from "../shared/IOfferRow";
import classes from "./ToolsPrintPage.module.scss";

enum EInputs {
  factory = "factory",
}

const ToolsPrintPage: React.FC = () => {
  const navigate = useNavigate();
  const { createInput, inputs } = useToolsInputs();
  
  const factory = useMemo(() => inputs[EInputs.factory].value as EFactory, [inputs]);

  const printHandler = useCallback(() => {
    window.print();
  }, []);

  const returnHandler = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      <PageHeading className={classes.PrintHidden}>
        <span>Nostovälineiden QR-koodien tulostus</span>
        <Button style={{ fontSize: "1rem" }} size={EButtonSize.SMALL} onClick={printHandler} color={EButtonColor.SUCCESS}>Tulosta</Button>
        <Button style={{ fontSize: "1rem" }} size={EButtonSize.SMALL} onClick={returnHandler} color={EButtonColor.SECONDARY}>Takaisin</Button>
      </PageHeading>
      <Container className={classes.Container}>
        <div className={classes.PrintHidden}>{createInput(EInputs.factory)}</div>
        <hr className={classes.PrintHidden} />
        <ToolsQRCodes factory={factory} />
      </Container>
    </>
  );
};

const useToolsInputs = (data?: {}) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      label: "Tehdas",
      options: FactoryOptions,
      value: "",
      placeholder: "Suodata tehtaalla",
      boldContent: true,
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit, inputs };
};

export default ToolsPrintPage;
