import { useQuery } from "@tanstack/react-query";
import { useElementsService } from "../../services/elements-service";
import IWarehouseBalanceReportDTO from "../../shared/IWarehouseBalanceReportDTO";
import { ErrorResponse } from "../../custom-fetch";
import { QueryKey } from "../../services/query-keys";
import ErrorsAlert, { combineErrors } from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import WarehouseBalanceReportsTable from "./WarehouseBalanceReportsTable";

interface IProps {
  startDate: string;
  endDate: string;
}

const WarehouseBalanceReports: React.FC<IProps> = ({ startDate, endDate }) => {
  const { warehouseBalanceReports, isLoading, isError, error } =
    useFetchWarehouseBalanceReports(startDate, endDate);

  const errorMessages = combineErrors({ isError, error });

  return (
    <>
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      {isLoading ? (
        <Spinner />
      ) : warehouseBalanceReports && warehouseBalanceReports.length > 0 ? (
        <WarehouseBalanceReportsTable
          warehouseBalanceReports={warehouseBalanceReports}
        />
      ) : <p>Päivämäärälle ei löytynyt varastosaldoja.</p>}
    </>
  );
};

const useFetchWarehouseBalanceReports = (startDate: string, endDate: string) => {
  const { fetchWarehouseBalanceReports } = useElementsService();

  const {
    data: warehouseBalanceReports,
    isLoading,
    isError,
    error,
  } = useQuery<IWarehouseBalanceReportDTO[], ErrorResponse>({
    queryKey: [QueryKey.warehouseBalanceReports, startDate, endDate],
    queryFn: ({ signal }) => fetchWarehouseBalanceReports({ signal, startDate, endDate }),
    staleTime: 5000,
  });

  return { warehouseBalanceReports, isLoading, isError, error };
};

export default WarehouseBalanceReports;
