import { useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Logo from "../components/Logo/Logo";
import Container from "../components/ui/Container/Container";
import CustomerProject from "../components/Customer/CustomerProject";
import IPublicProjectDTO from "../shared/IPublicProjectDTO";
import ErrorsAlert, {
  combineErrors,
} from "../components/ErrorsAlert/ErrorsAlert";
import Spinner from "../components/ui/Spinner/Spinner";
import { useMutation } from "@tanstack/react-query";
import { ErrorResponse } from "../custom-fetch";
import {
  IGetCustomerProject,
  usePublicCustomersService,
} from "../services/publicCustomers-service";
import { useInputs } from "../components/ui/Input/useInputs";
import { EInputType, IInputField } from "../components/ui/Input/Input";
import Button from "../components/ui/Button/Button";
import PageHeading from "../components/ui/PageHeading/PageHeading";

enum EInputs {
  projectNumber = "projectNumber",
}

const CustomerProjectPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const id = useMemo(() => searchParams.get("id"), [searchParams]);
  const uuid = useMemo(() => searchParams.get("uuid"), [searchParams]);

  const [project, setProject] = useState<IPublicProjectDTO | null>(null);
  const { createInput, submit } = useCustomerProjectInputs();

  const { getProjectMutate, isPending, isError, error } = useGetProject(id, uuid);
  const errorMessages = combineErrors({ isError, error });

  const submitHandler = useCallback(async () => {
    try {
      const data = await submit();
      if (!data) return;
      const project = await getProjectMutate(data.projectNumber);
      if (!project) return;
      setProject(project);
    } catch (error) {
      console.error(error);
    }
  }, [getProjectMutate, submit]);

  if (isPending) {
    return <Spinner />;
  }

  return (
    <>
      <Container style={{ margin: "0" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Logo />
          {project?.projectNumber && (
            <PageHeading variant="h1" noHorizontalMargin>
              Työmaa {project?.projectNumber}
            </PageHeading>
          )}
        </div>
        {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
        {project ? (
          <CustomerProject project={project} />
        ) : (
          <form
            style={{ padding: "1rem 0" }}
            onSubmit={(e) => e.preventDefault()}
          >
            {createInput(EInputs.projectNumber)}
            <Button type="submit" onClick={submitHandler}>
              Lähetä
            </Button>
          </form>
        )}
      </Container>
    </>
  );
};

const useCustomerProjectInputs = (data?: IGetCustomerProject) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.projectNumber]: {
      type: EInputType.text,
      label: "Työmaanumero",
      value: "",
      validation: {
        required: true,
      },
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit };
};

const useGetProject = (id: string | null, uuid: string | null) => {
  const { getCustomerProject } = usePublicCustomersService();

  const {
    mutateAsync: getProjectMutate,
    isPending,
    isError,
    error,
  } = useMutation<IPublicProjectDTO, ErrorResponse, string>({
    mutationFn: (projectNumber) =>
      getCustomerProject({
        id: id!,
        uuid: uuid!,
        projectNumber: projectNumber!,
      }),
  });

  return { getProjectMutate, isPending, isError, error };
};

export default CustomerProjectPage;
