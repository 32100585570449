import { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { RefHandle } from "../../shared/RefHandle";
import Alert from "../ui/Alert/Alert";
import Button, { EButtonColor } from "../ui/Button/Button";
import ModalBody from "../ui/Modal/ModalBody/ModalBody";
import ModalFooter from "../ui/Modal/ModalFooter/ModalFooter";
import Table from "../ui/Table/Table";
import RecipientEdit, { IRecipient } from "./RecipientEdit";
import { ISendWaybills } from "./useWaybillSendModal";

const createRecipient = (email?: string): IRecipient => {
  const id = uuid();
  return {
    id,
    email: email ?? "",
  };
};

interface IProps {
  onAccept: (data: ISendWaybills) => Promise<void>;
  onCancel: () => void;
  initialData: ISendWaybills;
}

const WaybillSendModal: React.FC<IProps> = ({
  onAccept,
  onCancel,
  initialData,
}) => {
  const [loading, setLoading] = useState(false);
  const [recipients, setRecipients] = useState<IRecipient[]>(initialData.recipients.length > 0 ? initialData.recipients.map(email => createRecipient(email)) : [createRecipient()]);
  const recipientRef = useRef<RefHandle<IRecipient>[]>([]);
  const [error, setError] = useState<string | null>(null);

  const submitHandler = useCallback(async () => {
    setError(null);
    setLoading(true);
    const rows = recipientRef.current;
    const newRows: IRecipient[] = [];
    if (rows?.length > 0) {
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        if (row) {
          const rowData = await row.getData();
          if (rowData && rowData.email) {
            newRows.push(rowData);
          }
        }
      }
    }
    if (!newRows.length) {
      setError("Vastaanottajia pitää olla ainakin yksi.");
      setLoading(false);
      return;
    }
    await onAccept({ ...initialData, recipients: newRows.map(row => row.email) });
    setLoading(false);
  }, [initialData, onAccept]);

  const addHandler = useCallback(async () => {
    setError(null);
    setRecipients((recipients) => [...recipients, createRecipient()]);
  }, []);

  const deleteHandler = useCallback((id: string) => {
    setRecipients(recipients => recipients.filter(recipient => recipient.id !== id));
  }, []);

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.repeat) return;
      if (!e.ctrlKey) return;
      const key = e.key.toLowerCase();
      switch (key) {
        case "+":
          e.preventDefault();
          addHandler();
          break;
        case "s":
          e.preventDefault();
          submitHandler();
          break;
      }
    };

    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [addHandler, submitHandler]);

  return (
    <>
      <ModalBody style={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
        <Table>
          <tbody>
            {recipients?.map((recipient, i) => (
              <RecipientEdit
                key={recipient.id}
                ref={(rowHandle) => (recipientRef.current[i] = rowHandle!)}
                recipient={recipient}
                onDelete={() => deleteHandler(recipient.id)}
              />
            ))}
          </tbody>
        </Table>
        <div>
          <Button onClick={addHandler}>Lisää uusi vastaanottaja</Button>
        </div>
        {error && <Alert style={{ margin: 0 }}>{error}</Alert>}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={submitHandler}
          style={{ marginRight: "0.5rem" }}
          loading={loading}
        >
          Lähetä
        </Button>
        <Button onClick={onCancel} color={EButtonColor.DANGER}>
          Peruuta
        </Button>
      </ModalFooter>
    </>
  );
};

export default WaybillSendModal;
