import { IOption } from "../components/ui/Input/Input";
import { EFactory } from "./IOfferRow";
import IProductGroup from "./IProductGroup";
import IProductType from "./IProductType";
import IUser from "./IUser";

enum EResponsibility {
  engineer = "engineer",
  customer = "customer",
  company = "company",
  logistics = "logistics",
  install = "install",
  other = "other",
}

enum EResolvedBy {
  company = "company",
  customer = "customer",
  other = "other",
}

export const RESPONSIBILITY_OPTIONS: IOption[] = [
  { value: EResponsibility.engineer, label: "Suunnittelija" },
  { value: EResponsibility.customer, label: "Asiakas" },
  { value: EResponsibility.company, label: "Me" },
  { value: EResponsibility.logistics, label: "Kuljetusliike" },
  { value: EResponsibility.install, label: "Asentaja" },
  { value: EResponsibility.other, label: "Muu" },
];

export const RESOLVED_BY_OPTIONS: IOption[] = [
  { value: EResolvedBy.company, label: "Me" },
  { value: EResolvedBy.customer, label: "Asiakas/työmaa" },
  { value: EResolvedBy.other, label: "Muu" },
];

enum ERootCauseCategory {
  DELIVERY_ERROR = "DELIVERY_ERROR",
  ORDER_ERROR = "ORDER_ERROR",
  DELIVERY_TIME_ERROR = "DELIVERY_TIME_ERROR",
  TRANSPORT_DAMAGE = "TRANSPORT_DAMAGE",
  DEFECTIVE_PRODUCT = "DEFECTIVE_PRODUCT",
  INCORRECT_CHARGE = "INCORRECT_CHARGE",
  OTHER_ERROR = "OTHER_ERROR",
  ORDER_CANCELLATION = "ORDER_CANCELLATION",
  INTERNAL_PLANNING = "INTERNAL_PLANNING",
  INTERNAL_PRODUCTION = "INTERNAL_PRODUCTION",
  INTERNAL_HANDLING = "INTERNAL_HANDLING",
}

export const ROOT_CAUSE_CATEGORY_OPTIONS: IOption[] = [
  { value: ERootCauseCategory.DELIVERY_ERROR, label: "Toimitusvirhe" },
  { value: ERootCauseCategory.ORDER_ERROR, label: "Tilausvirhe" },
  { value: ERootCauseCategory.DELIVERY_TIME_ERROR, label: "Toimitusaikavirhe" },
  { value: ERootCauseCategory.TRANSPORT_DAMAGE, label: "Kuljetusvaurio" },
  { value: ERootCauseCategory.DEFECTIVE_PRODUCT, label: "Viallinen tuote" },
  { value: ERootCauseCategory.INCORRECT_CHARGE, label: "Virheellinen veloitus" },
  { value: ERootCauseCategory.OTHER_ERROR, label: "Muu virhe" },
  { value: ERootCauseCategory.ORDER_CANCELLATION, label: "Tilauksen peruuttaminen" },
  { value: ERootCauseCategory.INTERNAL_PLANNING, label: "Sisäinen, myynti/suunnittelu" },
  { value: ERootCauseCategory.INTERNAL_PRODUCTION, label: "Sisäinen, tuotanto" },
  { value: ERootCauseCategory.INTERNAL_HANDLING, label: "Sisäinen, käsittely/varasto" },
];

interface IClaim {
  id: string;
  created: string;
  createdBy: IUser;
  projectNumber: string;
  projectId: string;
  projectName: string;
  customerName: string;
  factory: EFactory;
  claimReporter: string;
  claimReporterContact: string;
  productGroup?: IProductGroup;
  productType?: IProductType;
  elementIdentifier: string;
  rootCauseCategory: string;
  rootCauseSubCategory: string;
  descriptionDefect: string;
  asPlanned: boolean;
  responsibility: EResponsibility;
  descriptionFix: string;
  cost: number;
  resolved: boolean;
  resolvedDate: Date;
  resolveContactCustomer: string;
  resolveContactCompany: string;
  resolvedBy: EResolvedBy;
  deleted: boolean;
  reclamation: boolean;
  elementId: string;
  productionLineId: string;
  waybillId: string;
  productionLineIdentifier: string;
  waybillIdentifier: string;
  identifier?: string;
}

export default IClaim;
