import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { QueryKey } from "../services/query-keys";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import { ErrorResponse } from "../custom-fetch";
import Spinner from "../components/ui/Spinner/Spinner";
import Container from "../components/ui/Container/Container";
import { useDriverOrganizationService } from "../services/driverOrganizations-service";
import IDriverOrganization from "../shared/IDriverOrganization";
import DriverOrganizationEdit from "../components/DriverOrganizations/DriverOrganizationEdit";

type Params = {
  id: string;
};

const DriverOrganizationPage: React.FC = () => {
  const { id } = useParams<Params>();
  const { getDriverOrganization } = useDriverOrganizationService();
  
  const {
    data: driverOrganization,
    isLoading,
    isError,
    error,
  } = useQuery<IDriverOrganization, ErrorResponse>({
    queryKey: [QueryKey.driverOrganizations, id],
    queryFn: ({ signal }) => getDriverOrganization({ signal, id: id! }),
    enabled: id !== 'add',
  });

  return (
    <>
      <h1 style={{ margin: "1rem" }}>Kuljetusliike</h1>
      <Container>
        {isError ? (
          <ErrorsAlert errors={error.messages} />
        ) : isLoading ? (
          <Spinner />
        ) : (
          <DriverOrganizationEdit id={id!} driverOrganization={driverOrganization} />
        )}
      </Container>
    </>
  );
};

export default DriverOrganizationPage;
