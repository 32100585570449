import { ReactNode, createContext, useContext } from "react";
import IProject from "../../../shared/IProject";
import { IOrganizationUserSelectHandle } from "../../OrganizationUserSelect/OrganizationUserSelect";
import { RefHandle } from "../../../shared/RefHandle";
import IWeeklyProductionRow from "../../../shared/IWeeklyProductionRow";
import IAdditionalInvoice from "../../../shared/IAdditionalInvoice";
import IElement from "../../../shared/IElement";
import { IProjectDirty } from "../Project/Project";
import IProjectUser from "../../../shared/IProjectUser";

interface IProjectEditContext {
  id: string;
  project: IProject;
  customerRef: React.RefObject<IOrganizationUserSelectHandle>;
  engineerRef: React.RefObject<IOrganizationUserSelectHandle>;
  elementsRef: React.RefObject<RefHandle<IElement[]>>;
  weeklyProductionRowsRef: React.RefObject<RefHandle<IWeeklyProductionRow[]>>;
  additionalInvoicesRef: React.RefObject<RefHandle<IAdditionalInvoice[]>>;
  weeklyProductionRows: IWeeklyProductionRow[];
  setWeeklyProductionRows: React.Dispatch<React.SetStateAction<IWeeklyProductionRow[]>>;
  additionalInvoices: IAdditionalInvoice[];
  setAdditionalInvoices: React.Dispatch<React.SetStateAction<IAdditionalInvoice[]>>;
  elements: IElement[];
  setElements: React.Dispatch<React.SetStateAction<IElement[]>>;
  setIsProjectDirty: React.Dispatch<React.SetStateAction<IProjectDirty>>;
  deleteHandler?: () => void;
  isDeleting: boolean;
  isProvisionsModalOpen: boolean;
  setIsProvisionsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  projectUsersRef: React.RefObject<RefHandle<IProjectUser[]>>;
  projectUsers: IProjectUser[];
  setProjectUsers: React.Dispatch<React.SetStateAction<IProjectUser[]>>;
  submitHandler: () => Promise<boolean>;
}

const ProjectEditContext = createContext<IProjectEditContext>({
  id: "",
  project: {} as any,
  customerRef: { current: null },
  engineerRef: { current: null },
  elementsRef: { current: null },
  weeklyProductionRowsRef: { current: null },
  additionalInvoicesRef: { current: null },
  weeklyProductionRows: [],
  setWeeklyProductionRows: () => Promise.reject(),
  additionalInvoices: [],
  setAdditionalInvoices: () => Promise.reject(),
  elements: [],
  setElements: () => Promise.resolve(),
  setIsProjectDirty: () => Promise.resolve(),
  deleteHandler: undefined,
  isDeleting: false,
  isProvisionsModalOpen: false,
  setIsProvisionsModalOpen: () => Promise.resolve(),
  projectUsersRef: { current: null },
  projectUsers: [],
  setProjectUsers: () => Promise.resolve(),
  submitHandler: () => Promise.resolve(true),
});

export const useProjectEditContext = () => useContext(ProjectEditContext);

const ProjectEditContextProvider: React.FC<{
  value: IProjectEditContext;
  children: ReactNode;
}> = ({ value, children }) => (
  <ProjectEditContext.Provider value={value}>
    {children}
  </ProjectEditContext.Provider>
);

export default ProjectEditContextProvider;
