import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import Container from "../components/ui/Container/Container";
import { EInputType, IInputField } from "../components/ui/Input/Input";
import { useInputs } from "../components/ui/Input/useInputs";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import Spinner from "../components/ui/Spinner/Spinner";
import WeeklyProductionLines from "../components/WeeklyProductionLines/WeeklyProductionLines";
import { ErrorResponse } from "../custom-fetch";
import { useProductionLinesService } from "../services/productionLines-service";
import { QueryKey } from "../services/query-keys";
import { FactoryOptions } from "../shared/FactoryOptions";
import IWeeklyProductionLine from "../shared/IWeeklyProductionLine";
import { storage } from "../utils/localstorage-utils";
import { EFactory } from "../shared/IOfferRow";
import { format, subWeeks } from "date-fns";

enum EInputs {
  factory = "factory",
  startDate = "startDate"
}

const WeeklyProductionLinesPage: React.FC = () => {
  const { fetchWeeklyProductionLines } = useProductionLinesService();
  const { createInput, inputs } = useWeeklyProductionLinesInputs();

  const factory = useMemo(() => inputs[EInputs.factory].value as EFactory, [inputs]);
  const startDate = useMemo(() => inputs[EInputs.startDate].value as string, [inputs]);

  useEffect(() => {
    if (factory) {
      storage.saveSearchFactory(factory);
    }
  }, [factory]);

  const {
    data: weeklyProductionLines,
    isPending,
    isError,
    error,
  } = useQuery<IWeeklyProductionLine[], ErrorResponse>({
    queryKey: [QueryKey.weeklyProductionLines, factory, startDate],
    queryFn: ({ signal }) => fetchWeeklyProductionLines({ signal, factory, startDate }),
    staleTime: 5000,
    enabled: !!factory,
  });

  return (
    <>
      <PageHeading>Pedit viikolle</PageHeading>
      {isError && <ErrorsAlert errors={error.messages} />}
      <Container>
        <div style={{ display: "flex", gap: "1rem" }}>
          {createInput(EInputs.factory, { containerStyles: { width: "300px" }})}
          {createInput(EInputs.startDate, { containerStyles: { width: "300px" }})}
        </div>
        <hr />
        {factory ? isPending ? (
          <Spinner />
        ) : !weeklyProductionLines || weeklyProductionLines?.length === 0 ? (
          <p>Ei petejä</p>
        ) : (
          <WeeklyProductionLines
            weeklyProductionLines={weeklyProductionLines}
          />
        ) : null}
      </Container>
    </>
  );
};

const useWeeklyProductionLinesInputs = (data?: {}) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      label: "Tehdas",
      options: FactoryOptions,
      value: storage.getSearchFactory() ?? "HOL",
      placeholder: "",
      boldContent: true,
    },
    [EInputs.startDate]: {
      type: EInputType.date,
      label: "Alku viikko",
      value: format(subWeeks(new Date(), 1), 'yyyy-MM-dd'),
      placeholder: "",
      boldContent: true,
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit, inputs };
};

export default WeeklyProductionLinesPage;
