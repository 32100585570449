import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { queryClient } from "../../../App";
import { ErrorResponse } from "../../../custom-fetch";
import { useProjectsService } from "../../../services/projects-service";
import { QueryKey } from "../../../services/query-keys";
import IProject, { IProjectPhase } from "../../../shared/IProject";
import ErrorsAlert, { combineErrors } from "../../ErrorsAlert/ErrorsAlert";
import { useProjectPhaseEditModal } from "../../ProjectPhaseEditModal/useProjectPhaseEditModal";
import { IOption } from "../../ui/Input/Input";
import { EModalId, useModalContext } from "../../ui/Modal/modal-context";
import { useConfirmModal } from "../../ui/Modal/useConfirmModal";
import PageHeading from "../../ui/PageHeading/PageHeading";
import RadioButton from "../../ui/RadioButton/RadioButton";
import { useProjectEditContext } from "../ProjectEdit/project-edit-context";
import ProjectTabs from "../ProjectTabs/ProjectTabs";
import { TCreateInput } from "../../ui/Input/useCreateInput";

interface IProps {
  id: string;
  initialPhases: IProjectPhase[];
  createProjectInput: TCreateInput;
}

const allPhaseOption: IOption = { value: "", label: "Kaikki" };

const ProjectPhases: React.FC<IProps> = ({ id, initialPhases, createProjectInput }) => {
  const [phases, setPhases] = useState<IProjectPhase[]>([]);
  const [selectedPhase, setSelectedPhase] = useState<IProjectPhase | null>(null);

  useEffect(() => {
    if (initialPhases) {
      setPhases(initialPhases);
    }
  }, [initialPhases]);

  const { closeModal } = useModalContext();
  const openEditModal = useProjectPhaseEditModal(id);
  const openConfirmModal = useConfirmModal();

  const { setElements } = useProjectEditContext();
  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete(id);

  const editHandler = useCallback(async () => {
    const data = await openEditModal();
    if (data) {
      setPhases((phases) => [...phases, data]);
      const oldData = queryClient.getQueryData<IProject>([QueryKey.projects, id]);
      queryClient.setQueryData([QueryKey.projects, id], { ...oldData, phases: [...oldData?.phases ?? [], data] });
    }
    closeModal(EModalId.PROJECT_PHASE_EDIT);
  }, [openEditModal, closeModal, id]);

  const deleteHandler = useCallback(async (phase: IProjectPhase) => {
    const isConfirm = await openConfirmModal("Oletko varma, että haluat poistaa kerroksen / lohkon?");
    if (!isConfirm) return;
    try {
      const deleteSuccess = await deleteMutate(phase.phaseId);
      if (!deleteSuccess) return;

      setPhases(phases => phases.filter(p => p.phaseId !== phase.phaseId));
      if (selectedPhase?.phaseId === phase.phaseId) {
        setSelectedPhase(null);
      }
      const oldData = queryClient.getQueryData<IProject>([QueryKey.projects, id]);
      queryClient.setQueryData([QueryKey.projects, id], { ...oldData, phases: [...oldData?.phases ?? []].filter(p => p.phaseId !== phase.phaseId) });

      // const elements = queryClient.getQueryData<IElement[]>([QueryKey.elements, id]);
      // queryClient.setQueryData([QueryKey.elements, id], [...elements ?? []].map(el => el.phaseId === phase.phaseId ? ({ ...el, phaseId: undefined, phaseName: undefined }) : el));
      setElements(elements => elements.map(el => el.phaseId === phase.phaseId ? ({ ...el, phaseId: undefined, phaseName: undefined }) : el));
    } catch (error) {
      console.error(error);
    }
  }, [openConfirmModal, deleteMutate, selectedPhase?.phaseId, id, setElements]);

  let phaseOptions: IOption[] = useMemo(
    () =>
      phases?.map((phase) => ({
        value: phase.phaseId,
        label: phase.name,
      })) ?? [],
    [phases]
  );

  const errorMessages = combineErrors({ isError: isDeletingError, error: deletingError });

  return (
    <>
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      <PageHeading
        onAdd={editHandler}
        variant="h3"
        style={{ marginLeft: "0" }}
        onDelete={selectedPhase ? () => deleteHandler(selectedPhase) : undefined}
        deleteText={`Poista ${selectedPhase?.name}`}
        deleteLoading={isDeleting}
      >
        Kerrokset ja lohkot
      </PageHeading>
      <RadioButton
        onChange={(value) => setSelectedPhase(phases.find(phase => phase?.phaseId === value) ?? null)}
        options={[allPhaseOption, ...phaseOptions]}
        value={selectedPhase?.phaseId ?? ""}
      />
      <hr />
      <ProjectTabs
        phaseOptions={phaseOptions}
        phases={phases}
        selectedPhase={selectedPhase?.phaseId}
        createProjectInput={createProjectInput}
      />
    </>
  );
};

const useDelete = (projectId: string) => {
  const { deleteProjectPhase } = useProjectsService();

  const {
    mutateAsync: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse, string>({
    mutationFn: (phaseId) => deleteProjectPhase(projectId, phaseId),
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

export default ProjectPhases;
