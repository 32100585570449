import { useMemo, useState } from "react";
import { Navigate, Route as RouterRoute, Routes, useNavigate } from "react-router-dom";
import { Route } from "../../../routes";
import IOfferRow from "../../../shared/IOfferRow";
import { IProjectPhase } from "../../../shared/IProject";
import ClaimsList from "../../Claims/ClaimsList";
import OfferProducts from "../../Offers/OfferProducts/OfferProducts";
import OfferProductsContextProvider from "../../Offers/OfferProducts/offer-products-context";
import Button, { EButtonSize } from "../../ui/Button/Button";
import { IOption } from "../../ui/Input/Input";
import { TCreateInput } from "../../ui/Input/useCreateInput";
import Tabs, { ITab } from "../../ui/Tabs/Tabs";
import AdditionalInvoices from "../AdditionalInvoices/AdditionalInvoices";
import ElementStatus from "../Elements/ElementStatus";
import Elements from "../Elements/Elements";
import ProjectAttachments from "../ProjectAttachments/ProjectAttachments";
import { useProjectEditContext } from "../ProjectEdit/project-edit-context";
import ProjectSecurity from "../ProjectSecurity/ProjectSecurity";
import ProjectSettings from "../ProjectSettings/ProjectSettings";
import ProjectWaybills from "../ProjectWaybills/ProjectWaybills";
import WeeklyProductionRows from "../WeeklyProductionRows/WeeklyProductionRows";

enum ETab {
  production = "production",
  elements = "elements",
  order = "order",
  delivery = "delivery",
  claims = "claims",
  additionalInvoices = "additional-invoices",
  attachments = "attachments",
  security = "security",
  settings = "settings",
}

const tabs: ITab[] = [
  { path: ETab.production, title: "Tuotanto", visible: true },
  { path: ETab.elements, title: "Elementit", visible: true },
  { path: ETab.order, title: "Tilaus", visible: true },
  { path: ETab.delivery, title: "Kuljetus", visible: true },
  { path: ETab.claims, title: "Reklamaatiot", visible: true },
  { path: ETab.additionalInvoices, title: "Lisätyöt", visible: true },
  { path: ETab.attachments, title: "Liitteet", visible: true },
  { path: ETab.security, title: "Käyttöoikeudet", visible: true },
  { path: ETab.settings, title: "Asetukset", visible: true },
];

interface IProps {
  phaseOptions: IOption[];
  phases: IProjectPhase[];
  selectedPhase?: string;
  createProjectInput: TCreateInput;
}

const ProjectTabs: React.FC<IProps> = ({ phaseOptions, phases, selectedPhase, createProjectInput }) => {
  // const defaultTab = useMemo(() => ETab.production, []);
  // const [currentTab, setCurrentTab] = useState<ETab>(defaultTab);
  const navigate = useNavigate();

  const { project, weeklyProductionRowsRef, additionalInvoicesRef, elementsRef, projectUsersRef } = useProjectEditContext();
  const [currentOfferRow, setCurrentOfferRow] = useState<IOfferRow | null>(null);
  
  // const { user } = useAuthContext();
  // const showPrices = useMemo(() => BASIC_ROLE.includes(user!.role), [user]);

  const offerProducts = useMemo(() => (
    <OfferProductsContextProvider
      value={{
        offerRows: project.offer?.rows ?? [],
        setOfferRows: () => {},
        currentOfferRow,
        setCurrentOfferRow,
        updateCurrentRow: () => Promise.reject(),
        updateConditionalTerms: () => Promise.reject(),
        currentDeliveryPriceForTon: "",
        summary: {} as any,
        setSummary: () => {},
        installations: {} as any,
        setInstallations: () => {},
        offerKilometers: [],
        overwriteDescription: true,
        setOverwriteDescription: () => {},
        showControls: false,
        showPrices: false // if role != admin role
      }}
    >
      <OfferProducts />
    </OfferProductsContextProvider>
  ), [currentOfferRow, project.offer?.rows]);

  // const getTabStyle = useCallback((id: ETab): React.CSSProperties => currentTab === id ? {} : { display: "none" }, [currentTab]);

  const currentPhase = useMemo(() => phases.find((phase) => phase.phaseId === selectedPhase), [phases, selectedPhase]);

  // const getCurrentTab = useCallback((currentTab: ETab) => {
  //   switch (currentTab) {
  //     case ETab.production:
  //       return (
  //         <div style={{ display: "flex", flexDirection: "row" }}>
  //           <WeeklyProductionRows selectedPhase={currentPhase} phaseOptions={phaseOptions} ref={weeklyProductionRowsRef} />
  //           <div style={{ flexBasis: "min-content" }}>{offerProducts}</div>
  //         </div>
  //       );
  //     case ETab.elements: return <Elements phase={currentPhase} phaseOptions={phaseOptions} ref={elementsRef} />;
  //     case ETab.order: return offerProducts;
  //     case ETab.delivery:
  //       return (
  //         <div>
  //           <Button onClick={() => navigate(Route.waybill(`add?projectId=${project.id}`))} size={EButtonSize.SMALL}>Lisää rahtikirja</Button>
  //           <WaybillsList projectId={project.id} />
  //         </div>
  //       );
  //     case ETab.claims:
  //       return (
  //         <div>
  //           <Button onClick={() => navigate(Route.claim(`add?projectId=${project.id}`))} size={EButtonSize.SMALL}>Lisää reklamaatio</Button>
  //           <ClaimsList projectId={project.id} />
  //         </div>
  //       );
  //     case ETab.additionalInvoices: return <AdditionalInvoices ref={additionalInvoicesRef} />;
  //     case ETab.attachments: return <Attachments kind="Project" parentId={project.id} />;
  //   }
  // }, [additionalInvoicesRef, currentPhase, elementsRef, navigate, offerProducts, phaseOptions, project.id, weeklyProductionRowsRef]);

  return (
    <div style={{ display: "initial" }}>
      {/* <div style={{ display: "flex", paddingBottom: "1rem" }}>
        {tabs.map((tab) => (
          <Button key={tab.path} onClick={() => setCurrentTab(tab.path as ETab)} color={EButtonColor.SECONDARY} size={EButtonSize.SMALL} style={tab.path === currentTab ? { background: "#f8f9fa", color: "black" } : {}}>
            {tab.title}
          </Button>
        ))}
      </div>
      {getCurrentTab(currentTab)} */}
      <Tabs tabs={tabs} />
      <Routes>
        <RouterRoute index element={<Navigate to={`/projects/${project.id}/production`} replace />} />
        <RouterRoute path={ETab.production} element={
          <div style={{ display: "flex", flexDirection: "row" }}>
            <WeeklyProductionRows selectedPhase={currentPhase} phaseOptions={phaseOptions} ref={weeklyProductionRowsRef} />
            <div>
              <div style={{ flexBasis: "min-content" }}>{offerProducts}</div>
              <ElementStatus projectId={project.id} />
            </div>
          </div>
        } />
        <RouterRoute path={ETab.elements} element={<Elements phase={currentPhase} phaseOptions={phaseOptions} ref={elementsRef} />} />
        <RouterRoute path={ETab.order} element={offerProducts} />
        <RouterRoute path={ETab.delivery} element={<ProjectWaybills project={project} />} />
        <RouterRoute path={ETab.claims} element={
          <div>
            <Button onClick={() => navigate(Route.claim(`add?projectId=${project.id}`))} size={EButtonSize.SMALL}>Lisää reklamaatio</Button>
            <ClaimsList projectId={project.id} />
          </div>
        } />
        <RouterRoute path={ETab.additionalInvoices} element={<AdditionalInvoices ref={additionalInvoicesRef} />} />
        <RouterRoute path={ETab.attachments} element={<ProjectAttachments project={project} />} />
        <RouterRoute path={ETab.security} element={<ProjectSecurity createProjectInput={createProjectInput} ref={projectUsersRef} />} />
        <RouterRoute path={ETab.settings} element={<ProjectSettings createProjectInput={createProjectInput} />} />
      </Routes>
    </div>
  );
};

export default ProjectTabs;
