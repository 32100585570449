import { faRemove } from "@fortawesome/free-solid-svg-icons";
import { calculateTotalWeight } from "../../utils/element-utils";
import Button, { EButtonColor, EButtonSize } from "../ui/Button/Button";
import PageHeading from "../ui/PageHeading/PageHeading";
import Table from "../ui/Table/Table";
import InputGroup from "../ui/InputGroup/InputGroup";
import IElement from "../../shared/IElement";
import { ElementSlot, ElementSlots } from "../../shared/IElementUnloading";

interface IProps {
  selectedElements: IElement[];
  selectHandler: (element: IElement) => void;
  addHandler: (slot: ElementSlot) => void;
  isWaybill: boolean;
}

const ElementUnloadingSelectedElements: React.FC<IProps> = ({
  selectedElements,
  selectHandler,
  addHandler,
  isWaybill,
}) => {
  return (
    <>
      <PageHeading variant="h3" noHorizontalMargin>
        Valitut elementit {calculateTotalWeight(selectedElements)}
      </PageHeading>
      {selectedElements.length > 0 ? (
        <>
          <Table>
            <thead>
              <tr>
                <th>Tunnus</th>
                <th>Paino</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {selectedElements.map((element) => (
                <tr key={element.id}>
                  <td>{element.name}</td>
                  <td>{element.weightTons}</td>
                  <td>
                    <Button
                      style={{ marginRight: "0.1rem" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        selectHandler(element);
                      }}
                      size={EButtonSize.SMALL}
                      color={EButtonColor.DANGER}
                      icon={faRemove}
                      title="Poista"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <hr />
          <PageHeading variant="h3" noHorizontalMargin>Vie</PageHeading>
          {isWaybill ? (
            <>
              <InputGroup style={{ padding: "0.25rem 0", gap: "0.25rem" }}>
                <Button onClick={() => addHandler(ElementSlots.A)}>A</Button>
                <Button onClick={() => addHandler(ElementSlots.B)}>B</Button>
                <Button onClick={() => addHandler(ElementSlots.C)}>C</Button>
                <Button onClick={() => addHandler(ElementSlots.D)}>D</Button>
                <Button onClick={() => addHandler(ElementSlots.E)}>E</Button>
              </InputGroup>
              <InputGroup style={{ padding: "0.25rem 0", gap: "0.25rem" }}>
                <Button onClick={() => addHandler(ElementSlots.F)}>F</Button>
                <Button onClick={() => addHandler(ElementSlots.G)}>G</Button>
                <Button onClick={() => addHandler(ElementSlots.H)}>H</Button>
                <Button onClick={() => addHandler(ElementSlots.I)}>I</Button>
                <Button onClick={() => addHandler(ElementSlots.J)}>J</Button>
              </InputGroup>
              <InputGroup style={{ padding: "0.25rem 0", gap: "0.25rem" }}>
                <Button onClick={() => addHandler(ElementSlots["1"])}>1</Button>
                <Button onClick={() => addHandler(ElementSlots["2"])}>2</Button>
                <Button onClick={() => addHandler(ElementSlots["3"])}>3</Button>
                <Button onClick={() => addHandler(ElementSlots["4"])}>4</Button>
                <Button onClick={() => addHandler(ElementSlots["5"])}>5</Button>
              </InputGroup>
            </>
          ) : (
            <>
              <InputGroup style={{ padding: "0.25rem 0", gap: "0.25rem" }}>
                <Button onClick={() => addHandler(ElementSlots.A)}>A</Button>
                <Button onClick={() => addHandler(ElementSlots.B)}>B</Button>
                <Button onClick={() => addHandler(ElementSlots.C)}>C</Button>
                <Button onClick={() => addHandler(ElementSlots.D)}>D</Button>
              </InputGroup>
              <InputGroup style={{ padding: "0.25rem 0", gap: "0.25rem" }}>
                <Button onClick={() => addHandler(ElementSlots.E)}>E</Button>
                <Button onClick={() => addHandler(ElementSlots.F)}>F</Button>
                <Button onClick={() => addHandler(ElementSlots.G)}>G</Button>
                <Button onClick={() => addHandler(ElementSlots.H)}>H</Button>
              </InputGroup>
            </>
          )}
        </>
      ) : (
        <p>Ei valittuja elementtejä.</p>
      )}
    </>
  );
};

export default ElementUnloadingSelectedElements;
