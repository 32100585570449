import { faPrint, faQrcode } from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../../App";
import { adminUiUrl } from "../../../config";
import { useNavigationBlockerContext } from "../../../context/navigation-blocker-context";
import { ErrorResponse } from "../../../custom-fetch";
import { Route } from "../../../routes";
import { useProductionLinesService } from "../../../services/productionLines-service";
import { QueryKey } from "../../../services/query-keys";
import IElement from "../../../shared/IElement";
import IProductionLine from "../../../shared/IProductionLine";
import IWaybillRow from "../../../shared/IWaybillRow";
import ErrorsAlert, { combineErrors } from "../../ErrorsAlert/ErrorsAlert";
import Button, { EButtonColor } from "../../ui/Button/Button";
import Container from "../../ui/Container/Container";
import PageHeading from "../../ui/PageHeading/PageHeading";
import ProductionLineUnloading from "../ProductionLineUnloading/ProductionLineUnloading";
import { useAttachmentsService } from "../../../services/attachments-service";

interface IProps {
  id: string;
  productionLine: IProductionLine;
  productionLineElements: IElement[];
}

const ProductionLineEditLogistics: React.FC<IProps> = ({ id, productionLine, productionLineElements }) => {
  const [loading, setLoading] = useState(false);
  // const [unloadingRows, setUnloadingRows] = useState<IWaybillRow[]>([]);
  const [warehouseRows, setWarehouseRows] = useState<IWaybillRow[]>([]);
  // const [castingRows, setCastingRows] = useState<IWaybillRow[]>([]);

  const { showBlocker, showNavigationBlockerModal, setIsDirty } = useNavigationBlockerContext();

  // useEffect(() => {
  //   if (productionLine?.unloadingRows) {
  //     setUnloadingRows(productionLine.unloadingRows);
  //   }
  // }, [productionLine?.unloadingRows]);

  useEffect(() => {
    if (productionLine?.warehouseRows) {
      setWarehouseRows(productionLine.warehouseRows);
    }
  }, [productionLine?.warehouseRows]);
  
  // useEffect(() => {
  //   if (productionLine?.castingRows) {
  //     setCastingRows(productionLine.castingRows);
  //   }
  // }, [productionLine?.castingRows]);

  const navigate = useNavigate();
  const { updateMutate, isUpdateError, updateError } = useUpdate(id);

  const submitHandler = useCallback(async () => {
    setLoading(true);
    try {
      await updateMutate({
        ...productionLine,
        // unloadingRows,
        warehouseRows,
        // castingRows,
      });
      setIsDirty(false);
      setLoading(false);
      return true;
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    return false;
  }, [productionLine, setIsDirty, updateMutate, warehouseRows]);

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.repeat) return;
      if (!e.ctrlKey) return;
      const key = e.key.toLowerCase();
      switch (key) {
        case "s":
          e.preventDefault();
          submitHandler();
          break;
      }
    };

    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [submitHandler]);

  useEffect(() => {
    if (showBlocker) {
      showNavigationBlockerModal(submitHandler);
    }
  }, [showBlocker, showNavigationBlockerModal, submitHandler]);

  const errorMessages = combineErrors(
    { isError: isUpdateError, error: updateError },
  );

  const submitAndNavigateHandler = useCallback(async (route: Route) => {
    await submitHandler();
    setIsDirty(false);
    navigate(route);
  }, [submitHandler, setIsDirty, navigate]);

  const openQRHandler = useCallback(() => {
    if (productionLine?.uuid) {
      const url = `${adminUiUrl}/qr/production-line/${productionLine.uuid}`;
      window.open(url, "_blank");
    }
  }, [productionLine?.uuid]);

  const { downloadMergedAttachmentsMutate } = useProductionLineAttachments(productionLine?.id ?? "");
  const [mergedLoading, setMergedLoading] = useState(false);

  const downloadAttachmentsHandler = useCallback(async () => {
    setMergedLoading(true);
    try {
      const attachment = await downloadMergedAttachmentsMutate();
      const objectUrl = URL.createObjectURL(attachment);
      window.open(objectUrl, "_blank");
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.error(error);
    }
    setMergedLoading(false);
  }, [downloadMergedAttachmentsMutate]);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <PageHeading>
          {productionLine?.prevProductionLineId ? <span onClick={() => navigate(Route.productionLine(productionLine.prevProductionLineId!))} style={{cursor: "pointer"}}>{"<"}</span> : ""}
            Peti {productionLine?.productionLineNumber}
          {productionLine?.nextProductionLineId ? <span onClick={() => navigate(Route.productionLine(productionLine.nextProductionLineId!))} style={{cursor: "pointer"}}>{">"}</span> : ""}
          </PageHeading>
        {productionLine?.id && (
          <>
            <Button onClick={() => submitAndNavigateHandler(Route.productionLineElements(productionLine.id!))} loading={loading} icon={faPrint} color={EButtonColor.SECONDARY}>Tunnuslaput</Button>
            <Button onClick={() => submitAndNavigateHandler(Route.productionLinePrint(productionLine.id!))} loading={loading} icon={faPrint} color={EButtonColor.SECONDARY}>Tulosta</Button>
            {productionLine.hasAttachments && <Button onClick={downloadAttachmentsHandler} loading={loading || mergedLoading} icon={faPrint} color={EButtonColor.SECONDARY}>Liitteet</Button>}
            <Button onClick={openQRHandler} loading={loading} icon={faQrcode} color={EButtonColor.SECONDARY} title="QR"/>
          </>
        )}
        <Button onClick={submitHandler} loading={loading}>Tallenna</Button>
        <Button onClick={() => navigate(Route.productionLines)} color={EButtonColor.SECONDARY}>Palaa</Button>
      </div>
      <Container>
        <form onBlur={loading ? undefined : () => setIsDirty(true)}>
          {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
          {productionLine.factory && productionLine?.id ? (
            <ProductionLineUnloading
              id={productionLine.id}
              statusOrder={productionLine.statusOrder}
              productionLineElements={productionLineElements}
              // unloadingRows={unloadingRows}
              // setUnloadingRows={setUnloadingRows}
              warehouseRows={warehouseRows}
              setWarehouseRows={setWarehouseRows}
              // castingRows={castingRows}
              // setCastingRows={setCastingRows}
              setIsDirty={setIsDirty}
              lineLength={productionLine.productionLineLength}
            />
          ) : null}
        </form>
      </Container>
    </>
  );
};

const useUpdate = (id: string) => {
  const { updateProductionLine } = useProductionLinesService();

  const {
    mutateAsync: updateMutate,
    isPending,
    isError,
    error,
  } = useMutation<IProductionLine, ErrorResponse, IProductionLine>({
    mutationFn: (data) => updateProductionLine(id, data),
    onSuccess: (data) => {
      // queryClient.invalidateQueries({ queryKey: [QueryKey.productionLines] });
      if (data.factory) {
        queryClient.invalidateQueries({ queryKey: [QueryKey.productionLines, data.factory] });
      }
      // if (data.id) {
      //   if (!isEdit) {
      //     redirectHandler(data.id);
      //   }
      //   queryClient.setQueryData([QueryKey.productionLines, data.id], data);
      // }
    },
  });

  return {
    updateMutate,
    isUpdating: isPending,
    isUpdateError: isError,
    updateError: error,
  };
};

const useProductionLineAttachments = (productionLineId: string) => {
  const { downloadLatestAttachment } = useAttachmentsService();

  const {
    mutateAsync: downloadMergedAttachmentsMutate,
  } = useMutation<Blob, ErrorResponse>({
    mutationFn: () => downloadLatestAttachment({ kind: "ProductionLine", parentId: productionLineId }),
  });

  return { downloadMergedAttachmentsMutate };
}

export default ProductionLineEditLogistics;
