import { format } from "date-fns";
import { useCallback, useState } from "react";
import IAuditHistory, { AuditHistoryType } from "../../../shared/IAuditHistory";
import Button, { EButtonColor, EButtonSize } from "../../ui/Button/Button";
import { ECommonValue, EInputType, IInputField } from "../../ui/Input/Input";
import { useInputs } from "../../ui/Input/useInputs";
import ModalBody from "../../ui/Modal/ModalBody/ModalBody";
import ModalFooter from "../../ui/Modal/ModalFooter/ModalFooter";
import InputGroup from "../../ui/InputGroup/InputGroup";
import { ISaveToolAuditHistory } from "./useAuditHistoryEditModal";
import ErrorsAlert from "../../ErrorsAlert/ErrorsAlert";
import Dropzone from "../../Dropzone/Dropzone";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

enum EInputs {
  text = "text",
  date = "date",
  accepted = "accepted",
}

interface IProps {
  onAccept: (data: ISaveToolAuditHistory) => Promise<void>;
  onCancel: () => void;
  auditHistory?: IAuditHistory;
  type: AuditHistoryType;
}

const AuditHistoryEditModal: React.FC<IProps> = ({
  onAccept,
  onCancel,
  auditHistory,
  type,
}) => {
  const { createInput, submit } = useAuditHistoryInputs(auditHistory);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);

  const submitHandler = async () => {
    if (type === "AuditHistory") {
      if (!file) {
        setFileError("Tiedosto puuttuu!");
        return;
      }
      setFileError(null);
    }
    const data = await submit();
    if (data) {
      setLoading(true);
      await onAccept({ ...data, file });
      setLoading(false);
    }
  };

  const dropHandler = useCallback(async (files: File[]) => {
    if (!files || !files.length) return;
    setFile(files[0]);
    setFileError(null);
  }, []);

  return (
    <>
      <ModalBody>
        <InputGroup>
          {createInput(EInputs.date)}
          {type === "AuditHistory" && createInput(EInputs.accepted)}
        </InputGroup>
        {createInput(EInputs.text)}
        <Dropzone onDrop={dropHandler} canPaste style={{ margin: "1rem 0" }} />
        {fileError && <ErrorsAlert errors={[fileError]} />}
        {file && (
          <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <span>Valittu tiedosto: {file?.name}</span>
            <Button
              onClick={() => setFile(null)}
              size={EButtonSize.X_SMALL}
              color={EButtonColor.DANGER}
              icon={faTrash}
              title="Poista"
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={submitHandler}
          style={{ marginRight: "0.5rem" }}
          loading={loading}
        >
          Tallenna
        </Button>
        <Button onClick={onCancel} color={EButtonColor.DANGER}>
          Peruuta
        </Button>
      </ModalFooter>
    </>
  );
};

const useAuditHistoryInputs = (data?: IAuditHistory) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.text]: {
      type: EInputType.textarea,
      label: "Selite",
      value: "",
      rows: 4,
      validation: {
        required: true,
      },
    },
    [EInputs.date]: {
      type: EInputType.date,
      label: "Pvm",
      value: format(new Date(), "yyyy-MM-dd"),
      validation: {
        required: true,
      },
    },
    [EInputs.accepted]: {
      type: EInputType.checkbox,
      label: "Hyväksytty",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit };
};

export default AuditHistoryEditModal;
