import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { queryClient } from "../../App";
import { ErrorResponse } from "../../custom-fetch";
import { Route } from "../../routes";
import { QueryKey } from "../../services/query-keys";
import ErrorsAlert, { combineErrors } from "../ErrorsAlert/ErrorsAlert";
import FormButtons from "../ui/FormButtons/FormButtons";
import { ECommonValue, EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import { useDriverOrganizationService } from "../../services/driverOrganizations-service";
import IDriverOrganization from "../../shared/IDriverOrganization";

interface IProps {
  id: string;
  driverOrganization?: IDriverOrganization;
}

enum EInputs {
  name = "name",
  archived = "archived"
}

const DriverOrganizationEdit: React.FC<IProps> = ({ id, driverOrganization }) => {
  const isEdit = id !== "add";
  
  const navigate = useNavigate();

  const redirectHandler = () => {
    return navigate(Route.driverOrganizations);
  };

  const { saveOrUpdate, error, isError, isPending } = useSaveOrUpdate(id, isEdit, redirectHandler);
  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete(id, redirectHandler);
  const { createInput, submit } = useDriverOrganizationInputs(driverOrganization);

  const submitHandler = useCallback(async () => {
    const data = await submit();
    if (data) {
      saveOrUpdate(data);
    }
  }, [saveOrUpdate, submit]);

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.repeat) return;
      if (!e.ctrlKey) return;
      const key = e.key.toLowerCase();
      switch (key) {
        case "s":
          e.preventDefault();
          submitHandler();
          break;
      }
    };

    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [submitHandler]);

  const errorMessages = combineErrors({ isError, error }, { isError: isDeletingError, error: deletingError });

  return (
    <>
      {createInput(EInputs.name)}
      {/*createInput(EInputs.archived)*/}
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      <FormButtons
        onSubmit={submitHandler}
        isLoading={isPending}
        onDelete={isEdit ? deleteMutate : undefined}
        isDeleting={isDeleting}
        deleteConfirmMessage="Haluatko varmasti poistaa kuljetusliikkeen?"
      />
    </>
  );
};

const useDriverOrganizationInputs = (data?: IDriverOrganization) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.name]: {
      type: EInputType.text,
      label: "Nimi",
      value: "",
      validation: {
        required: true,
      },
      autoFocus: true
    },
    [EInputs.archived]: {
      type: EInputType.checkbox,
      label: "Arkistoitu",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit };
};

const useSaveOrUpdate = (id: string, isEdit: boolean, redirectHandler: () => void) => {
  const { updateDriverOrganization, saveDriverOrganization } = useDriverOrganizationService();

  const mutationFn = (data: IDriverOrganization) => {
    return isEdit ? updateDriverOrganization(id, data) : saveDriverOrganization(data);
  };

  const {
    mutate: saveOrUpdate,
    isPending,
    isError,
    error,
  } = useMutation<IDriverOrganization, ErrorResponse, IDriverOrganization>({
    mutationFn,
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: [QueryKey.driverOrganizations, id] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.driverOrganizations] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.driverOrganizationOptions] });
      redirectHandler();
    },
  });

  return { saveOrUpdate, isPending, isError, error };
};

const useDelete = (id: string, redirectHandler: () => void) => {
  const { deleteDriverOrganization } = useDriverOrganizationService();

  const {
    mutate: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse>({
    mutationFn: () => deleteDriverOrganization(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.driverOrganizations],
        refetchType: "none",
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKey.driverOrganizationOptions],
        refetchType: "none",
      });
      redirectHandler();
    },
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

export default DriverOrganizationEdit;
