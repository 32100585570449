import { IOption } from "../components/ui/Input/Input";

interface IWireReel {
  id: string;
  shipmentFactory: string;
  locationFactory: string;
  type: string;
  transferDate: string;
  activationDate: string;
  arrivalDate: string;
  activationLocation: string;
  reelNumber: string;
  manufacturer: string;
  notes: string;
  crossSectionalArea: string;
  pricePerTon: string;
  priceTotal: string;
  elasticModulus: string;
  massPerMeter: string;
  weight: string;
  proof02: string;
  elongation: string;
  breakingStrength: string;
  finished: boolean;
  archived: boolean;
  updatedString: string;
}

export enum EWireReelType {
  HOLLOWCORE = "HOLLOWCORE",
  BEAM = "BEAM",
}

export const WIRE_REEL_TYPE_OPTIONS: IOption[] = [
  { value: EWireReelType.HOLLOWCORE, label: "Ontelo" },
  { value: EWireReelType.BEAM, label: "Palkki" },
];

export default IWireReel;
