import { useMutation } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { queryClient } from "../../../App";
import { ErrorResponse } from "../../../custom-fetch";
import { useToolOptions } from "../../../hooks/useToolOptions";
import { usePublicQRCodesService } from "../../../services/publicQRCodes-service";
import { QueryKey } from "../../../services/query-keys";
import ITool, { WaybillToolType } from "../../../shared/ITool";
import Button, { EButtonSize } from "../../ui/Button/Button";
import { EInputType, IInputField } from "../../ui/Input/Input";
import { updateInputHandler } from "../../ui/Input/input-utils";
import { useInputs } from "../../ui/Input/useInputs";
import IWaybillDTO from "../../../shared/IWaybillDTO";
import ErrorsAlert, { combineErrors } from "../../ErrorsAlert/ErrorsAlert";
import IWaybill from "../../../shared/IWaybill";

enum EInputs {
  toolId = "toolId",
}

interface IProps {
  type: WaybillToolType;
  waybillTools: ITool[];
  waybill: IWaybill | IWaybillDTO;
  disabled?: boolean;
}

const WaybillToolEdit: React.FC<IProps> = ({ type, waybillTools = [], waybill, disabled }) => {
  const { factory, deliveryDate, id, uuid } = waybill;
  const { createInput, submit, setInputs } = useWaybillToolInputs();
  const { options, loading, tools } = useToolOptions(type, factory, deliveryDate);

  const { saveWaybillToolMutate, isPending, isError, error } = useSaveWaybillTool(type, id);

  const submitHandler = useCallback(async () => {
    const data = await submit();
    console.log(data);
    if (!data) return;
    const newTool = tools?.find((tool) => tool.id === data.toolId);
    if (!newTool) return;
    try {
        await saveWaybillToolMutate(newTool.id);
        queryClient.setQueryData<IWaybillDTO>([QueryKey.publicWaybills, uuid], (waybill) => ({ ...waybill!, [type]: [...waybill![type] ?? [], newTool] }));
        updateInputHandler(EInputs.toolId, "", setInputs);
        queryClient.setQueryData<ITool[]>([QueryKey.toolOptions, type], (tools) => tools?.filter((tool) => tool.id !== data.toolId));
    } catch (error) {
        console.error(error);
    }
  }, [saveWaybillToolMutate, setInputs, submit, tools, type, uuid]);

  const errorMessages = combineErrors({ isError, error });

  const toolIds = useMemo(() => (waybillTools ?? []).map(tool => tool.id), [waybillTools]);
  const filteredOptions = useMemo(() => options?.filter(option => !toolIds.includes(option.value)), [options, toolIds]);

  return (
    <>
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      {!disabled && (
        <div style={{ display: "flex", gap: "1rem" }}>
            {createInput(EInputs.toolId, {
            options: filteredOptions,
            loading,
            containerStyles: { width: "200px" },
            })}
            <div>
            <Button onClick={submitHandler} size={EButtonSize.SMALL} loading={isPending}>
                Lisää
            </Button>
            </div>
        </div>
      )}
      {waybillTools.length > 0 ? (
        <ul style={{ margin: "0" }}>
          {waybillTools.map((tool) => (
            <li key={tool.id}>{tool.label}</li>
          ))}
        </ul>
      ) : disabled ? (
        <span>Ei nostovälineitä</span>
      ) : null}
    </>
  );
};

const useWaybillToolInputs = (data?: { toolId: string }) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.toolId]: {
      type: EInputType.reactSelect,
      options: [],
      placeholder: "Valitse nostoväline",
      value: "",
      hideControls: true,
      menuPosition: "fixed",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit, setInputs };
};

const useSaveWaybillTool = (type: WaybillToolType, id: string) => {
  const { saveWaybillTool } = usePublicQRCodesService();

  const {
    mutateAsync: saveWaybillToolMutate,
    isPending,
    isError,
    error,
  } = useMutation<boolean, ErrorResponse, string>({
    mutationFn: (toolId) => saveWaybillTool(type, id, toolId),
  });

  return { saveWaybillToolMutate, isPending, isError, error };
};

export default WaybillToolEdit;
