import { useNavigate } from "react-router-dom";
import { useTransportOrdersService } from "../../services/transportOrders-service";
import { useQuery } from "@tanstack/react-query";
import ITransportOrder from "../../shared/ITransportOrder";
import { ErrorResponse } from "../../custom-fetch";
import { QueryKey } from "../../services/query-keys";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import { Route } from "../../routes";
import Table from "../ui/Table/Table";
import { formatDate } from "../../utils/date-utils";
import { EFactory } from "../../shared/IOfferRow";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  factory: EFactory;
}

enum EListSortBy {
  transportOrderNumber = "transportOrderNumber",
  factory = "factory",
  projectNumber = "projectNumber",
  deliveryDate = "deliveryDate",
  tons = "tons",
  count = "count"
}

const TransportOrdersList: React.FC<IProps> = ({ factory }) => {
  const { fetchTransportOrders } = useTransportOrdersService();

  const navigate = useNavigate();
  const {
    data: transportOrders,
    isPending,
    isError,
    error,
  } = useQuery<ITransportOrder[], ErrorResponse>({
    queryKey: [QueryKey.transportOrders, factory],
    queryFn: ({ signal }) => fetchTransportOrders({ signal, factory }),
    staleTime: 5000,
  });

  const [sorted, setSorted] = useState<ITransportOrder[]>([]);
  const [sort, setSort] = useState<EListSortBy>();
  const [sortDirection, setSortDirection] = useState<boolean>(false); // true = up, false = down
  const changeSortOrDirection = (sortBy: EListSortBy) => {
    if (sortBy === sort) {
      setSortDirection(!sortDirection);
    } else {
      setSort(sortBy);
      setSortDirection(false);
    }
  }
  const getChevron = () => {
    return <FontAwesomeIcon style={{ marginLeft: '.5rem', fontSize: '.75rem'}} icon={sortDirection ? faChevronUp : faChevronDown} />
  };

  useEffect(() => {
    const newSorted = [...sorted];
    if (sort && newSorted) {
      if (sortDirection) {
        newSorted.reverse();
        setSorted(newSorted);
        return;
      }
      newSorted.sort((a, b) => {
        const getFieldValue = (item: ITransportOrder, field: EListSortBy) => {
          return item[field];
        };
        const aValue = getFieldValue(a, sort) || "";
        const bValue = getFieldValue(b, sort) || "";
        if (aValue === bValue) return 0;
        return aValue > bValue ? 1 : -1;
      });
      setSorted(newSorted);
    }
    // eslint-disable-next-line
  }, [sort, sortDirection]);

  useEffect(() => {
    if (transportOrders) {
      setSorted(transportOrders);
    }
  }, [transportOrders])

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  if (!transportOrders || transportOrders.length === 0) {
    return <p>Ei kuljetustilauksia</p>;
  }

  const openHandler = (id: string) => {
    navigate(Route.transportOrder(id));
  };

  return (
    <Table hover>
      <thead style={{cursor: "pointer"}}>
        <tr>
          <th onClick={() => changeSortOrDirection(EListSortBy.transportOrderNumber)}>Numero {sort === EListSortBy.transportOrderNumber && getChevron()}</th>
          <th onClick={() => changeSortOrDirection(EListSortBy.factory)}>Tehdas {sort === EListSortBy.factory && getChevron()}</th>
          <th onClick={() => changeSortOrDirection(EListSortBy.projectNumber)}>Työmaa {sort === EListSortBy.projectNumber && getChevron()}</th>
          <th onClick={() => changeSortOrDirection(EListSortBy.deliveryDate)}>Kuljetuksen pvm {sort === EListSortBy.deliveryDate && getChevron()}</th>
          <th onClick={() => changeSortOrDirection(EListSortBy.tons)}>Tonnit {sort === EListSortBy.tons && getChevron()}</th>
          <th onClick={() => changeSortOrDirection(EListSortBy.count)}>Kpl {sort === EListSortBy.count && getChevron()}</th>
        </tr>
      </thead>
      <tbody>
        {sorted.map((transportOrder) => (
          <tr key={transportOrder.id} onClick={() => openHandler(transportOrder.id)}>
            <td>{transportOrder.transportOrderNumber}</td>
            <td>{transportOrder.factory}</td>
            <td>{transportOrder.projectNumber} {transportOrder.projectName}</td>
            <td>{formatDate(transportOrder.deliveryDate)} {transportOrder.deliveryTime}</td>
            <td>{transportOrder.tons}</td>
            <td>{transportOrder.count}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TransportOrdersList;
