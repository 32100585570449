import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import Table from "../ui/Table/Table";
import { useWaybillsSearch } from "./useWaybillsSearch";
import { formatDate, getCurrentDay } from "../../utils/date-utils";
import classes from './WaybillsPrint.module.scss';
import { useEffect, useState } from "react";

const WaybillsPrintList: React.FC = () => {
  const { isError, error, isLoading, waybills } = useWaybillsSearch({});

  const [printOpened, setPrintOpened] = useState(false);
  useEffect(() => {
    if (waybills && !isLoading && !printOpened) {
      setPrintOpened(true);
      setTimeout(() => {
        window.print();
      }, 500);
    }
  }, [waybills, isLoading, printOpened]);

  if (isError && error) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (!waybills || waybills.length === 0) {
    return <p>Ei rahtikirjoja</p>;
  }

  return (
    <>
      <Table className={classes.printTable}>
        <thead>
          <tr>
            <th>Num</th>
            <th colSpan={2}>Työmaa</th>
            <th>Pvm</th>
            <th>Klo</th>
            <th>Km</th>
            <th>Kuljettaja</th>
            <th>Auto</th>
            <th>Tonnit</th>
            <th>Kpl</th>
            <th>Huomautus</th>
          </tr>
        </thead>
        <tbody>
          {waybills.map((waybill) => (
            <tr key={waybill.id}>
              <td>{waybill.waybillId}</td>
              <td>{waybill.projectNumber}</td>
              <td>{waybill.projectName}</td>
              <td>{formatDate(waybill.deliveryDate)}</td>
              <td>{waybill.deliveryTime}</td>
              <td>{waybill.kilometers}</td>
              <td>{waybill.driverOrganization?.name}</td>
              <td>{waybill.car?.name}</td>
              <td>{waybill.tons && (+waybill.tons).toFixed(2)}</td>
              <td>{waybill.count}</td>
              <td>{waybill.notes}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <p  className={classes.printTable}>Tulostettu {getCurrentDay()}</p>
    </>
  );
};

export default WaybillsPrintList;
