import { faAdd, faEdit, faMailBulk, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useState
} from "react";
import IProjectUser from "../../../shared/IProjectUser";
import { RefHandle } from "../../../shared/RefHandle";
import Button, { EButtonColor, EButtonSize } from "../../ui/Button/Button";
import { ECommonValue, EInputType, IInputField } from "../../ui/Input/Input";
import { EInputUpdateAction } from "../../ui/Input/input-context";
import { useInputs } from "../../ui/Input/useInputs";
import { useProjectUserEditModal } from "../../UserEditModal/useProjectUserEditModal";
import { IProjectDirty } from "../Project/Project";

enum EInputs {
//   user = "user",
  role = "role",
  allowLoginEndDate = "allowLoginEndDate",
  allowWaybills = "allowWaybills",
  allowProjectStatus = "allowProjectStatus",
  allowBilling = "allowBilling",
}

interface IProps {
  user: IProjectUser;
  onDelete: (event: React.MouseEvent) => void;
  onSend: () => void;
  projectUsers?: IProjectUser[];
  setProjectUsers: React.Dispatch<React.SetStateAction<IProjectUser[]>>;
  setIsProjectDirty: React.Dispatch<React.SetStateAction<IProjectDirty>>;
  isLoading?: boolean;
}

const ProjectUserEdit: React.ForwardRefRenderFunction<
  RefHandle<IProjectUser>,
  IProps
> = ({ user, onDelete, onSend, setProjectUsers, setIsProjectDirty, isLoading }, ref) => {
  const { createInput, submit } = useProjectUserInputs(user);

  const openUserEditModal = useProjectUserEditModal();

  const addUserHandler = useCallback(async () => {
    const newUser = await openUserEditModal(user?.user);
    if (newUser) {
    //   newUser.id = user.id;
      console.log(newUser);
      setProjectUsers((users) => [
        ...users.map((u) =>
          u.id === user.id ? { ...u, user: { ...newUser } } : u
        ),
      ]);
      setIsProjectDirty((dirty) => ({ ...dirty, projectUsers: true }));
    }
  }, [openUserEditModal, setIsProjectDirty, setProjectUsers, user.id, user?.user]);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const data = await submit();
      // console.log(data);
      if (!data) return null;
      if (data.user) {
        data.user = { ...user.user, id: undefined } as any;
      }
      console.log(data);
      return { ...user, ...data };
    },
  }));

  return (
    <>
      <tr>
        <td>
          {user.user?.firstName ?? ""} {user.user?.lastName ?? ""}{" "}
          <Button onClick={addUserHandler} size={EButtonSize.X_SMALL}>
            <FontAwesomeIcon icon={user.user ? faEdit : faAdd} />
          </Button>
        </td>
        <td>{createInput(EInputs.role)}</td>
        <td style={{ display: "flex", gap: "1rem" }}>
          {createInput(EInputs.allowWaybills)}
          {createInput(EInputs.allowProjectStatus)}
          {createInput(EInputs.allowBilling)}
        </td>
        <td>{createInput(EInputs.allowLoginEndDate)}</td>
        <td style={{ borderTop: 0, display: "flex", gap: "0.1rem" }}>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onDelete(e);
            }}
            size={EButtonSize.SMALL}
            color={EButtonColor.DANGER}
            icon={faTimes}
            title="Poista"
          />
          {user.user?.email && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onSend();
              }}
              size={EButtonSize.SMALL}
              icon={faMailBulk}
              title="Lähetä kirjautumislinkki"
              loading={isLoading}
            />
          )}
        </td>
      </tr>
    </>
  );
};

const useProjectUserInputs = (data?: IProjectUser) => {
  const [inputs, setInputs] = useState<IInputField>({
    // [EInputs.user]: {
    //   type: EInputType.reactSelect,
    //   options: [],
    //   value: "",
    //   hideControls: true,
    //   menuPosition: "fixed",
    //   placeholder: "",
    //   //   label: "Käyttäjä",
    // },
    [EInputs.role]: {
      type: EInputType.text,
      value: "",
      //   label: "Rooli",
    },
    [EInputs.allowLoginEndDate]: {
      type: EInputType.date,
      value: "",
      //   label: "Viimeinen kirjautumispvm",
    },
    [EInputs.allowWaybills]: {
      type: EInputType.checkbox,
      label: "Rahtikirjat",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
    [EInputs.allowProjectStatus]: {
      type: EInputType.checkbox,
      label: "Työmaan tilanne",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
    [EInputs.allowBilling]: {
      type: EInputType.checkbox,
      label: "Laskutus",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
  });

  const { createInput, submit } = useInputs({
    data,
    inputs,
    setInputs,
    updateAction: EInputUpdateAction.PROJECT_USERS,
  });

  return { createInput, submit, inputs, setInputs };
};

export default forwardRef(ProjectUserEdit);
