import { ReactNode, createContext, useContext } from 'react';
import { TInputValue } from './Input';

export enum EInputUpdateAction {
  OFFER = "OFFER",
  STEEL_PART = "STEEL_PART",
  HOLLOWCORE = "HOLLOWCORE",
  CONCRETE = "CONCRETE",
  STEEL = "STEEL",
  STEEL_TENDON = "STEEL_TENDON",
  MESH = "MESH",
  INSULATION = "INSULATION",
  WORK = "WORK",
  OFFER_ROW = "OFFER_ROW",
  OFFER_TERM = "OFFER_TERM",
  CONSIDERATIONS = "CONSIDERATIONS",
  OFFER_KILOMETERS = "OFFER_KILOMETERS",
  INSTALLATIONS = "INSTALLATIONS",
  INSTALLATION_ROW = "INSTALLATION_ROW",
  OFFER_SUMMARY = "OFFER_SUMMARY",
  ORDER = "ORDER",
  INSTALMENT_ROW = "INSTALMENT_ROW",
  WAYBILL_ROW = "WAYBILL_ROW",
  WEEKLY_PRODUCTION_ROWS = "WEEKLY_PRODUCTION_ROWS",
  ADDITIONAL_INVOICES = "ADDITIONAL_INVOICES",
  ELEMENTS = "ELEMENTS",
  ELEMENTS_SELECT_ALL = "ELEMENTS_SELECT_ALL",
  PROJECT_USERS = "PROJECT_USERS",
}

export type TAutoUpdate = (inputName: string, value: TInputValue, action: string, data?: Object | undefined) => Promise<boolean>;

type InputType = {
  onAutoUpdate: TAutoUpdate;
  initDone: boolean;
  showValidation?: boolean;
  setShowValidation?: React.Dispatch<React.SetStateAction<boolean>>;
};

const InputContext = createContext<InputType>({
  onAutoUpdate: async () => { return false },
  initDone: false,
});

export const useInputContext = () => useContext(InputContext);

const InputContextProvider: React.FC<InputType & { children: ReactNode }> = ({
  initDone,
  onAutoUpdate,
  setShowValidation,
  showValidation,
  children,
}) => {
  return (
    <InputContext.Provider
      value={{
        initDone,
        onAutoUpdate,
        setShowValidation,
        showValidation,
      }}
    >
      {children}
    </InputContext.Provider>
  );
};

export default InputContextProvider;
