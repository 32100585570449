import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Route } from "../../routes";
import { formatDate } from "../../utils/date-utils";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Button, { EButtonColor, EButtonSize } from "../ui/Button/Button";
import Spinner from "../ui/Spinner/Spinner";
import Table from "../ui/Table/Table";
import ElementStatus from "./Elements/ElementStatus";
import { useProjectsSearch } from "./useProjectsSearch";
import IProject from "../../shared/IProject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

enum EListSortBy {
  offerDate = "offerDate",
  projectNumber = "projectNumber",
  name = "name",
  targetName = "targetName",
  customer = "customer",
  seller = "seller",
}

const ProjectsList: React.FC = () => {
  const { isError, error, isLoading, projects } = useProjectsSearch();
  //const { fetchProjects } = useProjectsService();
  const [activeProjectId, setActiveProjectId] = useState<string | null>(null);

  const navigate = useNavigate();
  /*
  const {
    data: projects,
    isPending,
    isError,
    error,
  } = useQuery<IProject[], ErrorResponse>({
    queryKey: [QueryKey.projects],
    queryFn: fetchProjects,
    staleTime: 5000,
  });
  */

  const [sorted, setSorted] = useState<IProject[]>([]);
  const [sort, setSort] = useState<EListSortBy>();
  const [sortDirection, setSortDirection] = useState<boolean>(false); // true = up, false = down
  const changeSortOrDirection = (sortBy: EListSortBy) => {
    if (sortBy === sort) {
      setSortDirection(!sortDirection);
    } else {
      setSort(sortBy);
      setSortDirection(false);
    }
  }
  const getChevron = () => {
    return <FontAwesomeIcon style={{ marginLeft: '.5rem', fontSize: '.75rem'}} icon={sortDirection ? faChevronUp : faChevronDown} />
  };

  useEffect(() => {
    const newSorted = [...sorted];
    if (sort && newSorted) {
      if (sortDirection) {
        newSorted.reverse();
        setSorted(newSorted);
        return;
      }
      newSorted.sort((a, b) => {
        const getFieldValue = (item: IProject, field: EListSortBy) => {
          if (field === EListSortBy.offerDate) return item.offer?.offerDate;
          if (field === EListSortBy.targetName) return item.offer?.targetName;
          if (field === EListSortBy.customer) return item.offer?.customer?.name;
          if (field === EListSortBy.seller) return item.offer?.seller?.name;
          return item[field];
        };
        const aValue = getFieldValue(a, sort) || "";
        const bValue = getFieldValue(b, sort) || "";
        if (aValue === bValue) return 0;
        return aValue > bValue ? 1 : -1;
      });
      setSorted(newSorted);
    }
    // eslint-disable-next-line
  }, [sort, sortDirection]);

  useEffect(() => {
    if (projects) {
      setSorted(projects);
    }
  }, [projects])

  const toggleHandler = useCallback((event: React.MouseEvent, id: string) => {
    event.stopPropagation();
    if (id === activeProjectId) {
      setActiveProjectId(null);
      return;
    }
    setActiveProjectId(id);
  }, [activeProjectId])

  if (isError && error) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (!projects || projects.length === 0) {
    return <p>Ei työmaita</p>;
  }

  const openHandler = (id: string) => {
    navigate(Route.project(id));
  };

  return (
    <>
      <Table hover>
        <thead style={{cursor: "pointer"}}>
          <tr>
            <th onClick={() => changeSortOrDirection(EListSortBy.offerDate)}>Pvm {sort === EListSortBy.offerDate && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.projectNumber)}>Työmaan nro {sort === EListSortBy.projectNumber && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.name)}>Työmaan nimi {sort === EListSortBy.name && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.targetName)}>Kohteen nimi {sort === EListSortBy.targetName && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.customer)}>Asiakkaan nimi {sort === EListSortBy.customer && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.seller)}>Myyjä {sort === EListSortBy.seller && getChevron()}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sorted.map((project) => (
            <React.Fragment key={project.id}>
              <tr onClick={() => openHandler(project.id)} style={project.id === activeProjectId ? { background: "#ccc" } : {}}>
                <td>{formatDate(project.offer?.offerDate)}</td>
                <td>
                  {project.projectNumber} / {project.offer?.offerNumber}
                </td>
                <td>{project.name}</td>
                <td>{project.offer?.targetName}</td>
                <td>{project.offer?.customer?.name}</td>
                <td>{project.offer?.seller?.name}</td>
                <td>
                  {activeProjectId === project.id ? (
                    <Button
                      onClick={(event) => toggleHandler(event, project.id)}
                      size={EButtonSize.SMALL}
                      icon={faChevronUp}
                      color={EButtonColor.DEFAULT}
                      title="Sulje"
                    />
                  ) : (
                    <Button
                      onClick={(event) => toggleHandler(event, project.id)}
                      size={EButtonSize.SMALL}
                      icon={faChevronDown}
                      color={EButtonColor.DEFAULT}
                      title="Avaa"
                    />
                  )}
                </td>
              </tr>
              {activeProjectId === project.id && (
                <tr style={project.id === activeProjectId ? { background: "#ccc" } : {}}>
                  <td colSpan={7}>
                    <ElementStatus projectId={activeProjectId} />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ProjectsList;
