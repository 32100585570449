import { useNavigate } from "react-router-dom";
import { Route } from "../../../routes";
import IProject from "../../../shared/IProject";
import PageHeading from "../../ui/PageHeading/PageHeading";
import WaybillsList from "../../Waybills/WaybillsList";
import ProjectTools from "./ProjectTools";

interface IProps {
  project: IProject;
}

const ProjectWaybills: React.FC<IProps> = ({ project }) => {
  const navigate = useNavigate();
  return (
    <div>
      <PageHeading
        variant="h3"
        onAdd={() => navigate(Route.waybill(`add?projectId=${project.id}`))}
        addText="Lisää rahtikirja"
        noHorizontalMargin
      >
        Rahtikirjat
      </PageHeading>
      <WaybillsList projectId={project.id} />
      <PageHeading variant="h3" noHorizontalMargin>
        Nostovälineet työmaalla
      </PageHeading>
      <ProjectTools project={project} />
    </div>
  );
};

export default ProjectWaybills;
