import { useQuery } from "@tanstack/react-query";
import { ErrorResponse } from "../../../custom-fetch";
import { QueryKey } from "../../../services/query-keys";
import { useToolsService } from "../../../services/tools-service";
import ITool from "../../../shared/ITool";
import ErrorsAlert from "../../ErrorsAlert/ErrorsAlert";
import Spinner from "../../ui/Spinner/Spinner";
import ToolsQRCodesPrint from "./ToolsQRCodesPrint";

interface IProps {
  factory?: string;
}

const ToolsQRCodes: React.FC<IProps> = ({ factory }) => {
  const { fetchTools } = useToolsService();

  const {
    data: tools,
    isPending,
    isError,
    error,
  } = useQuery<ITool[], ErrorResponse>({
    queryKey: [QueryKey.tools],
    queryFn: ({ signal }) => fetchTools({ signal }),
  });

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  const filteredTools = factory
    ? tools?.filter((tool) => tool.factory === factory)
    : tools;

  if (!filteredTools || filteredTools?.length === 0) {
    return <p>Ei nostovälineitä</p>;
  }

  return <ToolsQRCodesPrint tools={filteredTools} />;
};

export default ToolsQRCodes;
