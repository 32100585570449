import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { queryClient } from "../../App";
import { ErrorResponse } from "../../custom-fetch";
import { useElementsService } from "../../services/elements-service";
import { QueryKey } from "../../services/query-keys";
import IElementsCSVResponseDTO from "../../shared/IElementsCSVResponseDTO";
import IElement from "../../shared/IElement";
import IProject, { IProjectPhase } from "../../shared/IProject";
import {
  EModalId,
  EModalSize,
  useModalContext,
} from "../ui/Modal/modal-context";
import EliplanModal from "./EliplanModal";
import { IOption } from "../ui/Input/Input";
import { IAIModalData } from "../AIModal/AIModal";

export const useEliplanModal = (projectId: string) => {
  const { addModal, closeModal } = useModalContext();

  const closeModalHandler = useCallback(
    () => closeModal(EModalId.ELIPLAN_UPLOAD),
    [closeModal]
  );

  const { uploadMutate } = useEliplanUpload(projectId);
  const { saveElementsMutate } = useSaveElements(projectId);

  const handler = (productTypeOptions?: IOption[], phaseOptions?: IOption[], phaseId?: string): Promise<IElementsCSVResponseDTO | null> => {
    let title = "Syötä Eliplan";
    return new Promise((resolve) => {
      addModal({
        id: EModalId.ELIPLAN_UPLOAD,
        isOpen: true,
        size: EModalSize.MEDIUM,
        title,
        backdropNotClose: true,
        content: (
          <EliplanModal
            onAccept={async (data) => {
              try {
                if (data.newItems) {
                  data.newItems = await saveElementsMutate(data.newItems);
                }
                resolve(data);
                closeModalHandler();
                return data;
              } catch (error) {
                console.error(error);
                return { error: error as ErrorResponse };
              }
            }}
            onCancel={() => {
              closeModalHandler();
              resolve(null);
            }}
            onUpload={async (data) => {
              try {
                return await uploadMutate(data);
              } catch (error) {
                console.error(error);
                return { error: error as ErrorResponse };
              }
            }}
            productTypeOptions={productTypeOptions}
            phaseOptions={phaseOptions}
            phaseId={phaseId}
          />
        ),
      });
    });
  };

  return handler;
};

const useEliplanUpload = (projectId: string) => {
  const { readEliplanElements } = useElementsService();
  
  const {
    mutateAsync: uploadMutate,
    isPending: isUploading,
    isError: isUploadError,
    error: uploadError,
  } = useMutation<IElementsCSVResponseDTO, ErrorResponse, IAIModalData>({
    mutationFn: ({ file, phaseId, productTypeId, factory, productionWeek, deliveryWeek }) => readEliplanElements(projectId, file, phaseId, productTypeId, factory, productionWeek, deliveryWeek),
  });

  return { uploadMutate, isUploading, isUploadError, uploadError };
};

const useSaveElements = (projectId: string) => {
  const { saveElements } = useElementsService();
  
  const {
    mutateAsync: saveElementsMutate,
    isPending: isSavingElements,
    isError: isSaveElementsError,
    error: saveElementsError,
  } = useMutation<IElement[], ErrorResponse, IElement[]>({
    mutationFn: (elements) => saveElements(projectId, elements),
    onSuccess: (data) => {
      const oldData = queryClient.getQueryData<IElement[]>([QueryKey.elements, projectId]) ?? [];
      queryClient.setQueryData([QueryKey.elements, projectId], [
        ...oldData,
        ...data,
      ]);

      const oldProjectData = queryClient.getQueryData<IProject>([QueryKey.projects, projectId]);
      let phases = [...data].filter(element => !!element.phaseId && !!element.phaseName).map(element => ({ phaseId: element.phaseId, name: element.phaseName }) as IProjectPhase) ?? [];
      phases = [...oldProjectData?.phases ?? [], ...phases].filter((value, index, array) => array.findIndex(el => el.phaseId === value.phaseId) === index);
      queryClient.setQueryData([QueryKey.projects, projectId], { ...oldProjectData, phases });
    },
  });

  return { saveElementsMutate, isSavingElements, isSaveElementsError, saveElementsError };
};
