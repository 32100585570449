import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../../App";
import { ErrorResponse } from "../../../custom-fetch";
import { useProductGroupOptions } from "../../../hooks/useProductGroupOptions";
import { useProductTypeOptions } from "../../../hooks/useProductTypeOptions";
import { useProjectOptions } from "../../../hooks/useProjectOptions";
import { useClaimService } from "../../../services/claims-service";
import { QueryKey } from "../../../services/query-keys";
import { FactoryOptions } from "../../../shared/FactoryOptions";
import IClaim, { RESOLVED_BY_OPTIONS, RESPONSIBILITY_OPTIONS, ROOT_CAUSE_CATEGORY_OPTIONS } from "../../../shared/IClaim";
import ErrorsAlert, { combineErrors } from "../../ErrorsAlert/ErrorsAlert";
import FormButtons from "../../ui/FormButtons/FormButtons";
import { ECommonValue, EInputType, IInputField } from "../../ui/Input/Input";
import { initForm } from "../../ui/Input/input-utils";
import { useInputs } from "../../ui/Input/useInputs";
import IProductionLine from "../../../shared/IProductionLine";
import IElementDTO from "../../../shared/IElementDTO";
import { usePublicQRCodesService } from "../../../services/publicQRCodes-service";
import IWaybillDTO from "../../../shared/IWaybillDTO";
import Attachments from "../../Attachments/Attachments";
import { Route } from "../../../routes";

interface IProps {
  id: string;
  claim?: IClaim;
  projectId?: string | null;
  element?: IElementDTO;
  productionLine?: IProductionLine;
  waybill?: IWaybillDTO;
  isPublic: boolean;
}

enum EInputs {
  projectNumber = "projectNumber",
  projectId = "projectId",
  projectName = "projectName",
  customerName = "customerName",
  factory = "factory",
  claimReporter = "claimReporter",
  claimReporterContact = "claimReporterContact",
  productGroup = "productGroup",
  productType = "productType",
  elementIdentifier = "elementIdentifier",
  rootCauseCategory = "rootCauseCategory",
  rootCauseSubCategory = "rootCauseSubCategory",
  descriptionDefect = "descriptionDefect",
  asPlanned = "asPlanned",
  responsibility = "responsibility",
  descriptionFix = "descriptionFix",
  cost = "cost",
  resolved = "resolved",
  resolvedDate = "resolvedDate",
  resolveContactCustomer = "resolveContactCustomer",
  resolveContactCompany = "resolveContactCompany",
  resolvedBy = "resolvedBy",
  reclamation = "reclamation",
  productionLineIdentifier = "productionLineIdentifier",
  waybillIdentifier = "waybillIdentifier",
}

const ClaimEdit: React.FC<IProps> = ({ id, claim, projectId, element, productionLine, waybill, isPublic }) => {
  const isEdit = useMemo(() => id !== "add", [id]);
  const navigate = useNavigate();

  const redirectHandler = useCallback(() => {
    return navigate(-1);
  }, [navigate]);

  const { createInput, submit, inputs, setInputs } = useClaimInputs(isPublic, claim);
  const { saveOrUpdate, error, isError, isPending } = useSaveOrUpdate(id, isEdit, redirectHandler, isPublic);
  const { deleteMutate, deletingError, isDeleting, isDeletingError } = useDelete(id, redirectHandler);

  const { productGroups, loading: productGroupsLoading, options: productGroupsOptions } = useProductGroupOptions();
  
  const selectedProductGroupId = useMemo(() => inputs[EInputs.productGroup].value as string, [inputs]);
  const { productTypes, loading: productTypesLoading, options: productTypesOptions } = useProductTypeOptions(true, selectedProductGroupId);

  const { projects, options: projectOptions, loading: projectOptionsLoading } = useProjectOptions();
  const selectedProject = useMemo(() => projects?.find((project) => project.id === inputs[EInputs.projectId].value as any), [inputs, projects]);

  useEffect(() => {
    if (projectId) {
      setInputs((inputs) => {
        const newInputs = {...inputs};
        newInputs[EInputs.projectId].value = projectId;
        return newInputs;
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (element) {
      setInputs((inputs) => {
        const newInputs = {...inputs};
        newInputs[EInputs.elementIdentifier].value = element.name;
        return newInputs;
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]);

  useEffect(() => {
    if (productionLine) {
      setInputs((inputs) => {
        const newInputs = {...inputs};
        newInputs[EInputs.productionLineIdentifier].value = "Peti "+productionLine.productionLineNumber;
        return newInputs;
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionLine]);

  useEffect(() => {
    if (waybill) {
      setInputs((inputs) => {
        const newInputs = {...inputs};
        newInputs[EInputs.waybillIdentifier].value = "Rahtikirja "+waybill.waybillId;
        return newInputs;
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waybill]);

  useEffect(() => {
    if (selectedProject?.id === claim?.projectId) return;
    if (selectedProject) {
      initForm(setInputs, {
        projectName: selectedProject.name,
        projectNumber: selectedProject.projectNumber,
        customerName: selectedProject.offer?.customer?.name,
      });
    }
  }, [selectedProject, setInputs, claim?.projectId]);

  const submitHandler = useCallback(async () => {
    const data = await submit();
    if (data) {
      data.productGroup = productGroups?.find((p) => p.id === data.productGroup as any);
      data.productType = productTypes?.find((p) => p.id === data.productType as any);
      data.elementId = element?.id ?? "";
      data.productionLineId = productionLine?.id ?? "";
      data.waybillId = waybill?.id ?? "";
      saveOrUpdate(data);
    }
  }, [submit, saveOrUpdate, productGroups, productTypes, element?.id, productionLine?.id, waybill?.id]);

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.repeat) return;
      if (!e.ctrlKey) return;
      const key = e.key.toLowerCase();
      switch (key) {
        case "s":
          e.preventDefault();
          submitHandler();
          break;
      }
    };

    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [submitHandler]);

  const errorMessages = combineErrors({ isError, error }, { isError: isDeletingError, error: deletingError });

  return (
    <>
      {isPublic ?
      <>
        {createInput(EInputs.descriptionDefect)}
        {createInput(EInputs.claimReporter)}
        {createInput(EInputs.claimReporterContact)}
      </>
      :
      <>
        {createInput(EInputs.projectId, { options: projectOptions, loading: projectOptionsLoading })}

        <div style={{ display: "flex", width: "1000px" }}>
          <div style={{ width: "300px", paddingRight: "20px" }}>
            {createInput(EInputs.projectNumber)}
            {createInput(EInputs.reclamation)}
            {createInput(EInputs.factory)}
            {createInput(EInputs.elementIdentifier)}
          </div>
          <div style={{ width: "300px", paddingRight: "20px" }}>
            {createInput(EInputs.projectName)}
            {createInput(EInputs.claimReporter)}
            {createInput(EInputs.productGroup, { options: productGroupsOptions, loading: productGroupsLoading })}
            {createInput(EInputs.rootCauseCategory)}
          </div>
          <div style={{ width: "400px" }}>
            {createInput(EInputs.customerName)}
            {createInput(EInputs.claimReporterContact)}
            {createInput(EInputs.productType, { options: productTypesOptions, loading: productTypesLoading })}
            {createInput(EInputs.rootCauseSubCategory)}
          </div>
        </div>

        {createInput(EInputs.descriptionDefect)}
        
        <div style={{ display: "flex", width: "1000px" }}>
          <div style={{ width: "300px", paddingRight: "20px" }}>
            {createInput(EInputs.asPlanned)}
          </div>
          <div style={{ width: "300px", paddingRight: "20px" }}>
            {createInput(EInputs.responsibility)}
          </div>
          <div style={{ width: "400px" }}></div>
        </div>

        {createInput(EInputs.descriptionFix)}

        <div style={{ display: "flex", width: "1000px" }}>
          <div style={{ width: "300px", paddingRight: "20px" }}>
            {createInput(EInputs.cost)}
            {createInput(EInputs.resolveContactCustomer)}
          </div>
          <div style={{ width: "300px", paddingRight: "20px" }}>
            {createInput(EInputs.resolved)}
            {createInput(EInputs.resolveContactCompany)}
          </div>
          <div style={{ width: "400px" }}>
            {createInput(EInputs.resolvedDate)}
            {createInput(EInputs.resolvedBy)}
          </div>
        </div>
      </>
      }

      {isEdit && isPublic &&
        <p style={{color: "green"}}><b>Kiitos, tiedot on tallennettu.</b><br/><br/> Voit liittää ilmoitukseen kuvan tai muun liitteen, tai sulkea lomakkeen.</p>
      }

      {isEdit && claim?.id && 
      <div style={{maxWidth: "1000px"}}>
        <Attachments kind="Claim" parentId={claim.id} noScroll isPublic={isPublic}/>
      </div>
      }
      
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      <FormButtons
        onSubmit={submitHandler}
        isLoading={isPending}
        onDelete={isEdit && !isPublic ? deleteMutate : undefined}
        isDeleting={isDeleting}
        deleteConfirmMessage="Haluatko varmasti poistaa reklamaation?"
      />
    </>
  );
};

const useClaimInputs = (isPublic: boolean, data?: IClaim) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.projectNumber]: {
      type: EInputType.text,
      label: "Työmaan nro",
      value: "",
    },
    [EInputs.projectId]: {
      type: EInputType.reactSelect,
      options: [],
      label: "Työmaa",
      placeholder: "Työmaa",
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      validation: {
        required: !isPublic,
      },
      autoFocus: true,
    },
    [EInputs.projectName]: {
      type: EInputType.text,
      label: "Työmaan nimi",
      value: "",
    },
    [EInputs.customerName]: {
      type: EInputType.text,
      label: "Asiakkaan nimi",
      value: "",
    },
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      options: FactoryOptions,
      label: "Tehdas",
      placeholder: "Tehdas",
      value: "",
      hideControls: true,
      menuPosition: "fixed",
    },
    [EInputs.claimReporter]: {
      type: EInputType.text,
      label: isPublic ? "Ilmoittaja" : "Reklamaation lähettäjä",
      value: "",
    },
    [EInputs.claimReporterContact]: {
      type: EInputType.text,
      label: isPublic ? "Puhelinnumerosi" : "Yhteystiedot",
      value: "",
    },
    [EInputs.productGroup]: {
      type: EInputType.reactSelect,
      options: [],
      label: "Tuoteryhmä",
      placeholder: "Tuoteryhmä",
      value: "",
      hideControls: true,
      menuPosition: "fixed",
    },
    [EInputs.productType]: {
      type: EInputType.reactSelect,
      options: [],
      label: "TR2",
      placeholder: "TR2",
      value: "",
      hideControls: true,
      menuPosition: "fixed",
    },
    [EInputs.elementIdentifier]: {
      type: EInputType.text,
      label: "Elementin tunnus",
      value: "",
    },
    [EInputs.productionLineIdentifier]: {
      type: EInputType.text,
      label: "Pedin tunnus",
      value: "",
    },
    [EInputs.waybillIdentifier]: {
      type: EInputType.text,
      label: "Rahtikirjan tunnus",
      value: "",
    },
    [EInputs.rootCauseCategory]: {
      type: EInputType.reactSelect,
      options: ROOT_CAUSE_CATEGORY_OPTIONS,
      label: "Juurisyy",
      placeholder: "Juurisyy",
      value: "",
      hideControls: true,
      menuPosition: "fixed",
    },
    [EInputs.rootCauseSubCategory]: {
      type: EInputType.text,
      label: "Juurisyyn tarkennus",
      value: "",
    },
    [EInputs.descriptionDefect]: {
      type: EInputType.textarea,
      label: "Poikkeaman kuvaus",
      value: "",
      rows: 4,
    },
    [EInputs.asPlanned]: {
      type: EInputType.checkbox,
      label: "Suunnitelman mukainen",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
    [EInputs.responsibility]: {
      type: EInputType.reactSelect,
      options: RESPONSIBILITY_OPTIONS,
      label: "Kenen vastuulla",
      placeholder: "Kenen vastuulla",
      value: "",
      hideControls: true,
      menuPosition: "fixed",
    },
    [EInputs.descriptionFix]: {
      type: EInputType.textarea,
      label: "Korjauksen kuvaus",
      value: "",
      rows: 4,
    },
    [EInputs.cost]: {
      type: EInputType.number,
      label: "Kustannus",
      value: "",
      post: "€",
    },
    [EInputs.resolved]: {
      type: EInputType.checkbox,
      label: "Hoidettu",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
    [EInputs.resolvedDate]: {
      type: EInputType.date,
      label: "Hoidettu pvm",
      value: "",
    },
    [EInputs.resolveContactCustomer]: {
      type: EInputType.text,
      label: "Sopijaosapuoli asiakkaalla",
      value: "",
    },
    [EInputs.resolveContactCompany]: {
      type: EInputType.text,
      label: "Sopijaosapuoli PB:llä",
      value: "",
    },
    [EInputs.resolvedBy]: {
      type: EInputType.reactSelect,
      options: RESOLVED_BY_OPTIONS,
      label: "Ratkaisija",
      placeholder: "Ratkaisija",
      value: "",
      hideControls: true,
      menuPosition: "fixed",
    },
    [EInputs.reclamation]: {
      type: EInputType.checkbox,
      label: "Reklamaatio",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit, inputs, setInputs };
};

const useSaveOrUpdate = (
  id: string,
  isEdit: boolean,
  redirectHandler: () => void,
  isPublic: boolean
) => {
  const { updateClaim, saveClaim } = useClaimService();
  const { saveClaim: saveClaimPublic, updateClaim: updateClaimPublic } = usePublicQRCodesService();
  const navigate = useNavigate();

  const mutationFn = (data: IClaim) => {
    return isEdit ? isPublic ? updateClaimPublic(id, data) : updateClaim(id, data) : 
      isPublic ? saveClaimPublic(data) : saveClaim(data);
  };

  const {
    mutate: saveOrUpdate,
    isPending,
    isError,
    error,
  } = useMutation<IClaim, ErrorResponse, IClaim>({
    mutationFn,
    onSuccess: (data) => {
      queryClient.removeQueries({ queryKey: [QueryKey.claims, id] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.claims] });
      if (data.projectId) {
        queryClient.invalidateQueries({ queryKey: [QueryKey.claims, data.projectId] });
      }
      isPublic ? navigate(Route.claimQR(data.id), { replace: true }) : redirectHandler();
    },
  });

  return { saveOrUpdate, isPending, isError, error };
};

const useDelete = (id: string, redirectHandler: () => void) => {
  const { deleteClaim } = useClaimService();

  const {
    mutate: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse>({
    mutationFn: () => deleteClaim(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.claims],
        refetchType: "none",
      });
      redirectHandler();
    },
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

export default ClaimEdit;
