import { useMutation } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { ErrorResponse } from "../../../custom-fetch";
import { useProductionLinesService } from "../../../services/productionLines-service";
import IElement from "../../../shared/IElement";
import IWaybillRow from "../../../shared/IWaybillRow";
import ErrorsAlert, { combineErrors } from "../../ErrorsAlert/ErrorsAlert";
import Button from "../../ui/Button/Button";
import PageHeading from "../../ui/PageHeading/PageHeading";
import ProductionLineElementUnloadingDragAndDropTable from "../../ElementUnloading/ProductionLineElementUnloadingDragAndDropTable";
import ProductionLineElementWarehouseDragAndDropTable from "../../ElementUnloading/ProductionLineElementWarehouseDragAndDropTable";
import { queryClient } from "../../../App";
import { QueryKey } from "../../../services/query-keys";
import IProductionLine, { EProductionLineStatus } from "../../../shared/IProductionLine";
import { useConfirmModal } from "../../ui/Modal/useConfirmModal";

interface IProps {
  id: string;
  statusOrder?: number;
  productionLineElements: IElement[];
  unloadingRows?: IWaybillRow[];
  setUnloadingRows?: React.Dispatch<React.SetStateAction<IWaybillRow[]>>;
  warehouseRows: IWaybillRow[];
  setWarehouseRows: React.Dispatch<React.SetStateAction<IWaybillRow[]>>;
  castingRows?: IWaybillRow[];
  setCastingRows?: React.Dispatch<React.SetStateAction<IWaybillRow[]>>;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  lineLength: string;
}

const ProductionLineUnloading: React.FC<IProps> = ({ id, statusOrder = 0, productionLineElements, unloadingRows, setUnloadingRows, warehouseRows, setWarehouseRows, castingRows, setCastingRows, setIsDirty, lineLength }) => {
  const { createUnloadingOrderMutate, isPending, isError, error } = useCreateUnloadingOrder(id);
  const openConfirmModal = useConfirmModal();

  const createUnloadingOrderHandler = useCallback(async () => {
    const isConfirm = await openConfirmModal("Oletko varma, että haluat luoda purkujärjestyksen?");
    if (!isConfirm) return;
    try {
      const newRows = await createUnloadingOrderMutate(warehouseRows);
      setUnloadingRows && setUnloadingRows(structuredClone(newRows));
      setCastingRows && setCastingRows(structuredClone(newRows));
      setIsDirty(true);
    } catch (error) {
      console.error(error);
    }
  }, [createUnloadingOrderMutate, openConfirmModal, setCastingRows, setIsDirty, setUnloadingRows, warehouseRows]);

  const errorMessages = combineErrors({ isError, error });

  const isWarehouseDone = useMemo(() => productionLineElements?.length === warehouseRows?.length, [productionLineElements.length, warehouseRows.length]);
  const hasAdminRights = !!castingRows;

  if (!productionLineElements.length) return null;
  return (
    <>
      {/* 3, 4 */}
      {statusOrder > 1 && unloadingRows && unloadingRows.length > 0 && castingRows && castingRows.length > 0 && setCastingRows && (
        <>
          <hr />
          {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
          <ProductionLineElementUnloadingDragAndDropTable
            elements={productionLineElements}
            unloadingRows={unloadingRows}
            castingRows={castingRows}
            setCastingRows={setCastingRows}
            setIsDirty={setIsDirty}
            lineLength={lineLength}
          />
        </>
      )}
      {hasAdminRights && <hr />}
      <PageHeading variant="h3" noHorizontalMargin>{hasAdminRights && "2. "}Varastoon vienti järjestys</PageHeading>
      <ProductionLineElementWarehouseDragAndDropTable
        initialElements={productionLineElements}
        warehouseRows={warehouseRows}
        setWarehouseRows={setWarehouseRows}
        setIsDirty={setIsDirty}
      />
      {(statusOrder === 1 || hasAdminRights) && isWarehouseDone && <Button style={{ marginTop: "1rem" }} onClick={createUnloadingOrderHandler} loading={isPending}>Luo purkujärjestys</Button>}
    </>
  );
};

const useCreateUnloadingOrder = (id: string) => {
  const { createUnloadingOrder } = useProductionLinesService();

  const {
    mutateAsync: createUnloadingOrderMutate,
    isPending,
    isError,
    error,
  } = useMutation<IWaybillRow[], ErrorResponse, IWaybillRow[]>({
    mutationFn: (data) => createUnloadingOrder(id, data),
    onSuccess: () => {
      queryClient.setQueryData(
        [QueryKey.productionLines, id],
        (productionLine: IProductionLine) => ({
          ...productionLine,
          status: [
            EProductionLineStatus.DRAFT,
            EProductionLineStatus.CONTENT_PLANNED,
          ].includes(productionLine.status)
            ? EProductionLineStatus.UNLOADING_PLANNED
            : productionLine.status,
        })
      );
    }
  });

  return { createUnloadingOrderMutate, isPending, isError, error };
};

export default ProductionLineUnloading;
