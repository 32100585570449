import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ErrorResponse } from "../../../custom-fetch";
import { usePublicQRCodesService } from "../../../services/publicQRCodes-service";
import { QueryKey } from "../../../services/query-keys";
import IElementDTO from "../../../shared/IElementDTO";
import IProductionLine from "../../../shared/IProductionLine";
import IWireType from "../../../shared/IWireType";
import { getCurrentDay } from "../../../utils/date-utils";
import OfferHeader from "../../Offers/OfferHeader/OfferHeader";
import PrintCheckbox from "../../PrintCheckbox/PrintCheckbox";
import Container from "../../ui/Container/Container";
import Spinner from "../../ui/Spinner/Spinner";
import Table from "../../ui/Table/Table";
import { useSlabMeasurementModal } from "../SlabMeasurement/useSlabMeasurementModal";
import classes from './ProductionLinePrint.module.scss';
import { useAuthContext } from "../../../context/auth-context";
import { BASIC_ROLE, Route } from "../../../routes";
import { useNavigate } from "react-router-dom";
import Button from "../../ui/Button/Button";

interface IProps {
  productionLine: IProductionLine;
}

const ProductionLinePrint: React.FC<IProps> = ({ productionLine }) => {
  const { fetchProductionLineElementsByUuid } = usePublicQRCodesService();
  let totalLength = 0;
  let totalWeight = 0;
  const wireType = productionLine.wireType as IWireType

  const [objectUrl, setObjectUrl] = useState<string | null>(null);
  const openSlabMeasurementModal = useSlabMeasurementModal();
  const { attachment, isDownloading } = useWireTypeAttachment(wireType?.id);
  const navigate = useNavigate();

  const {
    data: elements,
    isLoading: elementsIsLoading,
    /*isError,
    error,*/
  } = useQuery<IElementDTO[], ErrorResponse>({
    queryKey: [QueryKey.elements, productionLine.uuid, "print"],
    queryFn: ({ signal }) =>
      fetchProductionLineElementsByUuid({ signal, id: productionLine.uuid! }),
    staleTime: 5000,
  });

  useEffect(() => {
    if (attachment && attachment.size > 0) {
      setObjectUrl(URL.createObjectURL(attachment));
    }
  }, [attachment]);
    
  useEffect(() => {
    if (objectUrl) {
      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    }
  }, [objectUrl]);
  
  const image = useMemo(() => {
    if (attachment && objectUrl) {
      return <img style={{ maxWidth: "180px", maxHeight: "80px" }} src={objectUrl} alt="liite" />;
    }
    return null;
  }, [attachment, objectUrl]);

  const slabMeasurementHandler = useCallback(async () => {
    const wireTypeName = wireType.name ?? "";
    let wires = 0;
    if (wireType.tendonCountThick) wires += +wireType.tendonCountThick;
    if (wireType.tendonCountThin) wires += +wireType.tendonCountThin;
    await openSlabMeasurementModal(productionLine, elements ?? [], wireTypeName, wires, image);
  }, [elements, image, openSlabMeasurementModal, productionLine, wireType.name, wireType.tendonCountThick, wireType.tendonCountThin]);

  const { user } = useAuthContext();
  const allowEdit = [...BASIC_ROLE, "PRODUCTION"].includes(user?.role ?? "");

  return (
    <>
      {allowEdit && (
        <OfferHeader
          onSubmit={slabMeasurementHandler}
          submitText="Laattamittaus"
          isLoading={isDownloading}
          title={`Peti ${productionLine.productionLineNumber}`}
          hideReturn
        />
      )}
      <Container style={{maxWidth: "1000px"}}>
        <div style={{ display: "flex", gap: "1rem", alignItems: "flex-end" }}>
          <div>
            <div>Peti nro: {productionLine.productionLineNumber}</div>
            <div>Valu pvm: {productionLine.startDateFin}</div>
            <div>Vaijerityyppi: {wireType?.name}</div>
            <div>Huom: {productionLine.notes}</div>
            <div>Pedin työmaat:</div>
            {productionLine.projects?.map((project) => {
              const uniquePhases = Array.from(new Set(
                (elements ?? [])
                  .filter((element) => element.projectId === project.id)
                  .map((element) => element.phaseName)
              )).join(", ");
              return (
                <div key={project.id}>
                  {project.projectNumber} {uniquePhases && ("/ "+uniquePhases+" /")} {project.name}
                </div>
              );
            })}
          </div>
          <div>{isDownloading ? <Spinner /> : image}</div>
        </div>
        {elementsIsLoading ? <Spinner/> :
        <Table className={classes.printTable}>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th colSpan={3}>Sijainti</th>
              <th colSpan={2}>Käänt/vaik</th>
              <th>Pituus</th>
              <th>Syve</th>
              <th>Va</th>
              <th>U</th>
              <th>N</th>
              <th>P</th>
              <th>Halk</th>
              <th>V</th>
              <th>O</th>
              <th colSpan={2}>Punok</th>
              <th>Paino</th>
            </tr>
          </thead>
          <tbody>
            {elements?.map((element) => {
              totalLength += +(element.length ?? 0);
              totalWeight += +(element.weightTons ?? 0);
              const provisionSummary = element.provisionSummary;
              return (
                <tr key={element.id}>
                  <td style={{textAlign: 'right', paddingRight: '15px'}}>{element.productionLineLength}</td>
                  <td>{element.position}</td>
                  <td style={{width: '8px'}}><PrintCheckbox checked={element.sideBySide}/></td>
                  <td>{provisionSummary.hasSlanted && "vino"}</td>
                  <td style={{width: '8px'}}><PrintCheckbox checked={element.overlapLeft || element.overlapRight}/></td>
                  <td>{element.overlapLeft ? provisionSummary.slantedDistanceLeft : element.overlapRight ? provisionSummary.slantedDistanceRight : ""}</td>
                  <td>{element.projectNumber}{element.phaseName && ("/"+element.phaseName)}</td>
                  <td>{element.name}<span style={{fontSize: "70%"}}>{element.deliveryWeek ? " ("+element.deliveryWeek+")":""}</span></td>
                  <td>{element.length}</td>
                  <td>{provisionSummary.countHollow || ""}</td>
                  <td>{(provisionSummary.countHole + provisionSummary.countCircle + provisionSummary.countRectangle + provisionSummary.countOther + provisionSummary.countFill) || ""}</td>
                  <td>{provisionSummary.countSlot || ""}</td>
                  <td>{provisionSummary.countLiftingLoop || ""}</td>
                  <td>{provisionSummary.countWireLoop || ""}</td>
                  <td>{provisionSummary.hasSplit ? element.width : ""}</td>
                  <td><PrintCheckbox checked={provisionSummary.hasLeft}/></td>
                  <td><PrintCheckbox checked={provisionSummary.hasRight}/></td>
                  <td>{element.tendonCountThick}</td>
                  <td>{element.tendonCountThin}</td>
                  <td style={{textAlign: 'right', paddingRight: '15px'}}>{element.weightTons}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={8}>{elements?.at(elements?.length-1)?.productionLineLengthTotal}</th>
              <th colSpan={11}>{totalLength.toFixed(0)}</th>
              <th>{totalWeight.toFixed(2)}</th>
            </tr>
          </tfoot>
        </Table>
        }
        <p>Tulostettu {getCurrentDay()}</p>
        <Button onClick={()=>navigate(Route.claimQR("add?productionLineId="+productionLine.uuid))} style={{marginTop: "1rem"}}>Ilmoita poikkeama</Button>
      </Container>
    </>
  )
}

const useWireTypeAttachment = (id?: string) => {
  const { downloadLatestAttachment } = usePublicQRCodesService();

  const {
    data: attachment,
    isLoading: isDownloading,
  } = useQuery<Blob, ErrorResponse>({
    queryKey: [QueryKey.latestAttachments, "WireType", id],
    queryFn: ({ signal }) => downloadLatestAttachment({ signal, kind: "WireType", parentId: id! }),
    staleTime: 5000,
    enabled: !!id,
  });

  return { attachment, isDownloading };
}

export default ProductionLinePrint;