import { useCallback } from "react";
import { useCustomFetch } from "../custom-fetch";
import IPublicProjectDTO from "../shared/IPublicProjectDTO";
import { objectToQueryString } from "../utils/query-string-utils";

export interface IGetCustomerProject {
  id: string;
  uuid: string;
  projectNumber: string;
}

export const usePublicCustomersService = () => {
  const customFetch = useCustomFetch();

  const getCustomerProject = useCallback(
    async ({ id, uuid, projectNumber }: IGetCustomerProject) => {
      const [element] = await customFetch<IPublicProjectDTO>(
        `/public/customer/project${objectToQueryString({
          id,
          uuid,
          projectNumber,
        })}`
      );
      return element;
    },
    [customFetch]
  );

  return {
    getCustomerProject,
  };
};
