import React, { ReactNode } from 'react';
import Button, { EButtonColor } from '../Button/Button';
import ModalBody from '../Modal/ModalBody/ModalBody';
import ModalFooter from '../Modal/ModalFooter/ModalFooter';


interface IProps {
  children: ReactNode;
  onAccept: () => void;
  onCancel: () => void;
  acceptText?: string;
  cancelText?: string;
  cancelColor?: EButtonColor;
}

const ConfirmModal: React.FC<IProps> = ({ children, onAccept, onCancel, acceptText, cancelText, cancelColor }) => {

  return (
    <>
      <ModalBody style={{ whiteSpace: "pre-wrap" }}>
        {children}
      </ModalBody>
      <ModalFooter>
        <Button onClick={onAccept} style={{ marginRight: '.5rem' }}>{acceptText ?? 'Vahvista'}</Button>
        <Button onClick={onCancel} color={cancelColor ?? EButtonColor.DANGER}>{cancelText ?? 'Peruuta'}</Button>
      </ModalFooter>
    </>
  )
}

export default ConfirmModal;