import { useQuery } from "@tanstack/react-query";
import { ErrorResponse } from "../../../custom-fetch";
import { useProjectsService } from "../../../services/projects-service";
import { QueryKey } from "../../../services/query-keys";
import IProject from "../../../shared/IProject";
import ITool from "../../../shared/ITool";
import ErrorsAlert from "../../ErrorsAlert/ErrorsAlert";
import ToolsTable from "../../Tools/ToolsTable";
import Spinner from "../../ui/Spinner/Spinner";

interface IProps {
  project: IProject;
}

const ProjectTools: React.FC<IProps> = ({ project }) => {
  const { listProjectTools } = useProjectsService();

  const {
    data: tools,
    isPending,
    isError,
    error,
  } = useQuery<ITool[], ErrorResponse>({
    queryKey: [QueryKey.tools, project.id],
    queryFn: ({ signal }) => listProjectTools({ signal, id: project.id }),
  });

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  return <ToolsTable tools={tools} />;
};

export default ProjectTools;
