import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IElement from "../../../shared/IElement";
import ElementsList from "../../Elements/ElementsList";
import ElementsSearch, { IElementsSearch } from "../../Elements/ElementsSearch";
import PageHeading from "../../ui/PageHeading/PageHeading";
import ProductionLineActiveElement from "./ProductionLineActiveElement";

interface IProps {
  elements: IElement[];
  onElementClick: (element: IElement) => void;
  activeElement: IElement | null;
  setActiveElement: (element: IElement | null) => void;
  id: string;
  showNext: () => void;
  showPrevious: () => void;
  updateActiveElement: (element: IElement) => void;
  refetchElementsHandler: () => void;
  searchHandler: () => void;
  factory: string;
  search: IElementsSearch | null | undefined;
  typeName: string;
  height?: string;
  productionLineElements: IElement[];
}

const ProductionLineSearch: React.FC<IProps> = ({
  elements,
  onElementClick,
  activeElement,
  setActiveElement,
  id,
  showNext,
  showPrevious,
  updateActiveElement,
  refetchElementsHandler,
  searchHandler,
  factory,
  search,
  typeName,
  height,
  productionLineElements,
}) => {
  return (
    <>
      <div style={{ width: "100%" }}>
        {activeElement ? (
          <ProductionLineActiveElement
            productionLineId={id}
            element={activeElement}
            showNext={showNext}
            showPrevious={showPrevious}
            unselectHandler={() => setActiveElement(null)}
            updateActiveElement={updateActiveElement}
          />
        ) : (
          <>
            <PageHeading variant="h2" noHorizontalMargin>
              Elementtien haku
              <FontAwesomeIcon
                icon={faRefresh}
                onClick={refetchElementsHandler}
                size="xs"
                style={{ cursor: "pointer" }}
              />
            </PageHeading>
            <div>
              <ElementsSearch searchHandler={searchHandler} factory={factory} />
            </div>
            <hr />
            <ElementsList
              onClick={onElementClick}
              selectedElements={elements}
              search={search}
              productionLineId={id}
              typeName={typeName}
              height={height}
              productionLineElements={productionLineElements}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ProductionLineSearch;
