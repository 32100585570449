import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { apiUrl } from './config';
import { Route } from './routes';

export type FetchMethod = "GET" | "POST" | "DELETE" | "PUT";

export interface IBlob {
  blob: Blob;
  fileName: string;
}

export enum EBlobType {
  PDF = "application/pdf",
  CSV = "text/csv",
}

export interface ISignal {
  signal?: AbortSignal;
}

type TBody =
  | string
  | Blob
  | ArrayBufferView
  | ArrayBuffer
  | FormData
  | URLSearchParams
  | ReadableStream<Uint8Array>
  | null
  | undefined;

type ResponseType = "json" | "blob";

interface IOptions {
  method?: FetchMethod;
  body?: TBody;
  signal?: AbortSignal;
  multipart?: boolean;
  responseType?: ResponseType;
}

export const useCustomFetch = () => {
  const navigate = useNavigate();

  const customFetch = useCallback(async function <T>(
    url: string,
    { method = "GET", body, signal, multipart, responseType = "json" }: IOptions = {
      method: "GET"
    }
  ): Promise<[T, Response]> {
    const token = localStorage.getItem("token");

    const headers: Record<string, string> = {
      Accept: "application/json;charset=UTF-8",
      Authorization: token ? `Bearer ${token}` : "",
    };
    
    if (!multipart) {
      headers["Content-Type"] = "application/json;charset=UTF-8";
    }

    const resp = await fetch(`${apiUrl}${url}`, {
      method,
      body,
      signal,
      headers: headers,
    });

    if (resp.status === 500) {
      throw new ErrorResponse(["Palvelimeen ei saatu yhteyttä, yritä uudelleen."]);
    }
  
    const data = responseType === "json" ? await resp.json() : await resp.blob();
    if (resp.status === 401) {
      navigate(Route.logout);
      return [data, resp];
    }
  
    if (!resp.ok) {
      throw new ErrorResponse(data.messages ? data.messages : [data.detail]);
    }
    return [data, resp];
  }, [navigate]);

  return customFetch;
}



export class ErrorResponse extends Error {
  messages: string[] = [];
  constructor(messages: string[]) {
    super("");
    this.messages = messages;
  }
}

/*

.then(async res => {
      if (res.ok) {
        return {
          data: await res.json(),
          response: res
        };
      }
      return res.json().then(error => {
        throw new Error(error.Message);
      });
    })
    .catch(error => {
      if(error instanceof Error) {
        throw error;
      } else {
        throw new Error(error);
      }
    });

  */
