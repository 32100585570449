import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { queryClient } from "../../App";
import { ErrorResponse } from "../../custom-fetch";
import { Route } from "../../routes";
import { QueryKey } from "../../services/query-keys";
import ErrorsAlert, { combineErrors } from "../ErrorsAlert/ErrorsAlert";
import FormButtons from "../ui/FormButtons/FormButtons";
import { ECommonValue, EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import { useVehicleService } from "../../services/vehicles-service";
import IVehicle from "../../shared/IVehicle";
import { useDriverOrganizationOptions } from "../../hooks/useDriverOrganizationOptions";

interface IProps {
  id: string;
  vehicle?: IVehicle;
}

enum EInputs {
  name = "name",
  driverOrganization = "driverOrganization",
  driver = "driver",
  contractor = "contractor",
  phoneNumber = "phoneNumber",
  email = "email",
  archived = "archived"
}

const VehicleEdit: React.FC<IProps> = ({ id, vehicle }) => {
  const isEdit = id !== "add";
  
  const navigate = useNavigate();

  const redirectHandler = () => {
    return navigate(Route.vehicles);
  };

  const { saveOrUpdate, error, isError, isPending } = useSaveOrUpdate(id, isEdit, redirectHandler);
  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete(id, redirectHandler);
  const { createInput, submit } = useVehicleInputs(vehicle);
  const { options, driverOrganizations } = useDriverOrganizationOptions();

  const submitHandler = useCallback(async () => {
    const data = await submit();
    if (data) {
      const selectedDriverOrganization = driverOrganizations?.find(
        (p) => p.id === data.driverOrganization as any
      );
      if (!selectedDriverOrganization) return;
      data.driverOrganization = selectedDriverOrganization;
      saveOrUpdate(data);
    }
  }, [saveOrUpdate, submit, driverOrganizations]);

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.repeat) return;
      if (!e.ctrlKey) return;
      const key = e.key.toLowerCase();
      switch (key) {
        case "s":
          e.preventDefault();
          submitHandler();
          break;
      }
    };

    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [submitHandler]);

  const errorMessages = combineErrors({ isError, error }, { isError: isDeletingError, error: deletingError });

  return (
    <>
      {createInput(EInputs.name)}
      {createInput(EInputs.driverOrganization, {options})}
      {createInput(EInputs.driver)}
      {createInput(EInputs.contractor)}
      {createInput(EInputs.phoneNumber)}
      {createInput(EInputs.email)}
      {/*createInput(EInputs.archived)*/}
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      <FormButtons
        onSubmit={submitHandler}
        isLoading={isPending}
        onDelete={isEdit ? deleteMutate : undefined}
        isDeleting={isDeleting}
        deleteConfirmMessage="Haluatko varmasti poistaa auton?"
      />
    </>
  );
};

const useVehicleInputs = (data?: IVehicle) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.name]: {
      type: EInputType.text,
      label: "Nimi",
      value: "",
      validation: {
        required: true,
      },
      autoFocus: true
    },
    [EInputs.driverOrganization]: {
      type: EInputType.reactSelect,
      label: "Kuljetusliike",
      value: "",
      validation: {
        required: true,
      },
    },
    [EInputs.driver]: {
      type: EInputType.text,
      label: "Kuljettaja",
      value: "",
    },
    [EInputs.contractor]: {
      type: EInputType.checkbox,
      label: "Alihankkija",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
    [EInputs.phoneNumber]: {
      type: EInputType.text,
      label: "Puhelin",
      value: "",
    },
    [EInputs.email]: {
      type: EInputType.text,
      label: "Sähköposti",
      value: "",
    },
    [EInputs.archived]: {
      type: EInputType.checkbox,
      label: "Arkistoitu",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit };
};

const useSaveOrUpdate = (id: string, isEdit: boolean, redirectHandler: () => void) => {
  const { updateVehicle, saveVehicle } = useVehicleService();

  const mutationFn = (data: IVehicle) => {
    return isEdit ? updateVehicle(id, data) : saveVehicle(data);
  };

  const {
    mutate: saveOrUpdate,
    isPending,
    isError,
    error,
  } = useMutation<IVehicle, ErrorResponse, IVehicle>({
    mutationFn,
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: [QueryKey.vehicles, id] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.vehicles] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.vehicleOptions] });
      redirectHandler();
    },
  });

  return { saveOrUpdate, isPending, isError, error };
};

const useDelete = (id: string, redirectHandler: () => void) => {
  const { deleteVehicle } = useVehicleService();

  const {
    mutate: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse>({
    mutationFn: () => deleteVehicle(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.vehicles],
        refetchType: "none",
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKey.vehicleOptions],
        refetchType: "none",
      });
      redirectHandler();
    },
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

export default VehicleEdit;
