import IWaybill from "../../../shared/IWaybill";
import IWaybillDTO from "../../../shared/IWaybillDTO";
import WaybillToolEdit from "./WaybillToolEdit";

interface IProps {
  waybill: IWaybill | IWaybillDTO;
  disabled?: boolean;
}

const WaybillTools: React.FC<IProps> = ({ waybill, disabled }) => {
  return (
    <>
      <h4 style={{ margin: "1rem 0" }}>Tehtaalta työmaalle</h4>
      <WaybillToolEdit type="toolsFromFactory" waybillTools={waybill.toolsFromFactory} waybill={waybill} disabled={disabled} />
      <h4 style={{ margin: "1rem 0" }}>Työmaalta tehtaalle</h4>
      <WaybillToolEdit type="toolsToFactory" waybillTools={waybill.toolsToFactory} waybill={waybill} disabled={disabled} />
    </>
  );
};

export default WaybillTools;
