import { useMutation } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { queryClient } from "../../../App";
import { ErrorResponse } from "../../../custom-fetch";
import { QueryKey } from "../../../services/query-keys";
import { useToolsService } from "../../../services/tools-service";
import IAuditHistory, { AuditHistoryType } from "../../../shared/IAuditHistory";
import ITool from "../../../shared/ITool";
import {
  EModalId,
  EModalSize,
  useModalContext,
} from "../../ui/Modal/modal-context";
import AuditHistoryEditModal from "./AuditHistoryEditModal";
import { usePublicQRCodesService } from "../../../services/publicQRCodes-service";
import IToolDTO from "../../../shared/IToolDTO";

export interface ISaveToolAuditHistory extends IAuditHistory {
  file: File | null;
}

export const useAuditHistoryEditModal = (
  type: AuditHistoryType,
  toolId: string,
  auditHistory?: IAuditHistory,
  isPublic?: boolean,
) => {
  const { addModal, closeModal, updateModal } = useModalContext();

  const id = useMemo(() => auditHistory?.id ?? "add", [auditHistory?.id]);
  const isEdit = useMemo(() => id !== "add", [id]);

  const mutateHandler = useCallback(
    () => updateModal(EModalId.AUDIT_HISTORY_EDIT, { error: null }),
    [updateModal]
  );
  const errorHandler = useCallback(
    (error: ErrorResponse) =>
      updateModal(EModalId.AUDIT_HISTORY_EDIT, {
        error: error.messages.join(", "),
      }),
    [updateModal]
  );

  const closeModalHandler = useCallback(
    () => closeModal(EModalId.AUDIT_HISTORY_EDIT),
    [closeModal]
  );
  const { saveOrUpdate } = useSaveOrUpdate(
    type,
    toolId,
    mutateHandler,
    closeModalHandler,
    errorHandler,
    isPublic
  );

  const handler = (): Promise<ITool | IToolDTO | null> => {
    let title = isEdit
      ? "Muokkaa tarkastuspöytäkirjaa"
      : "Lisää tarkastuspöytäkirja";
    if (type === "Remark") title = isEdit ? "Muokkaa huomiota" : "Lisää huomio";
    return new Promise((resolve) => {
      addModal({
        id: EModalId.AUDIT_HISTORY_EDIT,
        isOpen: true,
        size: EModalSize.SMALL,
        title,
        backdropNotClose: true,
        content: (
          <AuditHistoryEditModal
            onAccept={async (data) => {
              if (auditHistory?.id) {
                data.id = auditHistory.id;
              }
              try {
                const tool = await saveOrUpdate(data);
                resolve(tool);
              } catch (e) {
                console.error(e);
              }
            }}
            onCancel={() => {
              closeModalHandler();
              resolve(null);
            }}
            auditHistory={auditHistory}
            type={type}
          />
        ),
      });
    });
  };

  return handler;
};

const useSaveOrUpdate = (
  type: AuditHistoryType,
  toolId: string,
  onMutate: () => void,
  onSuccess: () => void,
  onError: (error: ErrorResponse) => void,
  isPublic?: boolean,
) => {
  const { saveToolAuditHistory } = useToolsService();
  const { saveToolAuditHistory: savePublicToolAuditHistory } = usePublicQRCodesService();

  const {
    mutateAsync: saveOrUpdate,
    isPending,
    isError,
    error,
  } = useMutation<ITool | IToolDTO, ErrorResponse, ISaveToolAuditHistory>({
    mutationFn: (data) => isPublic
      ? savePublicToolAuditHistory(type, toolId, data, data.file)
      : saveToolAuditHistory(type, toolId, data, data.file),
    onMutate,
    onSuccess: (data) => {
      if (isPublic) {
        queryClient.setQueryData([QueryKey.publicTools, data.uuid], data);
      } else {
        queryClient.setQueryData([QueryKey.tools, data.id], data);
      }
      onSuccess();
    },
    onError,
  });

  return { saveOrUpdate, isPending, isError, error };
};
