import { BlockerFunction, Outlet, useBlocker, useParams } from 'react-router-dom';

import Header from '../components/Header/Header';
import AuthContextProvider from '../context/auth-context';
import NavigationBlockerContextProvider from '../context/navigation-blocker-context';
import { useNavigationBlocker } from '../hooks/useNavigationBlocker';
import { useCallback, useEffect, useRef } from 'react';

// https://github.com/remix-run/react-router/issues/11430#issuecomment-2345829613
const RootLayout: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const isMountedRef = useRef<boolean>();

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const shouldBlock = useCallback<BlockerFunction>(({ currentLocation, nextLocation }) => {
    // console.log("shouldBlock", currentLocation.pathname, nextLocation.pathname);
    return (
      // cleanup -> "A router only supports one blocker at a time"
      isMountedRef.current! &&
      // do not block within same route
      currentLocation.pathname !== nextLocation.pathname &&
      !nextLocation.pathname.includes(id ?? "") &&
      // routes where blocking is active, implement useNavigationBlockerContext + useEffect
      (currentLocation.pathname.startsWith("/offers/") 
      || currentLocation.pathname.startsWith("/projects/") 
      || currentLocation.pathname.startsWith("/production-lines/")
      || currentLocation.pathname.startsWith("/waybills/")
      )
    );
  }, [id]);

  const blocker = useBlocker(shouldBlock);
  const { showNavigationBlockerModal, showBlocker, setIsDirty } = useNavigationBlocker(blocker);
  return (
    <NavigationBlockerContextProvider value={{ showNavigationBlockerModal, showBlocker, setIsDirty }}>
      <AuthContextProvider>
      <Header />
      <main>
        <Outlet />
      </main>
    </AuthContextProvider>
    </NavigationBlockerContextProvider>
  );
};

/*
export class Routes {

  public static withParams(route: ERoute, params: IParams): string {
    let routeWithParams: string = route;
    for(let key in params) {
      routeWithParams = routeWithParams.replace(`:${key}`, params[key]);
    }
    return routeWithParams;
  }
}
*/

/*
const RootLayout: React.FC = () => {
  const { isAuth, logout, isLoggingOut, user } = useAuthContext();

  const isVisible = (userRoles: UserRole[]) =>
    userRoles.length === 0 || (user && userRoles.includes(user.role));

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  return (
    <>
      <Navbar isBordered isBlurred={false} onMenuOpenChange={setIsMenuOpen}>
        <NavbarContent>
          <NavbarMenuToggle
            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
            className="sm:hidden"
          />
          <NavbarBrand>
            <p className="font-bold text-inherit">Pielisen Betoni</p>
          </NavbarBrand>
        </NavbarContent>

        <NavbarContent className="hidden sm:flex gap-4" justify="center">
          {Object.entries(routes).map(([to, value]) =>
            value.navigationTitle && isVisible(value.roles) ? (
              <NavbarItem isActive>
                <Link color="foreground" key={to} to={to}>
                  {value.navigationTitle}
                </Link>
              </NavbarItem>
            ) : null
          )}
        </NavbarContent>
        <NavbarContent justify="end">
          <NavbarItem className="hidden lg:flex">
            <Link to="/auth">Login</Link>
          </NavbarItem>
          <NavbarItem>
            <Button as={Link} color="primary" href="#" variant="flat">
              Sign Up
            </Button>
          </NavbarItem>
        </NavbarContent>
        <NavbarMenu>
        {Object.entries(routes).map(([to, value]) =>
            value.navigationTitle && isVisible(value.roles) ? (
              <NavbarMenuItem key={to}>
                <Link color="foreground" className="w-full" key={to} to={to}>
                  {value.navigationTitle}
                </Link>
              </NavbarMenuItem>
            ) : null
          )}
        </NavbarMenu>
      </Navbar>
      <main>
        <Outlet />
      </main>
    </>
  );
};
*/

export default RootLayout;
