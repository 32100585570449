import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ToolsList from "../components/Tools/ToolsList";
import Container from "../components/ui/Container/Container";
import { EInputType, IInputField } from "../components/ui/Input/Input";
import { useInputs } from "../components/ui/Input/useInputs";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import { Route } from "../routes";
import { FactoryOptions } from "../shared/FactoryOptions";
import { EFactory } from "../shared/IOfferRow";
import Button, { EButtonSize } from "../components/ui/Button/Button";

enum EInputs {
  factory = "factory",
}

const ToolsPage: React.FC = () => {
  const navigate = useNavigate();
  const { createInput, inputs } = useToolsInputs();
  
  const factory = useMemo(() => inputs[EInputs.factory].value as EFactory, [inputs]);

  const addHandler = useCallback(() => {
    navigate(Route.tool("add"));
  }, [navigate]);

  const printHandler = useCallback(() => {
    navigate(Route.toolsPrint);
  }, [navigate]);

  return (
    <>
      <PageHeading onAdd={addHandler}>
        Nostovälineet <Button style={{fontSize: "1rem"}} size={EButtonSize.SMALL} onClick={printHandler}>Tulosta</Button>
      </PageHeading>
      <Container>
        <div>{createInput(EInputs.factory)}</div>
        <hr />
        <ToolsList factory={factory} />
      </Container>
    </>
  );
};

const useToolsInputs = (data?: {}) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      label: "Tehdas",
      options: FactoryOptions,
      value: "",
      placeholder: "Suodata tehtaalla",
      boldContent: true,
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit, inputs };
};

export default ToolsPage;
