import { format } from "date-fns";
import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { useUserOptions } from "../../../hooks/useUserOptions";
import IOffer, { STATUS_OPTIONS } from "../../../shared/IOffer";
import IProject from "../../../shared/IProject";
import { RefHandle } from "../../../shared/RefHandle";
import SummaryTable from "../../Offers/OfferCalculationTabs/OfferCalculationSummary/SummaryTable";
import OrganizationUserSelect from "../../OrganizationUserSelect/OrganizationUserSelect";
import { ECommonValue, EInputType, IInputField } from "../../ui/Input/Input";
import { useInputs } from "../../ui/Input/useInputs";
import ProjectPhases from "../ProjectPhases/ProjectPhases";
import { useProjectEditContext } from "./project-edit-context";
import classes from "./ProjectEdit.module.scss";
import { useAuthContext } from "../../../context/auth-context";
import { BASIC_ROLE } from "../../../routes";

enum EOfferInputs {
  offerNumber = "offerNumber",
  seller = "seller",
  offerDate = "offerDate",
  expireInDays = "expireInDays",
  status = "status",
  targetName = "targetName",
  deliveryStreetAddress = "deliveryStreetAddress",
  deliveryZip = "deliveryZip",
  deliveryCity = "deliveryCity",
  customerReferenceNumber = "customerReferenceNumber",
  estimatedDeliveryWeeks = "estimatedDeliveryWeeks",
  productionNotes = "productionNotes",
  numberOfFloors = "numberOfFloors",
}

export enum EProjectInputs {
  contactPersonName = "contactPersonName",
  contactPersonPhone = "contactPersonPhone",
  contactPersonEmail = "contactPersonEmail",
  dataProtectionObject = "dataProtectionObject",
  waybillWaitingPrice = "waybillWaitingPrice",
  waybillUnloadFreeTime = "waybillUnloadFreeTime",
  waybillNotes = "waybillNotes",
  customerDisruption = "customerDisruption",
  customerDisruptionNotes = "customerDisruptionNotes",
}

const containerStyles: React.CSSProperties = { width: "200px" };
const containerStylesGroup: React.CSSProperties = { width: "100px" };

const DEFAULT_OFFER_DATE = format(new Date(), "yyyy-MM-dd");

const ProjectEdit: React.ForwardRefRenderFunction<RefHandle<IProject>> = (_, ref) => {
  const { id, project, customerRef, engineerRef } = useProjectEditContext();
  const { offer } = project;
  const { createOfferInput, submitOffer } = useOfferInputs(offer);
  const { createProjectInput, submitProject, projectInputs } = useProjectInputs(project);

  const { options: sellerUserOptions, loading: sellerUserOptionsLoading, users: sellers } = useUserOptions({ role: "SELLER" });

  const { user } = useAuthContext();
  const canEditProductionNotes = useMemo(() => BASIC_ROLE.includes(user!.role), [user]);
  const customerDisruption = useMemo(() => projectInputs[EProjectInputs.customerDisruption].value as string[], [projectInputs]);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const offerData = await submitOffer();
      const projectData = await submitProject();
      // console.log(data);
      if (!offerData || !projectData) return null;
      
      const customerData = await customerRef.current?.getData();
      if (customerData) {
        offerData.customer = customerData.organization;
        offerData.customerContactPerson = customerData.user;
      }

      const engineerData = await engineerRef.current?.getData();
      if (engineerData) {
        offerData.engineer = engineerData.organization;
        offerData.engineerContactPerson = engineerData.user;
      }

      if (offerData.seller) {
        offerData.seller = (sellers?.find((s) => s.id === (offerData.seller.id ?? offerData.seller)) ?? offerData.seller) || undefined;
      }

      // console.log("getData", selectData, userData, selectedOrganization);
      return {
        ...project,
        ...projectData,
        offer: {
          ...project.offer,
          ...offerData,
        },
      };
    },
  }), [submitOffer, submitProject, customerRef, engineerRef, project, sellers]);

  return (
    <div className={classes.Offer}>
      <div style={{ width: "100%" }}>
        <div className={classes.Inputs}>
          <div className={classes.OfferInputs}>
            {/* vasen */}
            <div>
              {createOfferInput(EOfferInputs.offerNumber, {
                disabled: true,
                containerStyles,
              })}
              {createOfferInput(EOfferInputs.seller, {
                disabled: true,
                options: sellerUserOptions,
                loading: sellerUserOptionsLoading,
                containerStyles,
              })}
              <div className={classes.Group}>
                {createOfferInput(EOfferInputs.offerDate, {
                  disabled: true,
                  containerStyles: { width: "140px" },
                })}
                {createOfferInput(EOfferInputs.expireInDays, {
                  disabled: true,
                  containerStyles: { width: "60px" },
                })}
              </div>
              {createOfferInput(EOfferInputs.status, { disabled: true, containerStyles })}
            </div>
            {/* oikea */}
            <div>
              {createOfferInput(EOfferInputs.targetName, { disabled: true, containerStyles })}
              {createOfferInput(EOfferInputs.deliveryStreetAddress, {
                disabled: true,
                containerStyles,
              })}
              <div className={classes.Group}>
                {createOfferInput(EOfferInputs.deliveryZip, {
                  disabled: true,
                  containerStyles: containerStylesGroup,
                })}
                {createOfferInput(EOfferInputs.deliveryCity, {
                  disabled: true,
                  containerStyles: containerStylesGroup,
                })}
              </div>

              {createOfferInput(EOfferInputs.customerReferenceNumber, {
                disabled: true,
                containerStyles,
              })}
              <div className={classes.Group}>
                {createOfferInput(EOfferInputs.estimatedDeliveryWeeks, {
                  disabled: true,
                  containerStyles: containerStylesGroup,
                })}
                {createOfferInput(EOfferInputs.numberOfFloors, {
                  disabled: true,
                  containerStyles: containerStylesGroup,
                })}
              </div>
            </div>
          </div>

          <div style={{ width: "100%" }}>
            <div className={classes.Selects}>
              <OrganizationUserSelect
                type="CUSTOMER"
                label="Asiakas"
                ref={customerRef}
                initialData={{
                  organization: offer?.customer,
                  organizationId: offer?.customer?.id || "",
                  userId: offer?.customerContactPerson?.id || "",
                }}
                disabled={true}
              />

              <OrganizationUserSelect
                type="ENGINEER"
                label="Suunnittelutoimisto"
                ref={engineerRef}
                initialData={{
                  organization: offer?.engineer,
                  organizationId: offer?.engineer?.id ?? "",
                  userId: offer?.engineerContactPerson?.id ?? "",
                }}
              />
            </div>
            <div className={classes.Selects}>
              {createProjectInput(EProjectInputs.contactPersonName, { disabled: false })}
              {createProjectInput(EProjectInputs.contactPersonPhone, { disabled: false })}
              {createProjectInput(EProjectInputs.contactPersonEmail, { disabled: false })}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {createOfferInput(EOfferInputs.productionNotes, { disabled: !canEditProductionNotes })}
            <SummaryTable summary={offer?.summary} showPrices={false} />
          </div>
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {createProjectInput(EProjectInputs.customerDisruption, { disabled: false })}
            {customerDisruption?.includes('Y') && createProjectInput(EProjectInputs.customerDisruptionNotes, { disabled: false })}
          </div>
        </div>
        
        <ProjectPhases
          id={id}
          initialPhases={project.phases}
          createProjectInput={createProjectInput}
        />

      </div>
    </div>
  );
};

const useOfferInputs = (data?: IOffer) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EOfferInputs.offerNumber]: {
      type: EInputType.text,
      placeholder: "Tarjouksen numero",
      value: "",
      validation: {
        required: true,
      },
    },
    [EOfferInputs.seller]: {
      type: EInputType.reactSelect,
      placeholder: "Myyjä",
      options: [],
      value: "",
    },
    [EOfferInputs.offerDate]: {
      type: EInputType.date,
      placeholder: "Pvm",
      value: DEFAULT_OFFER_DATE,
    },
    [EOfferInputs.expireInDays]: {
      type: EInputType.number,
      placeholder: "Voimassa",
      value: "14",
    },
    [EOfferInputs.status]: {
      type: EInputType.reactSelect,
      placeholder: "Tila",
      options: STATUS_OPTIONS,
      value: "",
    },
    [EOfferInputs.targetName]: {
      type: EInputType.textarea,
      placeholder: "Kohteen nimi",
      value: "",
      rows: 2,
    },
    [EOfferInputs.deliveryStreetAddress]: {
      type: EInputType.text,
      placeholder: "Katuosoite",
      value: "",
    },
    [EOfferInputs.deliveryZip]: {
      type: EInputType.text,
      placeholder: "Postinumero",
      value: "",
    },
    [EOfferInputs.deliveryCity]: {
      type: EInputType.text,
      placeholder: "Kaupunki",
      value: "",
    },
    [EOfferInputs.customerReferenceNumber]: {
      type: EInputType.text,
      placeholder: "Asiakkaan viite",
      value: "",
    },
    [EOfferInputs.estimatedDeliveryWeeks]: {
      type: EInputType.number,
      placeholder: "ArvTVko",
      value: "",
    },
    [EOfferInputs.productionNotes]: {
      type: EInputType.textarea,
      label: "Huom. tuotantoon",
      rows: 4,
      value: "",
    },
    [EOfferInputs.numberOfFloors]: {
      type: EInputType.number,
      placeholder: "Kerroksia",
      value: "",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createOfferInput: createInput, submitOffer: submit };
};

const useProjectInputs = (data?: IProject) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EProjectInputs.contactPersonName]: {
      type: EInputType.text,
      placeholder: "Yhteyshenkilön nimi",
      value: "",
    },
    [EProjectInputs.contactPersonPhone]: {
      type: EInputType.text,
      placeholder: "Yhteyshenkilön puhelin",
      value: "",
    },
    [EProjectInputs.contactPersonEmail]: {
      type: EInputType.text,
      placeholder: "Yhteyshenkilön email",
      value: "",
    },
    [EProjectInputs.dataProtectionObject]: {
      type: EInputType.checkbox,
      label: "Tietosuojakohde",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
    [EProjectInputs.waybillWaitingPrice]: {
      type: EInputType.number,
      placeholder: "Odotuksen hinta €/h",
      value: "",
      label: "Odotuksen hinta €/h",
    },
    [EProjectInputs.waybillUnloadFreeTime]: {
      type: EInputType.number,
      placeholder: "Vapaa purkuaika oletus",
      value: "",
      label: "Vapaa purkuaika oletus",
    },
    [EProjectInputs.waybillNotes]: {
      type: EInputType.textarea,
      placeholder: "Huomautukset rahtikirjalle",
      value: "",
      rows: 4,
      label: "Huomautukset rahtikirjalle",
    },
    [EProjectInputs.customerDisruption]: {
      type: EInputType.checkbox,
      label: "Asiakkaalla ongelmia, ei edistetä",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
    [EProjectInputs.customerDisruptionNotes]: {
      type: EInputType.textarea,
      placeholder: "Edistäminen vaatii luvan",
      label: "Edistäminen vaatii luvan",
      value: "",
      rows: 4,
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createProjectInput: createInput, submitProject: submit, projectInputs: inputs };
};

export default forwardRef(ProjectEdit);
