import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../../App";
import { useNavigationBlockerContext } from "../../../context/navigation-blocker-context";
import { ErrorResponse } from "../../../custom-fetch";
import { Route } from "../../../routes";
import { useAdditionalInvoicesService } from "../../../services/additionalInvoices-service";
import { useElementsService } from "../../../services/elements-service";
import { useProjectsService } from "../../../services/projects-service";
import { QueryKey } from "../../../services/query-keys";
import { useWeeklyProductionRowsService } from "../../../services/weeklyProductionRows-service";
import IAdditionalInvoice from "../../../shared/IAdditionalInvoice";
import IElement from "../../../shared/IElement";
import IProject from "../../../shared/IProject";
import IProjectUser from "../../../shared/IProjectUser";
import IWeeklyProductionRow from "../../../shared/IWeeklyProductionRow";
import { RefHandle } from "../../../shared/RefHandle";
import ErrorsAlert, { combineErrors } from "../../ErrorsAlert/ErrorsAlert";
import OfferHeader from "../../Offers/OfferHeader/OfferHeader";
import { IOrganizationUserSelectHandle } from "../../OrganizationUserSelect/OrganizationUserSelect";
import Container from "../../ui/Container/Container";
import { useConfirmModal } from "../../ui/Modal/useConfirmModal";
import ProjectEdit from "../ProjectEdit/ProjectEdit";
import ProjectEditContextProvider from "../ProjectEdit/project-edit-context";

interface IProps {
  id: string;
  project: IProject;
  isPending: boolean;
  isError: boolean;
  error: ErrorResponse | null;
  isEdit: boolean;
  initialWeeklyProductionRows: IWeeklyProductionRow[];
  initialAdditionalInvoices: IAdditionalInvoice[];
  initialElements: IElement[];
}

export interface IProjectDirty {
  weeklyProductionRows: boolean;
  additionalInvoices: boolean;
  elements: boolean;
  projectUsers: boolean;
}

const Project: React.FC<IProps> = ({ id, project, isEdit, initialWeeklyProductionRows, initialAdditionalInvoices, initialElements }) => {
  const { updateMutate, isPending: saveOrUpdatePending, isError: isSaveOrUpdateError, error: saveOrUpdateError } = useUpdate(id);
  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete(id);
  const { updateRowsMutate, isUpdatingRows, isUpdateRowsError, updateRowsError } = useUpdateWeeklyProductionRows(id);
  const { updateInvoicesMutate, isUpdatingInvoices, isUpdateInvoicesError, updateInvoicesError } = useUpdateAdditionalInvoices(id);
  const { updateElementMutate, isUpdatingElements, isUpdateElementsError, updateElementsError } = useUpdateElements(id);

  const openConfirmModal = useConfirmModal();

  const projectRef = useRef<RefHandle<IProject>>(null);
  const customerRef = useRef<IOrganizationUserSelectHandle>(null);
  const engineerRef = useRef<IOrganizationUserSelectHandle>(null);
  const elementsRef = useRef<RefHandle<IElement[]>>(null);
  const weeklyProductionRowsRef = useRef<RefHandle<IWeeklyProductionRow[]>>(null);
  const additionalInvoicesRef = useRef<RefHandle<IAdditionalInvoice[]>>(null);
  const projectUsersRef = useRef<RefHandle<IProjectUser[]>>(null);

  const [weeklyProductionRows, setWeeklyProductionRows] = useState<IWeeklyProductionRow[]>([]);
  const [additionalInvoices, setAdditionalInvoices] = useState<IAdditionalInvoice[]>([]);
  const [elements, setElements] = useState<IElement[]>([]);
  const [projectUsers, setProjectUsers] = useState<IProjectUser[]>([]);
  const [isProjectDirty, setIsProjectDirty] = useState<IProjectDirty>({ weeklyProductionRows: false, additionalInvoices: false, elements: false, projectUsers: false });
  const [isProvisionsModalOpen, setIsProvisionsModalOpen] = useState(false);

  useEffect(() => {
    if (initialWeeklyProductionRows) {
      setWeeklyProductionRows(initialWeeklyProductionRows);
    }
  }, [initialWeeklyProductionRows]);

  useEffect(() => {
    if (initialAdditionalInvoices) {
      setAdditionalInvoices(initialAdditionalInvoices);
    }
  }, [initialAdditionalInvoices]);

  useEffect(() => {
    if (initialElements) {
      setElements(initialElements);
    }
  }, [initialElements]);
  
  useEffect(() => {
    if (project.users) {
      setProjectUsers(project.users ?? []);
    }
  }, [project.users]);

  const submitHandler = useCallback(async () => {
    try {
      const data = await projectRef.current?.getData();
      if (data) {
        // console.log("update project");
        if (isProjectDirty.projectUsers) {
          console.log("projectUsers dirty -> update");
          const projectUsersData = await projectUsersRef.current?.getData();
          if (projectUsersData && projectUsersData.length > 0) {
            data.users = projectUsersData;
          } else if (projectUsers) {
            data.users = projectUsers;
          }
          console.log("data.users ->", data.users);
        }
        await updateMutate(data);
        if (isProjectDirty.weeklyProductionRows) {
          console.log("weeklyProductionRows dirty -> update");
          const weeklyProductionRowsData = await weeklyProductionRowsRef.current?.getData();
          if (weeklyProductionRowsData && weeklyProductionRowsData.length > 0) {
            // console.log("update weeklyProductionRows from ref");
            await updateRowsMutate(weeklyProductionRowsData);
          } else if (weeklyProductionRows.length > 0) {
            // console.log("update weeklyProductionRows from state");
            await updateRowsMutate(weeklyProductionRows);
          }
        }
        if (isProjectDirty.additionalInvoices) {
          console.log("additionalInvoices dirty -> update");
          const additionalInvoicesData = await additionalInvoicesRef.current?.getData();
          if (additionalInvoicesData && additionalInvoicesData.length > 0) {
            // console.log("update additional invoices from ref");
            await updateInvoicesMutate(additionalInvoicesData);
          } else if (additionalInvoices.length > 0) {
            // console.log("update additional invoices from state");
            await updateInvoicesMutate(additionalInvoices);
          }
        }
        if (isProjectDirty.elements) {
          console.log("elements dirty -> update");
          const elementsData = await elementsRef.current?.getData();
          if (elementsData) {
            // console.log("update elements from ref");
            await updateElementMutate(elementsData);
          } else if (elements.length > 0) {
            // console.log("update elements from state");
            await updateElementMutate(elements);
          }
        }
      }
      setIsProjectDirty({ weeklyProductionRows: false, additionalInvoices: false, elements: false, projectUsers: false });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }, [updateMutate, isProjectDirty.weeklyProductionRows, isProjectDirty.additionalInvoices, isProjectDirty.elements, weeklyProductionRows, updateRowsMutate, additionalInvoices, updateInvoicesMutate, elements, updateElementMutate, isProjectDirty.projectUsers, projectUsers]);

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (isProvisionsModalOpen) return;
      if (e.repeat) return;
      if (!e.ctrlKey) return;
      const key = e.key.toLowerCase();
      switch (key) {
        case "s":
          e.preventDefault();
          submitHandler();
          break;
      }
    };

    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [isProvisionsModalOpen, submitHandler]);

  const { showBlocker, showNavigationBlockerModal, setIsDirty } = useNavigationBlockerContext();

  useEffect(() => {
    if (showBlocker) {
      showNavigationBlockerModal(submitHandler);
    }
  }, [showBlocker, showNavigationBlockerModal, submitHandler]);

  const deleteHandler = useCallback(async () => {
    const isConfirm = await openConfirmModal("Oletko varma, että haluat poistaa työmaan?");
    if (isConfirm) {
      await deleteMutate();
    }
  }, [deleteMutate, openConfirmModal]);

  const errorMessages = combineErrors(
    { isError: isDeletingError, error: deletingError },
    { isError: isSaveOrUpdateError, error: saveOrUpdateError },
    { isError: isUpdateRowsError, error: updateRowsError },
    { isError: isUpdateInvoicesError, error: updateInvoicesError },
    { isError: isUpdateElementsError, error: updateElementsError },
  );

  return (
    <form onBlur={() => setIsDirty(true)}>
      <OfferHeader
        title="Työmaa"
        projectNumber={project?.projectNumber}
        offerNumber={project?.offer?.offerNumber}
        isWon
        onSubmit={submitHandler}
        isLoading={saveOrUpdatePending || isUpdatingRows || isUpdatingInvoices || isUpdatingElements}
        // onDelete={isEdit ? deleteHandler : undefined}
        // isDeleting={isDeleting}
      />
      <Container>
        {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
        <ProjectEditContextProvider
          value={{
            id,
            project,
            customerRef,
            engineerRef,
            elementsRef,
            weeklyProductionRowsRef,
            additionalInvoicesRef,
            weeklyProductionRows,
            setWeeklyProductionRows,
            additionalInvoices,
            setAdditionalInvoices,
            elements,
            setElements,
            setIsProjectDirty,
            deleteHandler: isEdit ? deleteHandler : undefined,
            isDeleting,
            isProvisionsModalOpen,
            setIsProvisionsModalOpen,
            projectUsersRef,
            projectUsers,
            setProjectUsers,
            submitHandler,
          }}
        >
          <ProjectEdit ref={projectRef} />
        </ProjectEditContextProvider>
      </Container>
    </form>
  );
};

const useUpdate = (id: string) => {
  const { updateProject } = useProjectsService();

  const {
    mutateAsync: updateMutate,
    isPending,
    isError,
    error,
  } = useMutation<IProject, ErrorResponse, IProject>({
    mutationFn: (data) => updateProject(id, data),
    onSuccess: (data) => {
      queryClient.setQueryData([QueryKey.projects, data.id], { ...data });
    },
  });

  return { updateMutate, isPending, isError, error };
};

const useDelete = (id: string) => {
  const { deleteProject } = useProjectsService();
  const navigate = useNavigate();

  const {
    mutateAsync: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse>({
    mutationFn: () => deleteProject(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.projects],
        refetchType: "none",
      });
      navigate(Route.projects);
    },
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

const useUpdateWeeklyProductionRows = (projectId: string) => {
  const { updateWeeklyProductionRows } = useWeeklyProductionRowsService();

  const {
    mutateAsync: updateRowsMutate,
    isPending: isUpdatingRows,
    isError: isUpdateRowsError,
    error: updateRowsError,
  } = useMutation<IWeeklyProductionRow[], ErrorResponse, IWeeklyProductionRow[]>({
    mutationFn: (data) => updateWeeklyProductionRows(data),
    onSuccess: (data) => {
      queryClient.setQueryData(
        [QueryKey.weeklyProductionRows, projectId],
        [...data]
      );
      queryClient.invalidateQueries({ queryKey: [QueryKey.weeklyProductionRows] });
    },
  });

  return { updateRowsMutate, isUpdatingRows, isUpdateRowsError, updateRowsError };
};

const useUpdateAdditionalInvoices = (projectId: string) => {
  const { updateAdditionalInvoices } = useAdditionalInvoicesService();

  const {
    mutateAsync: updateInvoicesMutate,
    isPending: isUpdatingInvoices,
    isError: isUpdateInvoicesError,
    error: updateInvoicesError,
  } = useMutation<IAdditionalInvoice[], ErrorResponse, IAdditionalInvoice[]>({
    mutationFn: (data) => updateAdditionalInvoices(data),
    onSuccess: (data) => {
      queryClient.setQueryData(
        [QueryKey.weeklyProductionRows, projectId],
        [...data]
      );
      queryClient.invalidateQueries({ queryKey: [QueryKey.weeklyProductionRows] });
    },
  });

  return { updateInvoicesMutate, isUpdatingInvoices, isUpdateInvoicesError, updateInvoicesError };
};

const useUpdateElements = (projectId: string) => {
  const { updateElements } = useElementsService();

  const {
    mutateAsync: updateElementMutate,
    isPending: isUpdatingElements,
    isError: isUpdateElementsError,
    error: updateElementsError,
  } = useMutation<IElement[], ErrorResponse, IElement[]>({
    mutationFn: (data) => updateElements(data),
    onSuccess: (data) => {
      queryClient.setQueryData(
        [QueryKey.elements, projectId],
        [...data]
      );
      queryClient.invalidateQueries({ queryKey: [QueryKey.elements] });
    },
  });

  return { updateElementMutate, isUpdatingElements, isUpdateElementsError, updateElementsError };
};

export default Project;
