import { TCreateInput } from "../../ui/Input/useCreateInput";
import { EProjectInputs } from "../ProjectEdit/ProjectEdit";

interface IProps {
  createProjectInput: TCreateInput;
}

const ProjectSettings: React.FC<IProps> = ({ createProjectInput }) => {
  return (
    <>
      {createProjectInput(EProjectInputs.waybillWaitingPrice)}
      {createProjectInput(EProjectInputs.waybillUnloadFreeTime)}
      {createProjectInput(EProjectInputs.waybillNotes)}
    </>
  );
};

export default ProjectSettings;
