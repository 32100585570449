import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { faQrcode, faRoute } from "@fortawesome/free-solid-svg-icons";
import { queryClient } from "../../App";
import { ErrorResponse } from "../../custom-fetch";
import { useProjectOptions } from "../../hooks/useProjectOptions";
import { Route } from "../../routes";
import { QueryKey } from "../../services/query-keys";
import { useToolsService } from "../../services/tools-service";
import { FactoryOptions } from "../../shared/FactoryOptions";
import ITool, {
  EToolLocation,
  TOOL_LOCATION_OPTIONS,
  TOOL_TYPE_OPTIONS,
} from "../../shared/ITool";
import ErrorsAlert, { combineErrors } from "../ErrorsAlert/ErrorsAlert";
import Button, { EButtonSize } from "../ui/Button/Button";
import FormButtons from "../ui/FormButtons/FormButtons";
import { EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import InputGroup from "../ui/InputGroup/InputGroup";
import AuditHistories from "./AuditHistories/AuditHistories";
import { openGoogleMaps } from "../../utils/location-utils";
import { formatDateTime } from "../../utils/date-utils";
import QRCode from "react-qr-code";
import { adminUiUrl } from "../../config";
import { useWaybillOptions } from "../../hooks/useWaybillOptions";

interface IProps {
  id: string;
  tool?: ITool;
}

enum EInputs {
  toolType = "toolType",
  type = "type",
  manufacturer = "manufacturer",
  number = "number",
  introductionDate = "introductionDate",
  safeWorkingLoad = "safeWorkingLoad",
  safeWorkingLoad11 = "safeWorkingLoad11",
  safeWorkingLoad125 = "safeWorkingLoad125",
  slingLength = "slingLength",
  innerTubeLength = "innerTubeLength",
  innerTubeWidth = "innerTubeWidth",
  innerTubeHeight = "innerTubeHeight",
  outerTubeLength = "outerTubeLength",
  outerTubeWidth = "outerTubeWidth",
  outerTubeHeight = "outerTubeHeight",
  weightKg = "weightKg",
  latestAuditDate = "latestAuditDate",
  location = "location",
  factory = "factory",
  projectId = "projectId",
  expirationDate = "expirationDate",
  waybillId = "waybillId",
}

const ToolEdit: React.FC<IProps> = ({ id, tool }) => {
  const isEdit = id !== "add";
  const navigate = useNavigate();

  const { saveOrUpdate, error, isError, isPending } = useSaveOrUpdate(
    id,
    isEdit,
    navigate
  );
  // const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete(id, navigate);
  const { createInput, submit, inputs } = useToolInputs(tool);
  const { options, loading } = useProjectOptions();

  const factory = useMemo(() => inputs[EInputs.factory].value as string, [inputs]);
  const { options: waybillOptions, loading: waybillOptionsLoading, waybills } = useWaybillOptions({ factory });

  const location = useMemo(() => inputs[EInputs.location].value as EToolLocation, [inputs]);

  const submitHandler = useCallback(async () => {
    const data = await submit();
    if (data) {
      data.toolType = data.toolType ?? undefined;
      if (data.location === EToolLocation.PROJECT) {
        data.waybillId = undefined;
        data.waybillDeliveryDate = undefined;
      } else if (data.location === EToolLocation.WAYBILL) {
        data.projectId = undefined;
        console.log(data.waybillId);
        data.waybillDeliveryDate = waybills?.find(waybill => waybill.id === data.waybillId)?.deliveryDate;
      } else {
        data.projectId = undefined;
        data.waybillId = undefined
        data.waybillDeliveryDate = undefined;
      }
      saveOrUpdate(data);
    }
  }, [saveOrUpdate, submit, waybills]);

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.repeat) return;
      if (!e.ctrlKey) return;
      const key = e.key.toLowerCase();
      switch (key) {
        case "s":
          e.preventDefault();
          submitHandler();
          break;
      }
    };

    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [submitHandler]);

  const errorMessages = combineErrors(
    { isError, error }
    // { isError: isDeletingError, error: deletingError }
  );

  const openQRHandler = useCallback(() => {
    if (tool?.uuid) {
      window.open(Route.toolQRCode(tool.uuid), "_blank");
    }
  }, [tool?.uuid]);

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <div>
          <InputGroup>
            {createInput(EInputs.factory, { containerStyles: { width: "125px" } })}
            {createInput(EInputs.toolType, {
              containerStyles: { width: "200px" },
            })}
            {createInput(EInputs.type)}
          </InputGroup>
          <InputGroup>
            {createInput(EInputs.introductionDate)}
            {createInput(EInputs.latestAuditDate)}
            {createInput(EInputs.expirationDate)}
          </InputGroup>
          <InputGroup>
            {createInput(EInputs.manufacturer)}
            {createInput(EInputs.number)}
          </InputGroup>
          <InputGroup>
            {createInput(EInputs.safeWorkingLoad)}
            {createInput(EInputs.safeWorkingLoad11)}
            {createInput(EInputs.safeWorkingLoad125)}
          </InputGroup>
          <InputGroup>
            {createInput(EInputs.slingLength)}
            {createInput(EInputs.weightKg)}
          </InputGroup>
          <InputGroup>
            {createInput(EInputs.innerTubeLength)}
            {createInput(EInputs.innerTubeWidth)}
            {createInput(EInputs.innerTubeHeight)}
          </InputGroup>
          <InputGroup>
            {createInput(EInputs.outerTubeLength)}
            {createInput(EInputs.outerTubeWidth)}
            {createInput(EInputs.outerTubeHeight)}
          </InputGroup>
          <InputGroup>
            {createInput(EInputs.location, {
              containerStyles: { width: "125px" },
            })}
            {location === EToolLocation.PROJECT &&
              createInput(EInputs.projectId, {
                options,
                loading,
                containerStyles: { width: "125px" },
            })}
            {location === EToolLocation.WAYBILL &&
              createInput(EInputs.waybillId, {
                options: waybillOptions,
                loading: waybillOptionsLoading,
                containerStyles: { width: "125px" },
            })}
          </InputGroup>
        </div>
        {tool?.uuid && (
          <div>
            <QRCode
              style={{ height: "96px", width: "96px", marginTop: "1rem" }}
              value={adminUiUrl + Route.toolQRCode(tool.uuid)}
            />
          </div>
        )}
      </div>
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      <FormButtons
        onSubmit={submitHandler}
        isLoading={isPending}
        // onDelete={isEdit ? deleteMutate : undefined}
        // isDeleting={isDeleting}
        deleteConfirmMessage="Haluatko varmasti poistaa kuljetustilauksen?"
      >
        {tool?.uuid && (
          <Button onClick={openQRHandler} icon={faQrcode} title="QR" />
        )}
      </FormButtons>
      {tool?.id && (
        <>
          <hr />
          <AuditHistories
            toolId={tool.id}
            auditHistories={tool?.auditHistories ?? []}
            type="AuditHistory"
          />
        </>
      )}
      {tool?.id && (
        <>
          <hr />
          <AuditHistories
            toolId={tool.id}
            auditHistories={tool?.remarks ?? []}
            type="Remark"
          />
        </>
      )}
      {tool?.locations && (
        <>
          <hr />
          <div>
            <h3>QR skannausten sijainnit</h3>
            {tool?.locations.map((location, index) => (
              <div key={index} style={{ display: "flex", marginBottom: "1rem" }}>
                <Button
                  onClick={() =>
                    openGoogleMaps(location.latitude, location.longitude)
                  }
                  icon={faRoute}
                  size={EButtonSize.X_SMALL}
                />
                <div style={{ paddingTop: "10px", paddingLeft: "10px" }}>
                  {formatDateTime(location.created)}, Leveys: {location.latitude},
                  Pituus: {location.longitude}, Tarkkuus:{" "}
                  {location.accuracy?.toFixed(0)} m
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

const useToolInputs = (data?: ITool) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.toolType]: {
      type: EInputType.reactSelect,
      options: TOOL_TYPE_OPTIONS,
      label: "Tyyppi",
      placeholder: "Tyyppi",
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      validation: {
        required: true,
      },
    },
    [EInputs.type]: {
      type: EInputType.text,
      label: "Tyypin selite",
      value: "",
    },
    [EInputs.manufacturer]: {
      type: EInputType.text,
      label: "Valmistaja",
      value: "",
    },
    [EInputs.number]: {
      type: EInputType.text,
      label: "Numero",
      value: "",
    },
    [EInputs.introductionDate]: {
      type: EInputType.date,
      label: "Käyttöönottopvm",
      value: "",
    },
    [EInputs.safeWorkingLoad]: {
      type: EInputType.number,
      label: "Kuorma SWL(kN)",
      value: "",
    },
    [EInputs.safeWorkingLoad11]: {
      type: EInputType.number,
      label: "Koekuorma 1,1xSWL(kN)",
      value: "",
    },
    [EInputs.safeWorkingLoad125]: {
      type: EInputType.number,
      label: "Koekuorma 1,25xSWL(kN)",
      value: "",
    },
    [EInputs.slingLength]: {
      type: EInputType.number,
      label: "Raksi pituus (m)",
      value: "",
    },
    [EInputs.innerTubeLength]: {
      type: EInputType.number,
      label: "Sisäputki pituus",
      value: "",
    },
    [EInputs.innerTubeWidth]: {
      type: EInputType.number,
      label: "Sisäputki leveys",
      value: "",
    },
    [EInputs.innerTubeHeight]: {
      type: EInputType.number,
      label: "Sisäputki korkeus",
      value: "",
    },
    [EInputs.outerTubeLength]: {
      type: EInputType.number,
      label: "Ulkoputki pituus",
      value: "",
    },
    [EInputs.outerTubeWidth]: {
      type: EInputType.number,
      label: "Ulkoputki leveys",
      value: "",
    },
    [EInputs.outerTubeHeight]: {
      type: EInputType.number,
      label: "Ulkoputki korkeus",
      value: "",
    },
    [EInputs.weightKg]: {
      type: EInputType.number,
      label: "Paino (kg)",
      value: "",
    },
    [EInputs.latestAuditDate]: {
      type: EInputType.date,
      label: "Viimeisin tarkistus pvm",
      value: "",
      disabled: true,
    },
    [EInputs.location]: {
      type: EInputType.reactSelect,
      options: TOOL_LOCATION_OPTIONS,
      label: "Sijainti",
      placeholder: "Sijainti",
      value: "",
      hideControls: true,
      menuPosition: "fixed",
    },
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      options: FactoryOptions,
      label: "Tehdas",
      placeholder: "Tehdas",
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      validation: {
        required: true,
      },
    },
    [EInputs.projectId]: {
      type: EInputType.reactSelect,
      options: [],
      label: "Työmaa",
      placeholder: "Työmaa",
      value: "",
      hideControls: true,
      menuPosition: "fixed",
    },
    [EInputs.expirationDate]: {
      type: EInputType.date,
      label: "Voimassaolopvm",
      value: "",
      disabled: true,
    },
    [EInputs.waybillId]: {
      type: EInputType.reactSelect,
      options: [],
      label: "Rahtikirja",
      placeholder: "Rahtikirja",
      value: "",
      hideControls: true,
      menuPosition: "fixed",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit, inputs };
};

const useSaveOrUpdate = (
  id: string,
  isEdit: boolean,
  navigate: NavigateFunction
) => {
  const { updateTool, saveTool } = useToolsService();

  const mutationFn = (data: ITool) => {
    return isEdit ? updateTool(id, data) : saveTool(data);
  };

  const {
    mutate: saveOrUpdate,
    isPending,
    isError,
    error,
  } = useMutation<ITool, ErrorResponse, ITool>({
    mutationFn,
    onSuccess: (data) => {
      queryClient.removeQueries({ queryKey: [QueryKey.tools, data.id] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.tools] });
      // queryClient.invalidateQueries({ queryKey: [QueryKey.toolOptions] });
      if (!isEdit) navigate(Route.tool(data.id), { replace: true });
    },
  });

  return { saveOrUpdate, isPending, isError, error };
};

// const useDelete = (id: string, navigate: NavigateFunction) => {
//   const { deleteTransportOrder } = useTransportOrdersService();

//   const {
//     mutate: deleteMutate,
//     isPending: isDeleting,
//     isError: isDeletingError,
//     error: deletingError,
//   } = useMutation<boolean, ErrorResponse>({
//     mutationFn: () => deleteTransportOrder(id),
//     onSuccess: () => {
//       queryClient.invalidateQueries({ queryKey: [QueryKey.transportOrders], refetchType: "none" });
//       queryClient.invalidateQueries({ queryKey: [QueryKey.transportOrderOptions], refetchType: "none" });
//       navigate(Route.transportOrders);
//     },
//   });

//   return { deleteMutate, isDeleting, isDeletingError, deletingError };
// };

export default ToolEdit;
