import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import ErrorsAlert, {
  combineErrors,
} from "../components/ErrorsAlert/ErrorsAlert";
import ToolQRCode from "../components/Tools/ToolsQRCodes/ToolQRCode";
import Container from "../components/ui/Container/Container";
import Spinner from "../components/ui/Spinner/Spinner";
import { ErrorResponse } from "../custom-fetch";
import { usePublicQRCodesService } from "../services/publicQRCodes-service";
import { QueryKey } from "../services/query-keys";
import IToolDTO from "../shared/IToolDTO";

const ToolQRCodePage: React.FC = () => {
  const { uuid } = useParams();
  const { tool, isPending, isError, error } = useGetTool(uuid!);
  const errorMessages = combineErrors({ isError, error });

  if (isPending) {
    return <Spinner />;
  }

  return (
    <Container style={{ margin: "0" }}>
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      {tool ? <ToolQRCode tool={tool} /> : null}
    </Container>
  );
};

const useGetTool = (uuid: string) => {
  const { getToolByUuid } = usePublicQRCodesService();

  const {
    data: tool,
    isPending,
    isError,
    error,
  } = useQuery<IToolDTO, ErrorResponse>({
    queryKey: [QueryKey.publicTools, uuid],
    queryFn: ({ signal }) => getToolByUuid({ signal, id: uuid }),
  });

  return { tool, isPending, isError, error };
};

export default ToolQRCodePage;
