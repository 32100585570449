import { faPlus, faPrint, faQrcode } from "@fortawesome/free-solid-svg-icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { queryClient } from "../../../App";
import { adminUiUrl } from "../../../config";
import { useNavigationBlockerContext } from "../../../context/navigation-blocker-context";
import { ErrorResponse } from "../../../custom-fetch";
import { useWireTypeOptions } from "../../../hooks/useWireTypeOptions";
import { Route } from "../../../routes";
import { useAttachmentsService } from "../../../services/attachments-service";
import { useElementsService } from "../../../services/elements-service";
import { Kind } from "../../../services/kind";
import { useProductionLinesService } from "../../../services/productionLines-service";
import { QueryKey } from "../../../services/query-keys";
import IElement from "../../../shared/IElement";
import { EFactory } from "../../../shared/IOfferRow";
import IProductionLine, { EProductionLineStatus } from "../../../shared/IProductionLine";
import IProductType from "../../../shared/IProductType";
import IProject from "../../../shared/IProject";
import IWaybillRow from "../../../shared/IWaybillRow";
import IWireType from "../../../shared/IWireType";
import { calculateTotalLength } from "../../Elements/ElementsDragAndDropTable";
import { IElementsSearch } from "../../Elements/ElementsSearch";
import ErrorsAlert, { combineErrors } from "../../ErrorsAlert/ErrorsAlert";
import Button, { EButtonColor } from "../../ui/Button/Button";
import Container from "../../ui/Container/Container";
import IFrame from "../../ui/IFrame/IFrame";
import { updateInputHandler } from "../../ui/Input/input-utils";
import InputGroup from "../../ui/InputGroup/InputGroup";
import { useConfirmModal } from "../../ui/Modal/useConfirmModal";
import PageHeading from "../../ui/PageHeading/PageHeading";
import Spinner from "../../ui/Spinner/Spinner";
import ProductionLineUnloading from "../ProductionLineUnloading/ProductionLineUnloading";
import ProductionLineDraft from "./ProductionLineDraft";
import ProductionLineProgressBar from "./ProductionLineProgressBar";
import ProductionLineSearch from "./ProductionLineSearch";
import { EInputs, useProductionLineInputs } from "./useProductionLineInputs";
import { useProjectOptions } from "../../../hooks/useProjectOptions";

interface IProps {
  id: string;
  isEdit: boolean;
  productionLine?: IProductionLine;
  productionLineElements: IElement[];
  factoryParam: string | null;
}

const ProductionLineEdit: React.FC<IProps> = ({ id, isEdit, productionLine, productionLineElements, factoryParam }) => {
  const { createInput, submit, inputs, setInputs } = useProductionLineInputs(productionLine);
  const { options: wireTypeOptions, loading: wireTypesLoading, wireTypes } = useWireTypeOptions();

  const [elements, setElements] = useState<IElement[]>([]);
  const [search, setSearch] = useState<IElementsSearch | null | undefined>(null);
  const [activeElement, setActiveElement] = useState<IElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [unloadingRows, setUnloadingRows] = useState<IWaybillRow[]>([]);
  const [warehouseRows, setWarehouseRows] = useState<IWaybillRow[]>([]);
  const [castingRows, setCastingRows] = useState<IWaybillRow[]>([]);
  const [isElementsDirty, setIsElementsDirty] = useState(false);

  const { showBlocker, showNavigationBlockerModal, setIsDirty } = useNavigationBlockerContext();
  const openConfirmModal = useConfirmModal();

  useEffect(() => {
    setUnloadingRows(productionLine?.unloadingRows ?? []);
  }, [productionLine?.unloadingRows]);

  useEffect(() => {
    setWarehouseRows(productionLine?.warehouseRows ?? []);
  }, [productionLine?.warehouseRows]);
  
  useEffect(() => {
    setCastingRows(productionLine?.castingRows ?? []);
  }, [productionLine?.castingRows]);

  useEffect(() => {
    setElements(productionLineElements ?? []);
  }, [productionLineElements]);

  const hasElements = useMemo(() => elements.length > 0, [elements.length]);
  const typeName = useMemo(() => hasElements ? (elements[0].productType as IProductType)?.name : "", [hasElements, elements]);
  // const height = useMemo(() => hasElements ? elements[0].height : "", [hasElements, elements]);
  const tendonCountThick = useMemo(() => hasElements ? elements[0].tendonCountThick : "", [hasElements, elements]);
  const tendonCountThin = useMemo(() => hasElements ? elements[0].tendonCountThin : "", [hasElements, elements]);
  // const deliveryWeek = useMemo(() => hasElements && elements[0].deliveryWeek, [hasElements, elements]);

  const lineLength = useMemo(() => inputs[EInputs.productionLineLength].value as string, [inputs]);
  const factory = useMemo(() => inputs[EInputs.factory].value as string, [inputs]);

  useEffect(() => {
    if (factoryParam) {
      updateInputHandler(EInputs.factory, factoryParam, setInputs);
    }
  }, [factoryParam, setInputs]);
  
  const wireTypeId = useMemo(() => inputs[EInputs.wireType].value as string, [inputs]);
  const { attachment, isDownloading } = useDownloadLatestAttachments("WireType", wireTypeId);
  const startDate = useMemo(() => inputs[EInputs.startDate].value as string, [inputs]);

  useEffect(() => {
    if (!productionLine?.id) return;
    if (wireTypeId || startDate) {
      const wireTypeIdChanged = wireTypeId !== (productionLine?.wireType as IWireType)?.id;
      const startDateChanged = startDate !== productionLine?.startDate;
      // console.log(wireTypeId, startDate, wireTypeIdChanged, startDateChanged);
      if (wireTypeIdChanged || startDateChanged) {
        setIsElementsDirty(true);
        // console.log("set elements dirty");
      }
    }
  }, [productionLine?.id, productionLine?.startDate, productionLine?.wireType, startDate, wireTypeId]);

  const maxTypeName = useMemo(() => {
    if (!elements.length) return "";
    if (elements.length === 1) return elements[0].typeName ?? "";
    let newElements = [...elements];
    newElements = newElements.sort((e1, e2) => (e2.typeName ?? "").localeCompare(e1.typeName ?? ""));
    // console.log("maxTypeName", newElements[0].typeName);
    return newElements[0].typeName ?? "";
  }, [elements]);

  // useEffect(() => {
  //   if (!productionLine?.wireType && maxTypeName) {
  //     const typeId = wireTypes?.find(wireType => wireType.name === maxTypeName)?.id;
  //     if (typeId) {
  //       // console.log("detected wireType -> update");
  //       updateInputHandler(EInputs.wireType, typeId, setInputs);
  //     }
  //   }
  // }, [maxTypeName, productionLine?.wireType, setInputs, wireTypes]);

  // const minTypeName = useMemo(() => {
  //   if (!elements.length) return "";
  //   let newElements = [...elements];
  //   newElements = newElements.sort((e1, e2) => (e1.typeName ?? "").localeCompare(e2.typeName ?? ""));
  //   console.log("minTypeName", newElements[0].typeName);
  // }, [elements]);

  const onElementClick = useCallback((element: IElement) => {
    setIsDirty(true);
    setIsElementsDirty(true);
    if (activeElement?.id === element?.id) {
      setActiveElement(null);
    }
    setElements((elements) => {
      let newElements = [...elements];
      if (newElements.length > 0) {
        const index = newElements.findIndex((el) => el.id === element.id);
        if (index > -1) {
          return [
            ...newElements
              .filter((el) => el.id !== element.id)
              .map((el, i) => ({ ...el, position: i + 1 })),
          ];
        }
      }
      return [...newElements, { ...element, position: newElements.length + 1 }];
    });
  }, [activeElement?.id, setIsDirty]);

  const searchHandler = useCallback((data?: IElementsSearch | null) => {
    // console.log("search", data);
    setSearch(data);
  }, []);

  const navigate = useNavigate();

  const { saveOrUpdate, isUpdating, isUpdateError, updateError, saveOrUpdateReset } = useSaveOrUpdate(id, isEdit);
  const { updateElementsMutate, isUpdatingElements, isUpdateElementsError, updateElementsError } = useUpdateElements();
  const isLoading = useMemo(() => isUpdating || isUpdatingElements, [isUpdating, isUpdatingElements]);

  const { projects: allProjects } = useProjectOptions();

  const projectIds = useMemo(() => [...elements ?? []].filter(element => !!element.projectId).map(element => element.projectId).filter((projectId, index, arr) => !!projectId && arr.indexOf(projectId) === index), [elements]);
  const phaseIds = useMemo(() => [...elements ?? []].filter(element => !!element.phaseId).map(element => element.phaseId).filter((phaseId, index, arr) => !!phaseId && arr.indexOf(phaseId) === index), [elements]);
  const projects = useMemo(() => allProjects.filter(project => projectIds.includes(project.id)).map(project => ({ ...project, phases: [...project.phases ?? []].filter(phase => phaseIds.includes(phase.phaseId)) })), [allProjects, phaseIds, projectIds]);

  const submitHandler = useCallback(async () => {
    setLoading(true);
    try {
      const data = await submit();
      if (!data) {
        setLoading(false);
        return false;
      }
      const linealMeters = calculateTotalLength(elements) / 1000;
      const firstDeliveryWeek = elements.sort((e1, e2) => (e1.deliveryWeek ?? "").localeCompare(e2.deliveryWeek ?? ""))[0]?.deliveryWeek;
      const lastDeliveryWeek = elements.sort((e1, e2) => (e2.deliveryWeek ?? "").localeCompare(e1.deliveryWeek ?? ""))[0]?.deliveryWeek;
      const weightTons = elements.reduce((sum, element) => sum + +(element.weightTons ?? 0), 0).toFixed(2);
      const wireType = wireTypes?.find(wire => wire.id === data.wireType);
      const height = wireType?.slabType?.thickness ?? "";
      const slabTypeNetWeight = +(wireType?.slabType?.netWeight ?? 0);
      const concreteVolume = slabTypeNetWeight / 1000 * linealMeters / 2.45;
      const concreteTypeNameArray = elements.filter(element => !!element.concreteTypeNames).reduce((text, element) => [...text, element.concreteTypeNames ?? ""], [""]);
      const concreteTypeNames = concreteTypeNameArray.filter((text, index, arr) => !!text && arr.indexOf(text) === index).join(", ");
      const liftingLoopCount = elements?.reduce((sum, element) => sum + (element.provisionSummary?.countLiftingLoop ?? 0), 0);

      let deleteProductionLineElements: IElement[] = [];
      let newWarehouseRows = [...warehouseRows];
      let newUnloadingRows = [...unloadingRows];
      let newCastingRows = [...castingRows];
      if (isEdit && productionLineElements.length > 0) {
        // delete missing elements
        deleteProductionLineElements = [...productionLineElements]
          .filter((el1) => !elements.find((el2) => el1.id === el2.id))
          .map((element) => ({
            ...element,
            position: undefined,
            productionLineId: undefined,
            productionLineNumber: undefined,
            productionLineStartDate: undefined,
            productionLineFactory: undefined,
          }));
        if (deleteProductionLineElements.length > 0) {
          // delete references to elements that have been removed
          for (let i = 0; i < deleteProductionLineElements.length; i++) {
            const element = deleteProductionLineElements[i];
            newWarehouseRows = newWarehouseRows.filter(row => row.elementId !== element.id);
            newUnloadingRows = newUnloadingRows.filter(row => row.elementId !== element.id);
            newCastingRows = newCastingRows.filter(row => row.elementId !== element.id);
          }
        }
        // delete references to elements that have been removed
        const ids = newWarehouseRows.filter(row => !!row.elementId).map(row => row.elementId);
        // console.log(ids);
        if (ids.length > 0) {
          newUnloadingRows = newUnloadingRows.filter(row => ids.includes(row.elementId));
          newCastingRows = newCastingRows.filter(row => ids.includes(row.elementId));
        } else {
          newUnloadingRows = [];
          newCastingRows = [];
          setWarehouseRows([]);
          setUnloadingRows([]);
          setCastingRows([]);
        }
      }

      const line = await saveOrUpdate({ ...productionLine, ...data, id: isEdit ? id : undefined, typeName: maxTypeName, height, projects: projects as IProject[], linealMeters, concreteVolume, liftingLoopCount, count: elements.length ?? 0, wireType, unloadingRows: newUnloadingRows, warehouseRows: newWarehouseRows, castingRows: newCastingRows, firstDeliveryWeek, lastDeliveryWeek, weightTons, concreteTypeNames });
      if (line && isElementsDirty) {
        const updatedElements = await updateElementsMutate({
          productionLineId: line.id!,
          data: [
            ...[...elements].map((element, index) => ({
              ...element,
              position: element.position || index + 1,
              productionLineId: line.id,
            })),
            ...deleteProductionLineElements,
          ],
        });
        if (updatedElements) {
          // setElements(updatedElements.filter(element => elementIds.includes(element.id)));
        }
      }
      setIsDirty(false);
      setLoading(false);
      if (!isEdit && line.id) navigate(Route.productionLine(line.id));
      return true;
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    return false;
  }, [castingRows, elements, id, isEdit, isElementsDirty, maxTypeName, navigate, productionLine, productionLineElements, projects, saveOrUpdate, setIsDirty, submit, unloadingRows, updateElementsMutate, warehouseRows, wireTypes]);

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.repeat) return;
      if (!e.ctrlKey) return;
      const key = e.key.toLowerCase();
      switch (key) {
        case "s":
          e.preventDefault();
          submitHandler();
          break;
      }
    };

    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [submitHandler]);

  useEffect(() => {
    if (showBlocker) {
      showNavigationBlockerModal(submitHandler);
    }
  }, [showBlocker, showNavigationBlockerModal, submitHandler]);

  const resetHandler = useCallback(() => {
    saveOrUpdateReset();
    setElements([]);
    setIsDirty(true);
    setIsElementsDirty(true);
  }, [saveOrUpdateReset, setIsDirty]);

  const reverseHandler = useCallback(() => {
    setElements(elements => [...elements].reverse().map((element, index) => ({...element, position: index + 1})));
    setIsDirty(true);
    setIsElementsDirty(true);
  }, [setIsDirty]);

  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDeleteProductionLine(id!, navigate, productionLine?.factory);

  const deleteHandler = useCallback(async () => {
    const isConfirm = await openConfirmModal("Oletko varma, että haluat poistaa pedin?");
    if (isConfirm) {
      deleteMutate();
      setIsDirty(false);
    }
  }, [deleteMutate, openConfirmModal, setIsDirty]);

  const errorMessages = combineErrors(
    { isError: isUpdateError, error: updateError },
    { isError: isUpdateElementsError, error: updateElementsError },
    { isError: isDeletingError, error: deletingError },
  );
  
  // lankatyyppi, sama tehdas
  const autofillHandler = useCallback(() => {
    const elementsData = queryClient.getQueryData([QueryKey.elements]) as IElement[];
    if (elementsData && elementsData.length > 0) {
      // console.log("typeName", typeName);
      // console.log("ex typeName", elementsData[0]?.typeName);
      // console.log("eq typeName", elementsData[0]?.typeName?.toLowerCase().includes(typeName.toLowerCase()));
      const potentialElements = [...elementsData].filter(element => (!element.productionLineId || element.productionLineId === id)
        && (factory ? element.factory === factory : true) 
        // TODO: vko gte eikä eq
        // && (deliveryWeek ? element.deliveryWeek === deliveryWeek : true)
        && (typeName ? element?.typeName?.toLowerCase().includes(typeName?.toLowerCase()) : true)
        && (tendonCountThick ? (element?.tendonCountThick ?? 0) >= (tendonCountThick ?? 0) : true)
        && (tendonCountThin ? (element?.tendonCountThin ?? 0) >= (tendonCountThin ?? 0) : true)
        && elements.findIndex(el => element.id === el.id) === -1
      );
      setElements(elements => [...elements, ...potentialElements].map((element, index) => ({ ...element, position: index + 1 })));
      setIsDirty(true);
      setIsElementsDirty(true);
    }
  }, [setIsDirty, id, factory, typeName, tendonCountThick, tendonCountThin, elements]);

  const setActiveIndex = useCallback((value: number) => {
    if (!elements) return;
    const currentIndex = activeElement ? elements?.findIndex(element => element.id === activeElement.id) ?? -1 : -1
    const index = currentIndex + value;
    if (index < 0 || index === elements.length) return;
    const element = elements[currentIndex + value];
    if (element?.id) {
        setActiveElement(element);
    }
  }, [activeElement, elements]);

  const showNext = useCallback(() => setActiveIndex(+1), [setActiveIndex]);
  const showPrevious = useCallback(() => setActiveIndex(-1), [setActiveIndex]);

  const updateActiveElement = useCallback((element: IElement) => {
    setActiveElement(element);
    setElements(elements => elements.map(el => el.id === element.id ? {...element} : el));
  }, []);

  const submitAndNavigateHandler = useCallback(async (route: Route) => {
    await submitHandler();
    setIsDirty(false);
    navigate(route);
  }, [navigate, setIsDirty, submitHandler]);

  const openQRHandler = useCallback(() => {
    if (productionLine?.uuid) {
      const url = `${adminUiUrl}/qr/production-line/${productionLine.uuid}`;
      window.open(url, "_blank");
    }
  }, [productionLine?.uuid]);

  const refetchElementsHandler = useCallback(() => {
    queryClient.refetchQueries({ queryKey: [QueryKey.elements], exact: true, fetchStatus: "idle" });
  }, []);

  // const { user } = useAuthContext();
  // const isProductionUser = useMemo(() => user?.role !== "LOGISTICS", [user?.role]);
  // const showDraft = useMemo(() => isProductionUser ? (!productionLine?.status || [EProductionLineStatus.DRAFT, EProductionLineStatus.CONTENT_PLANNED].includes(productionLine?.status)) : (!productionLine?.status || productionLine?.status === EProductionLineStatus.DRAFT), [isProductionUser, productionLine?.status]);

  const showDraft = useMemo(() => (!productionLine?.status || [EProductionLineStatus.DRAFT, EProductionLineStatus.CONTENT_PLANNED].includes(productionLine?.status)), [productionLine?.status]);

  const { downloadMergedAttachmentsMutate } = useProductionLineAttachments(productionLine?.id ?? "");
  const [mergedLoading, setMergedLoading] = useState(false);

  const downloadAttachmentsHandler = useCallback(async () => {
    setMergedLoading(true);
    try {
      const attachment = await downloadMergedAttachmentsMutate();
      const objectUrl = URL.createObjectURL(attachment);
      window.open(objectUrl, "_blank");
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.error(error);
    }
    setMergedLoading(false);
  }, [downloadMergedAttachmentsMutate]);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <PageHeading>
          {productionLine?.prevProductionLineId ? <span onClick={() => navigate(Route.productionLine(productionLine.prevProductionLineId!))} style={{cursor: "pointer"}}>{"<"}</span> : ""}
            Peti {productionLine?.productionLineNumber}
          {productionLine?.nextProductionLineId ? <span onClick={() => navigate(Route.productionLine(productionLine.nextProductionLineId!))} style={{cursor: "pointer"}}>{">"}</span> : ""}
          </PageHeading>
        {productionLine?.id && (
          <>
            <Button onClick={() => submitAndNavigateHandler(Route.productionLineElements(productionLine.id!))} loading={loading} icon={faPrint} color={EButtonColor.SECONDARY}>Tunnuslaput</Button>
            <Button onClick={() => submitAndNavigateHandler(Route.productionLinePrint(productionLine.id!))} loading={loading} icon={faPrint} color={EButtonColor.SECONDARY}>Tulosta</Button>
            {productionLine.hasAttachments && <Button onClick={downloadAttachmentsHandler} loading={loading || mergedLoading} icon={faPrint} color={EButtonColor.SECONDARY}>Liitteet</Button>}
            <Button onClick={openQRHandler} loading={loading} icon={faQrcode} color={EButtonColor.SECONDARY} title="QR"/>
            <Button onClick={() => navigate(Route.productionLine(`add${factory ? `?factory=${factory}` : ''}`))} loading={loading} icon={faPlus}>Lisää uusi</Button>
          </>
        )}
        <Button onClick={submitHandler} loading={loading}>Tallenna</Button>
        {/* {isEdit && productionLine?.status === EProductionLineStatus.DRAFT && elements.length > 0 && <Button onClick={setContentPlannedHandler} loading={isContentPlannedLoading}>Sisältö suunniteltu</Button>} */}
        <Button onClick={() => navigate(Route.productionLines)} color={EButtonColor.SECONDARY}>Palaa</Button>
        {isEdit && <Button onClick={deleteHandler} loading={isDeleting || loading} color={EButtonColor.DANGER}>Poista</Button>}
      </div>
      <Container>
        <form onBlur={loading ? undefined : () => setIsDirty(true)}>
          <div style={{ display: "flex", gap: "1rem" }}>
            <div>
              <PageHeading variant="h2" noHorizontalMargin>Pedin tiedot</PageHeading>
              <ProductionLineProgressBar id={id!} isEdit={isEdit} status={productionLine?.status} submitHandler={submitHandler} />
              <div>
                <InputGroup>
                  {createInput(EInputs.factory, { containerStyles: { minWidth: "80px" } })}
                  {createInput(EInputs.productionLineLength)}
                  {createInput(EInputs.startDate)}
                  {createInput(EInputs.endDate)}
                  {createInput(EInputs.position)}
                  {createInput(EInputs.brushed)}
                  {/* {createInput(EInputs.isPlanned)} */}
                </InputGroup>
                {createInput(EInputs.notes)}
                <InputGroup>
                  {createInput(EInputs.wireType, { options: wireTypeOptions, loading: wireTypesLoading, containerStyles: { width: "125px" } })}
                  {isDownloading ? <Spinner /> : attachment && <IFrame blob={attachment} style={{ maxWidth: "400px" }} />}
                </InputGroup>
                <InputGroup>
                  {/* {!!typeName && <p>Tunnistettu vaijerityyppi: {maxTypeName}</p>} */}
                  {/* {!!height && <p>Paksuus: {height}</p>} */}
                  {projects?.length > 0 && <p>Työmaat: {projects?.map(project => <Link style={{ margin: "0 0.25rem", background: project?.customerDisruption ? "red" : "" }} key={project.id} to={Route.project(`${project.id}/elements`)} title={project.customerDisruption ? "Edistäminen vaatii luvan" : undefined}>{project.projectNumber} {project.name}</Link>)}</p>}
                </InputGroup>
              </div>
              {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
              {showDraft && (
                <>
                  <hr />
                  <PageHeading variant="h3" noHorizontalMargin>1. Pedin sisältö</PageHeading>
                  <ProductionLineDraft
                    hasElements={hasElements}
                    autofillHandler={autofillHandler}
                    isLoading={isLoading}
                    resetHandler={resetHandler}
                    reverseHandler={reverseHandler}
                    elements={elements}
                    setElements={setElements}
                    onElementClick={onElementClick}
                    lineLength={lineLength}
                    setActiveElement={setActiveElement}
                    setIsDirty={setIsDirty}
                  />
                </>
              )}
              </div>
              {showDraft && (
                <>
                  <span style={{ borderLeft: "thin solid gray", height: "auto" }} />
                  <ProductionLineSearch
                    elements={elements}
                    onElementClick={onElementClick}
                    activeElement={activeElement}
                    setActiveElement={setActiveElement}
                    id={id}
                    showNext={showNext}
                    showPrevious={showPrevious}
                    updateActiveElement={updateActiveElement}
                    refetchElementsHandler={refetchElementsHandler}
                    searchHandler={searchHandler}
                    factory={factory}
                    search={search}
                    typeName={typeName}
                    // height={height}
                    productionLineElements={productionLineElements}
                  />
                </>
              )}
          </div>
          {factory && productionLine?.id && productionLine.status !== EProductionLineStatus.DRAFT ? (
            <ProductionLineUnloading
              id={productionLine.id}
              statusOrder={productionLine.statusOrder}
              productionLineElements={productionLineElements}
              unloadingRows={unloadingRows}
              setUnloadingRows={setUnloadingRows}
              warehouseRows={warehouseRows}
              setWarehouseRows={setWarehouseRows}
              castingRows={castingRows}
              setCastingRows={setCastingRows}
              setIsDirty={setIsDirty}
              lineLength={lineLength}
            />
          ) : null}
        </form>
      </Container>
    </>
  );
};

const useSaveOrUpdate = (
  id: string,
  isEdit: boolean,
) => {
  const { updateProductionLine, saveProductionLine } =
    useProductionLinesService();

  const mutationFn = (data: IProductionLine) => {
    return isEdit ? updateProductionLine(id, data) : saveProductionLine(data);
  };

  const {
    mutateAsync: saveOrUpdate,
    isPending,
    isError,
    error,
    reset,
  } = useMutation<IProductionLine, ErrorResponse, IProductionLine>({
    mutationFn,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.productionLines] });
      if (data.factory) {
        queryClient.invalidateQueries({ queryKey: [QueryKey.productionLines, data.factory] });
      }
      // if (data.id) {
      //   if (!isEdit) {
      //     redirectHandler(data.id);
      //   }
      queryClient.setQueryData([QueryKey.productionLines, data.id], data);
      // }
    },
  });

  return {
    saveOrUpdate,
    isUpdating: isPending,
    isUpdateError: isError,
    updateError: error,
    saveOrUpdateReset: reset,
  };
};

interface IUpdateElements {
  productionLineId: string; 
  data: IElement[];
}

const useUpdateElements = () => {
  const { updateProductionLineElements } = useElementsService();

  const {
    mutateAsync: updateElementsMutate,
    isPending: isUpdatingElements,
    isError: isUpdateElementsError,
    error: updateElementsError,
  } = useMutation<IElement[], ErrorResponse, IUpdateElements>({
    mutationFn: ({ productionLineId, data }) =>
      updateProductionLineElements(productionLineId, data),
    onSuccess: (data, variables) => {
      const productionLineId = variables.productionLineId;
      if (productionLineId) {
        queryClient.setQueryData(
          [QueryKey.elements, productionLineId],
          [
            ...data.filter(
              (element) => element.productionLineId === productionLineId
            ),
          ]
        );
      }
      const uniqueProjectIds = data
        .map((element) => element.projectId)
        .filter((value, index, array) => array.indexOf(value) === index);
      if (uniqueProjectIds?.length > 0) {
        for (let i = 0; i < uniqueProjectIds.length; i++) {
          const projectId = uniqueProjectIds[i];
          queryClient.invalidateQueries({
            queryKey: [QueryKey.elements, projectId],
            refetchType: "all",
          });
        }
      }
      queryClient.removeQueries({ queryKey: [QueryKey.elements] });
    },
  });

  return { updateElementsMutate, isUpdatingElements, isUpdateElementsError, updateElementsError };
};

const useDownloadLatestAttachments = (kind: Kind, parentId: string) => {
  const { downloadLatestAttachment } = useAttachmentsService();

  const {
    data: attachment,
    isLoading: isDownloading,
    isError: isDownloadError,
    error: downloadError,
  } = useQuery<Blob, ErrorResponse>({
    queryKey: [QueryKey.latestAttachments, kind, parentId],
    queryFn: ({ signal }) => downloadLatestAttachment({ signal, kind, parentId }),
    staleTime: 5000,
    enabled: !!parentId,
  });

  return { attachment, isDownloading, isDownloadError, downloadError };
}

const useDeleteProductionLine = (id: string, navigate: NavigateFunction, factory?: EFactory) => {
  const { deleteProductionLine } = useProductionLinesService();

  const {
    mutate: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse>({
    mutationFn: () => deleteProductionLine(id),
    onSuccess: () => {
      if (factory) {
        queryClient.invalidateQueries({
          queryKey: [QueryKey.productionLines, factory],
          refetchType: "none",
        });
      }
      navigate(Route.productionLines);
    },
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

const useProductionLineAttachments = (productionLineId: string) => {
  const { downloadLatestAttachment } = useAttachmentsService();

  const {
    mutateAsync: downloadMergedAttachmentsMutate,
  } = useMutation<Blob, ErrorResponse>({
    mutationFn: () => downloadLatestAttachment({ kind: "ProductionLine", parentId: productionLineId }),
  });

  return { downloadMergedAttachmentsMutate };
}

export default ProductionLineEdit;
