import { useNavigate } from "react-router-dom";

import { Route } from "../../routes";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import Table from "../ui/Table/Table";
import { useOffersSearch } from "./useOffersSearch";
import { formatDate } from "../../utils/date-utils";
import { useOfferService } from "../../services/offers-service";
import { useEffect, useState } from "react";
import { queryClient } from "../../App";
import { QueryKey } from "../../services/query-keys";
import IOffer from "../../shared/IOffer";
import Button, { EButtonSize } from "../ui/Button/Button";
import { faChevronDown, faChevronUp, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps { }

enum EListSortBy {
  offerNumber = "offerNumber",
  offerDate = "offerDate",
  targetName = "targetName",
  customer = "customer",
  seller = "seller",
  deliveryCity = "deliveryCity",
  archived = "archived"
}

const OffersList: React.FC<IProps> = () => {
  const { isError, error, isLoading, offers } = useOffersSearch();
  const { archivedUpdateOffer } = useOfferService();
  const navigate = useNavigate();
  const [updatingIds, setUpdatingIds] = useState<string[]>([]);

  const [sorted, setSorted] = useState<IOffer[]>([]);
  const [sort, setSort] = useState<EListSortBy>();
  const [sortDirection, setSortDirection] = useState<boolean>(false); // true = up, false = down
  const changeSortOrDirection = (sortBy: EListSortBy) => {
    if (sortBy === sort) {
      setSortDirection(!sortDirection);
    } else {
      setSort(sortBy);
      setSortDirection(false);
    }
  }
  const getChevron = () => {
    return <FontAwesomeIcon style={{ marginLeft: '.5rem', fontSize: '.75rem'}} icon={sortDirection ? faChevronUp : faChevronDown} />
  };

  useEffect(() => {
    const newSorted = [...sorted];
    if (sort && newSorted) {
      if (sortDirection) {
        newSorted.reverse();
        setSorted(newSorted);
        return;
      }
      newSorted.sort((a, b) => {
        const getFieldValue = (item: IOffer, field: EListSortBy) => {
          if (field === EListSortBy.customer) return item.customer?.name;
          if (field === EListSortBy.seller) return item.seller?.name;
          return item[field];
        };
        const aValue = getFieldValue(a, sort) || "";
        const bValue = getFieldValue(b, sort) || "";
        if (aValue === bValue) return 0;
        return aValue > bValue ? 1 : -1;
      });
      setSorted(newSorted);
    }
    // eslint-disable-next-line
  }, [sort, sortDirection]);

  useEffect(() => {
    if (offers) {
      setSorted(offers);
    }
  }, [offers])

  const archivedUpdateHandler = async (id: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setUpdatingIds((prevIds) => [...prevIds, id]);
    try {
      const updated = await archivedUpdateOffer(id);
      queryClient.setQueryData([QueryKey.offers], (offers: IOffer[]) => 
        offers.map((item) => 
          item.id === id ? { ...item, archived: updated.archived } : item
        )
      );
    } catch (error) {
      console.error('Error updating:', error);
    } finally {
      setUpdatingIds((prevIds) => prevIds.filter((rowId) => rowId !== id));
    }
  };

  if (isError && error) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (!offers || offers.length === 0) {
    return <p>Ei tarjouksia</p>;
  }

  const openHandler = (id: string) => {
    navigate(Route.offer(id));
  };

  return (
    <>
      <Table hover>
        <thead style={{cursor: "pointer"}}>
          <tr>
            <th onClick={() => changeSortOrDirection(EListSortBy.offerNumber)}>Tarjouksen numero {sort === EListSortBy.offerNumber && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.offerDate)}>Pvm {sort === EListSortBy.offerDate && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.targetName)}>Kohteen nimi {sort === EListSortBy.targetName && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.customer)}>Asiakas {sort === EListSortBy.customer && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.seller)}>Myyjä {sort === EListSortBy.seller && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.deliveryCity)}>Kaupunki {sort === EListSortBy.deliveryCity && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.archived)}>Arkistoitu {sort === EListSortBy.archived && getChevron()}</th>
          </tr>
        </thead>
        <tbody>
          {sorted.map((offer) => (
            <tr key={offer.id} onClick={() => openHandler(offer.id)} 
              style={{
                color: offer.archived ? "gray" : "inherit",
                fontStyle: offer.archived ? "italic" : "inherit",
              }}
            >
              <td>{offer.projectNumber && offer.projectNumber + " / "}{offer.offerNumber}</td>
              <td>{formatDate(offer.offerDate)}</td>
              <td>{offer.targetName}</td>
              <td>{offer.customer?.name}</td>
              <td>{offer.seller?.name}</td>
              <td>{offer.deliveryCity}</td>
              <td><Button size={EButtonSize.X_SMALL} loading={updatingIds.includes(offer.id)} onClick={(event)=>archivedUpdateHandler(offer.id, event)} icon={offer.archived ? faEyeSlash : faEye} title={offer.archived ? "Poista arkistosta" : "Arkistoi"} /></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default OffersList;
