import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IWireReel from '../shared/IWireReel';

interface IGetWireReel extends ISignal {
  id: string;
}

export const useWireReelService = () => {
  const customFetch = useCustomFetch();

  const fetchWireReels = useCallback(async ({ signal }: ISignal, archived?: boolean) => {
    const [wireReel] = await customFetch<IWireReel[]>(`/api/wire-reels/list${archived !== undefined ? `?archived=${archived}`:""}`, { signal });
    return wireReel;
  }, [customFetch]);
  
  const getWireReel = useCallback(async ({ signal, id }: IGetWireReel) => {
    const [wireReel] = await customFetch<IWireReel>("/api/wire-reels/get/" + id, { signal });
    return wireReel;
  }, [customFetch]);
  
  const saveWireReel = useCallback(async (data: IWireReel) => {
    const [wireReel] = await customFetch<IWireReel>("/api/wire-reels/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return wireReel;
  }, [customFetch]);
  
  const updateWireReel = useCallback(async (id: string, data: IWireReel) => {
    const [wireReel] = await customFetch<IWireReel>("/api/wire-reels/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return wireReel;
  }, [customFetch]);
  
  const deleteWireReel = useCallback(async (id: string) => {
    await customFetch<boolean>("/api/wire-reels/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);

  const archivedUpdateWireReel = useCallback(async (id: string) => {
    const [wireReel] = await customFetch<IWireReel>("/api/wire-reels/update-archived/" + id, {
      method: "PUT",
    });
    return wireReel;
  }, [customFetch]);
  
  return { fetchWireReels, getWireReel, saveWireReel, updateWireReel, deleteWireReel, archivedUpdateWireReel };
}
