import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { ReactNode } from "react";
import Button, { EButtonColor } from "../../ui/Button/Button";
import FormButtons from "../../ui/FormButtons/FormButtons";
import classes from "./OfferHeader.module.scss";
import { adminUiUrl } from "../../../config";

interface IProps {
  onSubmit?: () => void;
  submitText?: string;
  isLoading: boolean;
  onDelete?: () => void;
  isDeleting?: boolean;
  isEdit?: boolean;
  onUpdatePrices?: () => void;
  onCreateOrder?: () => void;
  onCopy?: () => void;
  offerNumber?: string;
  projectNumber?: string;
  onPrint?: () => void;
  onInstalmentTable?: () => void;
  onReturn?: () => void;
  children?: ReactNode;
  isCopy?: boolean;
  isWon?: boolean;
  title: string;
  projectId?: string;
  hideReturn?: boolean;
}

const OfferHeader: React.FC<IProps> = ({
  onSubmit,
  submitText,
  isLoading,
  onDelete,
  isDeleting,
  isEdit,
  onUpdatePrices,
  onCreateOrder,
  onCopy,
  onInstalmentTable,
  offerNumber,
  projectNumber,
  onPrint,
  onReturn,
  children,
  isCopy,
  isWon,
  title,
  projectId,
  hideReturn
}) => {
  return (
    <div className={classes.Header}>
      <h1>
        {title} {isCopy && "Kopio"}{" "}
        {isWon && projectNumber && projectNumber + " / "}{" "}
        {offerNumber}
      </h1>
      {onPrint && (
        <Button onClick={onPrint} icon={faPrint} color={EButtonColor.SECONDARY}>
          Tulosta
        </Button>
      )}
      <FormButtons
        onSubmit={onSubmit}
        isLoading={isLoading}
        onDelete={onDelete}
        isDeleting={isDeleting}
        deleteConfirmMessage="Haluatko varmasti poistaa tarjouksen?"
        submitText={submitText}
        onReturn={onReturn}
        hideReturn={hideReturn}
      >
        {isEdit && onUpdatePrices && (
          <Button onClick={onUpdatePrices}>
            Päivitä hinnat ja kate
          </Button>
        )}
        {children}
        {onCreateOrder && (
          <Button onClick={onCreateOrder}>
            {isWon ? "Tilausvahvistus" : "Tee tilaus"}
          </Button>
        )}
        {isEdit && onCopy && <Button onClick={onCopy}>Kopioi pohjaksi</Button>}
        {isEdit && onInstalmentTable && <Button onClick={onInstalmentTable}>Maksuerätaulukko</Button>}
        {isEdit && projectId && <Button onClick={()=> window.open(`${adminUiUrl}/projects/${projectId}`, "_blank")}>Työmaa</Button>}
      </FormButtons>
    </div>
  );
};

export default OfferHeader;
