import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import IWaybillDTO from "../../shared/IWaybillDTO";
import { formatDate } from "../../utils/date-utils";
import Table from "../ui/Table/Table";

interface IProps {
  waybills: IWaybillDTO[];
  onClick: (waybill: IWaybillDTO) => void;
}

enum EListSortBy {
  waybillId = "waybillId",
  destination = "destination",
  deliveryDate = "deliveryDate",
  deliveryTime = "deliveryTime",
  kilometers = "kilometers",
  tons = "tons",
  count = "count",
}

const CustomerWaybills: React.FC<IProps> = ({ waybills, onClick }) => {
  const [sorted, setSorted] = useState<IWaybillDTO[]>([]);
  const [sort, setSort] = useState<EListSortBy>();
  const [sortDirection, setSortDirection] = useState<boolean>(false); // true = up, false = down
  const changeSortOrDirection = (sortBy: EListSortBy) => {
    if (sortBy === sort) {
      setSortDirection(!sortDirection);
    } else {
      setSort(sortBy);
      setSortDirection(false);
    }
  };
  const getChevron = () => {
    return (
      <FontAwesomeIcon
        style={{ marginLeft: ".5rem", fontSize: ".75rem" }}
        icon={sortDirection ? faChevronUp : faChevronDown}
      />
    );
  };

  useEffect(() => {
    const newSorted = [...sorted];
    if (sort && newSorted) {
      if (sortDirection) {
        newSorted.reverse();
        setSorted(newSorted);
        return;
      }
      newSorted.sort((a, b) => {
        const getFieldValue = (item: IWaybillDTO, field: EListSortBy) => {
          return item[field];
        };
        const aValue = getFieldValue(a, sort) || "";
        const bValue = getFieldValue(b, sort) || "";
        if (aValue === bValue) return 0;
        return aValue > bValue ? 1 : -1;
      });
      setSorted(newSorted);
    }
    // eslint-disable-next-line
  }, [sort, sortDirection]);

  useEffect(() => {
    if (waybills) {
      setSorted(waybills);
    }
  }, [waybills]);

  return (
    <>
      <Table hover>
        <thead style={{ cursor: "pointer" }}>
          <tr>
            <th onClick={() => changeSortOrDirection(EListSortBy.deliveryDate)}>
              Pvm {sort === EListSortBy.deliveryDate && getChevron()}
            </th>
            <th onClick={() => changeSortOrDirection(EListSortBy.deliveryTime)}>
              Klo {sort === EListSortBy.deliveryTime && getChevron()}
            </th>
            <th onClick={() => changeSortOrDirection(EListSortBy.waybillId)}>
              Numero {sort === EListSortBy.waybillId && getChevron()}
            </th>
            {/* <th onClick={() => changeSortOrDirection(EListSortBy.destination)}>
              Sijainti {sort === EListSortBy.destination && getChevron()}
            </th> */}
            <th onClick={() => changeSortOrDirection(EListSortBy.count)}>
              Kpl {sort === EListSortBy.count && getChevron()}
            </th>            
            <th onClick={() => changeSortOrDirection(EListSortBy.tons)}>
              Tonnit {sort === EListSortBy.tons && getChevron()}
            </th>
          </tr>
        </thead>
        <tbody>
          {sorted.map((waybill) => (
            <tr key={waybill.id} onClick={() => onClick(waybill)}>
              <td>{waybill.waybillId}</td>
              <td>{waybill.destination}</td>
              <td>{formatDate(waybill.deliveryDate)}</td>
              <td>{waybill.deliveryTime}</td>
              <td>{waybill.waybillId}</td>
              {/* <td>{waybill.destination}</td> */}
              <td>{waybill.count}</td>
              <td>{waybill.tons && (+waybill.tons).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
        {waybills.length > 0 && (
          <tfoot>
            <tr>
              <td colSpan={2}></td>
              <td>Yhteensä</td>
              <td>
                {waybills.reduce(
                  (sum, waybill) => sum + +(waybill.count ?? 0),
                  0
                )}
              </td>
              <td>
                {waybills
                  .reduce((sum, waybill) => sum + +(waybill.tons ?? 0), 0)
                  .toFixed(2)}
              </td>
            </tr>
          </tfoot>
        )}
      </Table>
    </>
  );
};

export default CustomerWaybills;
