import { useMemo, useState } from "react";
import IProductionLine, { EProductionLineStatus } from "../../shared/IProductionLine";
import { dateIsPast } from "../../utils/date-utils";
import Button, { EButtonColor } from "../ui/Button/Button";
import { EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import InputGroup from "../ui/InputGroup/InputGroup";
import ModalBody from "../ui/Modal/ModalBody/ModalBody";
import ModalFooter from "../ui/Modal/ModalFooter/ModalFooter";
import { format } from "date-fns";

enum EInputs {
  endDate = "endDate",
}

interface IUpdateProductionLineEndDate {
  id: string;
  endDate: string;
  status?: EProductionLineStatus;
}

interface IProps {
  onAccept: (data: IUpdateProductionLineEndDate) => Promise<void>;
  onCancel: () => void;
  initialData: IProductionLine;
}

const ProductionLineEditModal: React.FC<IProps> = ({
  onAccept,
  onCancel,
  initialData,
}) => {
  const { createInput, submit, inputs } = useProductionLineInputs(initialData);
  const [loading, setLoading] = useState(false);

  const submitHandler = async (updateStatus: boolean) => {
    const data = await submit();
    if (data) {
      setLoading(true);
      await onAccept({ id: initialData.id!, endDate: data.endDate ?? "", status: updateStatus ? EProductionLineStatus.UNLOADED : undefined });
      setLoading(false);
    }
  };

  const endDate = useMemo(() => inputs[EInputs.endDate].value as string, [inputs]);

  return (
    <>
      <ModalBody
        style={{ display: "flex", gap: "1rem", flexDirection: "column" }}
      >
        <InputGroup>{createInput(EInputs.endDate)}</InputGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => submitHandler(false)}
          style={{ marginRight: "0.5rem" }}
          loading={loading}
        >
          Tallenna purku pvm
        </Button>
        {dateIsPast(endDate, -1) && (
          <Button
            onClick={() => submitHandler(true)}
            style={{ marginRight: "0.5rem" }}
            loading={loading}
          >
            Kuittaa puretuksi
          </Button>
        )}
        <Button onClick={onCancel} color={EButtonColor.DANGER}>
          Peruuta
        </Button>
      </ModalFooter>
    </>
  );
};

const useProductionLineInputs = (data?: IProductionLine) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.endDate]: {
      type: EInputType.date,
      value: format(new Date(), 'yyyy-MM-dd'),
      label: "Purku pvm",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit, inputs };
};

export default ProductionLineEditModal;
