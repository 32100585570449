import { useNavigate } from "react-router-dom";
import Container from "../components/ui/Container/Container";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import WireReelsList from "../components/WireReels/WireReelsList";
import { Route } from "../routes";

const WireReelsPage: React.FC = () => {
  const navigate = useNavigate();

  const addHandler = () => {
    navigate(Route.wireReel("add"));
  }

  return (
    <>
      <PageHeading onAdd={addHandler}>Vaijerikelat</PageHeading>
      <Container>
        <WireReelsList />
      </Container>
    </>
  );
};

export default WireReelsPage;
