import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { IOption } from "../components/ui/Input/Input";
import { ErrorResponse } from "../custom-fetch";
import { QueryKey } from "../services/query-keys";
import { useVehicleService } from "../services/vehicles-service";
import IVehicle from "../shared/IVehicle";

interface IProps {
  driverOrganizationId?: string;
}

export const useVehicleOptions = (props: IProps = {}) => {
  const { driverOrganizationId } = props;
  const { fetchVehicles } = useVehicleService();

  const {
    data: vehicles,
    isPending,
    isError,
  } = useQuery<IVehicle[], ErrorResponse>({
    queryKey: [QueryKey.vehicleOptions],
    queryFn: ({ signal }) => fetchVehicles({ signal }, false),
    staleTime: Infinity,
  });

  const filteredVehicles = useMemo(() => driverOrganizationId ? [...vehicles ?? []].filter((vehicle) => vehicle.driverOrganization.id === driverOrganizationId) : vehicles ?? [], [driverOrganizationId, vehicles]);

  const options = useMemo(
    () =>
      isError
        ? []
        : filteredVehicles?.map(
            (v) => ({ value: v.id, label: `${v.name || ""}` } as IOption)
          ),
    [filteredVehicles, isError]
  );

  return { options, loading: isPending, vehicles };
};
