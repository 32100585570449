import { Navigate } from "react-router-dom";
import { useAuthContext } from "../context/auth-context";
import { Route } from "../routes";

const HomePage: React.FC = () => {
  const { user } = useAuthContext();
  return user!.role === "PRODUCTION" ? (
    <Navigate to={Route.projects} />
  ) : user!.role === "LOGISTICS" ? (
    <Navigate to={Route.transportOrders} />
  ) : user!.role === "CONCRETE" ? (
    <Navigate to={Route.weeklyProductionLines} />
  ) : (
    <Navigate to={Route.offers} />
  );
};

export default HomePage;
