import { useMemo, useState } from "react";
import Container from "../components/ui/Container/Container";
import { EInputType, IInputField } from "../components/ui/Input/Input";
import { useInputs } from "../components/ui/Input/useInputs";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import WarehouseBalanceReports from "../components/WarehouseBalanceReports/WarehouseBalanceReports";
import InputGroup from "../components/ui/InputGroup/InputGroup";

enum EInputs {
  startDate = "startDate",
  endDate = "endDate",
}

const WarehouseBalanceReportPage: React.FC = () => {
  const { createInput, inputs } = useWarehouseBalanceReportInputs();
  const startDate = useMemo(() => inputs[EInputs.startDate].value as string, [inputs]);
  const endDate = useMemo(() => inputs[EInputs.endDate].value as string, [inputs]);
  return (
    <>
      <PageHeading>Varastosaldo</PageHeading>
      <Container>
        <InputGroup>
          {createInput(EInputs.startDate)}
          {createInput(EInputs.endDate)}
        </InputGroup>
        {startDate && endDate ? (
          <WarehouseBalanceReports startDate={startDate} endDate={endDate} />
        ) : (
          <p>Valitse päivämäärä.</p>
        )}
      </Container>
    </>
  );
};

const useWarehouseBalanceReportInputs = (data?: {}) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.startDate]: {
      type: EInputType.date,
      label: "Pvm alku",
      value: "",
      rows: 4,
    },
    [EInputs.endDate]: {
      type: EInputType.date,
      label: "Pvm loppu",
      value: "",
      rows: 4,
    },
  });

  const { createInput } = useInputs({ data, inputs, setInputs });

  return { createInput, inputs };
};

export default WarehouseBalanceReportPage;
