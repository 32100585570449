import { faChevronUp, faCopy, faEdit, faImage, faQrcode, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@tanstack/react-query";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { ErrorResponse } from "../../../custom-fetch";
import { Route } from "../../../routes";
import { useElementsService } from "../../../services/elements-service";
import { FactoryOptions } from "../../../shared/FactoryOptions";
import IElement from "../../../shared/IElement";
import IProductType from "../../../shared/IProductType";
import IProvision from "../../../shared/IProvision";
import { RefHandle } from "../../../shared/RefHandle";
import { dateIsFuture, formatDate } from "../../../utils/date-utils";
import Attachments from "../../Attachments/Attachments";
import { useProvisionsEditModal } from "../../ProvisionsEditModal/useProvisionsEditModal";
import Button, { EButtonColor, EButtonSize } from "../../ui/Button/Button";
import Checkbox from "../../ui/Checkbox/Checkbox";
import { EInputType, IInputField, IOption } from "../../ui/Input/Input";
import { EInputUpdateAction } from "../../ui/Input/input-context";
import { useInputs } from "../../ui/Input/useInputs";
import { EModalId, useModalContext } from "../../ui/Modal/modal-context";
import Spinner from "../../ui/Spinner/Spinner";

export enum EInputs {
  // elementId
  length = "length",
  width = "width",
  height = "height",
  weightTons = "weightTons",
  name = "name",
  productType = "productType",
  productionStatus = "productionStatus",
  measurements = "measurements",
  provisions = "provisions",
  finishing = "finishing",
  // projectId
  phaseId = "phaseId",
  factory = "factory",
  tendonCountThick = "tendonCountThick",
  tendonCountThin = "tendonCountThin",
  productionWeek = "productionWeek",
  deliveryWeek = "deliveryWeek",
}

interface IProps {
  element: IElement;
  hidden: boolean;
  phaseOptions: IOption[];
  onDelete: (event: React.MouseEvent) => void;
  isDeleting: boolean;
  onCopy: () => void;
  isCopying: boolean;
  onUpdate: (element: IElement) => void;
  isActive?: boolean;
  onClick: () => void;
  onRemoveFocus: () => void;
  productTypesOptions?: IOption[];
  productTypesLoading: boolean;
  slabProductTypes?: IProductType[];
  colSpan?: number;
  isSelected?: boolean;
  onSelect: () => void;
  setIsProvisionsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Element: React.ForwardRefRenderFunction<RefHandle<IElement>, IProps> = ({ element, hidden, phaseOptions, onDelete, isDeleting, onCopy, isCopying, onUpdate, isActive, onClick, onRemoveFocus, productTypesOptions, productTypesLoading, slabProductTypes, colSpan, isSelected, onSelect, setIsProvisionsModalOpen }, ref) => {
  const { createInput, submit } = useElementInputs(element);
  const { updateElementMutate, isUpdatingElements } = useUpdateElements();

  const [provisions, setProvisions] = useState<IProvision[]>([]);

  useEffect(() => {
    if (element.provisions) {
      setProvisions(element.provisions);
    }
  }, [element?.provisions]);

  const openProvisionsModal = useProvisionsEditModal(element.id, provisions);
  const { closeModal } = useModalContext();

  const getData = useCallback(async () => {
    const data = await submit();
    // console.log(data);
    if (!data) return null;
    const selectedProductType = slabProductTypes?.find(productType => productType.id === data.productType);
    data.productType = selectedProductType ?? undefined;
    const selectedPhase = phaseOptions.find(phase => phase.value === data.phaseId);
    data.phaseId = selectedPhase?.value ?? undefined;
    data.phaseName = selectedPhase?.label ?? undefined;
    // console.log(data);
    data.provisions = provisions;
    return { ...element, ...data };
  }, [element, phaseOptions, slabProductTypes, provisions, submit]);

  const provisionsUpdateHandler = useCallback(async (data: IProvision[] | null) => {
    if (!data) return;
    setProvisions(data);
    const newData = await getData();
    if (newData) {
      const newElement = await updateElementMutate([{ ...newData, provisions: data }]);
      if (newElement) {
        onUpdate(newElement[0]);
      }
    }
  }, [getData, onUpdate, updateElementMutate]);

  const editProvisionsHandler = useCallback(async () => {
    setIsProvisionsModalOpen(true);
    await openProvisionsModal(provisionsUpdateHandler);
    closeModal(EModalId.PROVISIONS_EDIT);
    setIsProvisionsModalOpen(false);
  }, [closeModal, openProvisionsModal, provisionsUpdateHandler, setIsProvisionsModalOpen]);

  const saveHandler = useCallback(async () => {
    const data = await getData();
    if (data) {
      const newElement = await updateElementMutate([{ ...data }]);
      if (newElement && newElement.length === 1) {
        onUpdate(newElement[0]);
      }
    }
  }, [getData, onUpdate, updateElementMutate]);

  const copyHandler = useCallback(async () => {
    const data = await getData();
    if (data) {
      onCopy()
    }
  }, [getData, onCopy]);

  useImperativeHandle(ref, () => ({ getData }));

  const hiddenStyle = useMemo(() => hidden ? { display: "none" } : {}, [hidden]);
  const activeStyle = useMemo(() => (isActive ? { background: "#ccc" } : { background: "#fff" }), [isActive]);
  const isPlannedStyle = useMemo(() => element.isPlanned ? { background: "#94da77" } : { background: "#f3b035" }, [element.isPlanned]);

  const selectedCheckbox = useMemo(() => <Checkbox name={`checkbox-${element.id}`} value={isSelected ? [element.id] : [""]} options={[{ value: element.id }]} onChange={() => onSelect()} />, [element.id, isSelected, onSelect]);

  if (isUpdatingElements) {
    return <tr><td colSpan={colSpan}><Spinner /></td></tr>;
  }
  return (
    <>
      {isActive ? (
        <>
          <tr style={{...hiddenStyle, ...activeStyle}}>
            <td>{selectedCheckbox}</td>
            <td>{createInput(EInputs.factory)}</td>
            <td>{createInput(EInputs.phaseId, { options: phaseOptions })}</td>
            <td>
              {createInput(EInputs.productType, {
                options: productTypesOptions,
                loading: productTypesLoading,
              })}
            </td>
            <td>{createInput(EInputs.name)}</td>
            <td>{createInput(EInputs.tendonCountThick)}</td>
            <td>{createInput(EInputs.tendonCountThin)}</td>
            <td>{createInput(EInputs.length)}</td>
            <td>{createInput(EInputs.width)}</td>
            <td>{createInput(EInputs.height)}</td>
            <td>{element.weightTons}</td>
            <td>
              <div style={{ display: "flex", alignItems: "flex-start", gap: "0.25rem" }}>
                <span title={element.provisionAmountLong} style={{ alignSelf: "center" }}>{element.provisionAmountShort}</span>
              </div>
            </td>
            <td>
              <div style={{ display: "flex", alignItems: "flex-start", gap: "0.25rem" }}>
                <Button onClick={(e) => { e.stopPropagation(); editProvisionsHandler(); }} size={EButtonSize.SMALL} icon={faEdit} title="Muokkaa varauksia" style={{ minWidth: "45px" }} />
              </div>
            </td>
            <td style={{ alignContent: "flex-start" }}>
              <Button style={{ marginRight: "0.1rem" }} onClick={(e) => { e.stopPropagation(); onDelete(e); }} size={EButtonSize.SMALL} color={EButtonColor.DANGER} icon={faTrash} title="Poista" loading={isDeleting} />
              <Button onClick={(e) => { e.stopPropagation(); copyHandler(); }} size={EButtonSize.SMALL} icon={faCopy} title="Kopioi" loading={isCopying} />
              <Button style={{ marginTop: "0.25rem", marginRight: "0.1rem" }} onClick={(e) => { e.stopPropagation(); saveHandler(); }} size={EButtonSize.SMALL} icon={faSave} title="Tallenna" loading={isCopying} />
              {element.elementId && <Button style={{ marginTop: "0.25rem" }} onClick={(e) => { e.stopPropagation(); window.open(Route.elementQRCode(element.elementId), "_blank"); }} size={EButtonSize.SMALL} icon={faQrcode} title="QR" />}
            </td>
            <td>{element.hasAttachments && <FontAwesomeIcon icon={faImage} size="2x" title="Sisältää liitteen" />}</td>
            <td>{createInput(EInputs.productionWeek)}</td>
            <td>→</td>
            <td>{createInput(EInputs.deliveryWeek)}</td>
            <td>{element.productionLineId && <Link to={Route.productionLine(element.productionLineId)} onClick={(e) => e.stopPropagation()}>{element.productionLineNumber}</Link>}</td>
            <td style={dateIsFuture(element.productionLineStartDate) ? { fontStyle: "italic" } : {}}>{formatDate(element.productionLineStartDate)}</td>
            <td>{element.productionLineFactory}</td>
            <td>varasto</td>
            <td>{element.waybills && element.waybills.map(waybill => <Link style={{ margin: "0 0.25rem" }} key={waybill.id} to={Route.waybill(waybill.id)} onClick={(e) => e.stopPropagation()}>{waybill.waybillId}</Link>)}</td>
            <td style={dateIsFuture(element.waybillDeliveryDate) ? { fontStyle: "italic" } : {}}>{formatDate(element.waybillDeliveryDate)}</td>
            <td><Button onClick={(e) => { e.stopPropagation(); onRemoveFocus(); }} size={EButtonSize.X_SMALL} color={EButtonColor.DEFAULT} icon={faChevronUp} title="Sulje" /></td>
          </tr>
          {!hidden && (
            <tr>
              <td colSpan={colSpan}>
                <Attachments kind="Element" parentId={element.id} noScroll />
              </td>
            </tr>
          )}
        </>
      ) : (
        <tr onClick={onClick} style={{...hiddenStyle, ...isPlannedStyle}}>
          <td>{selectedCheckbox}</td>
          <td>{element.factory}</td>
          <td>{element.phaseName}</td>
          <td>{(element.productType as IProductType)?.name ?? "!"}</td>
          <td>{element.name ?? "!"}</td>
          <td>{element.tendonCountThick ?? "!"}</td>
          <td>{element.tendonCountThin  ?? "!"}</td>
          <td>{element.length ?? "!"}</td>
          <td>{element.width ?? "!"}</td>
          <td>{element.height ?? "!"}</td>
          <td>{element.weightTons ?? "!"}</td>
          <td>
            <div style={{ display: "flex", alignItems: "flex-start", gap: "0.25rem" }}>
              <span title={element.provisionAmountLong} style={{ alignSelf: "center" }}>{element.provisionAmountShort}</span>
            </div>
          </td>
          <td>
            <div style={{ display: "flex", alignItems: "flex-start", gap: "0.25rem" }}>
              <Button onClick={(e) => { e.stopPropagation(); editProvisionsHandler(); }} size={EButtonSize.SMALL} icon={faEdit} title="Muokkaa varauksia" style={{ minWidth: "45px" }} />
              {element.provisionAmountShort && !element.isPlanned && !element.hasAttachments && <span title="Varausten liitteet puuttuu" style={{ alignSelf: "center" }}>!</span>}
              {!element.provisionAmountShort && !element.isPlanned && element.hasAttachments && <span title="Varaukset puuttuu" style={{ alignSelf: "center" }}>!</span>}
            </div>
          </td>
          <td style={{ alignContent: "flex-start" }}>
            <Button style={{ marginRight: "0.1rem" }} onClick={(e) => { e.stopPropagation(); onDelete(e); }} size={EButtonSize.SMALL} color={EButtonColor.DANGER} icon={faTrash} title="Poista" loading={isDeleting} />
            <Button onClick={(e) => { e.stopPropagation(); onCopy(); }} size={EButtonSize.SMALL} icon={faCopy} title="Kopioi" loading={isCopying} />
            <Button style={{ marginTop: "0.25rem", marginRight: "0.1rem" }} onClick={(e) => { e.stopPropagation(); saveHandler(); }} size={EButtonSize.SMALL} icon={faSave} title="Tallenna" loading={isCopying} />
            {element.elementId && <Button style={{ marginTop: "0.25rem" }} onClick={(e) => { e.stopPropagation(); window.open(Route.elementQRCode(element.elementId), "_blank"); }} size={EButtonSize.SMALL} icon={faQrcode} title="QR" />}
          </td>
          <td>{element.hasAttachments && <FontAwesomeIcon icon={faImage} size="2x" title="Sisältää liitteen"/>}</td>
          <td>{element.productionWeek}</td>
          <td>→</td>
          <td>{element.deliveryWeek}</td>
          <td>{element.productionLineId && <Link to={Route.productionLine(element.productionLineId)} onClick={(e) => e.stopPropagation()}>{element.productionLineNumber}</Link>}</td>
          <td style={dateIsFuture(element.productionLineStartDate) ? { fontStyle: "italic" } : {}}>{formatDate(element.productionLineStartDate)}</td>
          <td>{element.productionLineFactory}</td>
          <td>varasto</td>
          <td>{element.waybills && element.waybills.map(waybill => <Link style={{ margin: "0 0.25rem" }} key={waybill.id} to={Route.waybill(waybill.id)} onClick={(e) => e.stopPropagation()}>{waybill.waybillId}</Link>)}</td>
          <td style={dateIsFuture(element.waybillDeliveryDate) ? { fontStyle: "italic" } : {}}>{formatDate(element.waybillDeliveryDate)}</td>
          <td></td>
        </tr>
      )}
    </>
  );
};

const useElementInputs = (data?: IElement) => {
  const [inputs, setInputs] = useState<IInputField>({
    // [EInputs.elementId]: {
    //   type: EInputType.text,
    //   value: "",
    // },
    [EInputs.length]: {
      type: EInputType.number,
      value: "",
      validation: {
        required: true,
        minAmount: 0,
        allowEmpty : true
      },
    },
    [EInputs.width]: {
      type: EInputType.number,
      value: "",
      validation: {
        required: true,
        minAmount: 0,
        allowEmpty : true
      },
    },
    [EInputs.height]: {
      type: EInputType.number,
      value: "",
      validation: {
        required: true,
        minAmount: 0,
        allowEmpty : true
      },
    },
    [EInputs.weightTons]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.name]: {
      type: EInputType.text,
      value: "",
    },
    [EInputs.productType]: {
      type: EInputType.reactSelect,
      options: [],
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
    },
    [EInputs.productionStatus]: {
      type: EInputType.text,
      value: "",
    },
    [EInputs.measurements]: {
      type: EInputType.text,
      value: "",
    },
    [EInputs.provisions]: {
      type: EInputType.text,
      value: "",
    },
    [EInputs.finishing]: {
      type: EInputType.text,
      value: "",
    },
    // [EInputs.projectId]: {
    //   type: EInputType.text,
    //   value: "",
    // },
    [EInputs.phaseId]: {
      type: EInputType.reactSelect,
      options: [],
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
    },
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      options: FactoryOptions,
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
    },
    [EInputs.tendonCountThick]: {
      type: EInputType.number,
      value: "",
      validation: {
        required: true,
        minAmount: 0,
        allowEmpty : true
      },
    },
    [EInputs.tendonCountThin]: {
      type: EInputType.number,
      value: "",
      validation: {
        required: true,
        minAmount: 0,
        allowEmpty : true
      },
    },
    [EInputs.productionWeek]: {
      type: EInputType.text,
      value: "",
    },
    [EInputs.deliveryWeek]: {
      type: EInputType.text,
      value: "",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs, updateAction: EInputUpdateAction.ELEMENTS });

  return { createInput, submit };
};

const useUpdateElements = () => {
  const { updateElements } = useElementsService();

  const {
    mutateAsync: updateElementMutate,
    isPending: isUpdatingElements,
    isError: isUpdateElementsError,
    error: updateElementsError,
  } = useMutation<IElement[], ErrorResponse, IElement[]>({
    mutationFn: (data) => updateElements(data),
  });

  return { updateElementMutate, isUpdatingElements, isUpdateElementsError, updateElementsError };
};

export default forwardRef(Element);
