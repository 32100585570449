import { Fragment, useEffect, useMemo, useState } from "react";
import { useProjectOptions } from "../../hooks/useProjectOptions";
import { FactoryOptions } from "../../shared/FactoryOptions";
import { EFactory } from "../../shared/IOfferRow";
import { EInputType, IInputField } from "../ui/Input/Input";
import { getInputData, updateInputHandler } from "../ui/Input/input-utils";
import { useInputs } from "../ui/Input/useInputs";
import InputGroup from "../ui/InputGroup/InputGroup";
import { EWaybillType } from "../../shared/IWaybill";

export enum EInputs {
  factory = "factory",
  projectId = "projectId",
  typeName = "typeName",
  tendonCountThick = "tendonCountThick",
  tendonCountThin = "tendonCountThin",
  name = "name",
  phaseName = "phaseName",
}

export interface IElementsSearch {
  factory?: EFactory;
  productionLineFactory?: EFactory;
  projectId?: string;
  productionLineId?: string;
  typeName?: string;
  tendonCountThick?: string;
  tendonCountThin?: string;
  isPlanned?: boolean;
  name?: string;
  phaseName?: string;
  waybillType?: EWaybillType;
  waybillId?: string;
  noProductionLineId?: boolean;
}

interface IProps {
  searchHandler: (data?: IElementsSearch | null) => void;
  factory?: string;
  enabledInputs?: EInputs[][];
}

const ElementsSearch: React.FC<IProps> = ({
  searchHandler,
  factory,
  enabledInputs = [
    [
      EInputs.factory,
      EInputs.projectId,
      EInputs.tendonCountThick,
      EInputs.tendonCountThin,
    ],
    [
      EInputs.typeName,
      EInputs.name,
      EInputs.phaseName,
    ],
  ],
}) => {
  const { createInput, inputs, setInputs } = useElementsSearchInputs();

  // const onSearch = useCallback(async () => {
  //   const data = await submit();
  //   searchHandler(data);
  // }, [searchHandler, submit]);

  useEffect(() => {
    if (factory) {
      updateInputHandler(EInputs.factory, factory, setInputs);
    }
  }, [factory, setInputs]);

  useEffect(() => {
    if (inputs) {
      const data = getInputData<IElementsSearch>(inputs);
      if (data) {
        searchHandler(data);
      }
    }
  }, [inputs, searchHandler]);

  const selectedFactory = useMemo(
    () => inputs[EInputs.factory].value as string,
    [inputs]
  );
  const { loading, options } = useProjectOptions({ factory: selectedFactory, factoryRequired: true });

  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "column" }}
        onBlur={(event) => event.stopPropagation()}
      >
        {enabledInputs.map((inputGroup, index) => (
          <InputGroup key={index}>
            {inputGroup.map(input => <Fragment key={input}>{input === EInputs.projectId ? createInput(input, { loading, options }) : createInput(input)}</Fragment>)}
          </InputGroup>
        ))}
        {/* <InputGroup>
          {createInput(EInputs.factory)}
          {createInput(EInputs.projectId, { loading, options })}
          {createInput(EInputs.tendonCountThick)}
          {createInput(EInputs.tendonCountThin)}
        </InputGroup>
        <InputGroup>
          {createInput(EInputs.typeName)}
          {createInput(EInputs.name)}
          {createInput(EInputs.phaseName)}
        </InputGroup> */}
      </div>
      {/* <Button onClick={onSearch}>Hae</Button> */}
    </>
  );
};

const useElementsSearchInputs = (data?: IElementsSearch) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      label: "Tehdas",
      options: FactoryOptions,
      value: "",
      placeholder: "Valitse tehdas",
    },
    [EInputs.projectId]: {
      type: EInputType.reactSelect,
      label: "Työmaa",
      options: [],
      value: "",
      placeholder: "Valitse työmaa",
    },
    [EInputs.typeName]: {
      type: EInputType.text,
      label: "Vaijerityyppi",
      value: "",
      placeholder: "Syötä vaijerityyppi",
    },
    [EInputs.tendonCountThick]: {
      type: EInputType.number,
      label: "P",
      value: "",
      placeholder: "Syötä määrä",
    },
    [EInputs.tendonCountThin]: {
      type: EInputType.number,
      label: "O",
      value: "",
      placeholder: "Syötä määrä",
    },
    [EInputs.name]: {
      type: EInputType.text,
      label: "Tunnus",
      value: "",
      placeholder: "Syötä tunnus",
    },
    [EInputs.phaseName]: {
      type: EInputType.text,
      label: "Kerros / lohko",
      value: "",
      placeholder: "Syötä kerros / lohko",
    },
  });

  const { createInput, submit } = useInputs({ inputs, setInputs, data });

  return { createInput, submit, inputs, setInputs };
};

export default ElementsSearch;
