import { ReactNode } from "react";
import { EButtonColor } from "../Button/Button";
import ConfirmModal from "./ConfirmModal";
import { EModalId, EModalSize, useModalContext } from "./modal-context";



interface IOptions {
  title?: string;
  acceptText?: string;
  cancelText?: string;
  cancelColor?: EButtonColor;
}


export const useConfirmModal = () => {
  const { addModal, closeModal } = useModalContext();

  const handler = (text: ReactNode, options: IOptions = { title: "Vahvista toiminto" } ): Promise<boolean> => {
    const { title, acceptText, cancelText, cancelColor } = options;
    return new Promise((resolve) => {
      addModal({
        id: EModalId.CONFIRM,
        isOpen: true,
        size: EModalSize.SMALL,
        title: title,
        onModalClose: () => {
          resolve(false);
        },
        content: (
          <ConfirmModal
            onAccept={() => {
              closeModal(EModalId.CONFIRM);
              resolve(true);
            }}
            onCancel={() => {
              closeModal(EModalId.CONFIRM);
              resolve(false);
            }}
            acceptText={acceptText}
            cancelText={cancelText}
            cancelColor={cancelColor}
          >
            {text}
          </ConfirmModal>
        ),
      });
    });
  };

  return handler;
};
