let hostname;
if(window && window.location) {
  hostname = window.location.hostname;
}
const isDevelopment = hostname && (hostname === 'localhost' || hostname.startsWith('192.168'));

let apiUrl = '';
let adminUiUrl = '';
if (isDevelopment) {
  apiUrl = `http://localhost:8080`;
  adminUiUrl = `http://localhost:3000`;
  // apiUrl = `http://192.168.234.155:8080`;
  // adminUiUrl = `http://192.168.234.155:3000`;
} else {
  // TODO: update when domains are configured
  // apiUrl = `https://api.pielisenbetoni.fi`;
  apiUrl = `https://api-dot-pielisenbetoni.appspot.com`;
  adminUiUrl = `https://laskuri.hibe.fi`;
}

export { apiUrl, adminUiUrl };