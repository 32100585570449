import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IElementDTO from '../shared/IElementDTO';
import IWaybillDTO from '../shared/IWaybillDTO';
import IWaybill, { ILocation } from '../shared/IWaybill';
import { EFactory } from '../shared/IOfferRow';
import IProductionLine from '../shared/IProductionLine';
import IToolDTO from '../shared/IToolDTO';
import { getCurrentLocation } from '../utils/location-utils';
import { Kind } from './kind';
import IAuditHistory, { AuditHistoryType } from '../shared/IAuditHistory';
import { WaybillToolType } from '../shared/ITool';
import { objectToQueryString } from '../utils/query-string-utils';
import { IFetchTools } from './tools-service';
import IClaim from '../shared/IClaim';
import IAttachment from '../shared/IAttachment';
import { IFetchAttachments, IGetAttachment } from './attachments-service';

interface IGet extends ISignal {
    id: string;
}

interface IDownloadLatestAttachment extends ISignal {
  parentId: string;
  kind: Kind;
}

export const usePublicQRCodesService = () => {
  const customFetch = useCustomFetch();

  const getElementByUuid = useCallback(async ({ signal, id }: IGet) => {
    const [element] = await customFetch<IElementDTO>("/public/qr/elements/" + id, { signal });
    return element;
  }, [customFetch]);

  const getWaybillByUuid = useCallback(async ({ signal, id }: IGet) => {
    const [waybill] = await customFetch<IWaybillDTO>("/public/qr/waybills/" + id, { signal });
    return waybill;
  }, [customFetch]);

  const getProductionLineByUuid = useCallback(async ({ signal, id }: IGet) => {
    const [productionLine] = await customFetch<IProductionLine>("/public/qr/production-lines/" + id, { signal });
    return productionLine;
  }, [customFetch]);

  const fetchProductionLineElementsByUuid = useCallback(async ({ signal, id }: IGet) => {
    const [elements] = await customFetch<IElementDTO[]>("/public/qr/production-lines-elements/" + id, { signal });
    return elements;
  }, [customFetch]);

  const saveLocationWaybill = useCallback(async (id: string, data: ILocation) => {
    await customFetch<IWaybill>("/public/qr/waybills-location/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return true;
  }, [customFetch]);

  const updateTimeWaybill = useCallback(async (id: string, data: Partial<IWaybill>) => {
    const [waybill] = await customFetch<IWaybill>("/public/qr/waybills-update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return waybill;
  }, [customFetch]);

  const locationInDistance = useCallback(async (factory: EFactory, data: ILocation) => {
    const [inDistance] = await customFetch<boolean>("/public/qr/elements-distance/" + factory, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return inDistance;
  }, [customFetch]);

  const saveLocationElement = useCallback(async (id: string, data: ILocation) => {
    await customFetch<IWaybill>("/public/qr/elements-location/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return true;
  }, [customFetch]);

  const downloadLatestAttachment = useCallback(async ({ signal, parentId, kind }: IDownloadLatestAttachment) => {
      const [attachment] = await customFetch<Blob>(
        `/public/qr/attachments/latest/${kind}/${parentId}`,
        { signal, responseType: "blob" },
      );
      return attachment;
    },
    [customFetch]
  );

  const fetchAttachments = useCallback(
    async ({ signal, kind, parentId }: IFetchAttachments) => {
      let url = `/public/qr/attachments/list/${kind}/${parentId}`;
      const [attachments] = await customFetch<IAttachment[]>(url, { signal });
      return attachments;
    },
    [customFetch]
  );

  const getAttachment = useCallback(
    async ({ signal, kind, id }: IGetAttachment) => {
      const [attachment] = await customFetch<IAttachment>(
        `/public/qr/attachments/get/${kind}/${id}`,
        { signal }
      );
      return attachment;
    },
    [customFetch]
  );

  const saveAttachment = useCallback(
    async (kind: string, parentId: string, file: File) => {
      const data = new FormData();
      data.append("file", file);
      const [attachment] = await customFetch<IAttachment>(
        `/public/qr/attachments/add/${kind}/${parentId}`,
        {
          method: "POST",
          body: data,
          multipart: true,
        }
      );
      return attachment;
    },
    [customFetch]
  );

  const deleteAttachment = useCallback(
    async (kind: string, id: string) => {
      const [attachment] = await customFetch<IAttachment>(`/public/qr/attachments/delete/${kind}/${id}`, {
        method: "DELETE",
      });
      return attachment;
    },
    [customFetch]
  );

  const downloadAttachment = useCallback(
    async ({ signal, kind, id }: IGetAttachment) => {
      const [attachment] = await customFetch<Blob>(
        `/public/qr/attachments/download/${kind}/${id}`,
        { signal, responseType: "blob" },
      );
      return attachment;
    },
    [customFetch]
  );

  const getToolByUuid = useCallback(async ({ signal, id }: IGet) => {
    let location;
    try {
      location = await getCurrentLocation();
    } catch (error) {
      console.error(error);
    }
    const [tool] = await customFetch<IToolDTO>("/public/qr/tools/" + id, {
      signal,
      method: "POST",
      body: JSON.stringify(location),
    });
    return tool;
  }, [customFetch]);

  const saveToolAuditHistory = useCallback(async (type: AuditHistoryType, id: string, data: IAuditHistory, file: File | null) => {
    const formData = new FormData();
    if (file) formData.append("file", file);
    formData.append("text", data.text);
    formData.append("date", data.date);
    formData.append("accepted", data.accepted);
    formData.append("kind", type);
    const [tool] = await customFetch<IToolDTO>("/public/qr/tools/audit-histories/add/" + id, {
      method: "PUT",
      body: formData,
      multipart: true,
    });
    return tool;
  }, [customFetch]);

  const saveWaybillTool = useCallback(async (updateProperty: WaybillToolType, id: string, toolId: string) => {
    const [success] = await customFetch<boolean>(`/public/qr/waybills/tools/add/${id}${objectToQueryString({ updateProperty, toolId })}`, {
      method: "PUT",
    });
    return success;
  }, [customFetch]);

  const fetchTools = useCallback(async ({ signal, factory, waybillDeliveryDate }: IFetchTools) => {
    let url = "/public/qr/tools/list";
    if (factory) url += `/${factory}`;
    if (waybillDeliveryDate) url += `?waybillDeliveryDate=${waybillDeliveryDate}`;
    const [tools] = await customFetch<IToolDTO[]>(url, { signal });
    return tools;
  }, [customFetch]);

  const saveClaim = useCallback(async (data: IClaim) => {
    const [claim] = await customFetch<IClaim>("/public/qr/claims/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return claim;
  }, [customFetch]);

  const getClaim = useCallback(async ({ signal, id }: IGet) => {
    const [claim] = await customFetch<IClaim>("/public/qr/claims/get/" + id, { signal });
    return claim;
  }, [customFetch]);
  
  const updateClaim = useCallback(async (id: string, data: IClaim) => {
    const [claim] = await customFetch<IClaim>("/public/qr/claims/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return claim;
  }, [customFetch]);

  return { getElementByUuid, getWaybillByUuid, getProductionLineByUuid, fetchProductionLineElementsByUuid, saveLocationWaybill, updateTimeWaybill, locationInDistance, saveLocationElement, downloadLatestAttachment, fetchAttachments, getAttachment, saveAttachment, deleteAttachment, downloadAttachment, getToolByUuid, saveToolAuditHistory, saveWaybillTool, fetchTools, saveClaim, getClaim, updateClaim };
}
