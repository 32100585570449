import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Route } from "../../routes";
import IWaybill from "../../shared/IWaybill";
import { formatDate } from "../../utils/date-utils";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Checkbox from "../ui/Checkbox/Checkbox";
import Spinner from "../ui/Spinner/Spinner";
import Table from "../ui/Table/Table";
import { useWaybillsSearch } from "./useWaybillsSearch";

interface IProps {
  projectId?: string;
  transportOrderId?: string;
  showTotalRow?: boolean;
  selectedIds?: string[];
  setSelectedIds?: React.Dispatch<React.SetStateAction<string[]>>;
  isWaybillSendModalOpen?: boolean;
}

enum EListSortBy {
  factory = "factory",
  waybillId = "waybillId",
  projectNumber = "projectNumber",
  destination = "destination",
  deliveryDate = "deliveryDate",
  deliveryTime = "deliveryTime",
  kilometers = "kilometers",
  driverOrganization = "driverOrganization",
  car = "car",
  tons = "tons",
  count = "count",
  notes = "notes",
}

const WaybillsList: React.FC<IProps> = ({ projectId, transportOrderId, showTotalRow, selectedIds = [], setSelectedIds, isWaybillSendModalOpen }) => {
  const { isError, error, isLoading, waybills } = useWaybillsSearch({ projectId, transportOrderId });
  const navigate = useNavigate();

  const [sorted, setSorted] = useState<IWaybill[]>([]);
  const [sort, setSort] = useState<EListSortBy>();
  const [sortDirection, setSortDirection] = useState<boolean>(false); // true = up, false = down
  const changeSortOrDirection = (sortBy: EListSortBy) => {
    if (sortBy === sort) {
      setSortDirection(!sortDirection);
    } else {
      setSort(sortBy);
      setSortDirection(false);
    }
  }
  const getChevron = () => {
    return <FontAwesomeIcon style={{ marginLeft: '.5rem', fontSize: '.75rem'}} icon={sortDirection ? faChevronUp : faChevronDown} />
  };

  useEffect(() => {
    const newSorted = [...sorted];
    if (sort && newSorted) {
      if (sortDirection) {
        newSorted.reverse();
        setSorted(newSorted);
        return;
      }
      newSorted.sort((a, b) => {
        const getFieldValue = (item: IWaybill, field: EListSortBy) => {
          return item[field];
        };
        const aValue = getFieldValue(a, sort) || "";
        const bValue = getFieldValue(b, sort) || "";
        if (aValue === bValue) return 0;
        return aValue > bValue ? 1 : -1;
      });
      setSorted(newSorted);
    }
    // eslint-disable-next-line
  }, [sort, sortDirection]);

  useEffect(() => {
    if (waybills) {
      setSorted(waybills);
    }
  }, [waybills])

  const openHandler = (id: string) => {
    navigate(Route.waybill(id));
  };

  const openPrintHandler = (id: string, elements: boolean, event: React.MouseEvent) => {
    event.stopPropagation();
    if (elements) {
      navigate(Route.waybillPrintElements(id));
    } else {
      navigate(Route.waybillPrint(id));
    }
  };

  const selectEnabled = useMemo(() => !!setSelectedIds, [setSelectedIds]);
  const waybillIds = useMemo(() => [...waybills ?? []].map(waybill => waybill.id), [waybills]);
  const isAllSelected = useMemo(() => selectedIds.length && waybillIds.every(id => selectedIds.includes(id)), [selectedIds, waybillIds]);

  const selectAllHandler = useCallback(() => {
    if (!selectedIds || !setSelectedIds) return;
    if (isAllSelected) {
      setSelectedIds([]);
    } else {
      setSelectedIds(waybillIds);
    }
  }, [isAllSelected, selectedIds, setSelectedIds, waybillIds]);

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (isWaybillSendModalOpen) return;
      if (e.repeat) return;
      if (!e.ctrlKey) return;
      const key = e.key.toLowerCase();
      switch (key) {
        case "a":
          if (!selectEnabled) break;
          e.preventDefault();
          selectAllHandler();
          break;
      }
    };

    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [isWaybillSendModalOpen, selectAllHandler, selectEnabled]);

  if (isError && error) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (!waybills || waybills.length === 0) {
    return <p>Ei rahtikirjoja</p>;
  }

  return (
    <>
      <Table hover>
        <thead style={{cursor: "pointer"}}>
          <tr>
            {selectEnabled && (
              <th>
                <Checkbox name={`checkbox-all`} value={isAllSelected ? [""] : selectedIds} options={[{ value: "" }]} onChange={selectAllHandler} />
              </th>
            )}
            <th onClick={() => changeSortOrDirection(EListSortBy.factory)}>Tehdas {sort === EListSortBy.factory && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.waybillId)}>Numero {sort === EListSortBy.waybillId && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.projectNumber)}>Työmaa {sort === EListSortBy.projectNumber && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.destination)}>Sijainti {sort === EListSortBy.destination && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.deliveryDate)}>Pvm {sort === EListSortBy.deliveryDate && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.deliveryTime)}>Klo {sort === EListSortBy.deliveryTime && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.kilometers)}>Km {sort === EListSortBy.kilometers && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.driverOrganization)}>Kuljettaja {sort === EListSortBy.driverOrganization && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.car)}>Auto {sort === EListSortBy.car && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.tons)}>Tonnit {sort === EListSortBy.tons && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.count)}>Kpl {sort === EListSortBy.count && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.notes)}>Huomautus {sort === EListSortBy.notes && getChevron()}</th>
            <th>Tulostus</th>
          </tr>
        </thead>
        <tbody>
          {sorted.map((waybill) => (
            <tr key={waybill.id} onClick={() => openHandler(waybill.id)}>
              {selectEnabled && (
                <td>
                  <Checkbox name={`checkbox-${waybill.id}`} value={selectedIds.includes(waybill.id) ? [waybill.id] : [""]} options={[{ value: waybill.id }]} onChange={() => setSelectedIds ? selectedIds.includes(waybill.id) ? setSelectedIds(selectedIds => selectedIds.filter(id => id !== waybill.id)) : setSelectedIds(selectedIds => [...selectedIds, waybill.id]) : undefined} />
                </td>
              )}
              <td>{waybill.factory}</td>
              <td>{waybill.waybillId}</td>
              <td>{waybill.projectNumber} {waybill.projectName}</td>
              <td>{waybill.destination}</td>
              <td>{formatDate(waybill.deliveryDate)}</td>
              <td>{waybill.deliveryTime}</td>
              <td>{waybill.kilometers}</td>
              <td>{waybill.driverOrganization?.name}</td>
              <td>{waybill.car?.name}</td>
              <td>{waybill.tons && (+waybill.tons).toFixed(2)}</td>
              <td>{waybill.count}</td>
              <td>{waybill.notes}</td>
              <td>
                <p>
                  <span style={{textDecoration: "underline"}} onClick={(event)=> openPrintHandler(waybill.uuid, false, event)}>Rahtikirja</span> {" / "}
                  <span style={{textDecoration: "underline"}} onClick={(event)=> openPrintHandler(waybill.uuid, true, event)}>Lähetyslista</span>
                </p>
              </td>
            </tr>
          ))}
        </tbody>
        {showTotalRow && waybills.length > 0 && (
          <tfoot>
            <tr>
              <td colSpan={8}></td>
              <td>Yhteensä</td>
              <td>{waybills.reduce((sum, waybill) => sum + +(waybill.tons ?? 0), 0).toFixed(2)}</td>
              <td>{waybills.reduce((sum, waybill) => sum + +(waybill.count ?? 0), 0)}</td>
              <td colSpan={2}></td>
            </tr>
          </tfoot>
        )}
      </Table>
    </>
  );
};

export default WaybillsList;
