import { useCallback } from "react";
import { queryClient } from "../../../App";
import { useProductionLinesService } from "../../../services/productionLines-service";
import { QueryKey } from "../../../services/query-keys";
import IElementDTO from "../../../shared/IElementDTO";
import IProductionLine from "../../../shared/IProductionLine";
import {
  EModalId,
  EModalSize,
  useModalContext,
} from "../../ui/Modal/modal-context";
import SlabMeasurement from "./SlabMeasurement";

export const useSlabMeasurementModal = () => {
  const { addModal, closeModal } = useModalContext();
  const closeModalHandler = useCallback(
    () => closeModal(EModalId.SLAB_MEASUREMENT),
    [closeModal]
  );
  const { updateProductionLineSlabMeasurement } = useProductionLinesService();

  const handler = (
    productionLine: IProductionLine,
    elements: IElementDTO[],
    wireTypeName: string,
    wires: number,
    image: React.ReactNode,
  ): Promise<IProductionLine | null> => {
    const title = wireTypeName +  " Laattamittaus";
    return new Promise((resolve) => {
      addModal({
        id: EModalId.SLAB_MEASUREMENT,
        isOpen: true,
        size: EModalSize.SMALL,
        title,
        backdropNotClose: true,
        content: (
          <SlabMeasurement
            onAccept={async (data) => {
              try {
                const line = await updateProductionLineSlabMeasurement(
                  productionLine.id!,
                  { ...productionLine, slabMeasurement: data }
                );
                queryClient.setQueryData(
                  [QueryKey.productionLines, line.uuid],
                  line
                );
                closeModalHandler();
                resolve(line);
              } catch (error) {
                console.error(error);
              }
            }}
            onCancel={() => {
              closeModalHandler();
              resolve(null);
            }}
            productionLine={productionLine}
            elements={elements}
            wires={wires}
            image={image}
          />
        ),
      });
    });
  };

  return handler;
};
