import { useNavigate } from "react-router-dom";
import Container from "../components/ui/Container/Container";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import { Route } from "../routes";
import ConfirmedWaybillsList from "../components/ConfirmedWaybills/ConfirmedWaybillsList";

const ConfirmedWaybillsPage: React.FC = () => {
  const navigate = useNavigate();

  const addHandler = () => {
    navigate(Route.waybill("add"));
  };

  return (
    <>
      <PageHeading onAdd={addHandler}>Kuitatut rahtikirjat</PageHeading>
      <Container>
        <ConfirmedWaybillsList />
      </Container>
    </>
  );
};

export default ConfirmedWaybillsPage;
