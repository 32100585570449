import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { ErrorResponse } from "../../custom-fetch";
import { useProductionLinesService } from "../../services/productionLines-service";
import IProductionLine from "../../shared/IProductionLine";
import IWeeklyProductionLine from "../../shared/IWeeklyProductionLine";
import ErrorsAlert, { combineErrors } from "../ErrorsAlert/ErrorsAlert";
import ProductionLinesDragAndDropTable from "../ProductionLines/ProductionLinesDragAndDropTable";
import PageHeading from "../ui/PageHeading/PageHeading";
import { useWeeklyProductionLinesDragAndDrop } from "./useWeeklyProductionLinesDragAndDrop";
import { queryClient } from "../../App";
import { QueryKey } from "../../services/query-keys";
import { useAuthContext } from "../../context/auth-context";
import { BASIC_ROLE } from "../../routes";
import { useProductionLineEditModal } from "../ProductionLineEditModal/useProductionLineEditModal";

interface IProps {
  weeklyProductionLines: IWeeklyProductionLine[];
}

const WeeklyProductionLines: React.FC<IProps> = ({
  weeklyProductionLines: initialWeeklyProductionLines,
}) => {
  const [weeklyProductionLines, setWeeklyProductionLines] = useState<
    IWeeklyProductionLine[]
  >([]);
  const { dragEndHandler, dragUpdateHandler, items } =
    useWeeklyProductionLinesDragAndDrop(
      weeklyProductionLines,
      setWeeklyProductionLines
    );

  const { reorderMutate, isReordering, isReorderError, reorderError } = useReorder();

  const updateHandler = useCallback(
    async (result: DropResult) => {
      const newItems = dragEndHandler(result);
      if (newItems) {
        try {
          await reorderMutate(newItems);
        } catch (error) {
          console.error(error);
        }
      }
    },
    [dragEndHandler, reorderMutate]
  );

  useEffect(() => {
    setWeeklyProductionLines(initialWeeklyProductionLines ?? []);
  }, [initialWeeklyProductionLines]);

  const errorMessages = combineErrors({
    isError: isReorderError,
    error: reorderError,
  });

  const { user } = useAuthContext();
  const isDragAndDropDisabled = ![...BASIC_ROLE, "PRODUCTION"].includes(user?.role ?? "");

  const openProductionLineEditModal = useProductionLineEditModal();

  const updateEndDateHandler = useCallback(async (productionLine: IProductionLine, productionWeek: string) => {
    // console.log("update end date", productionLine.id, productionWeek);

    const newProductionLine = await openProductionLineEditModal(productionLine);
    if (!newProductionLine) return;

    // console.log(productionWeek, newProductionLine);

    setWeeklyProductionLines((weeks) => [
      ...weeks.map((w) =>
        w.productionWeek === productionWeek
          ? {
              ...w,
              days: [
                ...w.days.map((day) => ({
                  ...day,
                  productionLines: [
                    ...day.productionLines.map((line) =>
                      line.id === newProductionLine.id
                        ? {
                            ...line,
                            ...newProductionLine,
                            endDate: newProductionLine.endDate || undefined,
                            endDateFin: newProductionLine.endDateFin || undefined,
                            status: newProductionLine.status || undefined,
                            statusOrder: newProductionLine.statusOrder || undefined,
                            statusLabel: newProductionLine.statusLabel || undefined,
                          }
                        : line
                    ),
                  ],
                })),
              ],
            }
          : w
      ),
    ]);

  }, [openProductionLineEditModal]);

  return (
    <>
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      <DragDropContext
        onDragUpdate={dragUpdateHandler}
        onDragEnd={updateHandler}
      >
        {/* <Droppable droppableId={"MAIN"}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              // style={getListStyle(snapshot.isDraggingOver)}
            > */}
        {items.map((week) => (
          <div key={week.productionWeek}>
            <PageHeading variant="h2" noHorizontalMargin>
              {week.productionWeek !== "TODO"
                ? `Pedit viikolle ${week.productionWeek} ${calculateWeekTotalLength(week)}`
                : `Suunnittelemattomat pedit ${calculateWeekTotalLength(week)}`}
            </PageHeading>
            {!week.days || week.days.length === 0 ? (
              <p>Ei petejä</p>
            ) : (
              week.days.map((day) => (
                <ProductionLinesDragAndDropTable
                  key={day.startDate ?? "TODO"}
                  showTotalRow
                  day={day}
                  productionWeek={week.productionWeek}
                  isReordering={isReordering}
                  isDragAndDropDisabled={isDragAndDropDisabled}
                  updateEndDateHandler={!isDragAndDropDisabled && week.productionWeek !== "TODO" ? (productionLine) => updateEndDateHandler(productionLine, week.productionWeek) : undefined}
                />
              ))
            )}
            <hr />
          </div>
        ))}
        {/* {provided.placeholder}
            </div>
          )}
        </Droppable> */}
      </DragDropContext>
    </>
  );
};

const calculateWeekTotalLength = (week: IWeeklyProductionLine) => {
  let total = 0;
  if (week?.days?.length > 0) {
    for (let i = 0; i < week.days.length; i++) {
      const day = week.days[i];
      for (let j = 0; j < day.productionLines.length; j++) {
        const productionLine = day.productionLines[j];
        total += productionLine.linealMeters ?? 0;
      }
    }
  }
  return `(yhteensä ${total.toFixed(2)} jm)`;
}

const useReorder = () => {
  const { reorderProductionLines } = useProductionLinesService();

  const {
    mutateAsync: reorderMutate,
    isPending: isReordering,
    isError: isReorderError,
    error: reorderError,
  } = useMutation<boolean, ErrorResponse, IProductionLine[]>({
    mutationFn: (data) => reorderProductionLines(data),
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: [QueryKey.elements] });
    },
  });

  return { reorderMutate, isReordering, isReorderError, reorderError };
};

export default WeeklyProductionLines;
