import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { ErrorResponse } from "../custom-fetch";
import { QueryKey } from "../services/query-keys";
import { useWaybillsService } from "../services/waybills-service";
import IWaybill from "../shared/IWaybill";

interface IProps {
  factory?: string;
  factoryRequired?: boolean;
}

export const useWaybillOptions = (props: IProps = {}) => {
  const { factory, factoryRequired } = props;
  const { fetchWaybills } = useWaybillsService();

  const {
    data: waybills,
    isLoading: isPending,
    isError,
  } = useQuery<IWaybill[], ErrorResponse>({
    queryKey: [QueryKey.waybillOptions, factory ?? ""],
    // TODO: pelkkä rahtikirja id + label haku
    queryFn: ({ signal }) => fetchWaybills({ signal, search: { factory, deliveryDateStart: "2000-01-01" } }),
    staleTime: 5000,
    enabled: factoryRequired ? !!factory : true,
  });

  const options = useMemo(() =>
      isError 
      ? [] 
      : waybills?.map(waybill => ({value: waybill.id, label: waybill.waybillId})),
    [isError, waybills]
  );

  return { options, loading: isPending, waybills };
};
