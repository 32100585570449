import React, { useMemo, useRef, useState } from "react";
import IElementDTO from "../../../shared/IElementDTO";
import IProductionLine from "../../../shared/IProductionLine";
import ISlabMeasurement from "../../../shared/ISlabMeasurement";
import Button, { EButtonColor } from "../../ui/Button/Button";
import { EInputType, IInputField } from "../../ui/Input/Input";
import { useInputs } from "../../ui/Input/useInputs";
import ModalBody from "../../ui/Modal/ModalBody/ModalBody";
import ModalFooter from "../../ui/Modal/ModalFooter/ModalFooter";
import MeasurementResult from "./MeasurementResult";
import { RefHandle } from "../../../shared/RefHandle";

interface IProps {
  onAccept: (data: ISlabMeasurement) => Promise<void>;
  onCancel: () => void;
  productionLine: IProductionLine;
  elements: IElementDTO[];
  wires: number;
  image: React.ReactNode;
}

enum EInputs {
  elementId = "elementId",
  maxHeight = "maxHeight",
  minHeight = "minHeight",
}

const SlabMeasurement: React.FC<IProps> = ({
  onAccept,
  onCancel,
  productionLine,
  elements,
  wires,
  image,
}) => {
  const { createInput, submit } = useSlabMeasurementInputs(
    productionLine.slabMeasurement
  );

  const [loading, setLoading] = useState(false);
  const measurementResultRef = useRef<RefHandle<string>[]>([]);

  const submitHandler = async () => {
    const data = await submit();
    if (data) {
      const rows = measurementResultRef.current;
      const newRows: string[] = [];
      if (rows?.length > 0)  {
        for (let i = 0; i < rows.length; i++) {
          const row = rows[i];
          if (row) {
            const rowData = await row.getData();
            newRows.push(rowData || "0");
          }
        }
      }
      data.measurementResults = newRows;
      setLoading(true);
      await onAccept(data);
      setLoading(false);
    }
  };

  const elementOptions = useMemo(
    () =>
      elements.map((element) => ({
        value: element.id,
        label: `${element.position}. ${element.name}`,
      })),
    [elements]
  );

  const measurementResults = useMemo(
    () =>
      productionLine.slabMeasurement?.measurementResults
        ? productionLine.slabMeasurement?.measurementResults.map(
            (measurementResult, index) => (
              <MeasurementResult
                key={index}
                number={index + 1}
                measurementResult={{ height: measurementResult }}
                ref={(rowHandle) =>
                  (measurementResultRef.current[index] = rowHandle!)
                }
              />
            )
          )
        : Array.from(Array(wires)).map((_, index) => (
            <MeasurementResult
              key={index}
              number={index + 1}
              ref={(rowHandle) =>
                (measurementResultRef.current[index] = rowHandle!)
              }
            />
          )),
    [productionLine.slabMeasurement?.measurementResults, wires]
  );

  return (
    <>
      <ModalBody>
        {image}
        {createInput(EInputs.elementId, { options: elementOptions })}
        {createInput(EInputs.maxHeight)}
        {createInput(EInputs.minHeight)}
        <hr />
        <h3>Punokset</h3>
        {measurementResults}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={submitHandler}
          style={{ marginRight: "0.5rem" }}
          loading={loading}
        >
          Tallenna
        </Button>
        <Button onClick={onCancel} color={EButtonColor.DANGER}>
          Peruuta
        </Button>
      </ModalFooter>
    </>
  );
};

const useSlabMeasurementInputs = (data?: ISlabMeasurement) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.elementId]: {
      type: EInputType.reactSelect,
      options: [],
      label: "Mitattu laatta",
      placeholder: "Mitattu laatta",
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      validation: {
        required: true,
      },
    },
    [EInputs.maxHeight]: {
      type: EInputType.number,
      label: "Max korkeus (mm)",
      value: "",
    },
    [EInputs.minHeight]: {
      type: EInputType.number,
      label: "Min korkeus (mm)",
      value: "",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit };
};

export default SlabMeasurement;
