import IElement from "../shared/IElement";
import { IProjectPhase } from "../shared/IProject";

export const calculateTotalWeight = (elements: IElement[]) => {
  let total = 0;
  let maxLength = 0;
  if (elements?.length > 0) {
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      total += +(element.weightTons ?? 0);
      if (+element.length > maxLength) maxLength = +element.length;
    }
  }
  return `(yhteensä ${total.toFixed(2)} ton, pisin laatta ${maxLength} mm)`;
};

export const findElement = (id: string, elements: IElement[]) => {
  return elements.find((element) => element.id === id);
};

export const isElementHidden = (element: IElement, phase?: IProjectPhase, isPlannedHidden?: boolean, isNotPlannedHidden?: boolean) => {
    if (phase?.phaseId && phase.phaseId !== element.phaseId) return true;
    if (element.isPlanned && isPlannedHidden) return true;
    if (!element.isPlanned && isNotPlannedHidden) return true;
    return false;
};
