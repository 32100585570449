import { CSSProperties } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/auth-context";
import { ERoute, Route, routes } from "../../routes";
import { useDialog } from "../ui/Modal/useDialog";

export enum EMenuItem {
  OFFERS,
  TABLES_MENU,
  USERS_MENU,
  WIRES_MENU,
  USERS,
  LOGOUT,
  CUSTOMERS,
  STEEL_PARTS,
  ENGINEERS,
  INSULATIONS,
  MESHES,
  STEELS,
  CONCRETE_TYPES,
  TRANSPORTATION_COSTS,
  SLAB_TYPES,
  OFFER_TERMS,
  FACTORIES,
  REPORTS,
  HELP,
  VEHICLES,
  DRIVER_ORGANIZATIONS,
  TOOLS,
}

export interface IMenuItem {
  id?: EMenuItem;
  text: string;
  link?: string;
  to?: string;
  styles?: CSSProperties;
  menuItems?: IMenuItem[];
  isVisible: boolean;
  onClick?: () => void;
}

export const useMenuItems = () => {
  const navigate = useNavigate();
  const openDialog = useDialog();
  const { user, logout } = useAuthContext();
  let menuItems: IMenuItem[] = [];

  const isVisible = (routesArr: ERoute[]) => {
    return routesArr.some(route => {
      const roles = routes[route].roles;
      return roles.length === 0 || !!(user && roles.includes(user.role));
    });
  };

  menuItems = [
    {
      id: EMenuItem.OFFERS,
      text: "Tarjoukset",
      to: Route.offers,
      isVisible: isVisible([ERoute.offers]),
    },
    {
      id: EMenuItem.USERS_MENU,
      text: "Käyttäjät",
      isVisible: isVisible([ERoute.users, ERoute.settings]),
      // link: `${adminUiUrl}/index.cfm?template=logs.cfm`,
      menuItems: [
        {
          text: "Käyttäjät",
          to: ERoute.users,
          isVisible: isVisible([ERoute.users])
        },
        // {
        //   text: "Asetukset",
        //   to: ERoute.settings,
        //   isVisible: isVisible([ERoute.settings])
        // },
        {
          id: EMenuItem.CUSTOMERS,
          text: "Asiakkaat",
          to: ERoute.customers,
          isVisible: isVisible([ERoute.customers]),
        },
        {
          id: EMenuItem.ENGINEERS,
          text: "Suunnittelijat",
          to: ERoute.engineers,
          isVisible: isVisible([ERoute.engineers]),
        },
      ],
    },
    {
      id: EMenuItem.TABLES_MENU,
      text: "Taulukot",
      isVisible: isVisible([ERoute.steelParts, ERoute.insulations, ERoute.meshes, ERoute.steels, ERoute.concreteTypes, ERoute.transportationCosts, ERoute.slabTypes, ERoute.productTypes, ERoute.productGroups, ERoute.offerTerms, ERoute.factories, ERoute.vehicles, ERoute.driverOrganizations, ERoute.tools]),
      menuItems: [
        {
          id: EMenuItem.STEEL_PARTS,
          text: "Teräsosat",
          to: ERoute.steelParts,
          isVisible: isVisible([ERoute.steelParts]),
        },
        {
          id: EMenuItem.INSULATIONS,
          text: "Eristeet",
          to: ERoute.insulations,
          isVisible: isVisible([ERoute.insulations]),
        },
        {
          id: EMenuItem.MESHES,
          text: "Verkot",
          to: ERoute.meshes,
          isVisible: isVisible([ERoute.meshes]),
        },
        {
          id: EMenuItem.STEELS,
          text: "Teräkset",
          to: ERoute.steels,
          isVisible: isVisible([ERoute.steels]),
        },
        {
          id: EMenuItem.CONCRETE_TYPES,
          text: "Massat",
          to: ERoute.concreteTypes,
          isVisible: isVisible([ERoute.concreteTypes]),
        },
        {
          id: EMenuItem.TRANSPORTATION_COSTS,
          text: "Kuljetus",
          to: ERoute.transportationCosts,
          isVisible: isVisible([ERoute.transportationCosts]),
        },
        {
          id: EMenuItem.SLAB_TYPES,
          text: "Laatat",
          to: ERoute.slabTypes,
          isVisible: isVisible([ERoute.slabTypes]),
        },
        {
          text: "Tuotteet",
          to: ERoute.productTypes,
          isVisible: isVisible([ERoute.productTypes]),
        },
        {
          text: "Tuoteryhmät",
          to: ERoute.productGroups,
          isVisible: isVisible([ERoute.productGroups]),
          
        },
        {
          id: EMenuItem.OFFER_TERMS,
          text: "Sopimustekstit",
          to: ERoute.offerTerms,
          isVisible: isVisible([ERoute.offerTerms]),
        },
        {
          id: EMenuItem.FACTORIES,
          text: "Tehtaat",
          to: ERoute.factories,
          isVisible: isVisible([ERoute.factories]),
        },
        {
          id: EMenuItem.DRIVER_ORGANIZATIONS,
          text: "Kuljetusliikkeet",
          to: ERoute.driverOrganizations,
          isVisible: isVisible([ERoute.driverOrganizations]),
        },
        {
          id: EMenuItem.VEHICLES,
          text: "Autot",
          to: ERoute.vehicles,
          isVisible: isVisible([ERoute.vehicles]),
        },
        {
          id: EMenuItem.TOOLS,
          text: "Nostovälineet",
          to: ERoute.tools,
          isVisible: isVisible([ERoute.tools]),
        },
      ],
    },
    {
      text: "Työmaat",
      to: ERoute.projects,
      isVisible: isVisible([ERoute.projects]),
    },
    {
      id: EMenuItem.WIRES_MENU,
      text: "Vaijerit",
      isVisible: isVisible([ERoute.wireTypes]),
      menuItems: [
        {
          text: "Vaijerityypit",
          to: ERoute.wireTypes,
          isVisible: isVisible([ERoute.wireTypes]),
        },
        {
          text: "Vaijerikelat",
          to: ERoute.wireReels,
          isVisible: isVisible([ERoute.wireReels]),
        },
      ],
    },
    {
      text: "Reklamaatiot / poikkeamat",
      to: ERoute.claims,
      isVisible: isVisible([ERoute.claims]),
    },
    {
      text: "Kuljetustilaukset",
      to: ERoute.transportOrders,
      isVisible: isVisible([ERoute.transportOrders]),
    },
    {
      text: "Rahtikirjat",
      to: ERoute.waybills,
      isVisible: isVisible([ERoute.waybills]),
    },
    {
      text: "Kuitatut rahtikirjat",
      to: ERoute.confirmedWaybills,
      isVisible: isVisible([ERoute.confirmedWaybills]),
    },
    {
      text: "Suunnittelu viikottain",
      to: ERoute.weeklyProduction,
      isVisible: isVisible([ERoute.weeklyProduction]),
    },
    {
      text: "Pedit",
      to: ERoute.productionLines,
      isVisible: isVisible([ERoute.productionLines]),
    },
    {
      text: "Pedit viikolle",
      to: ERoute.weeklyProductionLines,
      isVisible: isVisible([ERoute.weeklyProductionLines]),
    },
    {
      id: EMenuItem.REPORTS,
      text: "Raportit",
      // to: ERoute.reports,
      isVisible: isVisible([ERoute.reports]),
      menuItems: [
        {
          text: "Looker Studio -raportit",
          to: ERoute.reports,
          isVisible: isVisible([ERoute.reports]),
        },
        {
          text: "Varastosaldo",
          to: ERoute.warehouseBalanceReport,
          isVisible: isVisible([ERoute.warehouseBalanceReport]),
        },
      ],
    },
    {
      id: EMenuItem.HELP,
      text: "?",
      onClick: () => openDialog("Pikanäppäimet", (
        <>
          <p>CTRL + yleensä lisää rivin (Tuoterivin, elementille varauksen)</p>
          <p>CTRL S yleensä tallentaa</p>
          <p>CTRL - yleensä sulkee avatun rivin (tuoterivi, elementin tiedot työmaalla)</p>
          <p>CTRL A  valitsee kaikki / poistaa valinnan (elementtien valinnassa)</p>
        </>
      )),
      isVisible: isVisible([ERoute.home, ERoute.projects]),
    }
  ];
  
  if (user != null) {
    menuItems.push({
      id: EMenuItem.LOGOUT,
      text: "Kirjaudu ulos",
      styles: { marginLeft: "auto" },
      isVisible: true,
      onClick: () => {
        logout();
        navigate('/auth');
      }
    });
  }

  

  return filterMenuItems(menuItems) as IMenuItem[];
};

const filterMenuItems = (menuItems?: IMenuItem[]) => {
  if (!menuItems) return undefined;
  const newMenuItems = [];
  for (let menuItem of menuItems) {
    if (menuItem.isVisible) {
      menuItem.menuItems = filterMenuItems(menuItem.menuItems);
      newMenuItems.push(menuItem);
    }
  }
  return newMenuItems;
};
