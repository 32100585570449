import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IVehicle from '../shared/IVehicle';

interface IGetVehicle extends ISignal {
  id: string;
}

export const useVehicleService = () => {
  const customFetch = useCustomFetch();

  const fetchVehicles = useCallback(async ({ signal }: ISignal, archived?: boolean) => {
    const [vehicles] = await customFetch<IVehicle[]>(`/api/vehicles/list${archived !== undefined ? `?archived=${archived}`:""}`, { signal });
    return vehicles;
  }, [customFetch]);
  
  const getVehicle = useCallback(async ({ signal, id }: IGetVehicle) => {
    const [vehicle] = await customFetch<IVehicle>("/api/vehicles/get/" + id, { signal });
    return vehicle;
  }, [customFetch]);
  
  const saveVehicle = useCallback(async (data: IVehicle) => {
    const [vehicle] = await customFetch<IVehicle>("/api/vehicles/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return vehicle;
  }, [customFetch]);
  
  const updateVehicle = useCallback(async (id: string, data: IVehicle) => {
    const [vehicle] = await customFetch<IVehicle>("/api/vehicles/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return vehicle;
  }, [customFetch]);
  
  const deleteVehicle = useCallback(async (id: string) => {
    await customFetch<IVehicle>("/api/vehicles/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);

  const archivedUpdateVehicle = useCallback(async (id: string) => {
    const [vehicle] = await customFetch<IVehicle>("/api/vehicles/update-archived/" + id, {
      method: "PUT",
    });
    return vehicle;
  }, [customFetch]);
  
  const archivedDeleteVehicles = useCallback(async () => {
    await customFetch<IVehicle>("/api/vehicles/delete-archived", {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);
  
  return { fetchVehicles, getVehicle, saveVehicle, updateVehicle, deleteVehicle, archivedUpdateVehicle, archivedDeleteVehicles };
}