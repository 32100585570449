import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import Container from "../components/ui/Container/Container";
import Spinner from "../components/ui/Spinner/Spinner";
import { ErrorResponse } from "../custom-fetch";
import { QueryKey } from "../services/query-keys";
import { useWireReelService } from "../services/wireReels-service";
import IWireReel from "../shared/IWireReel";
import WireReelEdit from "../components/WireReels/WireReelEdit";

type Params = {
  id: string;
};

const WireReelPage: React.FC = () => {
  const { id } = useParams<Params>();
  const { getWireReel } = useWireReelService();
  
  const {
    data: wireReel,
    isLoading,
    isError,
    error,
  } = useQuery<IWireReel, ErrorResponse>({
    queryKey: [QueryKey.wireReels, id],
    queryFn: ({ signal }) => getWireReel({ signal, id: id! }),
    enabled: id !== 'add',
  });

  return (
    <>
      <h1 style={{ margin: "1rem" }}>Vaijerikela</h1>
      <Container>
        {isError ? (
          <ErrorsAlert errors={error.messages} />
        ) : isLoading ? (
          <Spinner />
        ) : (
          <WireReelEdit id={id!} wireReel={wireReel} />
        )}
      </Container>
    </>
  );
};

export default WireReelPage;
