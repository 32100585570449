import { ILocation } from "../shared/IWaybill";

export const getCurrentLocation = () => {
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  return new Promise<ILocation>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          accuracy: position.coords.accuracy,
        });
      },
      (error) => {
        reject(error);
      },
      options
    );
  });
};

export const openGoogleMaps = (latitude?: number, longitude?: number) => {
  if (!latitude || !longitude) return;
  const url = `http://maps.google.com/?q=${latitude.toString()},${longitude.toString()}&ll=${latitude.toString()},${longitude.toString()}&z=14`
  window.open(url, "_blank");
};
