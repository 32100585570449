import IElement from "./IElement";

// TypeScript hack, enums cannot have both string and numeric values
export const ElementSlots = { "TODO": "TODO", "A": "A", "B": "B", "C": "C", "D": "D", "E": "E", "F": "F", "G": "G", "H": "H", "I": "I", "J": "J", "1": "1", "2": "2", "3": "3", "4": "4", "5": "5" } as const;
export type ElementSlot = keyof typeof ElementSlots;
export const ElementSlotOptions: ElementSlot[] = ["TODO", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "1", "2", "3", "4", "5"];

interface IElementUnloading {
  slot: ElementSlot;
  elements: IElement[];
}

export default IElementUnloading;
