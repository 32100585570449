import { DraggableElement } from "../hooks/useDragAndDrop";
import { EFactory } from "./IOfferRow";
import IProject from "./IProject";
import ISlabMeasurement from "./ISlabMeasurement";
import IWaybillRow from "./IWaybillRow";
import IWireType from "./IWireType";

export enum EProductionLineStatus {
  DRAFT = "DRAFT",
  CONTENT_PLANNED = "CONTENT_PLANNED",
  UNLOADING_PLANNED = "UNLOADING_PLANNED",
  CASTING_PLANNED = "CASTING_PLANNED",
  STICKERS_PRINTED = "STICKERS_PRINTED",
  DONE = "DONE",
  UNLOADED = "UNLOADED",
}

interface IProductionLine extends DraggableElement {
  created?: string;
  id?: string;
  uuid?: string;
  productionLineLength: string; // mm, production line max length
  startDate?: string;
  startDateFin?: string;
  endDate?: string;
  endDateFin?: string;
  status: EProductionLineStatus;
  //   items: IProductionItem[]; // List of batch items
  typeName: string;
  height: string;
  productionLineNumber: string;
  projectNumbers: string;
  projectNumberPhases: string;
  projectNumberPhasesName: string;
  factory?: EFactory;
  linealMeters: number;
  brushed: boolean;
  count: number;
  productionWeek?: string;
  projects: IProject[];
  wireType?: string | IWireType;
  isPlanned: boolean;
  notes: string;
  hasAttachments: boolean;
  unloadingRows?: IWaybillRow[];
  warehouseRows?: IWaybillRow[];
  castingRows?: IWaybillRow[];
  statusLabel?: string;
  statusOrder?: number;
  firstDeliveryWeek?: string;
  lastDeliveryWeek?: string;
  weightTons?: string;
  deliveryWeeks?: string;
  concreteVolume?: number;
  concreteTypeNames?: string;
  liftingLoopCount?: number;
  slabMeasurement?: ISlabMeasurement;
  customerDisruption?: boolean;

  prevProductionLineId?: string;
  nextProductionLineId?: string;
}

// export interface IProductionItem {
//   itemId: string;
//   description: string;
//   position: string;
//   startMeasure: string; // mm, rullamitasta juokseva alku
//   endMeasure: string;
//   element: IElement;
// }

export default IProductionLine;
