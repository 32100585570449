
import * as dateFns from 'date-fns';

export const dateIsFuture = (date?: string, daysToAdd?: number) => {
  if (!date) return false;
  return !dateIsPast(date, daysToAdd);
}

export const dateIsPast = (date?: string, daysToAdd?: number) => {
  if (!date) return false;
  return dateFns.differenceInDays(dateFns.addDays(dateFns.parseISO(date), daysToAdd ?? 0), dateFns.startOfDay(new Date())) < 0;
}

export const formatDate = (date?: string) => {
  if (!date) return "";
  return dateFns.format(dateFns.parseISO(date), 'dd.MM.yyyy');
}

export const formatDateTime = (date?: string) => {
  if (!date) return "";
  return dateFns.format(dateFns.parseISO(date), 'dd.MM.yyyy HH:mm');
}

export const formatDateRange = (startDate: string, endDate: string) => {
  const sDate = dateFns.parseISO(startDate);
  const eDate = dateFns.parseISO(endDate);
  if (dateFns.isSameDay(sDate, eDate)) {
    return dateFns.format(sDate, 'dd.MM.yyyy');
  } else if (dateFns.isSameYear(sDate, eDate)) {
    return dateFns.format(sDate, 'dd.MM') + " - " + dateFns.format(eDate, 'dd.MM.yyyy');
  } else {
    const dateArray = [];
    if (dateFns.isValid(sDate)) {
      dateArray.push(dateFns.format(sDate, 'dd.MM.yyyy'));
    }
    if (dateFns.isValid(eDate)) {
      dateArray.push(dateFns.format(eDate, 'dd.MM.yyyy'));
    }
    return dateArray.join(' - ');
  }
}

export const getCurrentTime = () => {
  return dateFns.format(new Date(), 'HH:mm')
}

export const getCurrentDay = () => {
  return dateFns.format(new Date(), 'dd.MM.yyyy')
}

export const getCurrentDayString = (date?: string) => {
  if (!date) return "";
  const finnishDayName = dateFns.parseISO(date).toLocaleDateString('fi-FI', { weekday: 'long' });
  const capitalizedDayName = finnishDayName.charAt(0).toUpperCase() + finnishDayName.slice(1);
  return capitalizedDayName;
}

export const dateIsAfterDate = (date1?: string, date2?: string, daysToAdd?: number) => {
  if (!date1 || !date2) return false;
  return dateFns.differenceInDays(dateFns.addDays(dateFns.parseISO(date1), daysToAdd ?? 0), dateFns.parseISO(date2)) > 0;
}

export const isTimeAfter = (time1?: string, time2?: string) => {
  if (!time1 || !time2) return false;
  const time1Date = dateFns.parse(time1, 'HH:mm', new Date());
  const time2Date = dateFns.parse(time2, 'HH:mm', new Date());
  return dateFns.isAfter(time1Date, time2Date);
}