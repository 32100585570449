export class QueryKey {
  static users = '/users'
  static customers = '/customers'
  static engineers = '/engineers'
  static steelParts = '/steel-parts'
  static offers = '/offers'
  static organizations = '/organizations'
  static insulations = '/insulations'
  static meshes = '/meshes'
  static steels = '/steels'
  static concreteTypes = '/concrete-types'
  static transportationCosts = '/transportation-costs'
  static slabTypes = '/slab-types'
  static productGroups = '/product-groups'
  static productTypes = '/product-types'
  static offerTerms = '/offer-terms'
  static latest5OfferNumbers = '/latest-5-offer-numbers'
  static instalmentTables = '/instalment-tables'
  static customerOffers = '/customer-offers'
  static factories = '/factories'
  static claims = '/claims'
  static projects = '/projects'
  static waybills = '/waybills'
  static confirmedWaybills = '/confirmed-waybills'
  static weeklyProductionRows = '/weeklyProductionRows'
  static weeklyProductionRowsByWeek = '/weeklyProductionRowsByWeek'
  static weeklyProductionRowsByProject = '/weeklyProductionRowsByProject'
  static additionalInvoices = '/additionalInvoices'
  static elements = '/elements'
  static productionLines = '/production-lines'
  static weeklyProductionLines = '/weekly-production-lines'
  static attachments = '/attachments'
  static latestAttachments = '/latest-attachments'
  static elementsStatus = '/elements-status'
  static wireTypes = '/wire-types'
  static transportOrders = '/transport-orders'
  static warehouseBalanceReports = '/warehouseBalanceReports'
  static vehicles = '/vehicles'
  static driverOrganizations = '/driverOrganizations'
  static tools = '/tools'
  static wireReels = '/wire-reels'

  private static options = '/options'
  static steelPartOptions = this.options + this.steelParts
  static insulationOptions = this.options + this.insulations
  static meshOptions = this.options + this.meshes
  static steelOptions = this.options + this.steels
  static concreteTypeOptions = this.options + this.concreteTypes
  static slabTypeOptions = this.options + this.slabTypes
  static productGroupOptions = this.options + this.productGroups
  static productTypeOptions = this.options + this.productTypes
  static slabProductTypeOptions = this.options + this.productTypes + '/slabs'
  static offerOptions = this.options + this.offers
  static projectOptions = this.options + this.projects
  static elementOptions = this.options + this.elements
  static wireTypeOptions = this.options + this.wireTypes
  static transportOrderOptions = this.options + this.transportOrders
  static vehicleOptions = this.options + this.vehicles
  static driverOrganizationOptions = this.options + this.driverOrganizations
  static toolOptions = this.options + this.tools
  static waybillOptions = this.options + this.waybills
  static wireReelOptions = this.options + this.wireReels

  private static public = '/public'
  static publicWaybills = this.public + this.waybills;
  static publicElements = this.public + this.elements;
  static publicTools = this.public + this.tools;
}
