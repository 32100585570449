import IElement from "../../shared/IElement";
import IElementDTO from "../../shared/IElementDTO";
import IWaybillRow from "../../shared/IWaybillRow";
import { findElement } from "../../utils/element-utils";
import PageHeading from "../ui/PageHeading/PageHeading";
import Table from "../ui/Table/Table";

interface IProps {
  rows: IWaybillRow[];
  elements: IElement[] | IElementDTO[];
  title?: string;
  removeGap?: boolean;
}

const ProductionLineUnloadingTable: React.FC<IProps> = ({
  rows,
  elements,
  title,
  removeGap,
}) => {
  return (
    <>
      <Table removeGap={removeGap}>
        {title && (
          <caption>
            <PageHeading variant="h3" noHorizontalMargin>
              {title}
            </PageHeading>
          </caption>
        )}
        <thead>
          <tr>
            <th style={{ width: "80px" }}>#</th>
            <th style={{ width: "125px" }}>Tunnus</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => {
            const element = row.elementId
              ? findElement(row.elementId, elements as IElement[])
              : undefined;
            return (
              <tr key={row.id}>
                <td style={{ width: "80px" }}>{row.order}</td>
                <td style={{ width: "125px" }}>{element?.name}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default ProductionLineUnloadingTable;
