import { createContext, ReactNode, useContext } from "react";
import IElementUnloading, { ElementSlot } from "../../shared/IElementUnloading";
import { DragUpdate, DropResult } from "react-beautiful-dnd";
import IElement from "../../shared/IElement";

interface IElementUnloadingContext {
  items: IElementUnloading[];
  // setItems: React.Dispatch<React.SetStateAction<IElementUnloading[]>>;
  dragUpdateHandler: (result: DragUpdate) => void;
  updateHandler: (result: DropResult) => Promise<void>;
  isDropDisabled?: boolean;
  isDragDisabled?: boolean;
  isWaybill?: boolean;
  waybillDeliveryDate?: string;
  addElementsHandler: (slot: ElementSlot, selectedElements: IElement[]) => void;
  removeElementHandler: (slot: ElementSlot, element: IElement) => void;
  addSpacerHandler?: (slot: ElementSlot, element: IElement) => void;
}

const ElementUnloadingContext = createContext<IElementUnloadingContext>({
  items: [],
  // setItems: () => Promise.resolve(),
  dragUpdateHandler: () => {},
  updateHandler: () => Promise.reject(),
  isDropDisabled: false,
  isDragDisabled: false,
  isWaybill: false,
  waybillDeliveryDate: undefined,
  addElementsHandler: () => {},
  removeElementHandler: () => {},
  addSpacerHandler: undefined,
});

export const useElementUnloadingContext = () => useContext(ElementUnloadingContext);

const ElementUnloadingContextProvider: React.FC<{
  value: IElementUnloadingContext;
  children: ReactNode;
}> = ({ value, children }) => (
  <ElementUnloadingContext.Provider value={value}>
    {children}
  </ElementUnloadingContext.Provider>
);

export default ElementUnloadingContextProvider;
