import { useState } from "react";
import IPublicProjectDTO from "../../shared/IPublicProjectDTO";
import IWaybillDTO from "../../shared/IWaybillDTO";
import ElementStatusTable from "../Projects/Elements/ElementStatusTable";
import CustomerInstalmentTable from "./CustomerInstalmentTable";
import CustomerTools from "./CustomerTools";
import CustomerWaybill from "./CustomerWaybill";
import CustomerWaybills from "./CustomerWaybills";
import IToolDTO from "../../shared/IToolDTO";
import ToolQRCode from "../Tools/ToolsQRCodes/ToolQRCode";

interface IProps {
  project: IPublicProjectDTO;
}

const CustomerProject: React.FC<IProps> = ({ project }) => {
  const [selectedWaybill, setSelectedWaybill] = useState<IWaybillDTO | null>(null);
  const [selectedTool, setSelectedTool] = useState<IToolDTO | null>(null);

  if (selectedWaybill) {
    return <CustomerWaybill waybill={selectedWaybill} onReturn={() => setSelectedWaybill(null)} />;
  }
  if (selectedTool) {
    return <ToolQRCode tool={selectedTool} onReturn={() => setSelectedTool(null)} />
  }
  return (
    <>
      {/* <p>Käyttäjä: {project.user?.user?.name}</p> */}
      {/* TODO: Odottaa kuittausta (odotuksia tai nostovälineitä rahtikirjoilla) */}
      {project.waybills && (
        <>
          <h3>Rahtikirjat</h3>
          <CustomerWaybills
            waybills={project.waybills}
            onClick={(waybill) => setSelectedWaybill(waybill)}
          />
        </>
      )}
      {project.tools && (
        <>
          <h3>Nostovälineet työmaalla</h3>
          <CustomerTools tools={project.tools} onClick={(tool) => setSelectedTool(tool)} />
        </>
      )}
      {project.projectStatus && (
        <>
          <hr/>
          <h3>Projektin tilanne</h3>
          <ElementStatusTable elements={project.projectStatus} />
        </>
      )}
      {project.instalmentTable && (
        <>
          <h3>Laskutus</h3>
          <CustomerInstalmentTable
            instalmentRows={project.instalmentTable?.instalmentRows ?? []}
          />
        </>
      )}
    </>
  );
};

export default CustomerProject;
