import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { IOption } from "../components/ui/Input/Input";
import { ErrorResponse } from "../custom-fetch";
import { usePublicQRCodesService } from "../services/publicQRCodes-service";
import { QueryKey } from "../services/query-keys";
import IToolDTO from "../shared/IToolDTO";

export const useToolOptions = (type: string, factory?: string, waybillDeliveryDate?: string) => {
  const { fetchTools } = usePublicQRCodesService();

  const {
    data: tools,
    isPending,
    isError,
  } = useQuery<IToolDTO[], ErrorResponse>({
    queryKey: [QueryKey.toolOptions, type, factory ?? "", waybillDeliveryDate ?? ""],
    queryFn: ({ signal }) => fetchTools({ signal, factory, waybillDeliveryDate }),
    staleTime: Infinity,
  });

  const options = useMemo(
    () =>
      isError
        ? []
        // TODO: haetaan vain tarvittavat kentät
        : tools?.map((tool) => ({ value: tool.id, label: tool.label } as IOption)),
    [tools, isError]
  );

  return { options, loading: isPending, tools };
};
