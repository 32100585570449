import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import ErrorsAlert, {
  combineErrors,
} from "../components/ErrorsAlert/ErrorsAlert";
import ProductionLineEdit from "../components/ProductionLines/ProductionLineEdit/ProductionLineEdit";
import ProductionLineEditLogistics from "../components/ProductionLines/ProductionLineEdit/ProductionLineEditLogistics";
import Spinner from "../components/ui/Spinner/Spinner";
import { useAuthContext } from "../context/auth-context";
import { ErrorResponse } from "../custom-fetch";
import { useElementsService } from "../services/elements-service";
import { useProductionLinesService } from "../services/productionLines-service";
import { QueryKey } from "../services/query-keys";
import IElement from "../shared/IElement";
import IProductionLine from "../shared/IProductionLine";

const ProductionLinePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const isEdit = useMemo(() => id !== "add", [id]);
  
  const [searchParams] = useSearchParams();
  const factory = useMemo(() => searchParams.get("factory"), [searchParams]);

  const { productionLine, isPending, isError, error } = useGetProductionLine(id!, isEdit);
  const { elements, isElementsPending, isElementsError, elementsError } = useFetchElements(id!, isEdit, isPending);

  const errorMessages = combineErrors(
    { isError, error },
    { isError: isElementsError, error: elementsError },
  );

  const { user } = useAuthContext();

  if (errorMessages.length > 0) {
    return <ErrorsAlert errors={errorMessages} />;
  }

  if (isEdit && (isPending || isElementsPending)) {
    return <Spinner />;
  }

  return (
    <>
      {user?.role === "LOGISTICS" ? (
        <ProductionLineEditLogistics
          key={id!}
          id={id!}
          productionLine={productionLine!}
          productionLineElements={elements ?? []}
        />
      ) : (
        <ProductionLineEdit
          key={id!}
          id={id!}
          isEdit={isEdit}
          productionLine={productionLine}
          productionLineElements={elements ?? []}
          factoryParam={factory}
        />
      )}
    </>
  );
};

const useGetProductionLine = (id: string, isEdit: boolean) => {
  const { getProductionLine } = useProductionLinesService();

  const {
    data: productionLine,
    isPending,
    isError,
    error,
  } = useQuery<IProductionLine, ErrorResponse>({
    queryKey: [QueryKey.productionLines, id],
    queryFn: ({ signal }) => getProductionLine({ signal, id: id! }),
    enabled: isEdit,
  });

  return { productionLine: isEdit ? productionLine : undefined, isPending, isError, error };
};

const useFetchElements = (
  productionLineId: string,
  isEdit: boolean,
  isGetPending: boolean,
) => {
  const { fetchElements } = useElementsService();

  const {
    data: elements,
    isPending: isElementsPending,
    isError: isElementsError,
    error: elementsError,
  } = useQuery<IElement[], ErrorResponse>({
    queryKey: [QueryKey.elements, productionLineId],
    queryFn: ({ signal }) => fetchElements({ signal, search: { productionLineId } }),
    enabled: isEdit && !isGetPending,
  });

  return { elements, isElementsPending, isElementsError, elementsError };
};

export default ProductionLinePage;
