import { ReactNode, useEffect, useState } from "react";
import imgValmistettuSuomessa from '../../assets/valmistettu-suomessa.png';
import imgLogo from '../../assets/logo.png';
import imgPrintWatermark from '../../assets/print-watermark.png';
import IOffer from "../../shared/IOffer";
import { EOfferTermType } from "../../shared/IOfferTerm";
import OfferHeader from "../Offers/OfferHeader/OfferHeader";
import Table from "../ui/Table/Table";
import classes from './Print.module.scss';
import IInstalmentTable from "../../shared/IInstalmentTable";
import Input, { EInputType, IOption } from "../ui/Input/Input";

interface IProps {
  offer: IOffer;
  instalmentTable?: IInstalmentTable
  onReturn?: () => void;
  order?: boolean;
}

interface IPrintProps {
  offer: IOffer;
  children?: ReactNode;
  order?: boolean;
  instalmentTable?: IInstalmentTable
}

interface IRowProps {
  label: string;
  data: ReactNode;
  boldLabel?: boolean;
}
const Row: React.FC<IRowProps> = ({ label, data, boldLabel }) => {
  return (
    <div style={{ display: "flex", paddingTop: "0.5rem", paddingBottom: "0.5rem", pageBreakInside: "avoid" }}>
      <div style={{ width: "25%", fontWeight: boldLabel ? "bold" : "normal" }}>{label}</div>
      <div style={{ width: "75%", whiteSpace: "pre-wrap" }}>{data}</div>
    </div>
  );
};

const Header: React.FC<IPrintProps> = ({ offer, order, instalmentTable }) => {
  return (
    <div className={classes.header}>
      <div style={{ width: "50%" }}>
        <div style={{ height: "3rem" }}>
          <img src={imgLogo} alt="Pielisen Betoni" style={{ maxHeight: "2.5rem" }} />
        </div>
        <span>{offer.seller?.name}</span><br/>
        <span>{offer.seller?.phoneNumber}</span><br/>
        <span>myynti@pielisenbetoni.fi</span><br/>
      </div>
      <div style={{ width: "50%" }}>
        <div style={{ height: "3rem", fontWeight: "bold" }}>
          <span>{instalmentTable ? "Maksuerätaulukko" : order ? "Tilausvahvistus" : "Tarjous"}</span><br/>
          <span>{order && offer.projectNumber && offer.projectNumber + " / "}{offer.offerNumber}</span><br/>
        </div>
        <br/>
        <br/>
        <span>{offer.offerDateString}</span><br/>
      </div>
    </div>
  );
};

const Footer: React.FC = () => {
  return (
    <div className={classes.footer}>
      <div>
        <img src={imgPrintWatermark} alt="Vesileima" style={{opacity: "10%", zIndex: "-1", maxHeight: "300px", display: "block", marginLeft:"auto" }} />
      </div>
      <div className={classes.footerSpace} style={{ display: "flex" }}>
        <div>
          <img src={imgLogo} alt="Pielisen Betoni" style={{ maxHeight: "50px" }} />
        </div>
        <div style={{ paddingLeft: "1rem" }}>
          <img src={imgValmistettuSuomessa} alt="Valmistettu suomessa" style={{ maxHeight: "50px" }} />
        </div>
        <div style={{ paddingLeft: "8rem" }}>
          <span style={{ color: "#f9bf37" }}><b>LAADUNTEKIJÄ ELEMENTISSÄÄN</b></span><br />
          <span style={{ paddingLeft: "9.4rem" }}><small><b>pielisenbetoni.fi</b></small></span>
        </div>
        {/*<p className={classes.pageCount}></p>*/}
      </div>
    </div>
  );
};

//header and footer on every page of print is possible with this
//footerSpace makes space for fixed position footer. Fixed position to get footer always at bottom instead of where last page ends
const PrintComponent: React.FC<IPrintProps> = ({ children, offer, order, instalmentTable }) => {
  return (
    <table className={classes.printComponent}>
      <thead><tr><th><Header offer={offer} order={order} instalmentTable={instalmentTable} /></th></tr></thead>
      <tbody><tr><td>{children}</td></tr></tbody>
      <tfoot><tr><td><div className={classes.footerSpace}>&nbsp;</div></td></tr></tfoot>
    </table>
  )
}

enum EShowPrice {
  rowPrice = "rowPrice",
  totalPrice = "totalPrice",
}

export const radioOptions: IOption[] = [
  { value: EShowPrice.rowPrice, label: "yksikköhinta" },
  { value: EShowPrice.totalPrice, label: "kokonaishinta" },
]

const Print: React.FC<IProps> = ({ offer, instalmentTable, onReturn, order }) => {
  const [showPrice, setShowPrice] = useState(EShowPrice.totalPrice);
  
  const [printOpened, setPrintOpened] = useState(false);
  useEffect(() => {
    if (offer && !printOpened) {
      setPrintOpened(true);
      setTimeout(() => {
        window.print();
      }, 100);
    }
  }, [offer, printOpened]);

  return (
    <>
      <OfferHeader
        onSubmit={() => window.print()}
        submitText="Tulosta"
        isLoading={false}
        onReturn={onReturn}
        title="Tulosta"
      >
        <div style={{marginBottom: "0px"}}>
          <Input
            inputName="printRadio"
            type={EInputType.radio}
            value={showPrice}
            onChange={(value) => setShowPrice(value as EShowPrice)}
            options={radioOptions}
          />
        </div>
      </OfferHeader>
      <div className={classes.container}>
        <PrintComponent offer={offer} order={order} instalmentTable={instalmentTable}>

          <div style={{ display: "flex", marginBottom: "2rem" }}>
              <div style={{ width: "50%" }}>
                <div>
                  {offer.customer?.name}<br />
                  {/*
                  {offer.customer?.businessId && <span>y-tunnus: {offer.customer.businessId}<br/></span>}
                  {offer.customer?.streetAddress && <span>{offer.customer.streetAddress}<br/></span>}
                  {offer.customer?.zip} {offer.customer?.city}<br />
                  */}
                  {offer.customerContactPerson?.name && <span>{offer.customerContactPerson.name}<br/></span>}
                  {offer.customerContactPerson?.phoneNumber && <span>{offer.customerContactPerson.phoneNumber}<br/></span>}
                  {offer.customerContactPerson?.email && <span>{offer.customerContactPerson.email}<br/></span>}
                </div>
              </div>
            <div style={{ width: "50%" }}>
              <div>
                <span><b>{offer.targetName}</b></span><br />
                {offer.deliveryStreetAddress}<br />
                {offer.deliveryZip} {offer.deliveryCity}<br/>
                {offer.customerReferenceNumber && <span>tilausnumero: {offer.customerReferenceNumber}<br/></span>}
              </div>
            </div>
          </div>

          {instalmentTable ? <>
            <Table className={classes.printTable} style={{marginBottom: "2rem", marginTop: "2rem"}}>
              <thead>
                <tr>
                  <th>Maksuerätaulukko</th><th  style={{ textAlign: "right" }} colSpan={2}>Summa alv 0%</th>
                </tr>
              </thead>
              <tbody>
                {instalmentTable.instalmentRows.map((row) => (
                  <tr key={row.number}>
                    <td>{row.description}</td>
                    <td style={{ textAlign: "right" }}>{row.rowSum}</td>
                    <td>€</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </> : <>
            <p>Kiitämme Teitä {order ? "tilauksestanne" : "tarjouspyynnöstänne"}. Tarjoamme teille tuotteitamme seuraavasti yllä mainittuun kohteeseen.</p>

            <Table className={classes.printTable}>
              <thead>
                <tr>
                  <th style={{ width: "60%", textAlign: "left" }}>Tuote</th>
                  <th style={{ textAlign: "right" }}>Kpl</th>
                  <th style={{ textAlign: "right" }}>Määrä</th>
                  <th>Yks</th>
                  {showPrice === EShowPrice.rowPrice &&
                    <th style={{ textAlign: "right" }}>Hinta €/yks</th>
                  }
                </tr>
              </thead>
              <tbody>
                {offer.rows.map((row) => (
                  !row.hidden &&
                  <tr key={row.id} style={{fontWeight: row.swatchbook > 0 ? "bold" : "normal"}}>
                    <td>{row.description}</td>
                    <td style={{ textAlign: "right" }}>{+row.count > 0 && row.count}</td>
                    <td style={{ textAlign: "right" }}>{row.unitQuantity}</td>
                    <td>{row.unitString}</td>
                    {showPrice === EShowPrice.rowPrice &&
                      <td style={{ textAlign: "right" }}>{row.unitPrice} €</td>
                    }
                  </tr>
                ))}
              </tbody>
            </Table>
            
            {showPrice === EShowPrice.totalPrice &&
              <div style={{ paddingTop: "1rem" }}>
                <span style={{ float: "left"}}>Kokonaishinta: </span>
                <span style={{ float: "right", fontSize: "1.3rem"}}>
                  {offer.summary.privatePerson ?
                    <span><b>{offer.summary.offerPriceVAT} €</b> Alv. 25,5 %</span>
                    :
                    <span><b>{offer.summary.offerPrice} €</b> Alv. 0 %</span>
                  }
                </span>
              </div>
            }
            
            <div style={{ paddingTop: "4rem" }}>
              <Row label={"Suunnitelmat:"} data={offer.planningNotes} />
            </div>

            {offer.terms.map((term) => (
              term.type === EOfferTermType.PAYMENT_TERM ?
                <Row key={term.id ?? term.idString} label={"Maksuehto:"} data={term.text} />
                :
                term.type === EOfferTermType.DELIVERY_TERM &&
                <Row key={term.id ?? term.idString} label={"Toimitusehto:"} data={term.text} />
            ))}

            {showPrice === EShowPrice.totalPrice && offer.summary.installed &&
              <Row label={"Tai:"} data={<span>{offer.installations.installationText}<b> {offer.summary.privatePerson ? offer.summary.offerPriceInstalledVAT + "€ Alv 25,5%" : offer.summary.offerPriceInstalled + "€ Alv 0%"}</b></span>} />
            }

            <Row label={"Toimitusaika:"} data={offer.deliveryNotes} />

            {offer.terms.map((term) => (
              term.type === EOfferTermType.INVOICING &&
              <Row key={term.idString} label={"Maksuerät:"} data={term.text} />
            ))}

            <Row label={"Muut ehdot"} data={"Rakennustuotteiden yleiset toimitusehdot (RYHT2000)"} />

            {order && <>
              <Row boldLabel={true} label={"Tilausnumero"} data={<span>Pyydämme käyttämään kaikessa yhteydenpidossa viitteenä numeroa <b>{offer.projectNumber}</b></span>} />
              <Row boldLabel={true} label={"Kuormien tilaus"} data={offer.freightNotes} />
              <Row boldLabel={true} label={"Suunnitelmien toimitus"} data={offer.planningDeliveryNotes} />
            </>}
            <p>Tarkennukset:</p>
            <div style={{ marginLeft: "2rem" }}>
              {offer.terms.map((term) => (
                term.type === EOfferTermType.COMMON &&
                <Row key={term.idString} label={term.name ?? "Muuta"} data={term.text} />
              ))}

              {offer.terms.map((term) => (
                term.type === EOfferTermType.CONDITIONAL &&
                <Row key={term.idString} label={term.name ?? "Ehdot"} data={term.text} />
              ))}

              {offer.terms.map((term) => (
                term.type === EOfferTermType.FREE_TEXT &&
                <Row key={term.idString} label={"Muuta"} data={term.text} />
              ))}
            </div>

            {/*Yksikköhintalista = teräsosien listasta ne tuotteet jotka on merkitty “YL” booleanilla*/}
            {offer.rows.some(row => row.offerRowCalculationSteelPart.rows.some(spRow => spRow.unitPriceList)) && (
              <Row label={"Yksikköhintalista:"} data={
                <Table className={classes.printTable}>
                  <thead>
                    <tr>
                      <th>Tuote</th>
                      <th>Hinta á (alv 0%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {offer.rows.map((row) => (
                      row.offerRowCalculationSteelPart.rows.map((spRow) => (
                        spRow.unitPriceList &&
                        <tr key={spRow.id}>
                          <td>{spRow.steelPart?.unitName}</td>
                          <td>{spRow.pricePerUnit} €</td>
                        </tr>
                      ))
                    ))}
                  </tbody>
                </Table>} 
              />
            )}

            {!order &&
              <Row label={"Tarjous voimassa:"} data={offer.expireInDays && offer.expireInDays + " vrk"} />
            }

            </>
          }

          <Row label={"Lisätiedot:"} data={
            <span>
              PIELISEN BETONI OY<br />
              Y-tunnus 2872212-8<br />
              Tilaajavastuulain mukaiset selvitykset ovat ladattavissa<br />
              internetistä: www.tilaajavastuu.fi
            </span>}
          />
          
        </PrintComponent>
        <Footer />
      </div>
    </>
  );
};

export default Print;
