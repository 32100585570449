import { useQuery } from '@tanstack/react-query';
import { useFactoryService } from '../../services/factory-service';
import IFactory from '../../shared/IFactory';
import { ErrorResponse } from '../../custom-fetch';
import { QueryKey } from '../../services/query-keys';
import classes from './ElementPrint.module.scss';
import imgLogo from '../../assets/logo.png';
import imgCeLogo from '../../assets/ce-logo.svg';
import IElement from '../../shared/IElement';
import { EFactory } from '../../shared/IOfferRow';
import QRCode from 'react-qr-code';
import { adminUiUrl } from '../../config';
import SquareFI from '../SquareLogos/SquareFI';
import PrintCheckbox from '../PrintCheckbox/PrintCheckbox';

interface IProps {
  element?: IElement;
  showCe?: boolean
}
const ElementPrintExample: React.FC<IProps> = ({ element, showCe }) => {
  const { getFactoryByFactoryId } = useFactoryService();
  
  const {
    data: factory,
    //isLoading,
  } = useQuery<IFactory, ErrorResponse>({
    //queryKey: [QueryKey.factories, element.factory],
    //queryFn: ({ signal }) => getFactoryByFactoryId({ signal, id: element.factory?.toString()! }),
    //enabled: !!element.factory,
    queryKey: [QueryKey.factories, EFactory.HOL],
    queryFn: ({ signal }) => getFactoryByFactoryId({ signal, id: EFactory.HOL.toString() }),
  });

  return (
    <>
      <div className={classes.container}>
        <div className={classes.row}>
          <div style={{width: "25%"}}>
            <p>Pituus:</p>
            <p>Tunnus:</p>
          </div>
          <div style={{width: "75%"}}>
            <p>6930 mm</p>
            <p>O27-6/2x-117</p>
          </div>
        </div>
        { showCe &&
        <div style={{borderStyle: "solid", borderWidth: "1px", marginBottom: "0.7rem"}}>
          <div className={classes.smallFont} style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", paddingTop: "0.5rem"}}>
            <div><img src={imgCeLogo} alt="CE" style={{ maxHeight: "3rem" }} /></div>
            <p>{factory?.dopYearHollow}</p>
            <div style={{ width: "100%", border: "none", borderTop: "1px solid" }} />
            <p>Pielisen Betoni Oy</p>
            <p>{factory?.streetAddress} {factory?.zip} {factory?.name}</p>
            <p>www.pielisenbetoni.fi</p>
            <p>
              <span>6890/1:O27-6/2x-117</span>
              <span style={{marginLeft: "2rem"}}>Dop {factory?.dopNumHollow}</span>
            </p>
            <p style={{marginTop: "1rem"}}>{factory?.ceId}{factory?.cett2}</p>
            <div style={{ width: "100%", border: "none", borderTop: "1px solid" }} />
            <p>{factory?.hollowStandardCett1}</p>
          </div>
        </div>
        }
        <div className={classes.row}>
          <div style={{width: "50%"}}>
            <img src={imgLogo} alt="Pielisen Betoni" style={{ maxHeight: "3rem" }} />
          </div>
          <div style={{width: "22%"}}>
            <div style={{marginLeft: "1rem", marginTop: "0.3rem"}}><SquareFI/></div>
          </div>
          <div style={{width: "28%"}}>
            <p>24069/13</p>
          </div>
        </div>
        <p className={classes.smallFont} style={{marginTop: "0.5rem"}}>{factory?.zip} {factory?.name}{/* puh {factory?.phoneNumber*/}</p>
        <div className={classes.row}>
          <div style={{width: "40%"}}>
            <p>Paino:</p>
            <p>Pituus:</p>
            <p>Leveys:</p>
            <p>Varauksia:</p>
            <p>Syvenn.:</p>
            <p>Vino V/O:</p>
          </div>
          <div style={{width: "30%", textAlign: "right"}}>
            <p>2,33</p>
            <p>6930</p>
            <p>1050</p>
            <p></p>
            <p>8</p>
            <div><PrintCheckbox checked={false}/>{" "}
            <PrintCheckbox checked={true}/></div>
          </div>
          <div style={{width: "30%"}}>
            <p>t</p>
            <p>mm</p>
            <p>mm</p>
            <p>kpl</p>
            <p>kpl</p>
            <p></p>
          </div>
        </div>

        <div className={classes.row} style={{fontSize: "1.5rem", background: "silver"}}>
          <p>O27-6/2x-117</p>
        </div>

        <div className={classes.row}>
          <div style={{width: "70%"}}>
            <div className={classes.row}>
              <div style={{width: "55%"}}>
                <p>Työmaa:</p>
              </div>
              <div style={{width: "45%"}}>
                <p>6890/1</p>
              </div>
            </div>
            <p className={classes.cutLongText}>Agco Power halli 7</p>
            <div className={classes.row}>
              <div style={{width: "55%"}}>
                <p>Valu pvm:</p>
                <p>Tehdas:</p>
              </div>
              <div style={{width: "45%"}}>
                <p>17.6.2024</p>
                <p>{factory?.factoryId}</p>
              </div>
            </div>
            <p>P 6930mm / 2,33 t</p>
          </div>
          <div style={{width: "30%"}}>
            <QRCode style={{height: "96px", width: "96px", marginTop: "1rem"}} value={`${adminUiUrl}/qr/element/example`}></QRCode>
          </div>
        </div>
      </div>
    </>
  )
}

export default ElementPrintExample;