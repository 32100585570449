import { IOption } from "../components/ui/Input/Input";

export type UserRole = "ADMIN" | "BASIC" | "SELLER" | "ENGINEER" | "CUSTOMER" | "PRODUCTION" | "LOGISTICS" | "CONCRETE";

export const ALL_ROLE_OPTIONS: IOption[] = [
  { value: "ADMIN", label: "Admin" },
  // { value: "BASIC", label: "Basic" },
  { value: "SELLER", label: "Myyjä" },
  { value: "ENGINEER", label: "Suunnittelija" },
  { value: "CUSTOMER", label: "Asiakas" },
  { value: "PRODUCTION", label: "Tuotanto" },
  { value: "LOGISTICS", label: "Kuljetus" },
  { value: "CONCRETE", label: "Betoniasema" },
];

export const USER_ROLE_OPTIONS: IOption[] = [
  { value: "SELLER", label: "Myyjä" },
  { value: "PRODUCTION", label: "Tuotanto" },
  { value: "LOGISTICS", label: "Kuljetus" },
  { value: "CONCRETE", label: "Betoniasema" },
];

interface IUser {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
  phoneNumber: string;
  organizationId: string;
  allowLogin: boolean;
}

export default IUser;