import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import Table from "../ui/Table/Table";
import { Route } from "../../routes";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/date-utils";
import { useWaybillsService } from "../../services/waybills-service";
import { QueryKey } from "../../services/query-keys";
import { useQuery } from "@tanstack/react-query";
import IWaybill from "../../shared/IWaybill";
import { ErrorResponse } from "../../custom-fetch";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

enum EListSortBy {
  factory = "factory",
  waybillId = "waybillId",
  projectNumber = "projectNumber",
  destination = "destination",
  deliveryDate = "deliveryDate",
  deliveryTime = "deliveryTime",
  kilometers = "kilometers",
  driverOrganization = "driverOrganization",
  car = "car",
  tons = "tons",
  count = "count",
  notes = "notes",
}

const ConfirmedWaybillsList: React.FC = () => {
  const { waybills, isLoading, isError, error } = useFetchConfirmedWaybills();
  const navigate = useNavigate();
  const [sorted, setSorted] = useState<IWaybill[]>([]);
  const [sort, setSort] = useState<EListSortBy>();
  const [sortDirection, setSortDirection] = useState<boolean>(false); // true = up, false = down
  const changeSortOrDirection = (sortBy: EListSortBy) => {
    if (sortBy === sort) {
      setSortDirection(!sortDirection);
    } else {
      setSort(sortBy);
      setSortDirection(false);
    }
  }
  const getChevron = () => {
    return <FontAwesomeIcon style={{ marginLeft: '.5rem', fontSize: '.75rem'}} icon={sortDirection ? faChevronUp : faChevronDown} />
  };

  useEffect(() => {
    const newSorted = [...sorted];
    if (sort && newSorted) {
      if (sortDirection) {
        newSorted.reverse();
        setSorted(newSorted);
        return;
      }
      newSorted.sort((a, b) => {
        const getFieldValue = (item: IWaybill, field: EListSortBy) => {
          return item[field];
        };
        const aValue = getFieldValue(a, sort) || "";
        const bValue = getFieldValue(b, sort) || "";
        if (aValue === bValue) return 0;
        return aValue > bValue ? 1 : -1;
      });
      setSorted(newSorted);
    }
    // eslint-disable-next-line
  }, [sort, sortDirection]);

  useEffect(() => {
    if (waybills) {
      setSorted(waybills);
    }
  }, [waybills])

  const openHandler = (id: string) => {
    navigate(Route.waybill(id));
  };

  const openPrintHandler = (
    id: string,
    elements: boolean,
    event: React.MouseEvent
  ) => {
    event.stopPropagation();
    if (elements) {
      navigate(Route.waybillPrintElements(id));
    } else {
      navigate(Route.waybillPrint(id));
    }
  };

  if (isError && error) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (!waybills || waybills.length === 0) {
    return <p>Ei rahtikirjoja</p>;
  }

  return (
    <>
      <Table hover>
        <thead style={{cursor: "pointer"}}>
          <tr>
            <th onClick={() => changeSortOrDirection(EListSortBy.factory)}>Tehdas {sort === EListSortBy.factory && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.waybillId)}>Numero {sort === EListSortBy.waybillId && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.projectNumber)}>Työmaa {sort === EListSortBy.projectNumber && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.destination)}>Sijainti {sort === EListSortBy.destination && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.deliveryDate)}>Pvm {sort === EListSortBy.deliveryDate && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.deliveryTime)}>Klo {sort === EListSortBy.deliveryTime && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.kilometers)}>Km {sort === EListSortBy.kilometers && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.driverOrganization)}>Kuljettaja {sort === EListSortBy.driverOrganization && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.car)}>Auto {sort === EListSortBy.car && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.tons)}>Tonnit {sort === EListSortBy.tons && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.count)}>Kpl {sort === EListSortBy.count && getChevron()}</th>
            <th onClick={() => changeSortOrDirection(EListSortBy.notes)}>Huomautus {sort === EListSortBy.notes && getChevron()}</th>
            <th>Tulostus</th>
          </tr>
        </thead>
        <tbody>
          {sorted.map((waybill) => (
            <tr key={waybill.id} onClick={() => openHandler(waybill.id)}>
              <td>{waybill.factory}</td>
              <td>{waybill.waybillId}</td>
              <td>{waybill.projectNumber} {waybill.projectName}</td>
              <td>{waybill.destination}</td>
              <td>{formatDate(waybill.deliveryDate)}</td>
              <td>{waybill.deliveryTime}</td>
              <td>{waybill.kilometers}</td>
              <td>{waybill.driverOrganization?.name}</td>
              <td>{waybill.car?.name}</td>
              <td>{waybill.tons && (+waybill.tons).toFixed(2)}</td>
              <td>{waybill.count}</td>
              <td>{waybill.notes}</td>
              <td>
                <p>
                  <span
                    style={{ textDecoration: "underline" }}
                    onClick={(event) =>
                      openPrintHandler(waybill.uuid, false, event)
                    }
                  >
                    Rahtikirja
                  </span>{" "}
                  {" / "}
                  <span
                    style={{ textDecoration: "underline" }}
                    onClick={(event) =>
                      openPrintHandler(waybill.uuid, true, event)
                    }
                  >
                    Lähetyslista
                  </span>
                </p>
              </td>
            </tr>
          ))}
        </tbody>
        {waybills.length > 0 && (
          <tfoot>
            <tr>
              <td colSpan={8}></td>
              <td>Yhteensä</td>
              <td>
                {waybills
                  .reduce((sum, waybill) => sum + +(waybill.tons ?? 0), 0)
                  .toFixed(2)}
              </td>
              <td>
                {waybills.reduce(
                  (sum, waybill) => sum + +(waybill.count ?? 0),
                  0
                )}
              </td>
              <td colSpan={2}></td>
            </tr>
          </tfoot>
        )}
      </Table>
    </>
  );
};

const useFetchConfirmedWaybills = () => {
  const { fetchConfirmedWaybills } = useWaybillsService();

  const {
    data: waybills,
    isPending,
    isRefetching,
    isError,
    error,
    refetch,
  } = useQuery<IWaybill[], ErrorResponse>({
    queryKey: [QueryKey.confirmedWaybills],
    queryFn: ({ signal }) =>
      fetchConfirmedWaybills({
        signal,
      }),
    staleTime: 5000,
  });

  return {
    waybills,
    isLoading: isPending || isRefetching,
    isError,
    error,
    refetch,
  };
};

export default ConfirmedWaybillsList;
