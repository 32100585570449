import { useCallback, useEffect, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import IElement from "../../shared/IElement";
import IElementUnloading, {
  ElementSlot
} from "../../shared/IElementUnloading";
import IWaybillRow from "../../shared/IWaybillRow";
import ElementUnloadingContextProvider from "./element-unloading-context";
import ElementUnloadingDragAndDropTable from "./ElementUnloadingDragAndDropTable";
import { useElementUnloadingDragAndDrop } from "./useElementUnloadingDragAndDrop";

interface IProps {
  waybillRows: IWaybillRow[];
  initialElements: IElement[];
  setWaybillRows: React.Dispatch<React.SetStateAction<IWaybillRow[]>>;
  waybillDeliveryDate: string;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

const waybillRowsToElements = (
  elements: IElement[],
  waybillRows: IWaybillRow[],
  slot: string
): IElement[] => {
  if (slot === "TODO") {
    const slotRows = waybillRows.filter((row) => row.slot === "TODO");
    const rowElements = elements.filter((element) => slotRows.find((row) => row.elementId === element.id) || !waybillRows.find(el => el.elementId === element.id));
    return rowElements;
  }
  const slotRows = waybillRows.filter((row) => row.slot === slot);
  return slotRows.map((row) => ({
    ...elements.find((element) => element.id === row.elementId)!,
    position: +(row.order ?? 0),
  })).filter(element => !!element.id);
};

const elementsToWaybillRows = (elements: IElement[]): IWaybillRow[] => {
  return elements
    .map((element) => ({
      id: uuid(),
      elementId: element.id,
      order: `${element.position ?? ""}`,
      slot: element.slot === "TODO" ? undefined : element.slot,
    }))
    .filter((row) => typeof row.slot !== "undefined");
};

const WaybillElementUnloadingDragAndDropTable: React.FC<IProps> = ({
  waybillRows,
  initialElements,
  setWaybillRows,
  waybillDeliveryDate,
  setIsDirty,
}) => {
  const [items, setItems] = useState<IElementUnloading[]>([]);

  useEffect(() => {
    if (initialElements.length > 0 || waybillRows.length > 0) {
      // console.log("set initial", initialElements.length, waybillRows.length);
      setItems([
        ...["TODO", "1", "A", "B", "2", "C", "D", "3", "E", "F", "4", "G", "H", "5", "I", "J"].map((key) => ({
          slot: key as ElementSlot,
          elements: waybillRowsToElements([...initialElements], [...waybillRows], key),
        })),
      ]);
    }
  }, [initialElements, waybillRows]);

  const { dragEndHandler, dragUpdateHandler } = useElementUnloadingDragAndDrop(
    items,
    setItems
  );

  const updateHandler = useCallback(
    async (result: DropResult) => {
      const newItems = dragEndHandler(result);
      if (newItems) {
        const newRows = elementsToWaybillRows(newItems);
        //   setWaybillRows([]);
        setWaybillRows((waybillRows) => [
          ...waybillRows.filter(
            (row) =>
              typeof row.slot !== "undefined" &&
              newItems.findIndex(item => item.id === row.elementId) === -1
          ),
          ...newRows,
        ]);
        // console.log(newItems);
        // console.log(newRows);
      }
      setIsDirty(true);
    },
    [dragEndHandler, setIsDirty, setWaybillRows]
  );

  const addElementsHandler = useCallback((slot: ElementSlot, selectedElements: IElement[]) => {
    // TODO: refactor
    setItems(items => {
      const newItems = [...items];
      const slotIndex = newItems.findIndex(item => item.slot === slot);
      if (slotIndex > -1) {
        newItems[slotIndex].elements = [...selectedElements, ...newItems[slotIndex].elements].map(((el, index, arr) => ({
          ...el,
          position: arr.length > 0 ? arr.length - (index ?? 0) : 1,
          slot,
        })));
        // const selectedIds = selectedElements.map(el => el.id);
        // newItems[0].elements = newItems[0].elements.filter(el => !selectedIds.includes(el.id));
        // update state
        const newRows = elementsToWaybillRows(newItems[slotIndex].elements);
        setWaybillRows((waybillRows) => [
          ...waybillRows.filter(
            (row) =>
              typeof row.slot !== "undefined" &&
              newItems[slotIndex].elements.findIndex(item => item.id === row.elementId) === -1
          ),
          ...newRows,
        ]);
      }
      return newItems;
    });
    setIsDirty(true);
    // setSelectedElements([]);
  }, [setIsDirty, setWaybillRows]);

  const removeElementHandler = useCallback((slot: ElementSlot, element: IElement) => {
    // console.log(element);
    let newOrder = 0;
    setWaybillRows((rows) => {
      let newRows = [...rows];
      newRows = newRows.filter((row) => row.elementId !== element.id);
      const slotRows = newRows.filter(row => row.slot === slot);
      return newRows.map((row) => (row.slot === slot ? { ...row, order: `${slotRows.length - newOrder++}` } : row))
    });
    setIsDirty(true);
  }, [setIsDirty, setWaybillRows]);

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <ElementUnloadingContextProvider value={{ 
      items,
      // setItems,
      dragUpdateHandler,
      updateHandler,
      isWaybill: true,
      waybillDeliveryDate,
      addElementsHandler,
      removeElementHandler,
    }}>
      <ElementUnloadingDragAndDropTable />
    </ElementUnloadingContextProvider>
  );
};

export default WaybillElementUnloadingDragAndDropTable;
