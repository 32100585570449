import { faQrcode, faRoute } from "@fortawesome/free-solid-svg-icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../App";
import { adminUiUrl } from "../../config";
import { useNavigationBlockerContext } from "../../context/navigation-blocker-context";
import { ErrorResponse } from "../../custom-fetch";
import { useDriverOrganizationOptions } from "../../hooks/useDriverOrganizationOptions";
import { useProjectOptions } from "../../hooks/useProjectOptions";
import { useTransportOrderOptions } from "../../hooks/useTransportOrderOptions";
import { useVehicleOptions } from "../../hooks/useVehicleOptions";
import { Route } from "../../routes";
import { useElementsService } from "../../services/elements-service";
import { QueryKey } from "../../services/query-keys";
import { useWaybillsService } from "../../services/waybills-service";
import { FactoryOptions } from "../../shared/FactoryOptions";
import IElement from "../../shared/IElement";
import { EFactory } from "../../shared/IOfferRow";
import IWaybill, { EWaybillType, WAITING_PAYER_OPTIONS, WAYBILL_TYPE_OPTIONS } from "../../shared/IWaybill";
import IWaybillRow from "../../shared/IWaybillRow";
import { formatDateTime } from "../../utils/date-utils";
import WaybillElementUnloadingDragAndDropTable from "../ElementUnloading/WaybillElementUnloadingDragAndDropTable";
import ErrorsAlert, { combineErrors } from "../ErrorsAlert/ErrorsAlert";
import Button, { EButtonSize } from "../ui/Button/Button";
import Container from "../ui/Container/Container";
import FormButtons from "../ui/FormButtons/FormButtons";
import { ECommonValue, EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import PageHeading from "../ui/PageHeading/PageHeading";
import Spinner from "../ui/Spinner/Spinner";
import { useWaybillSendModal } from "../WaybillSendModal/useWaybillSendModal";
import IDriverOrganization from "../../shared/IDriverOrganization";
import IVehicle from "../../shared/IVehicle";
import { openGoogleMaps } from "../../utils/location-utils";
import WaybillTools from "./WaybillTools/WaybillTools";
import { useProjectsService } from "../../services/projects-service";
import { useConfirmModal } from "../ui/Modal/useConfirmModal";
import { useDialog } from "../ui/Modal/useDialog";
import IWaybillUpdatePricesDTO from "../../shared/IWaybillUpdatePricesDTO";

interface IProps {
  id: string;
  waybill?: IWaybill;
  projectId?: string | null;
  transportOrderId?: string | null;
}

enum EInputs {
  projectId = "projectId",
  projectNumber = "projectNumber",
  destination = "destination",
  driverOrganization = "driverOrganization",
  driver = "driver",
  car = "car",
  deliveryDate = "deliveryDate",
  deliveryTime = "deliveryTime",
  deliveryPrice = "deliveryPrice",
  waitingTime = "waitingTime",
  waitingPrice = "waitingPrice",
  waitingReason = "waitingReason",
  waitingPayer = "waitingPayer",
  kilometers = "kilometers",
  accepted = "accepted",
  price = "price",
  tons = "tons",
  tonsInvoice = "tonsInvoice",
  count = "count",
  notes = "notes",
  additionalNotes = "additionalNotes",
  factory = "factory",
  orderNumber = "orderNumber",
  nakki = "nakki", // Jatkorekka
  contactPersonName = "contactPersonName",
  contactPersonPhone = "contactPersonPhone",
  contactPersonEmail = "contactPersonEmail",
  type = "type",
  unloadFreeTime = "unloadFreeTime"
}

const WaybillEdit: React.FC<IProps> = ({ id, waybill, projectId: paramProjectId, transportOrderId }) => {
  const isEdit = id !== "add";
  const navigate = useNavigate();
  
  const openDialog = useDialog();
  const openConfirmModal = useConfirmModal();
  const openWaybillSendModal = useWaybillSendModal();

  // const redirectHandler = () => {
  //   return navigate(Route.waybills);
  // };

  const { saveOrUpdate, error, isError, isPending } = useSaveOrUpdate(id, isEdit);
  // const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete(id, redirectHandler);
  const { createInput, submit, inputs, setInputs } = useWaybillInputs(waybill);
  const { copyWaybill } = useWaybillsService();

  const { transportOrders } = useTransportOrderOptions();
  const { showBlocker, showNavigationBlockerModal, setIsDirty } = useNavigationBlockerContext();

  useEffect(() => {
    if (transportOrderId) {
      const transportOrder = transportOrders.find(transportOrder => transportOrder.id === transportOrderId);
      if (transportOrder) {
        setInputs(inputs => {
          const newInputs = {...inputs};
          newInputs[EInputs.factory].value = transportOrder.factory ?? "";
          newInputs[EInputs.notes].value = transportOrder.notes ?? "";
          newInputs[EInputs.projectId].value = transportOrder.projectId ?? "";
          newInputs[EInputs.deliveryDate].value = transportOrder.deliveryDate ?? "";
          newInputs[EInputs.deliveryTime].value = transportOrder.deliveryTime ?? "";
          return newInputs;
        });
      }
    }
  }, [setInputs, transportOrderId, transportOrders]);

  const [waybillRows, setWaybillRows] = useState<IWaybillRow[]>([]);
  const [isWaybillSendModalOpen, setIsWaybillSendModalOpen] = useState(false);
  const [deliveryPrice, setDeliveryPrice] = useState<number>(+(waybill?.deliveryPrice ?? 0));

  const projectId = paramProjectId ?? inputs[EInputs.projectId].value as string;
  const factory = inputs[EInputs.factory].value as string;
  const driverOrganizationId = inputs[EInputs.driverOrganization].value as string;
  const vehicleId = inputs[EInputs.car].value as string;

  const { projects, options: projectOptions, loading: projectOptionsLoading } = useProjectOptions({ factory, factoryRequired: true });
  const { driverOrganizations, options: driverOrganizationOptions, loading: driverOrganizationOptionsLoading } = useDriverOrganizationOptions();
  const { vehicles, options: vehicleOptions, loading: vehicleOptionsLoading } = useVehicleOptions({ driverOrganizationId });
  const { updateWaybillPricesMutate, isUpdatingPrices, isUpdatePricesError, updatePricesError } = useUpdateWaybillPrices(projectId, id);

  const waitingTime = inputs[EInputs.waitingTime].value as string ?? "0";
  const waitingPrice = inputs[EInputs.waitingPrice].value as string ?? "0";
  const waitingTotal = (+waitingTime * +waitingPrice).toFixed(2);

  const project = useMemo(() => projects?.find((p) => p.id.toString() === projectId.toString()), [projectId, projects]);

  const vehicle = useMemo(() => vehicles?.find((v) => v.id.toString() === vehicleId.toString()), [vehicleId, vehicles]);
  const driver = vehicle?.driver ?? "";

  const projectNumber = project?.projectNumber;
  const projectName = project?.name;
  
  const type = useMemo(() => inputs[EInputs.type].value as EWaybillType, [inputs]);
  const { elements, isElementsLoading, isElementsError, elementsError, /*refetchElements*/ } = useListElements(factory as EFactory, projectId, type);
  const { waybillElements, isWaybillElementsLoading, isWaybillElementsError, waybillElementsError } = useListWaybillElements(factory as EFactory, projectId, id, isEdit);

  useEffect(() => {
    setWaybillRows(waybill?.waybillRows ?? []);
  }, [waybill?.waybillRows]);

  const tons = useMemo(() => (
    waybillRows
      .filter((row) => typeof row.slot !== "undefined" && row.slot !== "TODO")
      .reduce((sum, row) => {
        let element = waybillElements?.find((e) => e.id === row.elementId);
        if (!element) element = elements?.find((e) => e.id === row.elementId);
        const weightTons = +(element?.weightTons ?? "0");
        // console.log(row, element);
        return sum + weightTons;
  }, 0)), [elements, waybillElements, waybillRows]);

  const count = waybillRows.length;

  const kilometerCalculation = project?.offer?.kilometerCalculations?.find((kc) => kc.factory === factory);
  useEffect(() => {
    const offerFreightPrice = +(kilometerCalculation?.offerFreightPrice ?? 0);
    if (!waybill?.deliveryPrice && offerFreightPrice) {
      setDeliveryPrice(offerFreightPrice ?? 0);
    }
  }, [kilometerCalculation?.offerFreightPrice, waybill?.deliveryPrice]);

  const price = (deliveryPrice * tons).toFixed(2);
  const kilometers = kilometerCalculation?.freightKilometers;

  const contactPersonName = project?.contactPersonName ?? "";
  const contactPersonPhone = project?.contactPersonPhone ?? "";
  const contactPersonEmail = project?.contactPersonEmail ?? "";
  const orderNumber = project?.offer.customerReferenceNumber ?? "";
  const waybillWaitingPrice = project?.waybillWaitingPrice ?? "";
  const waybillUnloadFreeTime = project?.waybillUnloadFreeTime ?? "";
  const waybillNotes = project?.waybillNotes ?? "";

  const destination = [
    project?.offer.deliveryStreetAddress, 
    project?.offer.deliveryZip, 
    project?.offer.deliveryCity
  ].filter(Boolean).join(", ");

  const driverOrganizationsMemo = useMemo(() => {
    const options = [...(driverOrganizationOptions ?? [])];
    const items = [...driverOrganizations ?? []];
    if (waybill?.driverOrganization) {
      const index = options?.findIndex((driverOrganization) => driverOrganization.value === waybill.driverOrganization?.id);
      if (index === -1 && waybill?.driverOrganization.name) {
        options.push({ value: waybill.driverOrganization.id ?? waybill.driverOrganization.name, label: waybill.driverOrganization.name });
        items.push({ ...waybill.driverOrganization })
      }
    }
    return { options, items };
  }, [driverOrganizationOptions, driverOrganizations, waybill?.driverOrganization]);

  const vehiclesMemo = useMemo(() => {
    const options = [...(vehicleOptions ?? [])];
    const items = [...vehicles ?? []];
    if (waybill?.car) {
      const index = options?.findIndex((car) => car.value === waybill.car?.id);
      if (index === -1 && waybill?.car.name) {
        options.push({ value: waybill.car.id ?? waybill.car.name, label: waybill.car.name });
        items.push({ ...waybill.car })
      }
    }
    return { options, items };
  }, [vehicleOptions, vehicles, waybill?.car]);

  useEffect(() => {
    if (paramProjectId && project) {
      setInputs((inputs) => {
        const newInputs = {...inputs};
        newInputs[EInputs.projectId].value = paramProjectId;
        newInputs[EInputs.destination].value = destination;
        newInputs[EInputs.orderNumber].value = orderNumber;
        newInputs[EInputs.contactPersonName].value = contactPersonName;
        newInputs[EInputs.contactPersonPhone].value = contactPersonPhone;
        newInputs[EInputs.contactPersonEmail].value = contactPersonEmail;
        newInputs[EInputs.waitingPrice].value = waybillWaitingPrice;
        newInputs[EInputs.unloadFreeTime].value = waybillUnloadFreeTime;
        newInputs[EInputs.notes].value = waybillNotes;
        return newInputs;
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramProjectId, project]);

  useEffect(() => {
    if (projectId && waybill?.projectId !== projectId) {
      setInputs((inputs) => {
        const newInputs = {...inputs};
        newInputs[EInputs.destination].value = destination;
        newInputs[EInputs.orderNumber].value = orderNumber;
        newInputs[EInputs.contactPersonName].value = contactPersonName;
        newInputs[EInputs.contactPersonPhone].value = contactPersonPhone;
        newInputs[EInputs.contactPersonEmail].value = contactPersonEmail;
        newInputs[EInputs.waitingPrice].value = waybillWaitingPrice;
        newInputs[EInputs.unloadFreeTime].value = waybillUnloadFreeTime;
        newInputs[EInputs.notes].value = waybillNotes;
        return newInputs;
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (vehicleId && waybill?.car?.id !== vehicleId) {
      setInputs((inputs) => {
        const newInputs = {...inputs};
        newInputs[EInputs.driver].value = driver;
        return newInputs;
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleId]);

  const handleSubmitData = useCallback(async (redirectHandler?: () => void) => {
    try {
      const data = await submit();
      if (data) {
        data.kilometers = kilometers ?? "0";
        data.projectNumber = projectNumber ?? "";
        data.projectName = projectName ?? "";
        data.deliveryPrice = deliveryPrice.toString();
        data.price = price;
        data.waybillRows = projectId && factory ? waybillRows : [];
        data.count = count.toString();
        data.tons = tons.toString();
        data.transportOrderId = transportOrderId ?? "";
        const selectedDriverOrganization = driverOrganizationsMemo.items?.find((i) => i.id === data.driverOrganization as any);
        data.driverOrganization = selectedDriverOrganization ? selectedDriverOrganization : data.driverOrganization ? {name: data.driverOrganization as any} as IDriverOrganization : undefined;
        const selectedVehicle = vehiclesMemo.items?.find((i) => i.id === data.car as any);
        data.car = selectedVehicle ? selectedVehicle : data.car ? {name: data.car as any} as IVehicle : undefined;
        setIsDirty(false);
        await saveOrUpdate(data, { onSuccess: redirectHandler });
      }
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }, [count, deliveryPrice, driverOrganizationsMemo.items, factory, kilometers, price, projectId, projectName, projectNumber, saveOrUpdate, setIsDirty, submit, tons, transportOrderId, vehiclesMemo.items, waybillRows]);

  const submitHandler = useCallback(async () => {
    return await handleSubmitData();
  }, [handleSubmitData]);

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (isWaybillSendModalOpen) return;
      if (e.repeat) return;
      if (!e.ctrlKey) return;
      const key = e.key.toLowerCase();
      switch (key) {
        case "s":
          e.preventDefault();
          submitHandler();
          break;
      }
    };

    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [isWaybillSendModalOpen, submitHandler]);

  const waybillPrintHandler = async () => {
    await handleSubmitData(() => {
      navigate(Route.waybillPrint(waybill!.uuid));
    });
  };

  const waybillPrintElementsHandler = async () => {
    await handleSubmitData(() => {
      navigate(Route.waybillPrintElements(waybill!.uuid))
    });
  }

  const waybillCopyHandler = async () => {
    await handleSubmitData();
    const copiedWaybill = await copyWaybill({id: waybill!.id});
    queryClient.invalidateQueries({ queryKey: [QueryKey.waybills] });
    navigate(Route.waybill(copiedWaybill.id));
  };

  const openQRHandler = () => {
    if (waybill?.uuid) {
      const url = `${adminUiUrl}/qr/waybill/${waybill.uuid}`;
      window.open(url, "_blank");
    }
  }

  useEffect(() => {
    if (showBlocker) {
      showNavigationBlockerModal(submitHandler);
    }
  }, [showBlocker, showNavigationBlockerModal, submitHandler]);

  const errorMessages = combineErrors(
    { isError, error },
    // { isError: isDeletingError, error: deletingError },
    { isError: isElementsError, error: elementsError },
    { isError: isWaybillElementsError, error: waybillElementsError },
    { isError: isUpdatePricesError, error: updatePricesError },
  );

  const deliveryDate = useMemo(() => inputs[EInputs.deliveryDate].value as string, [inputs]);

  const sendHandler = useCallback(async () => {
    setIsWaybillSendModalOpen(true);
    const recipients: string[] = [];
    if (waybill?.car?.email) recipients.push(waybill.car.email);
    if (waybill?.contactPersonEmail && !recipients.includes(waybill.contactPersonEmail)) recipients.push(waybill.contactPersonEmail);
    await openWaybillSendModal({ waybillIds: [waybill!.id], recipients });
    setIsWaybillSendModalOpen(false);
  }, [openWaybillSendModal, waybill]);

  const updateWaybillPricesHandler = useCallback(async () => {
    const isConfirm = await openConfirmModal("Oletko varma, että haluat päivittää rahtikirjan kuljetushinnat?\nKuljetushinnat päivitetään samalla myös tuleviin saman työmaan rahtikirjoihin.");
    if (!isConfirm) return;
    const dto = await updateWaybillPricesMutate();
    // console.log("deliveryPrice", dto?.updatedWaybillDeliveryPrice);
    if (dto?.updatedWaybillDeliveryPrice) {
      setDeliveryPrice(dto.updatedWaybillDeliveryPrice);
    }
    await openDialog("Päivitetyt kuljetushinnat", dto?.updatedWaybillsText ?? "Kuljetushinnat ovat ajan tasalla.");
  }, [openConfirmModal, openDialog, updateWaybillPricesMutate]);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <PageHeading>
          {waybill?.prevWaybillId ? <span onClick={() => navigate(Route.waybill(waybill?.prevWaybillId!))} style={{cursor: "pointer"}}>{"<"}</span> : ""}
            Rahtikirja {waybill?.waybillId}
          {waybill?.nextWaybillId ? <span onClick={() => navigate(Route.waybill(waybill?.nextWaybillId!))} style={{cursor: "pointer"}}>{">"}</span> : ""}
        </PageHeading>
        <FormButtons
          onSubmit={submitHandler}
          isLoading={isPending}
          // onDelete={isEdit ? deleteMutate : undefined}
          // isDeleting={isDeleting}
          // deleteConfirmMessage="Haluatko varmasti poistaa rahtikirjan?"
          style={{ marginTop: "1rem", fontSize: "initial" }}
        >
          {isEdit && 
          <>
            <Button loading={isPending} onClick={waybillCopyHandler}>Tallenna ja uusi</Button>
            <Button loading={isPending} onClick={waybillPrintHandler}>Rahtikirja</Button>
            <Button loading={isPending} onClick={waybillPrintElementsHandler}>Lähetyslista</Button>
            {waybill?.uuid && <Button onClick={openQRHandler} icon={faQrcode} title="QR"/>}
            {waybill?.id && <Button onClick={sendHandler}>Lähetä</Button>}
            <Button loading={isUpdatingPrices} onClick={updateWaybillPricesHandler}>Päivitä kuljetushinnat</Button>
          </>
          }
        </FormButtons>
      </div>
    <Container>
      <form style={{ display: "flex", width: "1500px" }} onBlur={() => setIsDirty(true)}>
        <div style={{ width: "500px", paddingRight: "20px" }}>
          {createInput(EInputs.factory)}
          {createInput(EInputs.projectId, {options: projectOptions, loading: projectOptionsLoading})}
          {/*createInput(EInputs.waitingTime)*/}
          {createInput(EInputs.type, { disabled: isEdit })}
          {createInput(EInputs.tons)}
          <div style={{marginTop: "2rem", marginBottom: "2rem"}}>
            <span>Paino: {tons.toFixed(2)} t</span><br/>
            <span>Kappaleet: {count}</span><br/>
            <span>Kilometrit: {kilometers} km</span><br/>
            <span>Kuljetus hinta: {deliveryPrice} €/ton</span><br/>
            <span>Hinta: {price} €</span><br/><br/>
            <span>Odotuksen kulu: {waitingTotal} €</span><br/>
            <span>Lastaus päättynyt: {waybill?.loadEndTime}</span><br/>
            <span>Saapunut klo: {waybill?.arriveTime}</span><br/>
            <span>Purku alkanut: {waybill?.unloadStartTime}</span><br/>
            <span>Lähtenyt klo: {waybill?.departTime}</span><br/>
            <span>Myöhästymisen aika h: {waybill?.delayTime}</span><br/>
            <span>Myöhästymisen syy: {waybill?.delayReasonString}</span><br/>
            <span>Myöhästymisen lisätiedot: {waybill?.delayNotes}</span><br/>
            <span>Odotus aika h: {waybill?.waitingTime}</span><br/>
            <span>Odotuksen syy: {waybill?.waitingReasonString}</span><br/>
            <span>Odotuksen lisätiedot: {waybill?.waitingNotes}</span><br/>
            <span>Yleiset huomiot: {waybill?.driverNotes}</span><br/>
          </div>
          {createInput(EInputs.accepted)}
          {createInput(EInputs.nakki)}
        </div>
        <div style={{ width: "500px"}}>
          {createInput(EInputs.destination)}
          {createInput(EInputs.orderNumber)}
          {createInput(EInputs.deliveryDate)}
          {createInput(EInputs.deliveryTime)}
          {createInput(EInputs.contactPersonName)}
          {createInput(EInputs.contactPersonPhone)}
          {createInput(EInputs.contactPersonEmail)}
          {createInput(EInputs.notes)}
        </div>
        <div style={{ width: "500px", paddingLeft: "20px" }}>
          {createInput(EInputs.driverOrganization, { options: driverOrganizationsMemo.options, loading: driverOrganizationOptionsLoading })}
          {createInput(EInputs.car, { options: vehiclesMemo.options, loading: vehicleOptionsLoading })}
          {createInput(EInputs.driver)}
          {createInput(EInputs.tonsInvoice)}
          {createInput(EInputs.unloadFreeTime)}
          {createInput(EInputs.waitingPayer)}
          {createInput(EInputs.waitingPrice)}
          {createInput(EInputs.additionalNotes)}
        </div>
        {waybill?.id && waybill.toolIds?.length > 0 && (
          <>
            <span style={{ borderLeft: "thin solid gray", height: "auto", margin: "0 1rem" }} />
            <div>
              <h3>Nostovälineet</h3>
              <WaybillTools waybill={waybill} disabled />
            </div>
          </>
        )}
      </form>

      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}

      {isElementsLoading || isWaybillElementsLoading ? (
          <Spinner />
        ) : factory && projectId ? (
          <WaybillElementUnloadingDragAndDropTable
            initialElements={[...(elements ?? []), ...(waybillElements ?? [])]}
            waybillRows={waybillRows}
            setWaybillRows={setWaybillRows}
            waybillDeliveryDate={deliveryDate ?? ""}
            setIsDirty={setIsDirty}
          />
      ) : null}

      {waybill?.locations &&
      <div style={{marginTop: "3rem"}}>
        <h3>QR skannausten sijainnit</h3>
        {waybill?.locations.map((location, index) => (
          <div key={index} style={{display: "flex", marginBottom: "1rem"}}>
            <Button onClick={()=>openGoogleMaps(location.latitude, location.longitude)} icon={faRoute} size={EButtonSize.X_SMALL}/>
            <div style={{paddingTop: '10px', paddingLeft: '10px'}}>{formatDateTime(location.created)}, Leveys: {location.latitude}, Pituus: {location.longitude}, Tarkkuus: {location.accuracy?.toFixed(0)} m</div>
          </div>
        ))}
      </div>
      }
    </Container>
    </>
  );
};

const useWaybillInputs = (data?: IWaybill) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.projectId]: {
      type: EInputType.reactSelect,
      label: "Työmaa",
      value: "",
    },
    [EInputs.projectNumber]: {
      type: EInputType.text,
      label: "Työmaanumero",
      value: "",
    },
    [EInputs.destination]: {
      type: EInputType.text,
      label: "Sijainti",
      value: "",
    },
    [EInputs.driverOrganization]: {
      type: EInputType.reactSelect,
      label: "Kuljetusliike",
      value: "",
      isCreatable: true,
    },
    [EInputs.driver]: {
      type: EInputType.text,
      label: "Kuski",
      value: "",
    },
    [EInputs.car]: {
      type: EInputType.reactSelect,
      label: "Auto",
      value: "",
      isCreatable: true,
    },
    [EInputs.deliveryDate]: {
      type: EInputType.date,
      label: "Pvm",
      value: "",
      validation: {
        required: true,
      },
    },
    [EInputs.deliveryTime]: {
      type: EInputType.time,
      label: "Aika",
      value: "",
    },
    [EInputs.waitingTime]: {
      type: EInputType.number,
      label: "Odotukset, tuntia",
      value: "",
    },
    [EInputs.waitingPrice]: {
      type: EInputType.number,
      label: "Odotuksen hinta € /h",
      value: "",
    },
    [EInputs.waitingPayer]: {
      type: EInputType.reactSelect,
      label: "Odotuksen vastuu",
      value: "",
      options: WAITING_PAYER_OPTIONS
    },
    [EInputs.accepted]: {
      type: EInputType.checkbox,
      label: "Hyväksytty",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
    [EInputs.tonsInvoice]: {
      type: EInputType.number,
      label: "Tonnit laskulta",
      value: "",
    },
    [EInputs.notes]: {
      type: EInputType.textarea,
      label: "Huomautukset",
      value: "",
      rows: 4,
    },
    [EInputs.additionalNotes]: {
      type: EInputType.textarea,
      label: "Lisä huom",
      value: "",
      rows: 4,
    },
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      label: "Tehdas",
      value: "",
      options: FactoryOptions,
    },
    [EInputs.orderNumber]: {
      type: EInputType.text,
      label: "Tilausnumero",
      value: "",
    },
    [EInputs.nakki]: {
      type: EInputType.checkbox,
      label: "Jatkorekka",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
    [EInputs.contactPersonName]: {
      type: EInputType.text,
      label: "Yhteyshenkilön nimi",
      value: "",
    },
    [EInputs.contactPersonPhone]: {
      type: EInputType.text,
      label: "Yhteyshenkilön puhelin",
      value: "",
    },
    [EInputs.contactPersonEmail]: {
      type: EInputType.text,
      label: "Yhteyshenkilön email",
      value: "",
    },
    [EInputs.type]: {
      type: EInputType.reactSelect,
      label: "Siirto",
      value: EWaybillType.PROJECT,
      options: WAYBILL_TYPE_OPTIONS,
      validation: {
        required: true,
      },
    },
    [EInputs.unloadFreeTime]: {
      type: EInputType.number,
      label: "Vapaa purkuaika h",
      value: "",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit, inputs, setInputs };
};

const useSaveOrUpdate = (id: string, isEdit: boolean) => {
  const { updateWaybill, saveWaybill } = useWaybillsService();
  const navigate = useNavigate();

  const mutationFn = (data: IWaybill) => {
    return isEdit ? updateWaybill(id, data) : saveWaybill(data);
  };

  const {
    mutateAsync: saveOrUpdate,
    isPending,
    isError,
    error,
  } = useMutation<IWaybill, ErrorResponse, IWaybill>({
    mutationFn,
    onSuccess: (data) => {
      // console.log(data)
      // queryClient.removeQueries({ queryKey: [QueryKey.waybills, id] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.waybills] });
      if (data.projectId && data.waybillRows) {
        queryClient.invalidateQueries({ queryKey: [QueryKey.elements, data.projectId] });
      }
      queryClient.setQueryData([QueryKey.waybills, id], data);
      if (!isEdit) navigate(Route.waybill(data.id), { replace: true });
    },
  });

  return { saveOrUpdate, isPending, isError, error };
};

// const useDelete = (id: string, redirectHandler: () => void) => {
//   const { deleteWaybill } = useWaybillsService();

//   const {
//     mutate: deleteMutate,
//     isPending: isDeleting,
//     isError: isDeletingError,
//     error: deletingError,
//   } = useMutation<boolean, ErrorResponse>({
//     mutationFn: () => deleteWaybill(id),
//     onSuccess: () => {
//       queryClient.invalidateQueries({
//         queryKey: [QueryKey.waybills],
//         refetchType: "none",
//       });
//       redirectHandler();
//     },
//   });

//   return { deleteMutate, isDeleting, isDeletingError, deletingError };
// };

const useListElements = (factory: EFactory, projectId: string, waybillType: EWaybillType) => {
  const { fetchElements } = useElementsService();

  const {
    data: elements,
    isLoading,
    isError,
    error: elementsError,
    refetch,
    isRefetching,
    isRefetchError,
  } = useQuery<IElement[], ErrorResponse>({
    queryKey: [QueryKey.elements, factory, projectId, waybillType],
    queryFn: ({ signal }) => fetchElements({ signal, projectId, search: { productionLineFactory: factory, waybillType } }),
    staleTime: 5000,
    enabled: !!factory && !!projectId && !!waybillType,
  });

  // console.log("useListElements", factory, projectId, elements);

  return { elements, isElementsLoading: isLoading || isRefetching, isElementsError: isError || isRefetchError, elementsError, refetchElements: refetch };
};

const useListWaybillElements = (factory: EFactory, projectId: string, waybillId: string, isEdit: boolean) => {
  const { fetchElements } = useElementsService();

  const {
    data: waybillElements,
    isLoading,
    isError,
    error: waybillElementsError,
    // refetch,
    isRefetching,
    isRefetchError,
  } = useQuery<IElement[], ErrorResponse>({
    queryKey: [QueryKey.elements, factory, projectId, waybillId],
    queryFn: ({ signal }) => fetchElements({ signal, projectId, search: { waybillId } }),
    staleTime: 5000,
    enabled: !!factory && !!projectId && !!waybillId && !!isEdit,
  });

  // console.log("useListElements", factory, projectId, elements);

  return { waybillElements, isWaybillElementsLoading: isLoading || isRefetching, isWaybillElementsError: isError || isRefetchError, waybillElementsError };
};

const useUpdateWaybillPrices = (projectId: string, waybillId: string) => {
  const { updateWaybillPrices } = useProjectsService();

  const {
    mutateAsync: updateWaybillPricesMutate,
    isPending: isUpdatingPrices,
    isError: isUpdatePricesError,
    error: updatePricesError,
  } = useMutation<IWaybillUpdatePricesDTO, ErrorResponse>({
    mutationFn: () => updateWaybillPrices(projectId, waybillId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.waybills] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.projects, projectId] });
      // update project delivery price
    },
  });

  return { updateWaybillPricesMutate, isUpdatingPrices, isUpdatePricesError, updatePricesError };
};

export default WaybillEdit;
