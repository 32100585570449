import { IOption } from "../components/ui/Input/Input";
import IAuditHistory from "./IAuditHistory";
import { ILocation } from "./IWaybill";

export type WaybillToolType = "toolsFromFactory" | "toolsToFactory";

export enum EToolType {
  LIFTING_SCISSORS = "LIFTING_SCISSORS",
  LIFTING_BEAM = "LIFTING_BEAM",
  PENDULUM = "PENDULUM",
  LIFTING_FORK = "LIFTING_FORK",
  CHAIN_SLING = "CHAIN_SLING",
  SPLIT_LIFTING_CHAIN = "SPLIT_LIFTING_CHAIN",
}

export const TOOL_TYPE_OPTIONS: IOption[] = [
  { value: EToolType.LIFTING_SCISSORS, label: "Nostosaksi" },
  { value: EToolType.LIFTING_BEAM, label: "Nosto-orsi / puomi" },
  { value: EToolType.PENDULUM, label: "Tandem-keinu / heiluri" },
  { value: EToolType.LIFTING_FORK, label: "Nostohaarukka P150" },
  { value: EToolType.CHAIN_SLING, label: "Ketjuraksit" },
  { value: EToolType.SPLIT_LIFTING_CHAIN, label: "Halkaisunnostoroput" },
];

export enum EToolLocation {
  STORAGE = "STORAGE",
  PROJECT = "PROJECT",
  WAYBILL = "WAYBILL",
}

export const TOOL_LOCATION_OPTIONS: IOption[] = [
  { value: EToolLocation.STORAGE, label: "Tehtaalla" },
  { value: EToolLocation.PROJECT, label: "Työmaa" },
  { value: EToolLocation.WAYBILL, label: "Rahtikirja" },
];

interface ITool {
  id: string;
  toolType?: EToolType;
  type: string;
  manufacturer: string;
  number: string;
  introductionDate: string;
  safeWorkingLoad: string;
  safeWorkingLoad11: string;
  safeWorkingLoad125: string;
  slingLength: string;
  innerTubeLength: string;
  innerTubeWidth: string;
  innerTubeHeight: string;
  outerTubeLength: string;
  outerTubeWidth: string;
  outerTubeHeight: string;
  weightKg: string;
  latestAuditDate: string;
  location?: EToolLocation;
  factory?: string;
  projectId?: string;
  auditHistories: IAuditHistory[];
  expirationDate: string;
  remarks: IAuditHistory[];
  uuid: string;
  locations: ILocation[];
  waybillId?: string;
  waybillDeliveryDate?: string;

  toolTypeLabel?: string;
  label?: string;
  innerTubeMeasurements?: string;
  outerTubeMeasurements?: string;
}

export default ITool;
