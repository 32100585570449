import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import ElementPrint from "../components/Elements/ElementPrint";
import ElementPrintExample from "../components/Elements/ElementPrintExample";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import Spinner from "../components/ui/Spinner/Spinner";
import { ErrorResponse } from "../custom-fetch";
import { usePublicQRCodesService } from "../services/publicQRCodes-service";
import { QueryKey } from "../services/query-keys";
import IElementDTO from "../shared/IElementDTO";
import Button from "../components/ui/Button/Button";
import { useCallback, useEffect, useState } from "react";
import { getCurrentLocation } from "../utils/location-utils";
import { EFactory } from "../shared/IOfferRow";
import { ILocation } from "../shared/IWaybill";
import { Route } from "../routes";

const ElementQRCodePage: React.FC = () => {
  const { uuid } = useParams();
  const { getElementByUuid, locationInDistance, saveLocationElement, downloadLatestAttachment } = usePublicQRCodesService();
  const [location, setLocation] = useState<ILocation>();
  const [inDistance, setInDistance] = useState(false);
  const [inDistanceLoading, setInDistanceLoading] = useState(true);
  const [saveLocationLoading, setSaveLocationLoading] = useState(false);
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  const navigate = useNavigate();

  const {
    data: element,
    isLoading,
    isError,
    error,
  } = useQuery<IElementDTO, ErrorResponse>({
    queryKey: [QueryKey.publicElements, uuid],
    queryFn: ({ signal }) => getElementByUuid({ signal, id: uuid! }),
    enabled: uuid !== "example1" && uuid !== "example2",
    staleTime: 5000,
  });

  useEffect(() => {
    if (!isLoading && !isError) {
      setInDistanceLoading(true);
      getCurrentLocation()
      .then(async (location) => {
        setLocation(location);
        try {
          setInDistance(await locationInDistance(EFactory.OKU, location));
        } catch (error) {
          console.error("Error:", error);
        }
        setInDistanceLoading(false);
      })
      .catch((error) => {
        console.error("Error in getCurrentLocation:", error);
        setInDistanceLoading(false);
      });
    }
  }, [isLoading, isError, uuid, locationInDistance]);

  const {
    mutateAsync: downloadAttachmentMutate,
  } = useMutation<Blob, ErrorResponse>({
    mutationFn: () => downloadLatestAttachment({ kind: "Element", parentId: element?.id! }),
  });

  const downloadAttachmentsHandler = useCallback(async () => {
    setAttachmentLoading(true);
    try {
      const attachment = await downloadAttachmentMutate();
      const objectUrl = URL.createObjectURL(attachment);
      window.open(objectUrl, "_blank");
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.error(error);
    }
    setAttachmentLoading(false);
  }, [downloadAttachmentMutate]);

  const saveLocationHandler = useCallback(async (hwId: string, hwName: string) => {
    setSaveLocationLoading(true);
    try {
      if (uuid && location) {
        location.x = Math.floor(Math.random() * 1000) + 1;
        location.y = Math.floor(Math.random() * 1000) + 1;
        location.hwId = hwId;
        location.hwName = hwName;
        await saveLocationElement(uuid, location);
      }
      setSaveLocationLoading(false);
    } catch (e) {
      console.error(e);
      setSaveLocationLoading(false);
    }
  }, [location, saveLocationElement, uuid]);

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isLoading || inDistanceLoading) {
    return <Spinner />;
  }

  return (
    <>
      {inDistance &&
      <div style={{ width: "350px", marginLeft: "1rem", marginBottom: "2rem"}}>
        <h3 style={{ marginBottom: "1rem"}}>Merkitset varastoon, nosturi</h3>
        <div style={{ display: "flex", width: "100%", height: "150px" }}>
          <div style={{ width: "50%", paddingRight: "25px" }}>
            <Button loading={saveLocationLoading} style={{ width: "100%", height: "100%", fontSize: "4rem", backgroundColor: "seaGreen" }} 
              onClick={()=>saveLocationHandler("OKU_nosturi_1", "OKU nosturi 1")}>1
            </Button>
          </div>
          <div style={{ width: "50%", paddingLeft: "25px"}}>
            <Button loading={saveLocationLoading} style={{ width: "100%", height: "100%", fontSize: "4rem", backgroundColor: "steelBlue" }} 
              onClick={()=>saveLocationHandler("OKU_nosturi_2", "OKU nosturi 2")}>2
            </Button>
          </div>
        </div>
      </div>
      }
      {element ? (
        <div style={{margin: "1rem", width: "350px"}}>
          {element.hasAttachments && !element.dataProtectionObject &&
          <Button onClick={downloadAttachmentsHandler} loading={attachmentLoading} style={{ width: "100%", fontSize: "4rem", backgroundColor: "steelBlue", marginBottom: "1rem"}}>
            Katso liite
          </Button>}
          <ElementPrint element={element} />
          <Button onClick={()=>navigate(Route.claimQR("add?elementId="+element.elementId))} style={{marginTop: "1rem"}}>Ilmoita poikkeama</Button>
        </div>
      ) : uuid === "example1" ? (
          <ElementPrintExample showCe={true} />
      ) : (
        uuid === "example2" && (
          <ElementPrintExample />
        )
      )}
    </>
  );
};

export default ElementQRCodePage;
