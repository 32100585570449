import { useMutation } from "@tanstack/react-query";
import { ReactNode, useCallback } from "react";
import { ErrorResponse } from "../../../custom-fetch";
import { useProductionLinesService } from "../../../services/productionLines-service";
import IProductionLine, { EProductionLineStatus } from "../../../shared/IProductionLine";
import ErrorsAlert, { combineErrors } from "../../ErrorsAlert/ErrorsAlert";
import { useConfirmModal } from "../../ui/Modal/useConfirmModal";
import { queryClient } from "../../../App";
import { QueryKey } from "../../../services/query-keys";

interface IProps {
  id: string;
  isEdit: boolean;
  status?: EProductionLineStatus;
  submitHandler: () => Promise<boolean>;
}

const ProductionLineProgressBar: React.FC<IProps> = ({ id, isEdit, status = EProductionLineStatus.DRAFT, submitHandler }) => {
  const openConfirmModal = useConfirmModal();
  // TODO: isUpdatingStatus
  const { updateStatusMutate, isUpdateStatusError, updateStatusError } = useUpdateStatus(id);

  const updateStatusHandler = useCallback(async (statusOrder: number, newStatus: EProductionLineStatus, confirmText?: string) => {
    const isConfirm = await openConfirmModal(confirmText ?? "Oletko varma, että haluat päivittää tilan?");
    if (!isConfirm) return;
    try {
      await submitHandler();
      const productionLine = await updateStatusMutate(newStatus);
      queryClient.setQueryData([QueryKey.productionLines, id], productionLine);
      // queryClient.setQueryData([QueryKey.productionLines, id], (productionLine: IProductionLine) => ({ ...productionLine, status: newStatus, statusOrder }));
    } catch (error) {
      console.error(error);
    }
  }, [id, openConfirmModal, submitHandler, updateStatusMutate]);

  const updateStatus = useCallback(async (newStatus: EProductionLineStatus) => {
    if (!isEdit) return;
    switch(newStatus) {
      case EProductionLineStatus.DRAFT:
        updateStatusHandler(0, EProductionLineStatus.DRAFT);
        break;
      case EProductionLineStatus.CONTENT_PLANNED:
        updateStatusHandler(1, EProductionLineStatus.CONTENT_PLANNED, "Oletko varma, että haluat merkitä sisällön suunnitelluksi?");
        break;
      case EProductionLineStatus.UNLOADING_PLANNED:
        updateStatusHandler(2, EProductionLineStatus.UNLOADING_PLANNED);
        break;
      case EProductionLineStatus.CASTING_PLANNED:
        updateStatusHandler(3, EProductionLineStatus.CASTING_PLANNED);
        break;
      case EProductionLineStatus.STICKERS_PRINTED:
        updateStatusHandler(4, EProductionLineStatus.STICKERS_PRINTED);
        break;
      case EProductionLineStatus.DONE:
        updateStatusHandler(5, EProductionLineStatus.DONE);
        break;
      case EProductionLineStatus.UNLOADED:
        updateStatusHandler(6, EProductionLineStatus.UNLOADED);
        break;
    }
  }, [isEdit, updateStatusHandler]);

  const errorMessages = combineErrors({
    isError: isUpdateStatusError,
    error: updateStatusError,
  });

  return (
    <>
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      <div style={{ display: "flex", gap: "0.5rem", flexWrap: "nowrap", marginBottom: "1rem" }}>
        <span style={{ alignSelf: "center" }}>Tila:</span>
        <Status bold={status === EProductionLineStatus.DRAFT} onClick={() => updateStatus(EProductionLineStatus.DRAFT)}>0. luonnos</Status>
        <Spacer />
        <Status bold={status === EProductionLineStatus.CONTENT_PLANNED} onClick={() => updateStatus(EProductionLineStatus.CONTENT_PLANNED)}>1. sisältö suunniteltu</Status>
        <Spacer />
        <Status bold={status === EProductionLineStatus.UNLOADING_PLANNED} onClick={() => updateStatus(EProductionLineStatus.UNLOADING_PLANNED)}>2. purku suunniteltu</Status>
        <Spacer />
        <Status bold={status === EProductionLineStatus.CASTING_PLANNED} onClick={() => updateStatus(EProductionLineStatus.CASTING_PLANNED)}>3. valu suunniteltu</Status>
        <Spacer />
        <Status bold={status === EProductionLineStatus.STICKERS_PRINTED} onClick={() => updateStatus(EProductionLineStatus.STICKERS_PRINTED)}>4. kuvat tulostettu</Status>
        <Spacer />
        <Status bold={status === EProductionLineStatus.DONE} onClick={() => updateStatus(EProductionLineStatus.DONE)}>5. valettu</Status>
        <Spacer />
        <Status bold={status === EProductionLineStatus.UNLOADED} onClick={() => updateStatus(EProductionLineStatus.UNLOADED)}>6. purettu</Status>
      </div>
    </>
  );
};

const Status = ({ children, bold, onClick }: { children: ReactNode; bold: boolean; onClick: VoidFunction; }) =>
  bold ? (
    <span style={{ cursor: "pointer", fontWeight: "bold" }} onClick={onClick}>
      {children}
    </span>
  ) : (
    <span style={{ cursor: "pointer" }} onClick={onClick}>{children}</span>
);

const Spacer = () => (
  <span style={{ borderLeft: "thin solid gray", height: "auto" }} />
);

const useUpdateStatus = (id: string) => {
  const { updateStatus } = useProductionLinesService();

  const {
    mutateAsync: updateStatusMutate,
    isPending: isUpdatingStatus,
    isError: isUpdateStatusError,
    error: updateStatusError,
  } = useMutation<IProductionLine, ErrorResponse, EProductionLineStatus>({
    mutationFn: (status) => updateStatus(id, status),
    onSuccess: () => {
      // TODO succes
      // queryClient.setQueryData([QueryKey.productionLines, id], (productionLine: IProductionLine) => ({ ...productionLine, status: EProductionLineStatus.CONTENT_PLANNED }));
    },
  });

  return { updateStatusMutate, isUpdatingStatus, isUpdateStatusError, updateStatusError };
};

export default ProductionLineProgressBar;
