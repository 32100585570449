import { useQuery } from "@tanstack/react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { QueryKey } from "../services/query-keys";
import ErrorsAlert, { combineErrors } from "../components/ErrorsAlert/ErrorsAlert";
import { ErrorResponse } from "../custom-fetch";
import Spinner from "../components/ui/Spinner/Spinner";
import { useWaybillsService } from "../services/waybills-service";
import IWaybill from "../shared/IWaybill";
import WaybillEdit from "../components/Waybills/WaybillEdit";
import { useMemo } from "react";

type Params = {
  id: string;
};

const WaybillPage: React.FC = () => {
  const { id } = useParams<Params>();
  const isEdit = useMemo(() => id !== "add", [id]);

  const [searchParams] = useSearchParams();
  const projectId = useMemo(() => searchParams.get("projectId"), [searchParams]);
  const transportOrderId = useMemo(() => searchParams.get("transportOrderId"), [searchParams]);
  
  const { waybill, isPending, isError, error } = useGetWaybill(id!, isEdit);
  const errorMessages = combineErrors({ isError, error });

  if (errorMessages.length > 0) {
    return <ErrorsAlert errors={errorMessages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  return (
    <>
      <WaybillEdit key={id!} id={id!} waybill={waybill} projectId={projectId} transportOrderId={transportOrderId} />
    </>
  );
};

const useGetWaybill = (id: string, isEdit: boolean) => {
  const { getWaybill } = useWaybillsService();
  
  const {
    data: waybill,
    isLoading,
    isError,
    error,
  } = useQuery<IWaybill, ErrorResponse>({
    queryKey: [QueryKey.waybills, id],
    queryFn: ({ signal }) => getWaybill({ signal, id: id! }),
    enabled: isEdit,
  });

  return { waybill: isEdit ? waybill : undefined, isPending: isLoading, isError, error };
};

export default WaybillPage;
