import { useQuery } from "@tanstack/react-query";
import { getISOWeek } from "date-fns";
import { useMemo } from "react";
import { ErrorResponse } from "../../../custom-fetch";
import { QueryKey } from "../../../services/query-keys";
import { useWeeklyProductionRowsService } from "../../../services/weeklyProductionRows-service";
import IOfferRow, { EFactory } from "../../../shared/IOfferRow";
import { SLAB_TYPE_PRODUCT_GROUP_NAMES } from "../../../shared/IProductGroup";
import IProductType from "../../../shared/IProductType";
import IWeeklyProductionRow from "../../../shared/IWeeklyProductionRow";
import ErrorsAlert, { combineErrors } from "../../ErrorsAlert/ErrorsAlert";
import PageHeading from "../../ui/PageHeading/PageHeading";
import Spinner from "../../ui/Spinner/Spinner";
import Table from "../../ui/Table/Table";
import { useProjectEditContext } from "../ProjectEdit/project-edit-context";

interface IProps {
  factory?: EFactory;
  showProjects?: boolean;
}

const MAX_CAPACITY = 2100;

/**Check if sold amount equals planned amount*/
const checkIsPlanned = (projectId: string, offerRow: IOfferRow, rows: IWeeklyProductionRow[]) => {
  let productionCount = 0;
  let productionCapacity = 0;
  let productionLinealMeters = 0;
  let productionGrossArea = 0;
  let productionCubicMeters = 0;
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    if (projectId === row.projectId && (row.productType as IProductType)?.id === offerRow.productTypeId && row.factory === offerRow.factory) {
      productionCount += +(row.count ?? 0);
      productionCapacity += +(row.grossArea ?? 0);
      productionLinealMeters += +(row.linealMeters ?? 0);
      productionGrossArea += +(row.grossArea ?? 0);
      productionCubicMeters += +(row.cubicMeters ?? 0);
    }
  }
  // console.log("offerRow", name, "productTypeId", productTypeId, "count", count, "productionCount", productionCount);
  const countMatch = +offerRow.count === productionCount;
  const linealMetersMatch = +offerRow.linealMeters === productionLinealMeters;
  const areaMatch = +offerRow.netArea === productionGrossArea;
  const cubicMetersMatch = +offerRow.cubicMeters === productionCubicMeters;
  return {
    isPlanned: countMatch && linealMetersMatch && areaMatch && cubicMetersMatch,
    productionCount,
    isMaxCapacity: productionCapacity >= MAX_CAPACITY,
    productionLinealMeters,
    productionGrossArea,
    productionCubicMeters,
    countMatch,
    linealMetersMatch,
    areaMatch,
    cubicMetersMatch,
  };
}

const WeeklyProductionRowsTotalTable: React.FC<IProps> = ({ factory, showProjects }) => {
  const { project, weeklyProductionRows: projectRows } = useProjectEditContext();
  const { weeklyProductionRows, isFetching, isFetchError, fetchError } = useFetch(factory);

  const factories = useMemo(() => project.offer?.rows?.map(row => row.factory), [project.offer?.rows]);
  const filteredRows = useMemo(() => factories ? weeklyProductionRows?.filter(row => factories?.includes(row.factory)) : weeklyProductionRows, [factories, weeklyProductionRows]);

  if (isFetching) {
    return <Spinner />;
  }

  const errorMessages = combineErrors({ isError: isFetchError, error: fetchError });
  return (
    <>
      <PageHeading variant="h3" noHorizontalMargin>Suunnittelu viikottain (viikko {getISOWeek(new Date())})</PageHeading>
      {errorMessages?.length > 0 && <ErrorsAlert errors={errorMessages} />}
      {project.offer && (
        <Table>
          <thead>
            <tr>
              <th style={{ width: "200px" }}>Myyty / työmaa {project.projectNumber}</th>
              <th style={{ width: "80px" }}>TR2</th>
              <th style={{ width: "80px" }}>tehdas</th>
              <th style={{ width: "80px" }}>kpl</th>
              <th style={{ width: "80px" }}>jm</th>
              <th style={{ width: "80px" }}>m²</th>
              <th style={{ width: "80px" }}>m³</th>
            </tr>
          </thead>
          <tbody>
            {[...project.offer?.rows ?? []].filter(row => SLAB_TYPE_PRODUCT_GROUP_NAMES.includes(row?.productGroupName ?? "")).map((row) => {
              const { isPlanned, productionCount, productionLinealMeters, productionGrossArea, productionCubicMeters, countMatch, linealMetersMatch, areaMatch, cubicMetersMatch } = checkIsPlanned(project.id, row, projectRows ?? []);
              return (
                <tr key={row.id} style={isPlanned ? { background: "#94da77" } : {}}>
                  <td></td>
                  <td>{row.productTypeName}</td>
                  <td>{row.factory}</td>
                  <td style={countMatch ? {} : { background: "red" }}>{productionCount} / {row.count}</td>
                  <td style={linealMetersMatch ? {} : { background: "red" }}>{productionLinealMeters} / {row.linealMeters}</td>
                  <td style={areaMatch ? {} : { background: "red" }}>{productionGrossArea} / {row.netArea}</td>
                  <td style={cubicMetersMatch ? {} : { background: "red" }}>{productionCubicMeters} / {row.cubicMeters}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      <Table>
        <thead>
          <tr>
            <th style={{ width: "200px" }}>tuotantoviikko</th>
            <th style={{ width: "80px" }}>TR2</th>
            <th style={{ width: "80px" }}>tehdas</th>
            <th style={{ width: "80px" }}>kpl</th>
            <th style={{ width: "80px" }}>jm</th>
            <th style={{ width: "80px" }}>m²</th>
            <th style={{ width: "80px" }}>m³</th>
          </tr>
        </thead>
        <tbody>
          {filteredRows?.map((row) => (
            <tr key={row.id}>
              <td>{row.productionWeek}</td>
              <td>{(row.productType as IProductType)?.name}</td>
              <td>{row.factory}</td>
              <td>{row.count}</td>
              <td>{row.linealMeters}</td>
              <td>{row.grossArea}</td>
              <td>{row.cubicMeters}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

const useFetch = (factory?: string) => {
  const { fetchWeeklyProductionRows } = useWeeklyProductionRowsService();
  const queryKey = factory ? [QueryKey.weeklyProductionRows, factory] : [QueryKey.weeklyProductionRows];
  const {
    data: weeklyProductionRows,
    isPending: isFetching,
    isError: isFetchError,
    error: fetchError,
  } = useQuery<IWeeklyProductionRow[], ErrorResponse>({
    queryKey,
    queryFn: ({ signal }) => fetchWeeklyProductionRows({ signal, projectId: "", jsonBody: { factory } }),
    staleTime: 5000,
  });

  return { weeklyProductionRows, isFetching, isFetchError, fetchError };
};

export default WeeklyProductionRowsTotalTable;
