import { useNavigate } from "react-router-dom";
import Button, { EButtonColor } from "../Button/Button";
import { useConfirmModal } from "../Modal/useConfirmModal";
import { CSSProperties, ReactNode } from "react";


interface IProps {
  onSubmit?: () => void;
  isLoading: boolean;
  onDelete?: () => void;
  deleteConfirmMessage?: string;
  isDeleting?: boolean;
  submitDisabled?: boolean;
  submitText?: string;
  style?: CSSProperties;
  onReturn?: () => void;
  children?: ReactNode;
  hideReturn?: boolean;
}

const FormButtons: React.FC<IProps> = ({ onSubmit, isLoading, onDelete, isDeleting, deleteConfirmMessage, submitDisabled, submitText, style, onReturn, children, hideReturn }) => {
  const navigate = useNavigate();
  const openConfirmModal = useConfirmModal();

  const deleteHandler = async () => {
    const success = await openConfirmModal(deleteConfirmMessage);
    if (success && onDelete) {
      onDelete();
    }
  }
  return (
    <div style={{ display: 'flex', gap: '1rem', ...style }}>
      {onSubmit && (
        <Button onClick={onSubmit} loading={isLoading} color={EButtonColor.SUCCESS} disabled={submitDisabled}>{submitText ?? "Tallenna"}</Button>
      )}
      {children}
      {!hideReturn && <Button onClick={onReturn ? onReturn : () => navigate(-1)} color={EButtonColor.SECONDARY}>Takaisin</Button>}
      {onDelete && (
        <Button onClick={deleteHandler} loading={isDeleting} color={EButtonColor.DANGER}>Poista</Button>
      )}
    </div>
  )

}

export default FormButtons;