import IElement from "../../../shared/IElement";
import PrintCheckbox from "../../PrintCheckbox/PrintCheckbox";

interface IProps {
  element: IElement;
}

const ProjectElement: React.FC<IProps> = ({ element }) => {
  return (
    <tr>
      <td></td>
      <td>{element.slabType?.typeName}</td>
      <td style={{ width: "40px" }}>{element.tendonCountThick}</td>
      <td style={{ width: "40px" }}>{element.tendonCountThin}</td>
      <td>{element.name}</td>
      <td>{element.count}</td>
      <td>{element.length}</td>
      <td>{element.lengthInMeters}</td>
      <td>{element.provisionSummary?.hasSplit && element.width}</td>
      <td style={{ width: "40px" }}><PrintCheckbox checked={!!element.provisionSummary?.hasSlantedLeft}/></td>
      <td style={{ width: "40px" }}><PrintCheckbox checked={!!element.provisionSummary?.hasSlantedRight}/></td>
      <td>{element.provisionAmountShort}</td>
      <td>{element.weightTons}</td>
      <td>{element.deliveryWeek}</td>
      <td>{element.grossArea}</td>
      <td>{element.area}</td>
    </tr>
  );
};

export default ProjectElement;
